import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import ListIcon from "@mui/icons-material/List";
import { useDispatch } from "react-redux";
import { updateFoodCategoryByOrder } from "services/foodCategory.service";
import { fetchCategoryList } from "state/thunk-async";
import { useTranslation } from "react-i18next";

const PREFIX = 'PriorityFoodCategory';

const classes = {
  header: `${PREFIX}-header`,
  header2: `${PREFIX}-header2`,
  btn: `${PREFIX}-btn`
};

const StyledCard = styled(Card)({
  [`& .${classes.header}`]: {
    marginLeft: "20px",
    fontFamily: "Roboto",
    fontSize: "18px",
  },
  [`& .${classes.header2}`]: {
    float: "left",
    position: "relative",
    left: "-35px",
    fontSize: "15px",
  },
  [`& .${classes.btn}`]: {
    position: "absolute",
    right: "35px",
    top: "15px",
    height: "30px",
  },
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  color: "#000",
  padding: "10px",
  margin: `0 0 ${grid}px 0`,
  border: "1px solid",
  borderColor: isDragging ? "#ffc008" : "#10ab55",
  ...draggableStyle,
});

const PriorityFoodCategory = (props) => {

  const { foodCategoryDataList, drawerClose } = props;
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setItems(foodCategoryDataList);
  }, []);

  const onSubmit = () => {
    setSubmittingBtnProcess(true);
    updateFoodCategoryByOrder(items)
      .then((res) => {
        if (res) {
          setSubmittingBtnProcess(false);
          toast.success(t("food-category-priority-update-toast-message"));
          dispatch(fetchCategoryList());
          drawerClose();
        } else {
          toast.error(t("food-category-priority-failed-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(t("food-category-priority-failed-toast-message"));
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    console.log({ reorderedItems });
    setItems(reorderedItems);
  };

  return (
    <StyledCard>
      <Card.Body>
        <Container fluid>
          <Card.Title>
            <h5>{t("food-category-title")}</h5>
          </Card.Title>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={onSubmit}
            color="primary"
            type="submit"
            style={{
              background: "",
              color: "#fff",
            }}
            startIcon={<SaveIcon />}
          >
            {submittingBtnProcess ? t("processing") : t("save")}
          </Button>
          <hr />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item?.name + item?.id}
                      draggableId={item?.name + item?.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="card"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Container fluid>
                            <Row className="mt-1 mb-1">
                              <Col>
                                <ListIcon /> {item?.name}
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <hr />
        </Container>
      </Card.Body>
    </StyledCard>
  );
};

export default PriorityFoodCategory;

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { Button, FormControl, Checkbox, InputLabel } from "@mui/material";
import { Save as SaveIcon, ArrowBackIos } from "@mui/icons-material";
import { Form, Field } from "react-final-form";
import {
  createCustomerQueue,
  getCustomerQueueById,
  updateCustomerQueue,
} from "services/customer-queue.service";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { useInMobile } from "themes/Device";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";

const PREFIX = 'AddEditCustomerQueue';

const classes = {
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  selectBoxControl: `${PREFIX}-selectBoxControl`,
  root: `${PREFIX}-root`,
  buttonPosition: `${PREFIX}-buttonPosition`,
  footerButton: `${PREFIX}-footerButton`,
  btnSection: `${PREFIX}-btnSection`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    padding: "10px",
  },

  [`& .${classes.selectBoxControl}`]: {
    width: "100%",
  },

  [`& .${classes.btnSection}`]: {
    justifyContent: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '6px',
    background: '#fff'

  },

  [`& .${classes.root}`]: {
    width: "100%",
    [theme.breakpoints.down('md')]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },

  [`& .${classes.buttonPosition}`]: {
    display: "flex",
    justifyContent: "end",
  },

  [`& .${classes.footerButton}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    position: "absolute",
    bottom: "0",
    left: "0",
    padding: "0 15px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      display: "flex",
      position: "relative",
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      padding: "0 25px",
    },
  }
}));

const AddEditCustomerQueue = (props) => {

  const param = useParams();
  const { drawerClose, CustomerQueueList } = props;
  const [customerQueueId, setCustomerQueueId] = useState(props.customerQueueId);
  const [initialCustomerQueueData, setInitialCustomerQueueData] = useState();
  const isNew = customerQueueId > 0 || param?.id > 0 ? false : true;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const isMobileView = useInMobile();
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  const onSubmit = (values) => {
    var reqPayload = {
      name: values?.name,
      no_of_person: values?.no_of_person,
      mobile: values?.mobile,
      present_date_time: moment(values?.present_date_time),
      note: values?.note,
    };
    setSubmittingBtnProcess(true);
    if (isNew) {
      createCustomerQueue(reqPayload)
        .then((res) => {
          if (res?.status === 200 || res?.data) {
            setSubmittingBtnProcess(false);
            toast.success(res.data.message);
            CustomerQueueList();
            drawerClose();
          } else {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
          }
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.success(err?.message);
        });
    } else {
      updateCustomerQueue(customerQueueId, reqPayload)
        .then((res) => {
          if (res?.data) {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
            CustomerQueueList();
            drawerClose();
          } else {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
          }
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.success(err?.message);
        });
    }
  };

  useEffect(() => {
    if (param && isMobileView) {
      if (param.id > 0) {
        setCustomerQueueId(param.id);
      }
      let screenTitle = "";
      if (param.id > 0) {
        screenTitle = t("customer-queue-edit-title");
      } else {
        screenTitle = t("customer-queue-add-title");
      }

      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
    if (customerQueueId > 0) {
      getCustomerQueueById(customerQueueId)
        .then((res) => {
          setInitialCustomerQueueData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [customerQueueId]);

  return (
    (<Root>
      <div className={classes.root}>
        {customerQueueId > 0 && !initialCustomerQueueData ? (
          <SkeletonLoader count={5} />
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={initialCustomerQueueData}
            validate={(values) => {
              const errors = {};
              // const checkerror=checkSpaceAndRegex(values?.name)
              // if(checkerror!='')
              // errors.name = checkerror;
              if (!values?.name?.trim()) {
                errors.name = t("customer-queue-name-validation-message");
              }
              if (values?.name?.length > 50) {
                errors.name = t("maximumlength-validation-message");
              }

              if (!values?.mobile) {
                errors.mobile = t("phone-number-error-message");
              } else if (isNaN(values?.mobile)) {
                errors.mobile = t("phone-number-validation-message");
              } else if (values.mobile?.length !== 10) {
                errors.mobile = t("phone-number-digit-validation-message");
              }

              if (!values?.no_of_person) {
                errors.no_of_person = t(
                  "customer-queue-person-required-error-message",
                );
              } else if (isNaN(values?.no_of_person)) {
                errors.no_of_person = t("customer-queue-person-error-message");
              } else if (values?.no_of_person?.length > 5) {
                errors.no_of_person = t(
                  "customer-queue-person-validation-message",
                );
              }

              return errors;
            }}
            render={({ handleSubmit, form, submitting, values }) => (
              <form onSubmit={handleSubmit} className="mt-2">
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="name">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="text"
                            variant="outlined"
                            label={`${t("customer-name-label")}${"*"}`}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="mobile">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="number"
                            variant="outlined"
                            label={`${t("phone-label")}${"*"}`}
                            error={
                              (meta.touched || meta.visited) && meta.error
                                ? true
                                : false
                            }
                          />
                          {(meta.touched || meta.visited) && meta.error && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="no_of_person">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="number"
                            variant="outlined"
                            label={`${t("number-of-person-label")}${"*"}`}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="note">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            {...input}
                            type="text"
                            variant="outlined"
                            label={t("customer-queue-addition-note-label")}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        name="present_date_time"
                        defaultValue={moment().format("DD/MM/YYYY HH:MM")}
                        initialValue={moment().format("DD/MM/YYYY HH:MM")}
                      >
                        {({ input, meta }) => (
                          <FormControl >
                          {console.log('input.value', input.value)}
                            <DateTimePicker
                              label="Customer available time"
                              inputVariant="outlined"
                              value={input.value}
                              // selected={new Date()}
                              onChange={input.onChange}
                              format="dd-MM-yyyy hh:mm a"
                              // {...input}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </MuiPickersUtilsProvider> */}
                <Row className="m-0">
                  <Col s={12} sm={12} md={12}>
                    <Field name="present_date_time">
                      {({ input, meta }) => (
                        <FormControl className={classes.formcontrolwidth}>
                          <TextInputMui
                            label={t("customer-queue-available-time-label")}
                            type="datetime-local"
                            variant="outlined"
                            value={moment(input.value).format(
                              "yyyy-MM-DDThh:mm",
                            )}
                            onChange={input.onChange}
                            defaultValue={moment().format("yyyy-MM-DDThh:mm")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row className="m-0">
                  {customerQueueId > 0 && (
                    <Col xs={12} sm={12} md={12}>
                      <Field
                        name="is_waiting"
                        type="checkbox"
                        value={Boolean(!values?.is_waiting)}
                      >
                        {({ input, meta }) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              color="primary"
                              {...input}
                              style={{ paddingLeft: "0px" }}
                            />
                            <InputLabel
                              htmlFor="is_waiting"
                              style={{ marginBottom: "0px" }}
                            >
                              {t("customer-queue-input-label")}
                            </InputLabel>
                          </div>
                        )}
                      </Field>
                    </Col>
                  )}
                  <Col xs={12} sm={12} md={12}>
                    <div className={classes.btnSection}>
                      <div className="d-flex">
                        <div className="ml-2">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            type="button"
                            onClick={() => {
                              if (isMobileView) history.push("/app/customer-queue");
                              else if (!isMobileView && drawerClose) drawerClose();
                            }}
                            startIcon={<ArrowBackIos />}
                          >
                            {t("back")}
                          </Button>
                        </div>
                        <div className="ml-2">
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                            disabled={submitting}
                            style={{
                              background: "",
                              color: "#fff",
                            }}
                            startIcon={<SaveIcon />}
                          >
                            {submittingBtnProcess ? t("processing") : t("save")}
                          </Button>
                        </div>
                      </div>
                    </div>

                  </Col>
                </Row>
              </form>
            )}
          />
        )}
      </div>
    </Root>)
  );
};

export default AddEditCustomerQueue;

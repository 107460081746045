import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { MailOutlineSharp, NotificationImportant } from "@mui/icons-material";
import { useTheme, Checkbox } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Image from "../../components/Image";
import clsx from "clsx";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { Alert } from "react-bootstrap";
import { adminShopViaCreate } from "services/login.service";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "services/user.service";
import { SetUserInfo } from "state/actions/user.action";
import { SetUserPermission } from "state/actions/user.action";
import { t } from "i18next";
import { updateShopInfo } from "services/shop.service";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function OnBoarding(props) {

  function getToken() {
    return localStorage.getItem("token_onboading");
  }
  const mdUp = useResponsive("up", "md");
  const classes = useStyles();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const handleShopCreate = (values) => {
    setSubmittingBtnProcess(true);
    const object = {
      email_notification: true,
      email_notification_time: 3
    };
    if (values?.email)
      object.email = values?.email
    else
      object.email = userInfo?.user?.email

    if (values?.name)
      object.name = values?.name

    if (values?.phone)
      object.phone = values?.phone
    else
      object.phone = userInfo?.user?.phone

    updateShopInfo(object)
      .then((res) => {
        if (res?.data?.status == 200 || res?.data?.statusCode == 200) {
          setSubmittingBtnProcess(false);
          toast.success(res?.data?.message);
          if (res?.data?.status !== 400) {
            props.history.push({
              pathname: `/`,
            });
          }
          else {
            props.history.push({
              pathname: `/login`
            });
          }
        } else {
          setSubmittingBtnProcess(false);
          toast(res?.data?.message, {
            icon: '☹️',
          });
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
      });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (getToken()) {
      localStorage.setItem("token", localStorage.getItem("token_onboading"));
      localStorage.removeItem("token_onboading")
      getUserInfo()
        .then((res) => {
          dispatch(SetUserInfo(res?.data?.data));
          dispatch(SetUserPermission(res?.data?.data?.permissions));
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const userInfo = useSelector((state) => state.user.userData);
  return (
    <Page title="OnBoarding">
      <Alert variant="warning" className="online-offline-alert">
        <NotificationImportant /> <span>
          You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
        </span>
      </Alert>
      <RootStyle>
        <HeaderStyle>
          <Box>
            <Image
              disabledEffect
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
              }
              sx={{ width: 50, height: 50 }}
              className={classes.img}
            />
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5, fontSize: "2rem" }}
            >
              Manage the resturant more effectively with ServoFeat
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontSize: "1.5rem" }}
                >
                  Update Basic Information
                </Typography>
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleShopCreate}
              validate={(values) => {
                const errors = {};
                const emailRegex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if (values.name) {
                  if (!values.name?.trim()) {
                    errors.name = t("restaurant-validation-message");
                  } else if (values?.name?.length > 50) {
                    errors.name = t("maximumlength-validation-message");
                  }
                }
                if (values?.email) {
                  if (values?.email && values?.email?.length > 50) {
                    errors.email = t("maximumlength-validation-message");
                  } else if (values?.email && !emailRegex.test(values?.email)) {
                    errors.email = t("email-invalid-validation-message");
                  }
                }
                if (values?.phone) {
                  if (!values?.phone) {
                    errors.phone = t("phone-number-error-message");
                  } else if (isNaN(values?.phone)) {
                    errors.phone = t("phone-number-validation-message");
                  } else if (values?.phone?.length !== 10) {
                    errors.phone = t("phone-number-digit-validation-message");
                  }
                }
                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={2}>
                      <Stack>
                        <Field name="name">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              label="Shop Owner Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error
                                && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>
                      {
                        (!userInfo?.user?.email) &&
                        <Stack>
                          <Field name="email">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldUnderline,
                                )}
                                label="Email"
                                type="text"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>

                      }
                      {
                        (!userInfo?.user?.phone) &&
                        <Stack>
                          <Field name="phone">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                className={clsx(
                                  classes.textField,
                                  classes.textFieldUnderline,
                                )}
                                label="Phone No"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>
                      }
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submittingBtnProcess}
                      >
                        {submittingBtnProcess ? "Processing.." : "Update Information"}
                      </LoadingButton>
                      <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        type="button"
                        onClick={() => props.history.push("/")}
                      >
                        Skip
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page >
  );
}

import { makeStyles } from 'tss-react/mui';

const drawerWidth = 240;

export default makeStyles()(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    boxShadow: '1px 3px 20px 0px rgb(23 162 184)',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: "100!important",
    position: "absolute"
  },
  drawerOpen: {
    // width: drawerWidth,
    width: '300px',
    boxShadow: '1px 3px 20px 0px rgb(23 162 184)',
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'none',
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down('md')]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebarIcon: {

  },
  /*sidebarList: {
    marginTop: theme.spacing(6),
  }, */ 
  developmentsection: {
    padding: '16px',
    position: 'absolute',
    background: '#d6ffd6',
    left: 0,
    right: 0,
    bottom: 0
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  IconButton, Button
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
// import { Row, Col, Container, Badge } from "react-bootstrap";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import salePrice from "../../assets/img/sale-price.png";
import "./MobileSalesHistory.styles.css";
// import MenuButtonEditDelete from "components/MenuButtonEditDelete";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import {
  Delete,
  Done,
  Edit,
  ViewList,

  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { makeShortText } from "utility/makeShortText";
import { useTranslation } from "react-i18next";
import { Card, Stack } from "@mui/material";
import Label from "components/Label";
import MenuButtonEditDelete from "components/MenuButtonEditDelete/MenuButtonEditDelete";
const PREFIX = 'MobileSalesHistory';

const classes = {
  customerHeader: `${PREFIX}-customerHeader`,
  date: `${PREFIX}-date`,
  orderType: `${PREFIX}-orderType`,
  iconSize: `${PREFIX}-iconSize`,
  badge: `${PREFIX}-badge`,
  orderlbl: `${PREFIX}-orderlbl`,
  visibilityicon: `${PREFIX}-visibilityicon`,
  createddate: `${PREFIX}-createddate`,
  editDeleteButtonBlock: `${PREFIX}-editDeleteButtonBlock`,
  mobileDesignCard: `${PREFIX}-mobileDesignCard`,
  cardTitle: `${PREFIX}-cardTitle`,
  totalbill: `${PREFIX}-totalbill`,
  eye: `${PREFIX}-eye`,
  orderCard: `${PREFIX}-orderCard`
};

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`& .${classes.customerHeader}`]: {
    backgroundColor: "#eee",
    borderRadius: "9px",
    borderColor: "#d7d7d7 !important",
    borderWidth: "1px",
    boxShadow: "0px !important",
    display: "flex",
    height: "45px",
    minHeight: "45px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      height: "45px",
    },
  },

  [`& .${classes.date}`]: {
    marginLeft: "185px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    marginBottom: "0",
  },

  [`& .${classes.orderType}`]: {
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#636363",
  },

  [`& .${classes.iconSize}`]: {
    fontSize: "1rem",
    padding: "0.5rem",
  },

  [`& .${classes.badge}`]: {
    fontStyle: "normal",
    fontSize: "inherit",
    marginTop: "2px !important",
  },

  [`& .${classes.orderlbl}`]: {
  },

  [`& .${classes.visibilityicon}`]: {
    right: "5px",
    position: "absolute",
    fontSize: "28px",
  },

  [`& .${classes.createddate}`]: {
    fontSize: "12px",
  },

  [`& .${classes.editDeleteButtonBlock}`]: {
    display: "flex",
    padding: "12px",
    justifyContent: "space-between",
    alignItems: "center"
  },

  [`&.${classes.mobileDesignCard}`]: {
    boxShadow: "-1px 1px 3px 2px #eee!important",
    borderRadius: "13px!important",
    // border: "1px solid rgb(0 0 0 / 27%)!important",
    marginBottom: "15px"
  },

  [`& .${classes.cardTitle}`]: {
    display: "flex",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    width: "25%",
  },

  [`& .${classes.totalbill}`]: {
    display: "flex",
    width: "30%",
  },

  [`& .${classes.eye}`]: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    marginBottom: "0",
    width: "10%",
    right: "6px",
  },

  [`& .${classes.orderCard}`]: {
    padding: '7px 7px'
  }
}));

const MobileSalesHistory = (props) => {
  const {
    orderId,
    deleteData,
    // editData,
    itemId,
    customerName,
    createdDate,
    updatedDate,
    order_type,
    totalBill,
    tableNo,
    viewData,
    // printData,
    orderDetail,
  } = props;

  let history = useHistory();
  const { t } = useTranslation();

  return (
    <StyledCard
      style={{ border: orderDetail?.is_complete ? '2px solid #1b5e20' : '1px solid blue', color: orderDetail?.is_complete ? '#1b5e20' : '' }}
      className={classes.mobileDesignCard}>
      <Stack
        gap={1}
        className={classes.orderCard}

        onClick={() => {
          if (orderDetail.is_complete) return;
          orderDetail?.orderDetail?.order_type === "PARCEL"
            ? history.push({
              pathname:
                orderDetail?.id === 0 || orderDetail?.id == null
                  ? `/app/order/parcel/`
                  : `/app/order/parcel/${orderDetail?.id}`,
              tableId: "PARCEL",
            })
            : history.push({
              pathname:
                orderDetail?.id === 0 || orderDetail?.id == null
                  ? `/app/dine-in/order/`
                  : `/app/dine-in/order/${orderDetail?.id}`,
              tableId: orderDetail?.tableId,
            });
        }}>
        <Stack gap={2} direction="row" className="align-items-center justify-content-between">
          <div>
            <strong>TOKEN No:</strong>&nbsp; {orderDetail?.token_no}
          </div>
          <div>
            <strong>{t("order-label")}:- </strong>
            <Label color="info">
              {orderId}
            </Label>
          </div>
          <div className={classes.customername}>
            {customerName && makeShortText(customerName)}
          </div>
          <div style={{ fontSize: '18px' }}>{totalBill}</div>
        </Stack>
        <Stack gap={2} direction="row" className="justify-content-between">
          <Stack gap={0.5} direction="row" className="justify-content-between">
            <div className={classes.orderType}>
              {
                (tableNo && order_type !== "PARCEL") && <>
                  {t("table-no-label")}: <span>&nbsp; {tableNo}</span> </>}&nbsp; 
              ( &nbsp;<span>{order_type}</span>  &nbsp;)
            </div>
            {orderDetail?.is_complete && <Label color="success" style={{marginLeft: '10px'}}>{orderDetail?.is_complete ? "COMPLETED" : ""}</Label>}
          </Stack>

        </Stack>
        <Stack gap={0.5} direction="row" className="justify-content-between">
          <div className={classes.createddate}>Created: {createdDate}</div>
          <div className={classes.createddate}>Updated On: {updatedDate}</div>
        </Stack>

      </Stack>
      <hr style={{ margin: '0px', color: "#000" }} />
      <div
        className={classes.editDeleteButtonBlock}
      >
        <RoleBasedGuard
          resource={RESOURCE.SALES}
          action={ACTION.DELETE}
        >
          <Button
            color="secondary"
            title="Delete Customer"
            size="small"
            variant="outlined"
            aria-label="Delete Customer"
            onClick={() => {
              deleteData(itemId)
            }}
            style={{
              color: '#8b0000b8',
              border: '1px solid #6c0707',
              marginLeft: '10px'
            }}
          >
            <Delete
            />{" "}
            {t("delete")}
          </Button>
        </RoleBasedGuard>
        <RoleBasedGuard
          resource={RESOURCE.SALES}
          action={ACTION.SETTLE || ACTION.VIEW}
        >
          <Button
            variant="contained"
            color={orderDetail?.is_complete ?  "primary" : "secondary" }
            size="small"
            startIcon={orderDetail?.is_complete ?   <ViewList /> : <Done /> }
            onClick={() =>  viewData(orderDetail)}
          >
           { orderDetail?.is_complete ?  "View"  : "Settlement"}
          </Button>
        </RoleBasedGuard>
        {/* <RoleBasedGuard
          resource={RESOURCE.SALES}
          action={ACTION.VIEW}
        >
          <Button
            color="secondary"
            aria-label="Edit Customer"
            onClick={() => {
              viewData(orderDetail);
            }}
            size="small"
            variant="outlined"
          >
            <ViewList />
            View
          </Button>
        </RoleBasedGuard> */}
        <RoleBasedGuard
              resource={RESOURCE.SALES}
              action={ACTION.VIEW}
            >
              <Button
                color="primary"
                aria-label="Edit Customer"
                onClick={() => {
                  orderDetail?.orderDetail?.order_type === "PARCEL"
                    ? history.push({
                      pathname:
                        orderDetail?.id === 0 || orderDetail?.id == null
                          ? `/app/order/parcel/`
                          : `/app/order/parcel/${orderDetail?.id}`,
                      tableId: "PARCEL",
                    })
                    : history.push({
                      pathname:
                        orderDetail?.id === 0 || orderDetail?.id == null
                          ? `/app/dine-in/order/`
                          : `/app/dine-in/order/${orderDetail?.id}`,
                      tableId: orderDetail?.tableId,
                    });
                }}
                size="small"
                variant="outlined"
                style={{
                  marginLeft: '10px'
                }}
              >
                <Edit
                />
                {t("edit")}
              </Button>
            </RoleBasedGuard>
      </div>
    </StyledCard>
  );
};

export default MobileSalesHistory;

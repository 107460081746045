import { Stack } from "@mui/material";
import Card from "components/Card/Card";
import Label from "components/Label";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import moment from "moment";
import React from "react";
import "./MobileRole.styles.css";

const MobileRole = (props) => {
  const { name, deleteData, editData, itemId, resource, created_date } = props;

  return (
    <Card className="cardCategory">
      <Stack gap={2} direction="row" className="mt-1">
        <Stack >
          <h6 className="categoryName">{name}</h6>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" className="mt-1" justifyContent={"space-between"} alignItems="center">
        <Stack>
          <Label color="success">
            Created Date: {moment(created_date).format("DD/MM/YYYY")}
          </Label>
        </Stack>
        <Stack>
          <MenuButtonEditDelete
            resource={resource}
            deleteClick={deleteData}
            editClick={editData}
            itemId={itemId}
            isMenuBar={props?.isMenuBar}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default MobileRole;

import React from "react";
import { styled } from '@mui/material/styles';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButtonComponent from "components/IconButtonComponent";
import { Button, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION } from "common/constant";
import CheckPermission from "guards/CheckPermission";

const PREFIX = 'MenuButtonEditDelete';

const classes = {
  buttonStyle: `${PREFIX}-buttonStyle`,
  mainButton: `${PREFIX}-mainButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.buttonStyle}`]: {
    padding: "6px !important",
    minHeight: "30px !important",
  },

  [`& .${classes.mainButton}`]: {
    marginLeft: "auto",
  }
}));

const MenuButtonEditDelete = (props) => {

  const { deleteClick, editClick, itemId,
    deleteByname, isEdit, isDelete, isMenuBar, resource } =
    props;
  console.log("resource", resource)
  console.log(CheckPermission(resource, ACTION.DELETE));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root className="d-flex">
      {
        isMenuBar ?
          <>
            <div className="d-flex ml-auto">
              {deleteByname ? (
                <RoleBasedGuard
                  resource={resource}
                  action={ACTION.DELETE}
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="outlined"
                    title="Delete Customer"
                    aria-label="Delete Customer"
                    onClick={() => {
                      deleteClick(deleteByname);
                    }}
                  >
                    <Delete
                    />{" "}
                    {t("delete")}
                  </Button>
                </RoleBasedGuard>
              ) : (
                  <RoleBasedGuard
                    resource={resource}
                    action={ACTION.DELETE}
                  >
                    <Button
                      color="secondary"
                      title="Delete Customer"
                      size="small"
                      variant="outlined"
                      aria-label="Delete Customer"
                      onClick={() => {
                        deleteClick(itemId);
                      }}
                      style={{
                        color: '#8b0000b8',
                        border: '1px solid #6c0707',
                      }}
                    >
                      <Delete
                      />{" "}
                      {t("delete")}
                    </Button>
                  </RoleBasedGuard>
              )}
              <RoleBasedGuard
                resource={resource}
                action={ACTION.UPDATE}
              >
                <Button
                  color="primary"
                  aria-label="Edit Customer"
                  onClick={() => {
                    editClick(itemId);
                  }}
                  size="small"
                  variant="outlined"
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  <Edit
                  />
                  {t("edit")}
                </Button>
              </RoleBasedGuard>
            </div>
          </>
          :
          <>
            <IconButtonComponent
              onClick={handleClick}
              style={{ marginLeft: "auto", padding: "0px" }}
            >
              <MoreVertIcon />
            </IconButtonComponent>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem className={classes.buttonStyle} onClick={handleClose}>
                {deleteByname ? (
                  <RoleBasedGuard
                    resource={resource}
                    action={ACTION.DELETE}
                  >
                    <Button
                      color="secondary"
                      title="Delete Customer"
                      aria-label="Delete Customer"
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        deleteClick(deleteByname);
                      }}
                      style={{
                        color: '#8b0000b8',
                        border: '1px solid #6c0707',
                      }}
                    >
                      <Delete
                      />{" "}
                      {t("delete")}
                    </Button>
                  </RoleBasedGuard>
                ) : (
                    <RoleBasedGuard
                      resource={resource}
                      action={ACTION.DELETE}
                    >
                      <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        title="Delete Customer"
                        aria-label="Delete Customer"
                        onClick={() => {
                          deleteClick(itemId);
                        }}
                        style={{
                          color: '#8b0000b8',
                          border: '1px solid #6c0707',
                        }}
                      >
                        <Delete
                        />{" "}
                        {t("delete")}
                      </Button>
                    </RoleBasedGuard>
                )}
              </MenuItem>
              <MenuItem className={classes.buttonStyle} onClick={handleClose}>
                <RoleBasedGuard
                  resource={resource}
                  action={ACTION.UPDATE}
                >
                  <IconButton
                    color="primary"
                    aria-label="Edit Customer"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      editClick(itemId);
                    }}
                  >
                    <Edit
                    />
                    {t("edit")}
                  </IconButton>
                </RoleBasedGuard>
              </MenuItem>
            </Menu></>
      }

    </Root>
  );
};

export default MenuButtonEditDelete;

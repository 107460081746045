import { Stack, Card } from "@mui/material";
import { styled } from '@mui/material/styles';
import { LoadingButton } from "@mui/lab";
import { Form, Field } from "react-final-form";
import { toast } from "react-hot-toast";
import { Button, Container, FormControl } from "@mui/material";
import { useState } from "react";
import TextInputMui from "components/TextInputMui";
import { changePassword } from "services/login.service";
import { InputAdornment, IconButton } from "@mui/material";
import {
  Visibility as HidePassIcon,
  VisibilityOff as ShowPassIcon,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";

const PREFIX = 'AccountChangePassword';

const classes = {
  formcontrolwidth: `${PREFIX}-formcontrolwidth`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  }
}));

export default function AccountChangePassword() {

  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const handlePassWord = (values) => {
    setSubmittingBtnProcess(true);
    changePassword({
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    })
      .then((res) => {
        if (res?.status === 201 || res?.data) {
          setSubmittingBtnProcess(false);
          toast.success(res?.data?.message);
          localStorage.removeItem("token");
          localStorage.removeItem("billing");
          localStorage.removeItem("access");
          history.push("/login");
        } else {
          setSubmittingBtnProcess(false);
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
        setSubmittingBtnProcess(false);
      });
  };

  return (
    <StyledContainer className="profile-container">
      <Card sx={{ p: 3 }}>
        <Form
          onSubmit={handlePassWord}
          validate={(values) => {
            const errors = {};
            const whitespaceRegExp = /^$|\s+/;
            const poorRegExp = /[a-z]/;
            const weakRegExp = /(?=.*?[0-9])/;
            const strongRegExp = /(?=.*?[#?!@$%^&*-])/;

            const poorPassword = poorRegExp.test(values.confirmPassword);
            const weakPassword = weakRegExp.test(values.confirmPassword);
            const strongPassword = strongRegExp.test(values.confirmPassword);
            const whiteSpace = whitespaceRegExp.test(values.confirmPassword);

            if (!values.oldPassword) {
              errors.oldPassword = t(
                "change-password-screen-validation-message",
              );
            }

            if (!values.newPassword) {
              errors.newPassword = t("change-password-new-validation-message");
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = t(
                "change-password-confirm-validation-message",
              );
            } else if (values.newPassword !== values.confirmPassword) {
              errors.confirmPassword = t(
                "change-password-match-validation-message",
              );
            } else if (whiteSpace) {
              errors.confirmPassword = t(
                "password-whitespace-validatin-message",
              );
            } else if (
              values.confirmPassword.length <= 3 &&
              (poorPassword || weakPassword || strongPassword)
            ) {
              errors.confirmPassword = t("password-poor-validation-message");
            }

            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field name="oldPassword">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="password"
                          label={`${t(
                            "change-password-screen-old-password-label",
                          )}${" *"}`}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                  <Field name="newPassword">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          type="password"
                          label={`${t(
                            "change-password-screen-new-password-label",
                          )}${" *"}`}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Field name="confirmPassword">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <TextInputMui
                          {...input}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" size="large">
                                  {showPassword ? (
                                    <ShowPassIcon />
                                  ) : (
                                    <HidePassIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            classes: {
                              // underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          label={`${t(
                            "change-password-screen-confirm-password-label",
                          )}${" *"}`}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "end" }}>
                  <RoleBasedGuard
                    resource={RESOURCE.PROFILE}
                    action={ACTION.UPDATE}
                  >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#00AB55" }}
                  >
                    {submittingBtnProcess ? t("processing..") : t("save")}
                    </LoadingButton>
                  </RoleBasedGuard>
                </GridItem>
              </GridContainer>
            </form>
          )}
        />
      </Card>
    </StyledContainer>
  );
}

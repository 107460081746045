import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  TextField,
  MenuItem,
  Button as Btn,
  Button,
  Stack,
} from "@mui/material";
import {
  Card,
  Row,
  Table,
  Col,
  Container,
} from "react-bootstrap";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Form, Field } from "react-final-form";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import useStyles from "./../../pages/dashboard/styles";
import { useTheme } from "@mui/material/styles";
import { useInMobile } from "themes/Device";
//context
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "./../../assets/img/no-data-found.jpg";
import { AppWidgetSummary } from "components/general";
import { useTranslation } from "react-i18next";
import { AnalyticsCurrentVisits } from "sections/@dashboard/general/analytics";
import { CHART_COLORS } from "configV2";
import { useDashboardAnalytics } from "hooks/react-query/useReport";
import PageLoader from "components/Loader/PageLoader";
import Page from "components/Page";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#d43fbd",
  "#dad52b",
  "#49ddff",
  "#a940de",
];

const FoodItemReport = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [initialReportDate, setInitialReportDate] = useState({});
  const [selectValue, setSelectValue] = useState("");
  const isMobileView = useInMobile();
  // local
  const { t } = useTranslation();

  useEffect(() => {
    let initialInterval = {
      fromDate: moment().startOf("month").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
    setInitialReportDate(initialInterval);
  }, []);
  const { data: dashboardData, isLoading } = useDashboardAnalytics(initialReportDate);

  const salesReportFun = (body) => {
    setInitialReportDate(body);
  };

  const handleReportByInterval = (event) => {
    var intervalPeriod = event?.target?.value;
    setSelectValue(intervalPeriod);
    if (intervalPeriod === "WEEKLY") {
      let initialInterval = {
        fromDate: moment().startOf("week").add(1, "day").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "MONTHLY") {
      let initialInterval = {
        fromDate: moment().startOf("month").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "YEARLY") {
      let initialInterval = {
        fromDate: moment().startOf("year").format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else {
      let initialInterval = {
        fromDate: moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    }
  };

  const Cards = (props) => {
    const { cardTitle, cardValue, color } = props;
    return (
      <AppWidgetSummary
        title={cardTitle}
        total={cardValue}
        chartColor={color}
        chartData={[5, 18, 12, 51, 68, 11, 39, 37, 27, 20]}
        sx={{
          marginBottom: "5px",
          borderRadius: "15px",
        }}
      />
    );
  };
  return (
    <Page title="Dashboard Analytics">
      {!isMobileView ? <hr /> : <br />}
      {!isLoading ?
        <>
          <Card>
            <Card.Body>
              <Form
                onSubmit={salesReportFun}
                initialValues={initialReportDate}
                validate={(values) => {
                  const errors = {};

                  if (
                    values.fromDate > values.toDate &&
                    values.toDate &&
                    values.fromDate
                  ) {
                    errors.fromDate = t("date-error-message");
                  }

                  return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Container fluid>
                      <Row>
                        <Col md={3} sm={4}>
                          <FormControl style={{ width: "100%", marginTop: "10px" }}>
                            <TextField
                              select
                              label={t("calendar-wise-label")}
                              variant="outlined"
                              value={selectValue}
                              onChange={handleReportByInterval}
                            >
                              <MenuItem value="TODAY">
                                {t("analytics-today-label")}
                              </MenuItem>
                              <MenuItem value="WEEKLY">
                                {t("analytics-current-week")}
                              </MenuItem>
                              <MenuItem value="MONTHLY">
                                {t("analytics-current-month")}
                              </MenuItem>
                              <MenuItem value="YEARLY">
                                {t("analytics-current-year")}
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </Col>
                        <Col md={3} sm={4}>
                          <Field name="fromDate">
                            {({ input, meta }) => (
                              <FormControl
                                style={{ width: "100%", marginTop: "10px" }}
                              >

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    {...input}
                                    inputFormat="dd/MM/yyyy"
                                    label={t("analytics-from-date")}

                                    renderInput={(params) => <TextField {...params} />}
                                    error={meta.error && meta.touched ? true : false}
                                  />
                                </LocalizationProvider>
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>{meta.error}</span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Col>
                        <Col md={3} sm={4}>
                          <Field name="toDate">
                            {({ input, meta }) => (
                              <FormControl
                                style={{ width: "100%", marginTop: "10px" }}
                              >

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    {...input}
                                    label={t("analytics-to-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                    error={meta.error && meta.touched ? true : false}
                                  />
                                </LocalizationProvider>

                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>{meta.error}</span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Col>
                        <Col md={3} sm={4} className="align-self-center h-100">
                          <Stack direction={"row"} gap={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                backgroundColor: theme?.palette?.primary?.main,
                                textTransform: 'none',
                                paddingY: 2,
                                marginTop: 1
                              }}
                              type="submit"
                              size="medium"
                            >
                              {isLoading ? t("search-processing-label") : t("search")}
                            </Button>
                          </Stack>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                )}
              />
              <br />
              {dashboardData && dashboardData?.category?.length > 0 ? (
                <Container fluid>
                  <Grid container spacing={4}>
                    <Grid item lg={6} md={4} sm={6} xs={12}>
                      <ResponsiveContainer>
                        <PieChart width={400} height={400}>
                          <Pie
                            data={dashboardData?.category.map((data) => ({
                              name: data.name,
                              value: parseInt(data?.sum),
                            }))}
                            cx="50%"
                            cy="50%"
                            isAnimationActive={false}
                            // labelLine={false}
                            label
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {dashboardData?.category?.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item lg={6} md={4} sm={6} xs={12}>
                      <Table bordered>
                        <thead className={classes.tablehead}>
                          <tr>
                            <th>{t("rank-label")}</th>
                            <th>{t("food-menu-label")}</th>
                            <th>{t("total-count-label")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dashboardData?.category
                            ?.sort((a, b) => {
                              return parseInt(b?.sum) > parseInt(a?.sum);
                            })
                            .map((entry, index) => (
                              <tr key={`top-${index}`}>
                                <td>{index + 1}</td>
                                <td>{entry?.name}</td>
                                <td>{entry?.sum}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Grid>
                  </Grid>
                </Container>
              ) : (
                <NotFoundComponent imgUrl={noFoundImg}>
                  <p style={{ fontWeight: 600 }}>{t("record-not-found-message")}</p>
                </NotFoundComponent>
              )}
            </Card.Body>
          </Card>
        </>
        :
        <PageLoader></PageLoader>
      }
    </Page>
  );
};

export default FoodItemReport;
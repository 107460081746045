import { makeStyles } from 'tss-react/mui';

export default makeStyles()(theme => ({
  root: {
    maxWidth: "100vw",
    overflow: "hidden",
    height: '100%'
  },
  content: {
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: '6px',
    overflow: 'auto',
    top: '56px'
  },
  onlineContent: {
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: '6px',
    overflow: 'auto',
    top: '53px'
  },
  
  contentShift: {
    transition: `${theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })}`
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
    minHeight: '30px!important'
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  }
}));

import React from "react";
import { styled } from '@mui/material/styles';
import { Col, Modal, Row } from "react-bootstrap";
import { Button, Stack } from "@mui/material";
import whatsapp from "../../assets/img/whatsapp.png";
import gmail from "../../assets/img/gmail.png";
import "./InfoModel.styles.css";
import { useTranslation } from "react-i18next";

const PREFIX = 'InfoModel';

const classes = {
  wpDesign: `${PREFIX}-wpDesign`,
  gmDesign: `${PREFIX}-gmDesign`,
  centerProperty: `${PREFIX}-centerProperty`,
  underLine: `${PREFIX}-underLine`,
  closeButtonDesign: `${PREFIX}-closeButtonDesign`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.wpDesign}`]: {
    border: "1px solid #39b54a",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "block",
    color: "#39b54a",
  },
  [`& .${classes.gmDesign}`]: {
    border: "1px solid #de1f1f",
    borderTopLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    display: "block",
    color: "#de1f1f",
    textTransform: "none !important",
  },
  [`& .${classes.centerProperty}`]: {
    display: "flex",
    justifyContent: "center",
  },
  [`& .${classes.underLine}`]: {
    borderBottom: "2px solid  ",
  },
  [`& .${classes.closeButtonDesign}`]: {
    border: "1px solid ",
    color: "",
  },
});

const InfoModel = (props) => {

  const { t } = useTranslation();

  return (
    (<Root>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={classes.underLine}
          >
            {t("about-order")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={classes.centerProperty}>
              <h5>
                {t("website-label")} :{" "}
                <a target="_blank" href="https://www.servofeat.com">
                  https://www.servofeat.com
                </a>
              </h5>
            </Col>
          </Row>
          <Stack>
            
              <h6>
                <div>For Technical Issue/ DEMO / SUPPORT: +91 7990488965</div>
                <a href="https://wa.me/917990488965" target="_blank">
                  <Button
                    variant="outlined"
                    startIcon={<img src={whatsapp} style={{ width: "20px" }} />}
                    className={classes.wpDesign}
                  >
                    +91 7990488965
                  </Button>
                </a>
                <div>For Technical Issue: +91 9426016918</div>
                <a href="https://wa.me/919426016918" target="_blank">
                  <Button
                    variant="outlined"
                    startIcon={<img src={whatsapp} style={{ width: "20px" }} />}
                    className={classes.wpDesign}
                  >
                    +91 9426016918
                  </Button>
                </a>
                <br></br>
                <div>For new Inquiry or Software DEMO: +91 9773426554</div>
                  <a href="https://wa.me/919773426554" target="_blank">
                    <Button
                      variant="outlined"
                      startIcon={<img src={whatsapp} style={{ width: "20px" }} />}
                      className={classes.wpDesign}
                    >
                      +91 9773426554
                    </Button>
                  </a>
                  <br></br>
              </h6>
          </Stack>
          <Stack>
            <a href="mailto: info@servofeat.com" target="_blank">
                  <Button
                    variant="outlined"
                    startIcon={<img src={gmail} style={{ width: "20px" }} />}
                  >
                    info@servofeat.com
                  </Button>
                </a>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            className={classes.closeButtonDesign}
            onClick={props.onHide}
          >
            {t("close-button-label")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Root>)
  );
};

export default InfoModel;

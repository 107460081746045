import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { App as CapacitorApp } from "@capacitor/app";
import "react-loading-skeleton/dist/skeleton.css";
import { QueryClient, QueryClientProvider } from "react-query";

import ThemeProvider from "./theme";
import store from "../src/state";
import App from "./components/App";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LayoutProvider } from "context/LayoutContext";
import { UserProvider } from "context/UserContext";
import enJson from "./lang/en.json";
import guJson from "./lang/gu.json";
import { getShopSettingFromLocalStorage } from "utility/helpers";
import PushNotificationsContainer from "components/PushNotification";

import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
const queryClient = new QueryClient();
CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enJson,
      },
      gu: {
        translation: enJson,
      }
    },
    lng:  "en" || getShopSettingFromLocalStorage("STORE_LANGUAGE") === "gu" ? "gu" : "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en" || getShopSettingFromLocalStorage("STORE_LANGUAGE") === "gu" ? "gu" : "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
   <ErrorBoundary>
  <LayoutProvider>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
      <ThemeProvider>
            <Provider store={store}>
              {/* <PushNotificationsContainer /> */}
              <App />
            </Provider>
            </ThemeProvider>
      </UserProvider>
    </QueryClientProvider>
  </LayoutProvider>
  </ErrorBoundary>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

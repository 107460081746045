/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import Papa from "papaparse";
// core components
import {
  Button,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material";

// API Calls
import { useSelector } from "react-redux";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import { DeskTopContainer } from "themes/Device.js";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { postFoodMenuBulkUpload, postFoodCategoryBulkUpload } from "services/bulkUpload.service.js";
import { isMobile } from "react-device-detect";
import { toast } from "react-hot-toast";

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  tabel1: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  tabel2: {
    margin: "10px",
    marginTop: "100px",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  prioritybtn: {
    "& a:hover,& a:focus": {
      backgroundColor: "#FF5C93",
    },
    marginLeft: "5px",
    backgroundColor: "#FF5C93",
    color: "white",
  },
  mobileCard: {
    padding: "2px",
    paddingBottom: '80px'
  },
});

const useStyles = makeStyles(styles);

const BulkUpload = () => {
  const classes = useStyles();
  const theme = useTheme();
  const foodMenuLoader = useSelector((state) => state.api.foodMenuLoader);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isFoodMenuFileUploaded, setIsFoodMenuFileUploaded] = useState(false);
  const [foodMenuFile, setFoodMenuFile] = useState();
  const [foodMenuData, setFoodMenuData] = useState([]);
  const handleFoodMenuFileUpload = async () => {
    const result = await postFoodMenuBulkUpload(foodMenuData);
    console.log("result", result)
    if (result?.data?.status === 200) {
      toast.success(result?.data?.message);
      setFoodMenuData([])
    } else {
      toast.error(result?.message);
    }
  };
  useEffect(() => {
    if (foodMenuFile) {
      const reader = new FileReader();

      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        const filteredData = parsedData.filter(
          (item) => item?.category && item?.name && item.price,
        );
        setFoodMenuData(filteredData);
        setIsFoodMenuFileUploaded(true);
      };
      reader.readAsText(foodMenuFile);
    }
  }, [foodMenuFile]);
  const handleFoodMenuFileChange = (e) => {
    setError("");
    const allowedExtensions = ["csv"];
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      setFoodMenuFile(inputFile);
    }
  };

  return (
    <>
      <DeskTopContainer>
        <div className={classes.tabel2}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{t("bulk-upload-food-menu")}</b>
              </h5>
            }
            columns={[
              {
                title: t("food-menu-label"),
                field: "name",
                width: "15%",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Food Name Secondary Language",
                field: "name_secondary_language",
                width: "15%",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("category-tab-label"),
                field: "category",
                width: "15%",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Category Secondary Language",
                field: "category_secondary_language",
                width: "15%",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("price-label"),
                field: "price",
                width: "15%",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: "Description",
                field: "description",
                width: "30%",
                cellStyle: {
                  width: "30%",
                },
              }
            ]}
            data={
              isFoodMenuFileUploaded
                ? foodMenuData &&
                foodMenuData.map((item, index) => {
                  item.is_active = true;
                  return {
                    name: item?.name,
                    category: item?.category,
                    price: "₹" + parseFloat(item?.price).toFixed(2),
                    description: item?.description,
                    name_secondary_language: item?.name_secondary_language,
                    category_secondary_language: item?.category_secondary_language
                  };
                })
                : []
            }
            options={{
              emptyRowsWhenPaging: false,
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "10px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: foodMenuLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                      <p style={{ fontWeight: 600 }}>Select CSV File.</p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px", display: "flex" }}>
                    {!isMobile && (
                      <a
                        style={{ "padding": '0px 10px' }}
                        href={"https://servofeatproduction.s3.ap-south-1.amazonaws.com/bulk-food.csv"} download>
                        Download Sample File {"    "}
                      </a>
                    )}
                    <label htmlFor="file-upload">
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        onChange={handleFoodMenuFileChange}
                      />
                    </label>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={handleFoodMenuFileUpload}
                    >
                      {"Add Bulk Food Menu"}
                    </Button>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
    </>
  );
};

export default BulkUpload;

// const [foodCategoryFile, setFoodCategoryFile] = useState();
// const [foodCategoryData, setFoodCategoryData] = useState([]);
// useEffect(() => {
//   if (foodCategoryFile) {
//     const reader = new FileReader();
//     reader.onload = async ({ target }) => {
//       const csv = Papa.parse(target.result, { header: true });
//       const parsedData = csv?.data;
//       const filteredData = parsedData.filter(
//         (item) => item?.name
//       );
//       setFoodCategoryData(filteredData);
//       setIsFoodCategoryFileUploaded(true);
//     };
//     reader.readAsText(foodCategoryFile);
//   }
// }, [foodCategoryFile]);

// const [isFoodCategoryFileUploaded, setIsFoodCategoryFileUploaded] = useState(false);
// const handleFoodCategoryFileUpload = async () => {
//   postFoodCategoryBulkUpload(foodCategoryData);
// };
// const handleFoodCategoryFileChange = (e) => {
//   setError("");
//   const allowedExtensions = ["csv"];
//   if (e.target.files.length) {
//     const inputFile = e.target.files[0];
//     const fileExtension = inputFile?.type.split("/")[1];
//     if (!allowedExtensions.includes(fileExtension)) {
//       setError("Please input a csv file");
//       return;
//     }
//     setFoodCategoryFile(inputFile);
//   }
// };
{/* <DeskTopContainer>
        <div className={classes.tabel1}>
          <MaterialTable
            title={
              <h5>
                <b className="mr-3">{"Bulk Upload Category"}</b>
              </h5>
            }
            columns={[
              {
                title: "Name",
                field: "name",
                width: "30%",
                cellStyle: {
                  width: "30%",
                },
                render: (rowData) =>
                (<div>
                  <span style={{ marginLeft: 10 }}>{rowData.name}</span>
                </div>
                ),
              },
              {
                title: "Description",
                field: "description",
                width: "30%",
                cellStyle: {
                  width: "30%",
                },
              },
            ]}
            data={
              isFoodCategoryFileUploaded
                ? foodCategoryData &&
                foodCategoryData.map((item, index) => {
                  return {
                    name: item?.name,
                    description: item?.description,
                  };
                })
                : []
            }
            options={{
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "10px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: foodMenuLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>Select CSV File For Food Category.</p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px", display: "flex" }}>
                    <label htmlFor="file-upload">
                      <input
                        // style={{ display: "none" }}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        onChange={handleFoodCategoryFileChange}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleFoodCategoryFileUpload}
                      >
                        {"Add Bulk Category"}
                      </Button>
                    </label>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer> */}
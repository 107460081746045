import { api } from "./../utility/interceptor";
var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const getRoleList = async (roleGet) => {
  return await api
    .post(`/admin/role/getrole`, roleGet)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createRole = async (data) => {
  try {
    let res = await api.post(`/admin/role`, data);
    return { status: res.status, data: res.data };
  } catch (err) {
    return { status: err.response.status, message: err.response.data.message };
  }
};

export const getRoleById = async (roleGet) => {
  return await api
    .post(`/admin/role/getrole`, roleGet, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateRole = async (id, data) => {
  return await api
    .put(`/admin/role/${id}`, data)
    .then((response) => response.data)
    .catch((error) => error);
};

export const deleteRoleById = async (id, shop_id, username) => {
  return await api
    .delete(`/admin/role/${id}/${shop_id}/${username}`)
    .then((response) => response.data)
    .catch((error) => error);
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';

import styles from "assets/jss/material-dashboard-react/components/cardStyle";

const useStyles = makeStyles()(styles);

export default function Card(props) {
  const { classes, cx } = useStyles();
  const { className, children, plain, profile, chart, ...rest } = props;
  const cardClasses = cx({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node
};

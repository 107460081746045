import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";

const fetchExpenseQuery = async (date) => {
    if (date != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.REPORT}/order?monthDate=${date}`);
        return data?.data;
    }
    else
        return {}
};
const useOrderAnalytics = (date) => {
    return useQuery([API_ENDPOINTS.REPORT, date], () => fetchExpenseQuery(date));
};
const fetchExpenseReportQuery = async (date) => {
    if (date != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.REPORT}/expense?monthDate=${date}`);
        return data?.data;
    }
    else
        return {}
};
const fetchDashboardReportQuery = async (date) => {
    if (date != undefined) {
        let { data } = await api.post(`/user/dashboard`, date);
        return data?.data;
    }
    else
        return {}
};
const useExpenseAnalytics = (date) => {
    return useQuery([API_ENDPOINTS.REPORT + "report", date], () => fetchExpenseReportQuery(date));
};
const fetchCustomerReportQuery = async (date) => {
    if (date != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.REPORT}/customer?monthDate=${date}`);
        return data?.data;
    }
    else
        return {}
};
const useCustomerAnalytics = (date) => {
    return useQuery([API_ENDPOINTS.REPORT + "customer", date], () => fetchCustomerReportQuery(date));
};
const useDashboardAnalytics = (date) => {
    return useQuery([API_ENDPOINTS.REPORT + "user", date], () => fetchDashboardReportQuery(date));
};
export {
    useOrderAnalytics,
    useExpenseAnalytics,
    useCustomerAnalytics,
    useDashboardAnalytics
};

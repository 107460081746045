import { api } from "./../utility/interceptor";

export const postFoodMenuBulkUpload = async (data) => {
  try {
    let res = await api.post(`fooditem/json/food-item-bluk-upload`, data);
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};
export const postFoodCategoryBulkUpload = async (data) => {
  try {
    console.log(data)
    let res = await api.post(`fooditem/json/food-category-bluk-upload`, data);
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};

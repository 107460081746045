import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { MailOutlineSharp, NotificationImportant } from "@mui/icons-material";
import { useTheme, Checkbox } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Image from "../../components/Image";
import clsx from "clsx";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { Alert } from "react-bootstrap";
import { adminShopViaCreate } from "services/login.service";
import { toast } from "react-hot-toast";
import { checkUserName } from "utility/helpers";
import MobileFriendly from "@mui/icons-material/MobileFriendly";
import { containsOnlyNumbers } from "utility/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const TermSectionStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignContent: "center",
  marginTop: "40px",
}));

// ----------------------------------------------------------------------

export default function Register(props) {
  const mdUp = useResponsive("up", "md");
  const classes = useStyles();
  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const theme = useTheme();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [signUpMethod, setSignUpMethod] = useState("MOBILE");
  const handleShopCreate = (values) => {
    if (!termCheck) {
      return;
    }
    setSubmittingBtnProcess(true);
    var phone = values.phone;
    delete values.phone;
    const shopinfo = {
      username: values?.username?.trim(),
      is_common: true,
      franchise_name: "trynow",
      number_of_order: -1,
      number_of_food: -1,
      number_of_category: -1,
      number_of_table: -1,
      is_trial: false,
      trial_day: 60,
      is_otp_required: true,
      ...values
    };
    if (containsOnlyNumbers(phone) && phone?.length == 10) {
      shopinfo.phone = phone
    }
    else {
      shopinfo.email = phone
    }
    adminShopViaCreate(shopinfo)
      .then((res) => {
        if (res?.data?.status == 200 || res?.data?.statusCode == 200) {
          setSubmittingBtnProcess(false);
          toast.success(res?.data?.message);
          if (res?.data?.status !== 400)
          {
            props.history.push({
              pathname: `/verifyotp`,
              details: shopinfo,
            });
          }
          else {
            props.history.push({
              pathname: `/login`
            });
          }
        } else {
          setSubmittingBtnProcess(false);
          toast(res?.data?.message, {
            icon: '☹️',
          });
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.error(err?.message);
      });
  };

  return (
    <Page title="Register">
        <Alert variant="warning" className="online-offline-alert">
          <NotificationImportant /> <span>
            You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
          </span>
        </Alert>
      <RootStyle>
       
        <HeaderStyle>
          {/* <Logo /> */}
          <Box>
            <Image
              disabledEffect
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
              }
              sx={{ width: 50, height: 50 }}
              className={classes.img}
            />
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5, fontSize: "2rem" }}
            >
              Manage the resturant more effectively with ServoFeat
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontSize: "1.5rem" }}
                >
                  Get started absolutely free.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, color: theme.palette.grey[600] }}
                >
                  Already have an account?{" "}
                  <Link
                    variant="subtitle2"
                    to={"/login"}
                    component={RouterLink}
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleShopCreate}
              validate={(values) => {
                const errors = {};
                const whitespaceRegExp = /^$|\s+/;
                const poorRegExp = /[a-z]/;
                const weakRegExp = /(?=.*?[0-9])/;
                const strongRegExp = /(?=.*?[#?!@$%^&*-])/;

                const poorPassword = poorRegExp.test(values.password);
                const weakPassword = weakRegExp.test(values.password);
                const strongPassword = strongRegExp.test(values.password);
                const whiteSpace = whitespaceRegExp.test(values.password);

                const emailRegex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                
                
                // if (!values.firstname?.trim()) {
                //   errors.firstname = "First name is required.";
                // } else if (values?.firstname?.length > 50) {
                //   errors.firstname = "Maximum length should be less then 50.";
                // }

                // if (!values.lastname?.trim()) {
                //   errors.lastname = "Last name is required.";
                // } else if (values?.lastname?.length > 50) {
                //   errors.lastname = "Maximum length should be less then 50.";
                // }

                var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

                const checkForUserName = checkUserName(values?.username);
                if (checkForUserName !== "") errors.username = checkForUserName;
                if (!values.username) {
                  errors.username = "User name is required.";
                } else if (values?.username?.length > 50) {
                  errors.username = "Maximum length should be less then 50.";
                } else if (format.test(values.username)) {
                  errors.username = "Special Characters are not allowed.";
                }

                if (!values.password) {
                  errors.password = "Password is required.";
                } else if (whiteSpace) {
                  errors.password = "Whitespaces are not allowed";
                } else if (
                  values.password.length <= 3 &&
                  (poorPassword || weakPassword || strongPassword)
                ) {
                  errors.password = "Password is Poor.";
                }
                if (containsOnlyNumbers(values?.phone) && values?.phone?.length == 10) {
                  if (!values?.phone) {
                    errors.phone = "Phone number is required.";
                  } else if (values?.phone?.length > 10) {
                    errors.phone = "Maximum length should be less then 10.";
                  }
                }
                else {
                  if (!values?.phone) {
                    errors.phone = "Email is required.";
                  } else if (values?.phone?.length > 50) {
                    errors.phone = "Maximum length should be less then 50.";
                  } else if (!emailRegex.test(values?.phone)) {
                    errors.phone = "Email is invalid";
                  }
                }
                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={2}>
                      {/* <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <Field name="firstname">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="First Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>

                        <Field name="lastname">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="Last Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack> */}

                    <Stack>
                        <Field name="username">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              label="Shop Name/ User Name"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error
                                  && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      <Stack>
                        {
                          signUpMethod === "EMAIL" ?
                              <Field name="email">
                              {({ input, meta }) => (
                                <TextInputMui
                                  {...input}
                                  className={clsx(
                                    classes.textField,
                                    classes.textFieldUnderline,
                                  )}
                                  label="Email"
                                  type="text"
                                  autoComplete="none"
                                  variant="outlined"
                                  fullWidth
                                  error={meta.touched && meta.error ? true : false}
                                  helperText={
                                    meta.touched && meta.error && `${meta.error}`
                                  }
                                  FormHelperTextProps={{
                                    style: { margin: "5px" },
                                  }}
                                />
                              )}
                            </Field>
                          :  <Field name="phone">
                                {({ input, meta }) => (
                                  <TextInputMui
                                    {...input}
                                    className={clsx(
                                      classes.textField,
                                      classes.textFieldUnderline,
                                    )}
                                  label="Phone No/ Email Address"
                                    autoComplete="none"
                                    variant="outlined"
                                    fullWidth
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={
                                      meta.touched && meta.error && `${meta.error}`
                                    }
                                    FormHelperTextProps={{
                                      style: { margin: "5px" },
                                    }}
                                  />
                                )}
                           </Field>
                        }
                      </Stack>

                     

                      <Stack>
                        <Field name="password">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              label="Password"
                              type="password"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      <TermSectionStyle>
                        <Checkbox
                          name="term"
                          value={termCheck}
                          onChange={() => {
                            setTermCheck(!termCheck);
                          }}
                          style={{ paddingLeft: "0px" }}
                        />

                        <Typography
                          variant="body2"
                          align="left"
                          sx={{ color: "text.secondary" }}
                        >
                          By registering, I agree to ServoFeat &nbsp;
                          <Link
                            underline="always"
                            color="text.primary"
                            target={"_blank"}
                            href="https://www.servofeat.com/termsandcondition"
                          >
                            Terms of Service &nbsp;
                          </Link>
                          {""}and{""}
                          <Link
                            underline="always"
                            color="text.primary"
                            target={"_blank"}
                            href="https://www.servofeat.com/privacypolicy"
                          >
                            &nbsp; Privacy Policy
                          </Link>
                          .
                        </Typography>
                      </TermSectionStyle>

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submittingBtnProcess}
                        disabled={!termCheck}
                      >
                        {submittingBtnProcess ? "Processing.." : "Register"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </form>
              )}
            />
            {/* <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            style={{
                              marginTop: 10
                            }}
                            onClick={()=>
                              {
                                signUpMethod === "EMAIL" ?
                                  setSignUpMethod("PHONE")
                                :
                                  setSignUpMethod("EMAIL")}}
                          >
                            {
                              signUpMethod === "EMAIL" ?
                  <><MobileFriendly />&nbsp; &nbsp; Register via Phone</>
                              :
                              <>
                    <MailOutlineSharp />&nbsp; &nbsp; Register via Email
                              </>
                            }

                          </Button> */}
          
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

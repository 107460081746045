import { api } from "./../utility/interceptor";

export const getSalesReportData = async (body, searchParam) => {
    
    let queryParam =  `${searchParam?.order_type ? `order_type=${searchParam?.order_type}` : ''}&${searchParam?.bill_type ? `bill_type=${searchParam?.bill_type}` : ''}`

    let res = await api.post(`/report/sales?${queryParam}`, body);
    let data = res.data;
    return data;
}
export const getGstReportData = async (body) => {
    let res = await api.post(`/report/gst`, body);
    let data = res.data;
    return data;
}
export const getFoodMenuWiseReportData = async (body) => {
    let res = await api.get(`/report/foodmenuwise?fromDate=${body?.fromDate}&toDate=${body?.toDate}`, body);
    let data = res.data;
    return data;
}
export const getCategoryWiseReportData = async (body) => {
    let res = await api.get(`/report/categorywise?fromDate=${body?.fromDate}&toDate=${body?.toDate}`, body);
    let data = res.data;
    return data;
}
export const getStaffWiseWiseReportData = async (body) => {
    let res = await api.get(`/report/staffwise?fromDate=${body?.fromDate}&toDate=${body?.toDate}`, body);
    let data = res.data;
    return data;
}
export const getExpenaseReportData = async (body) => {
    let res = await api.get(`/report/expense_export?fromDate=${body?.fromDate}&toDate=${body?.toDate}`);
    let data = res.data;
    return data;
}
export const getCustomerReportData = async (body) => {
    let res = await api.get(`/report/customer_export?fromDate=${body?.fromDate}&toDate=${body?.toDate}`);
    let data = res.data;
    return data;
}
export const getInventoryReportData = async (body) => {
    let res = await api.get(`/report/inventory_export?fromDate=${body?.fromDate}&toDate=${body?.toDate}`);
    let data = res.data;
    return data;
}
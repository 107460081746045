import React from "react";
import { styled } from '@mui/material/styles';

import "./NotFoundComponent.styles.css";

const PREFIX = 'NotFoundComponent';

const classes = {
  container: `${PREFIX}-container`,
  img: `${PREFIX}-img`,
  title: `${PREFIX}-title`,
  centerPropery: `${PREFIX}-centerPropery`
};

const Root = styled('div')({
  [`& .${classes.container}`]: {
    margin: "5vh 0px",
    width: "100%",
    padding: "10px",
  },
  [`& .${classes.img}`]: {
    maxWidth: 300,
    objectFit: "contain",
    textAlign: "center",
  },
  [`& .${classes.title}`]: {
    margin: "5px 0px",
  },
  [`&.${classes.centerPropery}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop:"1rem"
  },
});

const NotFoundComponent = (props) => {

  const { imgUrl, children } = props;
  return (
    <Root className={classes.centerPropery}>
      <img className={classes.img} src={imgUrl} alt="" />
      <div className={classes.title}>{children}</div>
    </Root>
  );
};

export default NotFoundComponent;

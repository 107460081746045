import React from "react";
import { useSelector } from "react-redux";
import checkPermission from "utility/check-permission";

function RoleBasedGuard({
  children,
  resource,
  action,
  componentToRender,
  returnBoolean,
}) {
  const userData = useSelector((state) => state.user.userData);
  const userPermission = useSelector((state) => state.user.userPermission);
  if (returnBoolean) {
    return checkPermission(userData?.permissions ?? userPermission, resource, action);
  }
  
  return checkPermission(
    userData?.permissions || userPermission,
    resource,
    action,
  )
    ? children : (componentToRender ? componentToRender : null);
}

export default RoleBasedGuard;

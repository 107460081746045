import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
  // crossDomain: true,
  // Accept: 'application/json',
};

export const getAllDiningTable = async () => {
  let res = await api.get(`/dining-table`, config);
  let data = res?.data;
  return data;
};

export const CreateDiningTable = async (diningTableData) => {
  try {
    let res = await api.post(`/dining-table`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (err) {
    return { status: err?.response?.status, message: err?.response?.data?.message }
  }
};
export const CreateBulkDiningTable = async (diningTableData) => {
  try {
    let res = await api.post(`/dining-table/bulk`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (err) {
    return { status: err?.response?.status, message: err?.response?.data?.message }
  }
};
export const getDiningTableById = async (id) => {
  let res = await api.get(`/dining-table/${id}`, config);
  let data = res?.data;
  return data;
};

export const updateDiningTable = async (id, diningTableData) => {
  try {
    let res = await api.put(`/dining-table/${id}`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }
  }
};

export const updateDiningTableByOrder = async (diningTableData) => {
  try {
    let res = await api.put(`/dining-table`, diningTableData);
    let data = res?.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteDiningTable = async (id) => {
  try {
    let res = await api.delete(`/dining-table/${id}`, config);
    return { status: res?.status, data: res?.data };
  } catch (err) {
    return { status: err?.response?.status, message: err?.response?.data?.message }
  }
};
export const DiningTableBulkDelete = async (diningTableData) => {
  try {
    let res = await api.post(`/dining-table/remove_bulk`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (err) {
    return { status: err?.response?.status, message: err?.response?.data?.message }
  }
};

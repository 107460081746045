import React, { useState, Children, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Card } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";
import {
  saveStoreThemeSetting,
  updateStoreThemeSetting,
  getStoreDesignSetting,
} from "services/setting.service";
import {
  fetchShopSettingInfo
} from "state/thunk-async";
import { toast } from "react-hot-toast";
import { FormControl, MenuItem } from "@mui/material";
import TextInputMui from "components/TextInputMui";
import Select, { components } from "react-select";
import ControlledEditor from "@monaco-editor/react";
import { useSelector, useDispatch } from "react-redux";
import { setShopThemeSettingData } from "state/actions/shopSetting.types";
import { AppendCustomCss } from "utility/helpers";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE, ROUTE_ARRAY, DINING_OPTIONS } from "common/constant";
import { ACTION } from "common/constant";
import { updateSettingData } from "services/setting.service";
import UpdateSettingSelection from "./UpdateSettingSelection";
const PREFIX = 'CustomValueContainer';

const classes = {
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  monacoEditorStyle: `${PREFIX}-monacoEditorStyle`
};

const StyledCard = styled(Card)((
  {
    theme
  }
) => ({
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    marginBottom: 10,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },

  [`& .${classes.monacoEditorStyle}`]: {
    borderColor: "hsl(0, 0%, 80%)",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
  }
}));

export const CustomValueContainer = (props) => {
  const { selectProps, children } = props;
  return (
    <components.ValueContainer {...props}>
      <components.Placeholder {...props}>
        {selectProps.placeholder}
      </components.Placeholder>
      {Children.map(children, (child) =>
        child && child.type !== components.Placeholder ? child : null,
      )}
    </components.ValueContainer>
  );
};

export default function AccountTheme() {

  const { t } = useTranslation();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const shopThemeSettingData = useSelector(
    (state) => state?.shopSettingReducer?.shopThemeSettingData,
  );
  const shopSettingsData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [initialFormData, setInitialFormData] = useState(() => {
    if (shopThemeSettingData) {
      return {
        ...shopThemeSettingData,
      };
    }
  });
  const [customerTabs, setCustomerTabs] = useState([])
  const [customTableTabs, setCustomTableTabs] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    if (shopSettingsData) {
      setCustomerTabs(shopSettingsData?.customer_tabs ? shopSettingsData?.customer_tabs : [])
      setCustomTableTabs(shopSettingsData?.custom_table_tabs ? shopSettingsData?.custom_table_tabs : [])
    }
  }, [shopSettingsData]);

  const reFetchTheme = async () => {
    const storeTheme = await getStoreDesignSetting();
    if (storeTheme && storeTheme.status === 200) {
      if (storeTheme && storeTheme?.data && storeTheme.data.length > 0) {
        dispatch(setShopThemeSettingData(storeTheme?.data[0]));
        AppendCustomCss(storeTheme?.data[0]?.custom_css);
        // window.location.reload();
      }
    }
  };
  const handlePassWord = async (values) => {
    setSubmittingBtnProcess(true);
    const themeData = {
      is_active: true,
      primary_color: values?.primary_color || null,
      secondary_color: values?.secondary_color || null,
      btn_primary_bg_color: values?.btn_primary_bg_color || null,
      btn_primary_text_color: values?.btn_primary_text_color || null,
      btn_secondary_bg_color: values?.btn_secondary_bg_color || null,
      btn_secondary_text_color: values?.btn_secondary_text_color || null,
      dine_In_table_style: values?.dine_In_table_style || null,
      order_food_menu_style: values?.order_food_menu_style || null,
      order_food_category_style: values?.order_food_category_style || null,
      custom_css: values?.custom_css || null,
      theme_type: values?.theme_type || null,
      order_details_layout: values?.order_details_layout || null,
    };
    if (initialFormData?.id && initialFormData.id > 0) {
      const res = await updateStoreThemeSetting(initialFormData.id, themeData);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setSubmittingBtnProcess(false);
        await reFetchTheme();
      } else {
        setSubmittingBtnProcess(false);
        toast.error(res?.message);
      }
    } else {
      const res = await saveStoreThemeSetting(themeData);
      if (res.status === 201 || res?.data) {
        toast.success(res?.data?.message);
        setSubmittingBtnProcess(false);
        await reFetchTheme();
      } else {
        setSubmittingBtnProcess(false);
        toast.error(res?.message);
      }
    }
  };
  const [isUpdating, setIsUpdating] = useState(false);

  const updateCustomerTabs = async (dataArray) => {
    if (dataArray?.length < 4) {
      toast.success("Minimun 4 Screen Required")
    }
    setIsUpdating(true);
    let values = {
      customer_tabs: dataArray
    }
    const shopId = localStorage.getItem("shopId");
    const result = await updateSettingData(values, shopId);
    if (result.status === 200) {
      setIsUpdating(false);
      dispatch(fetchShopSettingInfo());
      toast.success(result.data.message);
    } else if (result.status === 400) {
      setIsUpdating(false);
      toast.error(result?.message);
    }
  };


  const updateCustomTableTabs = async (dataArray) => {
    if (dataArray?.length < 2) {
      toast.success("Minimun 2 Screen Required")
    }
    setIsUpdating(true);
    let values = {
      custom_table_tabs: dataArray
    }
    const shopId = localStorage.getItem("shopId");
    const result = await updateSettingData(values, shopId);
    if (result.status === 200) {
      setIsUpdating(false);
      dispatch(fetchShopSettingInfo());
      toast.success(result.data.message);
    } else if (result.status === 400) {
      setIsUpdating(false);
      toast.error(result?.message);
    }
  };

  return (
    <>
      <StyledCard sx={{ p: 3 }}>
        <Form
          onSubmit={handlePassWord}
          initialValues={initialFormData}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={12} md={6}>
                  <Row>
                    <Col sm={12} md={6}>
                      <Field name="primary_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t("theme-settings-primary-color-label")}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="secondary_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t("theme-settings-secondary-color-label")}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="btn_primary_bg_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t("theme-settings-button-bg-color-label")}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="btn_primary_text_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t(
                                "theme-settings-button-primary-text-color-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="btn_secondary_bg_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t(
                                "theme-settings-button-secondary-text-color-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="btn_secondary_text_color">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="color"
                              label={t(
                                "theme-settings-button-secondary-text-color-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="dine_In_table_style">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              select
                              label="Dine In Screen Layout"
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            >
                              <MenuItem value={"MOBILE_3_TABLE"}>
                                {"MOBILE_VIEW ( 3 TABLE)"}
                              </MenuItem>
                              <MenuItem value={"MOBILE_4_TABLE"}>
                                {"MOBILE_VIEW ( 4 TABLE)"}
                              </MenuItem>
                              <MenuItem value={"MOBILE_2_TABLE"}>
                                {"MOBILE_VIEW ( 2 TABLE DEFAULT)"}
                              </MenuItem>
                              <MenuItem value={"MOBILE_HIDE_MENU_OPTION"}>
                                {"MOBILE VIEW HIDE MENU OPTION BUTTON"}
                              </MenuItem>
                            </TextInputMui>
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="order_food_menu_style">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              select
                              label={t(
                                "theme-settings-order-screen-food-menu-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            >
                              <MenuItem value={"GRID4"}>
                                {`${t(
                                  "theme-settings-style-four-label",
                                )}${"(Desktop Grid 4, Mobile Grid 2)"}`}
                              </MenuItem>
                              <MenuItem value={"GRID5"}>
                                {`${t(
                                  "theme-settings-style-five-label",
                                )}${" (Desktop Grid 5, Mobile Grid 2)"}`}
                              </MenuItem>
                              <MenuItem value={"GRID6"}>
                                {`${t(
                                  "theme-settings-style-six-label",
                                )}${" (Desktop Grid 6, Mobile Grid 2)"}`}
                              </MenuItem>
                              <MenuItem value={"GRID2_TABLETVIEW"}>
                                GRID-2 (Tablet View)
                              </MenuItem>
                              <MenuItem value={"GRID3_TABLETVIEW"}>
                                GRID-3 (Tablet View)
                              </MenuItem>
                            </TextInputMui>
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={12}>
                      <Field name="order_food_category_style">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              select
                              label={t(
                                "theme-settings-order-screen-food-category-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            >
                              <MenuItem value={"Style1"}>
                                {t("theme-settings-style-one-label")}
                              </MenuItem>
                              <MenuItem value={"Style2"}>
                                {t("theme-settings-style-two-label")}
                              </MenuItem>
                              <MenuItem value={"Style3"}>
                                {t("theme-settings-style-three-label")}
                              </MenuItem>
                            </TextInputMui>
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={6}>
                      <Field name="order_details_layout">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              select
                              label={t(
                                "theme-settings-order-screen-layout-label",
                              )}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            >
                              <MenuItem value={"STYLE1(L9-R3)"}>
                                {`${t(
                                  "theme-settings-style-one-label",
                                )}${"(L9-R3)"}`}
                              </MenuItem>
                              <MenuItem value={"STYLE2(L8-R4)"}>
                                {`${t(
                                  "theme-settings-style-two-label",
                                )}${"(L8-R4)"}`}
                              </MenuItem>
                              <MenuItem value={"MOBILE_DEFAULT"}>
                                {"MOBILE STYLE1 (DEFAULT)"}
                              </MenuItem>
                              <MenuItem value={"MOBILE_ONESCREEN"}>
                                {"MOBILE STYLE2 (ONE SCREEN DISPLAY)"}
                              </MenuItem>
                            </TextInputMui>
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col sm={12} md={6}>
                      <Field name="theme_type">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              select
                              label={t("theme-settings-theme-type-label")}
                              variant="outlined"
                              error={meta.error && meta.touched ? true : false}
                            >
                              <MenuItem value={"LIGHT"}>
                                {t("theme-settings-theme-type-light-label")}
                              </MenuItem>
                              <MenuItem value={"DARK"}>
                                {" "}
                                {t("theme-settings-theme-type-dark-label")}
                              </MenuItem>
                            </TextInputMui>
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} md={6}>
                  <Field name="custom_css">
                    {({ input, meta }) => (
                      <FormControl className={classes.formcontrolwidth}>
                        <label>{t("theme-settings-customize-css-label")}</label>
                        <ControlledEditor
                          height="50vh"
                          value={input.value || ""}
                          onChange={(e, v) => {
                            input.onChange(e);
                          }}
                          className={classes.monacoEditorStyle}
                          defaultLanguage="css"
                        />
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Row>
              <RoleBasedGuard
                resource={RESOURCE.PROFILE}
                action={ACTION.UPDATE}
              >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: "#00AB55" }}
                >
                  {submittingBtnProcess ? t("processing..") : t("save")}
                </LoadingButton>
              </RoleBasedGuard>
            </form>
          )}
        />
      </StyledCard>
      {/* <StyledCard sx={{ p: 3, marginTop: 10 }}>
        <h5 className="m-auto py-3">Custom Screen Configuration</h5>
        <Form
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12} sm={12} md={6}>
                  <Field name="menu">
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(
                          classes.selectBoxControl,
                          classes.formcontrolwidth,
                        )}
                      >
                        <TextInputMui
                          {...input}
                          select
                          label={"Select Screen From Drow Down"}
                          variant="outlined"
                          error={meta.error && meta.touched ? true : false}
                        >
                          {ROUTE_ARRAY?.map((item) => (
                            <MenuItem value={item}>{item?.value}</MenuItem>
                          ))}
                        </TextInputMui>
                        {meta.error && meta.touched && (
                          <span style={{ color: "red" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>

                </Col>
                <Col xs={12} sm={12} md={6}>
                  <RoleBasedGuard
                    resource={RESOURCE.PROFILE}
                    action={ACTION.UPDATE}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "#00AB55" }}
                    >
                      {"Add Screen Into List"}
                    </LoadingButton>
                  </RoleBasedGuard>
                  <RoleBasedGuard
                    resource={RESOURCE.PROFILE}
                    action={ACTION.UPDATE}
                  >
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => clearScreenArray()}
                      variant="contained"
                    >
                      {"Clear All Screen"}
                    </Button>
                  </RoleBasedGuard>
                </Col>
              </Row>
            </form>
          )}
        />
        <Row style={{ padding: 20 }} >
          {screenArray?.map((r) => (
            <Col sm={6} md={6}>
              <Row>
                <p>{r?.value}</p>
                <Delete
                  style={{ marginLeft: 10 }}
                  color="secondary" onClick={() => removeElement(r)} />
              </Row>
            </Col>))}
        </Row>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => updateSetting()}
        >
          Update Screen Preference
        </Button>
        
      </StyledCard> */}
      <UpdateSettingSelection
        title="Custom Screen Configuration"
        data={customerTabs}
        maxSelection={5}
        onUpdate={updateCustomerTabs}
        optionArray={ROUTE_ARRAY}
        addLabel="Add Screen Into List"
        clearLabel="Clear All Screen"
        updateLabel="Update Screen Preference"
      />

      <UpdateSettingSelection
        title="Custom Table Tabs"
        data={customTableTabs}
        maxSelection={2}
        onUpdate={updateCustomTableTabs}
        optionArray={DINING_OPTIONS}
        addLabel="Add Tab"
        clearLabel="Clear All Tabs"
        updateLabel="Update Table Tabs"
      />
    </>
  );
}


// <Form
//   onSubmit={onSubmit}
//   validate={(values) => {
//     const errors = {};
//     return errors;
//   }}
//   // mutators={{
//   //   ...arrayMutators,
//   // }}
//   render={({ handleSubmit, values }) => (
//     <form onSubmit={handleSubmit} className="mt-3">
//       <Row>
//         {ROUTE_ARRAY?.map((r) => (
//           <Col sm={3} md={3}>
//             <Field name={r}>
//               {({ input, meta }) => (
//                 <FormControlLabel
//                   control={
//                     <Switch
//                       checked={
//                         Boolean(input.value) ||
//                         Boolean(input.checked)
//                       }
//                       value={Boolean(input.value)}
//                       onChange={(event) => {
//                         input.onChange(event.target.checked);
//                       }}
//                       inputProps={{
//                         "aria-label": "controlled",
//                       }}
//                     />
//                   }
//                   label={r}
//                 />
//               )}
//             </Field>
//           </Col>
//         ))}
//       </Row>
//       <RoleBasedGuard
//         resource={RESOURCE.PROFILE}
//         action={ACTION.UPDATE}
//       >
//         <LoadingButton
//           type="submit"
//           variant="contained"
//           style={{ backgroundColor: "#00AB55" }}
//         >
//           {submittingBtnProcess ? t("processing..") : t("save")}
//         </LoadingButton>
//       </RoleBasedGuard>
//     </form>)} />

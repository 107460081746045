import React, { useEffect, useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
// Importing toastify module
import { Row, Col } from "react-bootstrap";
import {
    FormControl,
    TextField,
    MenuItem,
    Stack,
    Button,
} from "@mui/material";
import "jspdf-autotable";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// API Calls
import moment from "moment";
import TextInputMui from "components/TextInputMui";
import { useTranslation } from "react-i18next";

const PREFIX = 'CalendarWise';

const classes = {
    root: `${PREFIX}-root`,
    header: `${PREFIX}-header`,
};

const Root = styled('div')({
    [`& .${classes.root}`]: {
        width: "100%",
    },
    [`&.${classes.header}`]: {
        backgroundColor: "white",
        boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        padding: "10px",
        marginBottom: "10px",
    },
});

const CalendarWise = ({ dataFetchingFunction, reportData, setReportData, queryParams }) => {
    const [initialReportDate, setInitialReportDate] = useState({
        startDate: moment().format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
        endDate: moment().format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
    });
    const [loading, setLoading] = useState(false);
    const [selectValue, setSelectValue] = useState("TODAY");
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (initialReportDate?.startDate && initialReportDate?.endDate) {
            if (moment(initialReportDate?.startDate).format("YYYY-MM-DD") > moment(initialReportDate?.endDate).format("YYYY-MM-DD")) {
            } else {
                reportFun();
            }
        }
    }, [initialReportDate, queryParams]);

    const reportFun = () => {
        let newData = {
            fromDate: moment(initialReportDate?.startDate).format('YYYY-MM-DD'),
            toDate: moment(initialReportDate?.endDate).format('YYYY-MM-DD'),
        };
        console.log("newData", newData)
        let finalQueryParams = {}
        setLoading(true);
        if (queryParams) {
            finalQueryParams = {
                order_type: queryParams.order_type !== "ALL" ? queryParams.order_type : "",
                bill_type: queryParams.bill_type !== "ALL" ? queryParams.bill_type : ""
            };
        }
        dataFetchingFunction(newData, finalQueryParams)
            .then((res) => {
                console.log("res?.data", res?.data)
                setReportData(res?.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleReportByInterval = (event) => {
        var intervalPeriod = event?.target?.value;
        setSelectValue(intervalPeriod);
        if (intervalPeriod === "WEEKLY") {
            let initialInterval = {
                startDate: moment().startOf("week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        } else if (intervalPeriod === "MONTHLY") {
            let initialInterval = {
                startDate: moment().startOf("month").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        } else if (intervalPeriod === "YEARLY") {
            let initialInterval = {
                startDate: moment().startOf("year").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        } else if (intervalPeriod === "YESTERDAY") {
            let initialInterval = {
                startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        }
        else if (intervalPeriod === "CUSTOM_DATE" || intervalPeriod === "TODAY") {
            let initialInterval = {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        } else {
            let initialInterval = {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            };
            setInitialReportDate(initialInterval);
        }
    };

    return (
        <Row>
            <Col md={2} sm={3}>
                <FormControl style={{ width: "100%", marginTop: "10px" }}>
                    <TextInputMui
                        select
                        label={t("calendar-wise-label")}
                        variant="outlined"
                        labelId="demo-simple-select-label"
                        value={selectValue}
                        onChange={handleReportByInterval}
                    >
                        <MenuItem value="TODAY">
                            {t("analytics-chart-today-label")}
                        </MenuItem>
                        <MenuItem value="YESTERDAY">
                            {t("analytics-chart-yesterday-label")}
                        </MenuItem>
                        <MenuItem value="WEEKLY">
                            {t("analytics-current-week")}
                        </MenuItem>
                        <MenuItem value="MONTHLY">
                            {t("analytics-current-month")}
                        </MenuItem>
                        <MenuItem value="YEARLY">
                            {t("analytics-current-year")}
                        </MenuItem>
                        <MenuItem value="CUSTOM_DATE">
                            {t("analytics-custom-date")}
                        </MenuItem>
                        <MenuItem value="DATE_RANGE">
                            {t("analytics-date-range")}
                        </MenuItem>
                    </TextInputMui>
                </FormControl>
            </Col>

            {
                selectValue == "DATE_RANGE" &&
                <>
                    <Col md={4} sm={6}>
                        <FormControl
                            style={{ width: "100%", marginTop: "10px" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(e) => setInitialReportDate({
                                        ...initialReportDate,
                                        startDate: e
                                    })}
                                    value={initialReportDate?.startDate}
                                    defaultValue={initialReportDate?.startDate}
                                    label={t("analytics-from-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col md={4} sm={6}>
                        <FormControl
                            style={{ width: "100%", marginTop: "10px" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id="endDate"
                                    onChange={(e) => setInitialReportDate({
                                        ...initialReportDate,
                                        endDate: e
                                    })}
                                    value={initialReportDate?.endDate}
                                    defaultValue={initialReportDate?.endDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={t("analytics-to-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col md={2} sm={3} className="align-self-center h-100">
                        <Stack direction={"row"} gap={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.main,
                                    textTransform: 'none',
                                    paddingY: 2,
                                    marginTop: 1
                                }}
                                onClick={reportFun}
                                size="medium"
                            >
                                {loading ? t("search-processing-label") : t("search")}
                            </Button>
                        </Stack>
                    </Col>
                </>
            }

            {
                selectValue == "CUSTOM_DATE" &&
                <>
                    <Col md={4} sm={6}>
                        <FormControl
                            style={{ width: "100%", marginTop: "10px" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(e) => setInitialReportDate({
                                        ...initialReportDate,
                                        startDate: e,
                                        endDate: e
                                    })}

                                    value={initialReportDate?.startDate}
                                    defaultValue={initialReportDate?.startDate}
                                    label={t("analytics-specific-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col md={2} sm={3} className="align-self-center h-100">
                        <Stack direction={"row"} gap={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.main,
                                    textTransform: 'none',
                                    paddingY: 2,
                                    marginTop: 1
                                }}
                                onClick={reportFun}
                                size="medium"
                            >
                                {loading ? t("search-processing-label") : t("search")}
                            </Button>
                        </Stack>
                    </Col>
                </>
            }

        </Row>
    );
};

export default CalendarWise;

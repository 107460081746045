import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchExpense = async ({ }) => {
    const url = `${API_ENDPOINTS.EXPENSECATEGORY}`;
    const { data } = await api.get(url);
    return data?.data;
};
const useExpenseCategory = (options = {}) => {
    return useQuery([API_ENDPOINTS.EXPENSECATEGORY, options], fetchExpense, {
        keepPreviousData: true,
    });
};
const fetchExpenseCategory = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.EXPENSECATEGORY}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useExpenseCategoryQuery = (id) => {
    return useQuery([API_ENDPOINTS.EXPENSECATEGORY, id], () => fetchExpenseCategory(id));
};
const useDeleteExpenseCategoryMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.EXPENSECATEGORY}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSECATEGORY);
            },
        }
    );
};

const useUpdateExpenseCategoryClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.EXPENSECATEGORY}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSECATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useBulkDeleteExpenseCategoryClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.EXPENSECATEGORY}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSECATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateExpenseCategoryClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.EXPENSECATEGORY, input),
        {
            onSuccess: (result) => {
                console.log("result", result)
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSECATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useExpenseCategory,
    useExpenseCategoryQuery,
    fetchExpenseCategory,
    useCreateExpenseCategoryClassMutation,
    useUpdateExpenseCategoryClassMutation,
    useDeleteExpenseCategoryMutation,
    useBulkDeleteExpenseCategoryClassMutation
};

import { api } from "./../utility/interceptor";
import { toast } from "react-hot-toast";
import axios from "axios";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const getAllFoodMenu = async () => {
  let res = await api.get(`/food-item`, config);
  return res.data;
};

export const createFoodMenu = async (foodMenuData) => {
  try {
    let res = await api
      .post(`/food-item`, foodMenuData, {
        // headers: { 'content-type': 'multipart/form-data' }
      })
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }
  }
};
export const deleteBulkFoodMenu = async (foodMenuData) => {
  try {
    let res = await api
      .post(`/food-item/remove_bulk`, foodMenuData)
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }
  }
};
export const getFoodMenuById = async (id) => {
  let res = await api.get(`/food-item/${id}`, config);
  return res.data;
};

export const updateFoodMenu = async (id, diningTableData) => {
  try {
    let res = await api.put(`/food-item/${id}`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }
  }
};

export const updateFoodItemByOrder = async (diningTableData) => {
  try {
    let res = await api.put("/food-item", diningTableData);
    let data = res?.data;
    return data;
  } catch (error) {
    toast.error(error);
  }
};

export const deleteFoodMenu = async (id) => {
  let res = await api.delete(
    `/food-item/${id}`,
    config
  );
  return res.data;
};

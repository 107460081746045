import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';

import styles from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles()(styles);

export default function CardBody(props) {
  const { classes, cx } = useStyles();
  const { className, children, plain, profile, ...rest } = props;
  const cardBodyClasses = cx({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  children: PropTypes.node
};

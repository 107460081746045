import React, { useEffect, useState } from 'react';
import { getOrderDateBasedOnTimeZone } from "utility/helpers";
import moment from "moment";

export default function useOrderDate(shopSettingListData) {
    const [initialReportDate, setInitialReportDate] = useState({});
    console.log('%cuseOrderDate.js line:9 shopSettingListData?.ORDER_TOKEN_RESET_TIME', 'color: #007acc;', shopSettingListData?.ORDER_TOKEN_RESET_TIME);
    console.log('%cuseOrderDate.js line:10 shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER', 'color: #007acc;', shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER);
    useEffect(() => {  
       
        if(shopSettingListData?.ORDER_TOKEN_RESET_TIME && parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER) !== 0)
        {
          const currentDate = getOrderDateBasedOnTimeZone(shopSettingListData?.ORDER_TOKEN_RESET_TIME);
          const orderDateBody = {
            startDate: currentDate, //  "endDate": "2021-03-30",
            endDate: moment(currentDate).add(1, "days").format("YYYY-MM-DD") // "startDate": "YYYY-MM-DD"
          };
          setInitialReportDate(orderDateBody)
        }   
      }, [shopSettingListData?.ORDER_TOKEN_RESET_TIME])
    
      useEffect(() => {
        if(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER) < 0)
        {
          const orderDateBody = {
            startDate: moment().add(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER), "days").format("YYYY-MM-DD"),
            endDate: moment().add(1, "days").format("YYYY-MM-DD")
          }
          setInitialReportDate(orderDateBody)
        }
      }, [shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER])

  return initialReportDate;
}

import React from "react";
import { useSelector } from "react-redux";
import checkPermission from "utility/check-permission";

function CheckPermission({
  resource,
  action,
}) {
  const userPermission = useSelector((state) => state.user.userPermission);
  return checkPermission(userPermission, resource, action);
}

export default CheckPermission;

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
// Importing toastify module
import { Row, Col } from "react-bootstrap";
import MaterialTable, { MTableToolbar } from "material-table";
import {
    FormControl,
    TextField,
    MenuItem,
    Button,
    Stack,
} from "@mui/material";
import "jspdf-autotable";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// API Calls
import { useSelector } from "react-redux";
import moment from "moment";
import { RESOURCE } from "common/constant";
//context
import {
    useLayoutDispatch,
    addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PageLoaderBlock from "components/PageLoaderBlock";
import { getStaffWiseWiseReportData } from "services/report.service";

const PREFIX = 'StaffWiseReport';

const classes = {
    cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
    cardTitleWhite: `${PREFIX}-cardTitleWhite`,
    root: `${PREFIX}-root`,
    tabel: `${PREFIX}-tabel`,
    header: `${PREFIX}-header`,
    searchbtn: `${PREFIX}-searchbtn`
};

const Root = styled('div')({
    [`& .${classes.cardCategoryWhite}`]: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    [`& .${classes.cardTitleWhite}`]: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontFamily: '"Oxygen", sans-serif',
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    [`& .${classes.root}`]: {
        width: "100%",
    },
    [`& .${classes.tabel}`]: {
        margin: "10px",
        marginTop: "0",
        "& .MuiPaper-root": {
            padding: 10,
            paddingBottom: 0,
        },
        "& tr": {
            height: 50,
            "& .MuiTableCell-root": {
                paddingLeft: "15px",
                fontSize: "14px !important",
            },
        },
    },
    [`&.${classes.header}`]: {
        backgroundColor: "white",
        boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        padding: "10px",
        marginBottom: "10px",
    },
    [`& .${classes.searchbtn}`]: {
        fontWeight: 500,
        fontSize: "1rem",
        marginTop: "10px",
        height: "50px",
        lineHeight: "2px",
    },
});

const StaffWiseReport = () => {
    require("jspdf-autotable");
    const theme = useTheme();
    const [initialReportDate, setInitialReportDate] = useState({
        startDate: moment().format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
        endDate: moment().add(1, "days").format("YYYY-MM-DD") // "startDate": "YYYY-MM-DD"
    });
    const [reportData, setReportData] = useState([]);

    const [loading, setLoading] = useState(false);
    const userPermission = useSelector((state) => state.user.userPermission);
    const layoutDispatch = useLayoutDispatch();
    const { t } = useTranslation();
    const hasExportPermission =
        userPermission.length > 0
            ? JSON.parse(
                userPermission?.find((a) => a.resource === RESOURCE.SALES_REPORT)
                    ?.permission,
            )?.DOWNLOAD
            : true;

    useEffect(() => {
        addCurrentScreenTitle(layoutDispatch, {
            currentScreenTitle: t("staff-wise-report-title"),
        });
        return () => {
            addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
        };
    }, []);

    useEffect(() => {
        if (initialReportDate?.startDate && initialReportDate?.endDate) {
            if (moment(initialReportDate?.startDate).format("YYYY-MM-DD") > moment(initialReportDate?.endDate).format("YYYY-MM-DD")) {
            } else {
                reportFunction();
            }
        }
    }, [initialReportDate]);

    const reportFunction = () => {
        let newData = {
            fromDate: moment(initialReportDate?.startDate).toISOString()?.split("T")[0],
            toDate: moment(initialReportDate?.endDate).toISOString()?.split("T")[0],
        };

        setLoading(true);
        getStaffWiseWiseReportData(newData)
            .then((res) => {
                console.log("res?.data", res?.data)
                setReportData(res?.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return (
        <Root className={classes.header}>
            <PageLoaderBlock
                processing={loading}
                message="Please wait, we are fetching the record">
                <Row>
                    <Col md={4} sm={6}>
                        <FormControl
                            style={{ width: "100%", marginTop: "10px" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    onChange={(e) => setInitialReportDate({
                                        ...initialReportDate,
                                        startDate: e
                                    })}
                                    value={initialReportDate?.startDate}
                                    defaultValue={initialReportDate?.startDate}
                                    label={t("analytics-from-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col md={4} sm={6}>
                        <FormControl
                            style={{ width: "100%", marginTop: "10px" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id="endDate"
                                    onChange={(e) => setInitialReportDate({
                                        ...initialReportDate,
                                        endDate: e
                                    })}
                                    value={initialReportDate?.endDate}
                                    defaultValue={initialReportDate?.endDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={t("analytics-to-date")}
                                    inputFormat="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Col>
                    <Col md={2} sm={3} className="align-self-center h-100">
                        <Stack direction={"row"} gap={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.main,
                                    textTransform: 'none',
                                    paddingY: 2,
                                    marginTop: 1
                                }}
                                onClick={reportFunction}
                                size="medium"
                            >
                                {loading ? t("search-processing-label") : t("search")}
                            </Button>
                        </Stack>
                    </Col>
                </Row>
                <MaterialTable
                    title={
                        <h5>
                            <b>staff wise </b>
                        </h5>
                    }
                    columns={
                        reportData && reportData.length > 0 ? Object.keys(reportData[0]).map(k => {
                            return {
                                title: k.toUpperCase(),
                                field: k,
                                cellStyle: {
                                    width: "15%"
                                },
                            }
                        }) : []
                    }
                    data={
                        reportData &&
                        reportData?.map((item, index) => {
                            return { ...item };
                        })
                    }
                    options={{
                        maxBodyHeight: 'calc(100vh - 240px)',
                        rowStyle: (rowData) => rowData.bill_types === "Total amount" && {
                            color: 'green',
                            background: '#eee'
                        },
                        exportButton: hasExportPermission
                            ? {
                                pdf: false,
                                csv: true,
                            }
                            : false,
                        exportFileName: `staff-wise-Report-${moment().format("DD-MM-YYYY")}`,
                        searchFieldStyle: {
                            color: theme.palette.grey[500],
                            borderColor: "rgba(0, 0, 0, 0.23)",
                            border: "0.8px solid",
                            borderRadius: "6px",
                            padding: "10px",
                            "&:before": {
                                borderBottom: "0px!important",
                            },
                            "&:after": {
                                borderBottom: "0px!important",
                            },
                        },
                        headerStyle: {
                            color: theme.palette.grey[600],
                            backgroundColor: theme.palette.grey[200],
                        },
                        pageSize: 10,
                        paging: false,
                        emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                        pageSizeOptions: [10, 20, 30],
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}

                />
            </PageLoaderBlock>
        </Root>
    );
};

export default StaffWiseReport;

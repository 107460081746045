import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";
import {
  Save as SaveIcon,
  ArrowBackIos,
} from "@mui/icons-material";
import { Form, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { Button, FormControl } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  CreateBulkDiningTable,
} from "services/diningTable.service";
import { useInMobile } from "themes/Device";
import { fetchDiningTable } from "state/thunk-async";
import TextInputMui from "components/TextInputMui";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
const PREFIX = 'AddEditDiningTable';
const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  rowHeight: `${PREFIX}-rowHeight`,
  textfield: `${PREFIX}-textfield`,
  centerProperty: `${PREFIX}-centerProperty`,
  centerButton: `${PREFIX}-centerButton`,
  btnSection: `${PREFIX}-btnSection`,
  buttonPosition: `${PREFIX}-buttonPosition`
};

const Root = styled("div")((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
  },
  [`& .${classes.btnSection}`]: {
    justifyContent: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '6px',
    background: '#fff'
  },
  [`&.${classes.root}`]: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },

  [`& .${classes.main}`]: {
    width: "60%",
  },

  [`& .${classes.rowHeight}`]: {
    height: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    minWidth: "0",
    background: " #FFF",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    borderRadius: "6px",
  },

  [`& .${classes.textfield}`]: {
    margin: "2%",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.centerProperty}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.centerButton}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.buttonPosition}`]: {
    display: "flex",
    justifyContent: "end",
  }
}));

const AddTableInBulk = (props) => {
  const isMobileView = useInMobile();
  const history = useHistory();
  let { drawerClose } = props;
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    const result = await CreateBulkDiningTable(values);
    dispatch(fetchDiningTable());
    if (result.status === 200 || result?.data?.status === 200) {
      setSubmittingBtnProcess(false);
      toast.success(result.data.message);
    } else if (result.status === 400) {
      setSubmittingBtnProcess(false);
      toast.error(result?.message);
    }
    if (!isMobileView && drawerClose) drawerClose();
    else history.push("/app/dining-table");
  };

  return (
    <Root className={classes.root}>
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {};
          if (!values?.number) {
            errors.number = "Number of table is required.";
          }
          if (!values?.prifix) {
            errors.prifix = "Prefix of table is required.";
          }
          if (!values.capacity) {
            errors.capacity = "Table capacity is required.";
          } else if (isNaN(values.capacity)) {
            errors.capacity = "Table capacity must be a number";
          } else if (values?.capacity > 50) {
            errors.capacity = "Capicity must be less then 50."
          } else if (values?.capacity < 0) {
            errors.capacity = "Capicity value must be greater then 0."
          } else if (!Number.isInteger(Number(values.capacity))) {
            errors.capacity = "capacity should be 1,2,3,4,5.."
          }
          if (values?.prifix && values?.prifix?.length > 50) {
            errors.prifix = "Prefix length must be less then 50.";
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then(() => {
                form.reset();
                form.restart();
              });
            }}
            className="mt-2"
          >
            <Row className="m-0">
              <Col
                xs={12}
                sm={12}
                md={12}
                className={classes.buttonPosition}
              ></Col>
            </Row>
            <Row className="m-0">
              <Col xs={12} sm={12} md={12}>
                <Field name="prifix">
                  {({ input, meta }) => (
                    <FormControl className={classes.textfield}>
                      <TextInputMui
                        {...input}
                        type="textarea"
                        label={"Prefix Name of Table"}
                        variant="outlined"
                        multiline={true}
                        error={meta.error && meta.touched ? true : false}
                      />
                      {meta.error && meta.touched && (
                        <span style={{ color: "red" }}>{meta.error}</span>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Col>
              <br></br>
              <Col xs={12} sm={12} md={12}>
                <Field name="number">
                  {({ input, meta }) => (
                    <FormControl className={classes.textfield}>
                      <TextInputMui
                        {...input}
                        type="number"
                        variant="outlined"
                        label={"Enter Number of Table"}
                        error={meta.error && meta.touched ? true : false}
                      />
                      {meta.error && meta.touched && (
                        <span style={{ color: "red" }}>{meta.error}</span>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Col>
              <br></br>
              <Col xs={12} sm={12} md={12}>
                <Field name="capacity">
                  {({ input, meta }) => (
                    <FormControl className={classes.textfield}>
                      <TextInputMui
                        {...input}
                        type="number"
                        variant="outlined"
                        label={t("dining-table-capacity-label")}
                        error={meta.error && meta.touched ? true : false}
                      />
                      {meta.error && meta.touched && (
                        <span style={{ color: "red" }}>{meta.error}</span>
                      )}
                    </FormControl>
                  )}
                </Field>
              </Col>
            </Row>
            <Row className="m-0">
              <Col xs={12} sm={12} md={12}>
                <div className={classes.btnSection}>
                  <div className="d-flex">
                    <div className="ml-2">
                      <Button
                        variant="outlined"
                        color="secondary"
                        type="button"
                        size="large"
                        onClick={() => {
                          if (isMobileView) history.push("/app/dining-table");
                          else if (!isMobileView && drawerClose)
                            drawerClose();
                        }}
                        startIcon={<ArrowBackIos />}
                      >
                        {t("back")}
                      </Button>
                    </div>
                    <div className="ml-2">
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        disabled={submitting}
                        startIcon={<SaveIcon />}
                      >
                        {submittingBtnProcess ? t("processing") : t("save")}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        )}
      />
    </Root>
  );
};

export default AddTableInBulk;

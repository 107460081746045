import { SET_STORE_THEME } from "../actions/action.types";
const initialState = {
    shopSettingData: {},
    shopSettingLoading: true,
    shopThemeSettingData: [],
}

export const shopsettingReducer = (state = initialState, { type, payLoad }) => {
    switch (type) {
        case "SET_SHOP_SETTING":
            return {
                ...state,
                shopSettingData: payLoad,
                shopSettingLoading: false,
            }
        case SET_STORE_THEME:
            return {
                ...state,
                shopThemeSettingData: payLoad,
                shopSettingLoading: false,
            }
        default:
            return state
    }
}

export default shopsettingReducer;
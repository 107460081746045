import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import QRCode from "qrcode.react";
import "../../assets/css/qrcode.css";

const PREFIX = 'UserQrPrints';

const classes = {
  qrcode: `${PREFIX}-qrcode`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.qrcode}`]: {
    textAlign: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: "160px",
      width: "160px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "160px",
      width: "160px",
    },
  }
}));

const UserQrPrints = React.forwardRef((props, ref) => {

  return (
    <Root ref={ref}>
      <QRCode
        width={500}
        height={500}

        id="qrCodeE2"
        value={
          props?.orderUrl ? props?.orderUrl : "http://servofeat.com/"
        }
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={true}
        className={`${classes.qrcode} profileqrcode`}
      />
    </Root>
  );
});

export default UserQrPrints;

import React from "react";
import { styled } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";
import { BorderBottom, CloseRounded } from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";
import "./TemporaryDrawer.styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { isElectron } from "react-device-detect";

const PREFIX = 'TemporaryDrawer';

const classes = {
  header: `${PREFIX}-header`,
  paper: `${PREFIX}-paper`,
  titleMain: `${PREFIX}-titleMain`,
  titleIcon: `${PREFIX}-titleIcon`,
  titleText: `${PREFIX}-titleText`
};

const StyledDrawer = styled(Drawer)((
  {
    theme
  }
) => ({
  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "flex-start !important",
    padding: "5px 15px",
    marginTop: isElectron ? '30px' : '',
    width: "100%",
    "& button": {
      padding: 0,
      marginRight: "5px",
    },
  },

  [`& .${classes.paper}`]: {
    width: "40%",
    paddingBottom: "20px",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },

  [`& .${classes.titleMain}`]: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    borderBottom: "2px solid #9e9e9e",
  },

  [`& .${classes.titleIcon}`]: {
    color: "",
  },

  [`& .${classes.titleText}`]: {
    color: "",
    marginTop: "3px",
  }
}));

const TemporaryDrawer = (props) => {
  const {
    anchor = "right",
    isOpen,
    drawerClose,
    drawerWidth = "40%",
    children,
    title = "title",
    icon,
  } = props;



  return (
    <StyledDrawer
      classes={{ paper: classes.paper }}
      anchor={anchor}
      open={isOpen}
      onClose={drawerClose}
      variant="temporary"
    >
      <Box className={classes.header}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className={classes.titleMain}>
            <IconButton className={classes.titleIcon} size="large">{icon}</IconButton>
            <h5 className={classes.titleText}>{title}</h5>
          </div>
          <IconButton
            aria-label="close"
            size="medium"
            color="primary"
            onClick={drawerClose}
          >
            <CloseRounded fontSize="medium" />
          </IconButton>
        </div>
      </Box>
      {children}
    </StyledDrawer>
  );
};

export default TemporaryDrawer;

import React, { Fragment, useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { toast } from "react-hot-toast";
import MaterialTable, { MTableToolbar } from "material-table";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Button, IconButton, CircularProgress, TablePagination } from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Delete,
  Cancel,
  People,
} from "@mui/icons-material";

import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";

import { getAllStaff, deleteStaff } from "services/staff.service";
import PopUpModel from "components/Popup/PopUpModel";
import AddEditStaff from "./AddEditStaff";
import TemporaryDrawer from "components/TemporaryDrawer";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileStaffMembersCard from "components/MobileStaffmembersCard/mobilestaffmemberscard";
import SearchBarInput from "components/SearchBarInput";
import MobileTitle from "components/MobileTitle";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";

//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import { isAndroid, isIOS } from "react-device-detect";
const PREFIX = 'StaffList';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  mobileCard: `${PREFIX}-mobileCard`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  [`& .${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
      "& div": {
        padding: "0",
      },
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  [`& .${classes.mobileCard}`]: {
    padding: "2px",
  },
});

const StaffList = () => {

  const theme = useTheme();
  const [staffDataList, setStaffDataList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteStaffId, setStaffTableId] = useState(0);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const [drawerShow, setDrawerShow] = useState(false);
  const [staffId, setStaffId] = useState(0);
  const [searchStaff, setSearchStaff] = useState("");
  const [filterStaffList, setFilterStaffList] = useState([]);
  const history = useHistory();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [paginationParam, setPaginationParam] = useState({
    type: 'DESC',
    search: '',
    page: 1,
    limit: 20,
    column: 'id'
  })
  const [staffPagerData, setStaffPagerData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setHeader()
  }, [layoutState])

  const setHeader = useCallback(() => {
    if(isAndroid || isIOS)
    {
      if (layoutState?.currentScreenTitle !== "Staff Members") {
        addCurrentScreenTitle(layoutDispatch, {
          currentScreenTitle: t("staff-screen-title"),
        });
      }
      return () => {
        if (layoutState?.currentScreenTitle === "Staff Members") {
          addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
        }
      };
    }
  }, [layoutState, layoutDispatch])

  useEffect(() => {
    setHeader()
  }, []);

  useEffect(() => {
    getStaffData(paginationParam);
  }, [paginationParam])
  

  const getStaffData = (paginationParam) => {
    setDataLoading(true);
    getAllStaff(paginationParam)
      .then((response) => {
        if (response?.data) {
          // toast.success(res.message);
          setStaffPagerData(response?.pager);
          setStaffDataList(response?.data);
          setDataLoading(false);
        }
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  const deleteStaffData = (staffId) => {
    if (staffId > 0) {
      setDeletingBtnProcess(true);
      deleteStaff(staffId)
        .then((res) => {
          setDeletingBtnProcess(true);
          setDeleteModalShow(false);
          if (res?.data) {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          } else {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          }
          setDeleteModalShow(false);
          getStaffData();
        })
        .catch((err) => {
          toast.success(err?.message);
          setDeletingBtnProcess(true);
          setDeleteModalShow(false);
        });
    }
  };

  const drawerClose = () => {
    setDrawerShow(false);
    setStaffId(0);
  };

  const deleteData = (deleteid) => {
    setStaffTableId(deleteid);
    setDeleteModalShow(true);
  };

  const editData = (editid) => {
    // setStaffId(editid);
    // setDrawerShow(true);
    history.push({
      pathname: `/app/staff/edit/${editid}`,
      state: {
        staffId: editid,
        backToMain: () => history.push(`/app/staff`),
      },
    });
  };

  const addDrawerShow = () => {
    // setDrawerShow(true);
    history.push({
      pathname: `/app/staff/add`,
      state: {
        staffId: 0,
        backToMain: () => history.push(`/app/staff`),
      },
    });
  };

  const clearTxt = () => {
    setSearchStaff("");
  };

  useEffect(() => {
    setFilterStaffList(staffDataList);
  }, [staffDataList]);

  useEffect(() => {
    if (searchStaff) {
      let filterStf = staffDataList.filter(
        (item) =>
          (item.first_name || item.last_name) &&
          (item.first_name
            ?.toLowerCase()
            ?.includes(searchStaff.toLowerCase()) ||
            item.last_name?.toLowerCase()?.includes(searchStaff.toLowerCase())),
      );
      setFilterStaffList(filterStf);
    } else {
      setFilterStaffList(staffDataList);
    }
  }, [searchStaff]);

  return (
    <Root>
      <PopUpModel
        show={deleteModalShow}
        heading={t("staff-delete-popup-title")}
        body={t("staff-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeleteModalShow(false)}
            >
              {" "}
              <Cancel />
              {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteStaffData(deleteStaffId)}
            >
              <Delete />
              {deletingBtnProcess ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => setDeleteModalShow(false)}
      />
      <TemporaryDrawer
        isOpen={drawerShow}
        drawerClose={drawerClose}
        drawerWidth="50%"
        title={t("staff-screen-title")}
        icon={<People />}
      >
        <AddEditStaff
          staffId={staffId}
          drawerClose={drawerClose}
          getStaffData={getStaffData}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="ml-3">{t("staff-screen-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("name-label"),
                field: "name",
                customSort: (a, b) => a.name.length - b.name.length,
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("username-label"),
                field: "user_name",
                customSort: (a, b) => a.name.length - b.name.length,
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("phone-label"),
                field: "phoneNo",
                customSort: (a, b) => a.name.length - b.name.length,
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("staff-joining-date-label"),
                field: "joiningDate",
                render: (rowData) =>
                  rowData.joiningDate &&
                  moment(rowData.joiningDate).format("DD/MM/YYYY"),
                customSort: (a, b) => a.name.length - b.name.length,
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("staff-birth-date-label"),
                field: "BirthDate",
                render: (rowData) =>
                  rowData.BirthDate &&
                  moment(rowData.BirthDate).format("DD/MM/YYYY"),
                customSort: (a, b) => a.name.length - b.name.length,
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.STAFF_MEMBERS}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("staff-edit-label")}
                        aria-label={t("staff-edit-label")}
                        onClick={() => {
                          setStaffId(rowData.id);
                          setDrawerShow(true);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    {/* <IconButton color="secondary" title="Delete Staff" aria-label="Delete Staff" onClick={() => deleteStaffData(rowData.id)}><DeleteIcon /></IconButton> */}
                    <RoleBasedGuard
                      resource={RESOURCE.STAFF_MEMBERS}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("staff-delete-label")}
                        aria-label={t("staff-delete-label")}
                        onClick={() => {
                          setStaffTableId(rowData?.id);
                          setDeleteModalShow(true);
                        }}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={
              staffDataList &&
              staffDataList.map((item, index) => {
                return {
                  name: item.first_name + " " + item.last_name,
                  user_name: item.user_name,
                  phoneNo: item.phone,
                  joiningDate: item.joining_date,
                  BirthDate: item.birth_date,
                  id: item.id,
                };
              })
            }
            options={{
              paging: false,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: dataLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("staff-record-empty-data-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <RoleBasedGuard
                      resource={RESOURCE.STAFF_MEMBERS}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ background: "", color: "#fff" }}
                        onClick={() => {
                          setDrawerShow(true);
                        }}
                      >
                        {t("staff-add-label")}
                      </Button>
                    </RoleBasedGuard>
                  </div>
                </div>
              ),
            }}
          />
           <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50]}
              component="div"
              count={parseInt(staffPagerData?.total || 1)}
              rowsPerPage={parseInt(staffPagerData?.take || 20)}
              page={parseInt(staffPagerData?.page - 1)}
              onPageChange={(event, newPage) => {
                setPaginationParam({
                  ...paginationParam,
                  page: newPage +1
                })
              }}
              onRowsPerPageChange={(event) => {
                setPaginationParam({
                  ...paginationParam,
                  limit: event.target.value
                })
              }}
            />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle
            resource={RESOURCE.STAFF_MEMBERS}
            title={t("staff-screen-title")}
            addButton={addDrawerShow}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchStaff(e.target.value);
            }}
            value={searchStaff}
            placeholder={t("staff-search-label")}
            clearTxt={clearTxt}
          />
          {dataLoading ? (
            <SkeletonLoader count={3} height="7rem" />
          ) : (
            <div>
              {filterStaffList &&
                filterStaffList.map((item, index) => (
                  <div className={classes.mobileCard} key={index}>
                    <MobileStaffMembersCard
                      resource={RESOURCE.STAFF_MEMBERS}
                      name={item.first_name + " " + item.last_name}
                      email={item.email}
                      phoneNo={item.phone}
                      joiningDate={moment(item.joining_date).format(
                        "DD/MM/YYYY",
                      )}
                      BirthDate={moment(item.birth_date).format("DD/MM/YYYY")}
                      deleteData={deleteData}
                      editData={editData}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
          {filterStaffList.length < 1 && !dataLoading && (
            <NotFoundComponent imgUrl={noFoundImg}>
              <p style={{ fontWeight: 600 }}>
                {t("staff-record-empty-data-message")}
              </p>
            </NotFoundComponent>
          )}
        </div>
      </MobileContainer>
    </Root>
  );
};

export default StaffList;

import React from "react";
import moment from "moment";

import "assets/css/print.css";
export default class KOTPrintComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDateTime: moment(Date().toLocaleString()).format(
        "DD/MM/YYYY hh:mm:ss",
      ),
    };
  }

  componentDidMount() {
    // console.log("PRINT DATA");
    // console.log(this.props?.printData);
    // console.log(this.props?.updatedOrderResData);
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props?.printData);
    // console.log(this.props?.updatedOrderResData);
  }

  render() {
    return this.props?.billingData[0] && this.props?.customerDetails ? (
      <>
        <div className="print-container">
          {/* {
                    this.props?.printData?.logo && <h2 style={{textAlign:"center"}}><img src={this.props?.printData?.logo} alt="logo"></img></h2>
                } */}
          {/* <p style={{textAlign:'center', fontSize: '24px', fontWeight: '900', marginBottom:'4px'}}>{this.props?.billingData[0]?.name}</p> */}
          <p
            style={{
              textAlign: "center",
              fontSize: "17px",
              fontWeight: "900",
              marginBottom: "2px",
              borderBottom: "1px solid black",
            }}
          >
            {"(KOT Print)"}
          </p>
          {this.props.customerDetails?.name_of_customer && (
            <p style={{ textAlign: "left" }}>
              Name : {this.props.customerDetails?.name_of_customer}
            </p>
          )}
          <p>Order On: {this.state?.currentDateTime}</p>
          {/* { this.props.customerDetails?.phone && <h6 style={{textAlign:'left'}}>Phone No : {this.props.customerDetails?.phone}</h6> } */}
          <p style={{ textAlign: "left", fontWeight: "900" }}>
            Bill/Token No:{" "}
            {parseInt(this.props?.updatedOrderResData?.token_no) ||
              parseInt(this.props?.printData?.token_no) ||
              parseInt(this.props?.printData?.token)}
            {" ("}
            {this.props?.printData?.order_type} {") "}
          </p>
          <table className="mytable responsive" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                {/* <th className="quantity">No</th> */}
                <th className="itemName">ITEM</th>
                {/* <th className="isVeg">IsVeg?</th> */}
                <th className="quantity">QTY</th>
                {/* <th className="price">AMT.</th> */}
              </tr>
            </thead>
            <tbody>
              {this.props?.updatedOrderResData?.print_obj ? 
                this.props?.updatedOrderResData?.print_obj?.map((item, index) => {
                  return (
                    <tr key={`print-${index}`}>
                      {/* <th className="quantity" scope="row" >{index + 1}</th> */}
                      <td className="itemName" style={{textDecoration: item?.kot_print?.type === 'DELETE' ? 'line-through' : ''}}>
                        {item.fooditem?.name
                          ? item.fooditem?.name
                          : item?.food_item_id?.label}
                        <span style={{ fontSize: "14px" }}>
                          {"("}{" "}
                          {item?.fooditem?.category?.name ||
                            item?.category_id?.label}{" "}
                          {")"}
                        </span>
                      </td>
                      {item?.is_non_veg && (
                        <td className="isVeg">
                          {item?.is_non_veg ? "N-Veg" : "Veg"}
                        </td>
                      )}
                      <td className="quantity">{item?.kot_print?.qty}</td>
                      {/* <td className="price">₹ {parseFloat(item?.qty * item?.price).toFixed(2)}</td> */}
                    </tr>
                  );
                })
              :
              this.props?.foodItemListArray?.map((item, index) => {
                return (
                  <tr key={`print-${index}`}>
                    <td className="itemName">
                      {item.fooditem?.name
                        ? item.fooditem?.name
                        : item?.food_item_id?.label}
                      <span style={{ fontSize: "14px" }}>
                        {"("}{" "}
                        {item?.fooditem?.category?.name ||
                          item?.category_id?.label}{" "}
                        {")"}
                      </span>
                    </td>
                    <td className="quantity">{item?.qty}</td>
                  </tr>
                );
              })
            }
            </tbody>
          </table>
          {/* <h6 style={{textAlign:'center'}}>Sub Total : ₹ {this.props.subTotal}</h6> */}
        </div>
      </>
    ) : (
      <p>There's no billing data</p>
    );
  }
}

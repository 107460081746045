import io from "socket.io-client";
import numeral from "numeral";
import { format, getTime } from "date-fns";

export const OrderStatus = Object.create({
  IN_PROGRESS: "IN_PROGRESS",
  DELIVERED: "DELIVERED",
  DONE: "DONE",
});

export const OrderType = Object.create({
  NEW: "NEW",
  DELETE: "DELETE",
  ADD_ON: "ADD",
});

export const QuantityType = Object.create({
  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
});

export const OrderDetailCommonMessage = Object.create({
  MIN_QUANTITY: "It requires minimum 1 quantity",
  MIN_ONE_FOODITEM_SELECTED: "Please select atleast one food item.",
  QUANTITY_ADDED: "Quantity added.",
});

export const shopSettingKeys = () => {
  return {
    key: "IS_SHOP_VEGETARIAN",
    name: "Is Vegetarian Shop",
  };
};

export const RESOURCE = {
  DASHBOARD: "Dashboard",
  ANALYTICS: "Analytics",
  DINE_IN_TABLE: "DineIn Table",
  PARCEL_ORDER: "New/Parcel Order",
  SALES: "Sales",
  KITCHEN_QUEUE: "Kitchen Queue",
  FOOD_CATEGORY: "Food Category",
  FOOD_MENUS: "Food Menus",
  BULK_UPLOAD: "Bulk Upload",
  ONLINE_ORDER: "Online Order",
  WAITING_QUEUE: "Waiting Queue",
  SALES_REPORT: "Report",
  CUSTOMERS: "Customers",
  CUSTOMER_HISTORY: "Customer History",
  STAFF_MEMBERS: "Staff Members",
  PROFILE: "Profile",
  SETTING: "Setting",
  ROLES: "Roles",
  ORDER_PRINT_KOT_BTN: "Order Print Kot Button",
  ORDER_PRINT_BILL_BTN: "Order Print Bill Button",
  ORDER_SETTLE_BTN: "Order Settle Button",
  ORDER_DELETE_BTN: "Order Delete Button",
  ORDER_SETTLE_AND_PRINT_BTN: "Order Settle & Print Button",
  EXPENSECATEGORY: "Expense Category",
  EXPENSE: "Expense",
  INVENTORYCATEGORY: "Inventory Category",
  INVENTORY: "Inventory",
  ORDER_TYPE: "Order Type",
  PAYMENT_TYPE: "Payment Type",
  COUPONS: "Coupons",
};

export const ROUTE_ARRAY = [
  {
    key: "DASHBOARD",
    value: "Dashboard",
    routeName: "/app/dashboard"
  },
  {
    key: "ANALYTICS",
    value: "Analytics",
    routeName: "/app/analytics"
  },
  {
    key: "DINE_IN",
    value: "Dine In",
    routeName: "/app/dine-in",
  },
  {
    key: "ORDER_HISTORY",
    value: "History",
    routeName: "/app/history/order",
  },
  {
    key: "NEW_ORDER",
    value: "Parcel/New",
    routeName: "/app/order/new"
  },
  {
    key: "KITCHAN_SERVICE",
    value: "Kitchen",
    routeName: "/app/kitchen"
  },
  {
    key: "CUSTOMERS",
    value: "Customers",
    routeName: "/app/customers",
  },
  {
    key: "FOOD_CATEGORY",
    value: "Category",
    routeName: "/app/food-category",
  },
  {
    key: "FOOD_MENU",
    value: "Menu",
    routeName: "/app/food-menu",
  },
  {
    key: "REPORTS",
    value: "Report",
    routeName: "/app/report"
  },
  {
    key: "STAFF",
    value: "Staff",
    routeName: "/app/staff"
  },
  {
    key: "CUSTOMER_QUEUE",
    value: "Queue",
    routeName: "/app/customer-queue"
  },
  {
    key: "COUPONS",
    value: "Coupons",
    routeName: "/app/coupons"
  }
  // "New/Parcel Order",
  // "Sales",
  // "Kitchen Queue",
  // "Food Category",
  // "Food Menus",
  // "Bulk Upload",
  // "Online Order",
  // "Waiting Queue",
  // "Report",
  // "Customers",
  // "Customer History",
  // "Staff Members",
  // "Profile",
  // "Setting",
  // "Roles",
  // "Order Print Kot Button",
  // "Order Print Bill Button",
  // "Order Settle Button",
  // "Expense Category",
  // "Expense",
  // "Inventory Category",
  // "Inventory",
  // "Order Type",
  // "Payment Type",
  // "Coupons",
];

export const ACTION = {
  VIEW: "VIEW",
  ADD: "ADD",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  PRINT: "PRINT",
  DOWNLOAD: "DOWNLOAD",
  SETTLE: "SETTLE",
};

export const ROLE_PERMISSIONS = [
  {
    RESOURCE: RESOURCE.DASHBOARD,
    VIEW: true,
  },
  {
    RESOURCE: RESOURCE.ANALYTICS,
    VIEW: true,
  },
  {
    RESOURCE: RESOURCE.DINE_IN_TABLE,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.PARCEL_ORDER,
    ADD: true,
  },
  {
    RESOURCE: RESOURCE.SALES,
    VIEW: true,
    UPDATE: true,
    DELETE: true,
    PRINT: true,
  },
  {
    RESOURCE: RESOURCE.KITCHEN_QUEUE,
    VIEW: true,
    UPDATE: true,
  },
  {
    RESOURCE: RESOURCE.FOOD_CATEGORY,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.FOOD_MENUS,
    ADD: true,
    VIEW: true,
    UPDATE: true,
  },
  {
    RESOURCE: RESOURCE.BULK_UPLOAD,
    VIEW: true,
    UPDATE: true,
  },
  {
    RESOURCE: RESOURCE.ONLINE_ORDER,
    VIEW: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.WAITING_QUEUE,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.SALES_REPORT,
    VIEW: true,
    DOWNLOAD: true,
  },
  {
    RESOURCE: RESOURCE.CUSTOMERS,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
  {
    RESOURCE: RESOURCE.STAFF_MEMBERS,
    VIEW: true,
    ADD: true,
    UPDATE: true,
    DELETE: true,
  },
];

export const ORDER_DETAILS_BUTTON = {
  SAVE_ORDER_BUTTON: "SAVE_ORDER_BUTTON",
  KOT_ORDER_BUTTON: "KOT_ORDER_BUTTON",
  BILL_ORDER_BUTTON: "BILL_ORDER_BUTTON",
  FINISH_ORDER_BUTTON: "FINISH_ORDER_BUTTON",
  FINISH_ORDER_WITHOUT_PRINT: "FINISH_ORDER_WITHOUT_PRINT",
  ELECTRON_PRINT_BUTTON: "ELECTRON_PRINT_BUTTON",
  MOBILE_BUTTON: "MOBILE_BUTTON",
  SETTLE_ORDER_WITH_PRINT: "SETTLE_ORDER_WITH_PRINT",
  SETTLE_ORDER_BUTTON: "SETTLE_ORDER_BUTTON",
  DELETE_ORDER_BUTTON: "DELETE_ORDER_BUTTON",
  SWAP_ORDER_BUTTON: "SWAP_ORDER_BUTTON",
  ORDER_SUMMERY_BUTTON: "ORDER_SUMMERY_BUTTON",
  BILL_AND_KOT_TOGETHER_PRINT: "BILL_AND_KOT_TOGETHER_PRINT"
};

export const DINING_OPTIONS = [
  {
    key: ORDER_DETAILS_BUTTON.SETTLE_ORDER_BUTTON,
    value: "Settle button"
  },
  {
    key: ORDER_DETAILS_BUTTON.DELETE_ORDER_BUTTON,
    value: "Delete button"
  },
  {
    key: ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON,
    value: "Bill print button"
  },
  {
    key: ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON,
    value: "KOT print button"
  },
  {
    key: ORDER_DETAILS_BUTTON.SETTLE_ORDER_WITH_PRINT,
    value: "Settle and print button"
  },
  {
    key: ORDER_DETAILS_BUTTON.ORDER_SUMMERY_BUTTON,
    value: "Order summery button"
  },
  {
    key: ORDER_DETAILS_BUTTON.SWAP_ORDER_BUTTON,
    value: "swap order button"
  }
]

const SocketUrl = process.env.REACT_APP_SOCKET_URL;
export const SocketEndPoint = io.connect(SocketUrl);

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? "₹0,0" : "₹0,0.00");
}

export function fPercent(number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format("0.00a").replace(".00", "");
}

export function fData(number) {
  return numeral(number).format("0.0 b");
}

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

const PRIMARY_NAME = ["A", "N", "H", "L", "Q", "9", "8"];
const INFO_NAME = ["F", "G", "T", "I", "J", "1", "2", "3"];
const SUCCESS_NAME = ["K", "D", "Y", "B", "O", "4", "5"];
const WARNING_NAME = ["P", "E", "R", "S", "C", "U", "6", "7"];
const ERROR_NAME = ["V", "W", "X", "M", "Z"];

function getFirstCharacter(name) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return "primary";
  if (INFO_NAME.includes(getFirstCharacter(name))) return "info";
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return "success";
  if (WARNING_NAME.includes(getFirstCharacter(name))) return "warning";
  if (ERROR_NAME.includes(getFirstCharacter(name))) return "warning";
  return "default";
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}

export const PRINTER_TYPE = {
  IP: "ip",
  BLUETOOTH: "bluetooth",
  USB: "usb",
};

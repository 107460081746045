import { useTheme } from "@mui/material/styles";
import { Container, Grid, Stack, Typography, Switch, Box } from "@mui/material";
import { AppWidgetSummary } from "../../components/general";

import Page from "components/Page";
import { getUserInfo } from "services/user.service";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useInMobile } from "themes/Device";
export default function PricingComp() {
  const [userData, setUserData] = useState({});
  const isMobileView = useInMobile();

  useEffect(() => {
    getInfor();
  }, []);

  const getInfor = async () => {
    const result = await getUserInfo();
    if (result.data?.status === 200) {
      setUserData(result?.data);
      //   toast.success(result.data.message);
    } else if (result.status === 400) {
      toast.error(result?.message);
    }
  };

  const theme = useTheme();
  return (
    <Page title="General: App">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Remaining Number of Categories"
              total={
                userData?.data?.user?.remaining_number_of_category > 0
                  ? userData?.data?.user?.remaining_number_of_category
                  : "-"
              }
              sx={{ backgroundColor: "#C8FACD", color: "#005249" }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Remaining Number of Foods"
              total={
                userData?.data?.user?.remaining_number_of_food > 0
                  ? userData?.data?.user?.remaining_number_of_food
                  : "-"
              }
              sx={{ backgroundColor: "#D0F2FF", color: "#04297A" }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Remaining Number of orders"
              total={
                userData?.data?.user?.remaining_number_of_order > 0
                  ? userData?.data?.user?.remaining_number_of_order
                  : "-"
              }
              sx={{ backgroundColor: "#FFF7CD", color: "#7A4F01" }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Remaining Trail Days"
              total={
                userData?.data?.user?.remaining_trail_days > 0
                  ? userData?.data?.user?.remaining_trail_days
                  : "-"
              }
              sx={{ backgroundColor: "#FFE7D9", color: "#7A0C2E" }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppWidgetSummary
              title="Remaining Number of Tables"
              total={
                userData?.data?.user?.remaining_number_of_table > 0
                  ? userData?.data?.user?.remaining_number_of_table
                  : "-"
              }
              sx={{ backgroundColor: "#ffd0fe", color: "#6c0c7b" }}
            />
          </Grid>

          <Container
            sx={{
              minHeight: "100%",
              paddingTop: theme.spacing(5),
              paddingBottom: theme.spacing(4),
            }}
          >
            <Typography variant="h5" align="center" paragraph>
              Flexible plans for your community&apos;s size and needs
            </Typography>

            <Typography align="center" sx={{ color: "text.secondary" }}>
              Choose your plan and make modern online conversation magic
            </Typography>

            <Box sx={{ my: 5 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography variant="overline" sx={{ mr: 1.5 }}>
                  MONTHLY
                </Typography>

                <Switch />
                <Typography variant="overline" sx={{ ml: 1.5 }}>
                  YEARLY (save 10%)
                </Typography>
              </Stack>

              <Typography
                variant="caption"
                align="right"
                sx={{ color: "text.secondary", display: "block" }}
              >
                * Plus applicable taxes
              </Typography>
            </Box>

          </Container>
        </Grid>
      </Container>
    </Page>
  );
}

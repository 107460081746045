import React, { useState } from "react";
import {
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Stack
} from "@mui/material";
import { Inbox as InboxIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";

// context
import {
  // useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import Dot from "./Dot";

import useStyles from "./styles";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";


export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  // toggleSidebar,
  ...props
}) {
  var { classes } = useStyles();
  const history = useHistory();
  var layoutDispatch = useLayoutDispatch();
  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
        style={{ marginLeft: 5, marginRight: 5 }}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (link && link.includes('http')) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </a>
      </ListItem>
    )
  }

  const toggleMenu = (link) => {
    //toggleSidebar(layoutDispatch);
    history.push(link);
  }

  if (!children)
    return (
      <>
        <Col
          className="mb-2"
          style={{ padding: '4px' }}
          xs={6}
        >
          {/* <ListItem
        button
        component={link && Link}
        to={link}
        // className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      > */}
          <Button
            onClick={() => {
              toggleMenu(link)
            }}
            className={classes.sidebarOutlineBtn}
            color="primary"
            variant="outline">
              <Stack sx={{ alignItems: 'center'}}>
              <Stack>{icon}</Stack>
            <Stack style={{ fontSize: 14 }}>{label}</Stack>
              </Stack>
          </Button>
          {/* </ListItem> */}

        </Col>
      </>
      // <ListItem
      //   button
      //   component={link && Link}
      //   to={link}
      //   className={classes.link}
      //   classes={{
      //     root: classnames(classes.linkRoot, {
      //       [classes.linkActive]: isLinkActive && !nested,
      //       [classes.linkNested]: nested,
      //     }),
      //   }}
      //   disableRipple
      // >
      //   <ListItemIcon
      //     className={classnames(classes.linkIcon, {
      //       [classes.linkIconActive]: isLinkActive,
      //     })}
      //   >
      //     {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
      //   </ListItemIcon>
      //   <ListItemText
      //     classes={{
      //       primary: classnames(classes.linkText, {
      //         [classes.linkTextActive]: isLinkActive,
      //         [classes.linkTextHidden]: !isSidebarOpened,
      //       }),
      //     }}
      //     primary={label}
      //   />
      // </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && Link}
        onClick={toggleCollapse}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
      {children && (
        <Collapse
          in={true}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <Container fluid>
            <Row className="mb-2">
              {children.map((childrenLink, index) => (
                <RoleBasedGuard
                  key={index + 'sidebar' + childrenLink}
                  resource={childrenLink?.resourceName}
                  action={ACTION.VIEW}
                >
                  <SidebarLink
                    key={childrenLink && childrenLink.link}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    classes={classes}
                    nested
                    {...childrenLink}
                  />
                </RoleBasedGuard>
              ))}
            </Row>
          </Container>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}

import { Button, Card, Container, Stack } from "@mui/material";
import Iconify from "components/Iconify";
import React, { Component } from "react";
import { signOut } from "utility/helpers";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    // this.logErrorToServices(error.toString(), errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container className="text-center pt-5">
            <Card>
           <br/>
          <h5>Something went wrong. Please refresh the page or logout or Call or Whatsapp us on +91 7990488965 or +91 9426016918</h5>
          {/* <Button>Refresh Page</Button> */}
          <Stack direction={'row'} gap={2} justifyContent={'center'}>
          <Button
              size="small" 
              onClick={() => window.location.reload()}
              variant="outlined" 
              startIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}>
              Referesh Page
            </Button>

            <Button
              size="small" 
              onClick={() => signOut(this.props)}
              variant="contained" 
              startIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}>
              Log Out
            </Button>
          </Stack>
         <br/>
           
        
          <p style={{ color: 'red'}}>{this.state.errorInfo}</p>
        
          <br/>
          <a href="https://www.servofeat.com/" target={"_blank"}>https://www.servofeat.com/</a>
          <br/>
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}

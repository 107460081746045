import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
// Importing toastify module
import MaterialTable, { MTableToolbar } from "material-table";

import "jspdf-autotable";
// API Calls
import { useSelector } from "react-redux";
import moment from "moment";
import { RESOURCE } from "common/constant";
//context
import {
    useLayoutDispatch,
    addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PageLoaderBlock from "components/PageLoaderBlock";
import { getFoodMenuWiseReportData } from "services/report.service";
import CalendarWise from "./CalendarWise";

const PREFIX = 'FoodMenuWiseReport';

const classes = {
    cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
    cardTitleWhite: `${PREFIX}-cardTitleWhite`,
    root: `${PREFIX}-root`,
    tabel: `${PREFIX}-tabel`,
    header: `${PREFIX}-header`,
    searchbtn: `${PREFIX}-searchbtn`
};

const Root = styled('div')({
    [`& .${classes.cardCategoryWhite}`]: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    [`& .${classes.cardTitleWhite}`]: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontFamily: '"Oxygen", sans-serif',
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    [`& .${classes.root}`]: {
        width: "100%",
    },
    [`& .${classes.tabel}`]: {
        margin: "10px",
        marginTop: "0",
        "& .MuiPaper-root": {
            padding: 10,
            paddingBottom: 0,
        },
        "& tr": {
            height: 50,
            "& .MuiTableCell-root": {
                paddingLeft: "15px",
                fontSize: "14px !important",
            },
        },
    },
    [`&.${classes.header}`]: {
        backgroundColor: "white",
        boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        padding: "10px",
        marginBottom: "10px",
    },
    [`& .${classes.searchbtn}`]: {
        fontWeight: 500,
        fontSize: "1rem",
        marginTop: "10px",
        height: "50px",
        lineHeight: "2px",
    },
});

const FoodMenuWiseReport = () => {
    require("jspdf-autotable");
    const theme = useTheme();
    const [reportData, setReportData] = useState([]);

    const [loading, setLoading] = useState(false);
    const userPermission = useSelector((state) => state.user.userPermission);
    const layoutDispatch = useLayoutDispatch();
    const { t } = useTranslation();
    const hasExportPermission =
        userPermission.length > 0
            ? JSON.parse(
                userPermission?.find((a) => a.resource === RESOURCE.SALES_REPORT)
                    ?.permission,
            )?.DOWNLOAD
            : true;

    useEffect(() => {
        addCurrentScreenTitle(layoutDispatch, {
            currentScreenTitle: t("food-menu-wise-report-title"),
        });
        return () => {
            addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
        };
    }, []);


    return (
        <Root className={classes.header}>
            <PageLoaderBlock
                processing={loading}
                message="Please wait, we are fetching the record">
                <CalendarWise
                    dataFetchingFunction={getFoodMenuWiseReportData}
                    setReportData={setReportData}
                />
                <MaterialTable
                    title={
                        <h5>
                            <b>Food Menu wise </b>
                        </h5>
                    }
                    columns={
                        reportData && reportData.length > 0 ? Object.keys(reportData[0]).map(k => {
                            return {
                                title: k.toUpperCase(),
                                field: k,
                                cellStyle: {
                                    width: "15%"
                                },
                            }
                        }) : []
                    }
                    data={
                        reportData &&
                        reportData?.map((item, index) => {
                            return { ...item };
                        })
                    }
                    options={{
                        maxBodyHeight: 'calc(100vh - 240px)',
                        rowStyle: (rowData) => rowData.bill_types === "Total amount" && {
                            color: 'green',
                            background: '#eee'
                        },
                        exportButton: hasExportPermission
                            ? {
                                pdf: false,
                                csv: true,
                            }
                            : false,
                        exportFileName: `foodmenu-wise-report-${moment().format("DD-MM-YYYY")}`,
                        searchFieldStyle: {
                            color: theme.palette.grey[500],
                            borderColor: "rgba(0, 0, 0, 0.23)",
                            border: "0.8px solid",
                            borderRadius: "6px",
                            padding: "10px",
                            "&:before": {
                                borderBottom: "0px!important",
                            },
                            "&:after": {
                                borderBottom: "0px!important",
                            },
                        },
                        headerStyle: {
                            color: theme.palette.grey[600],
                            backgroundColor: theme.palette.grey[200],
                        },
                        pageSize: 10,
                        paging: false,
                        emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                        pageSizeOptions: [10, 20, 30],
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}

                />
            </PageLoaderBlock>
        </Root>
    );
};

export default FoodMenuWiseReport;

import React from "react";
import { styled } from '@mui/material/styles';
import { Col, Container, Row } from "react-bootstrap";
import { useTheme } from "@mui/styles";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import "./MobileTitle.styles.css";
// import { Button, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { AddCircleOutline, AddCircleSharp, LowPrioritySharp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";

const PREFIX = 'MobileTitle';

const classes = {
  title: `${PREFIX}-title`,
  centerProperty: `${PREFIX}-centerProperty`,
  titleContainer: `${PREFIX}-titleContainer`,
  buttonBox: `${PREFIX}-buttonBox`,
  buttonPostion: `${PREFIX}-buttonPostion`,
  btnPostion: `${PREFIX}-btnPostion`,
  priorityButton: `${PREFIX}-priorityButton`,
  mobilebutton: `${PREFIX}-mobilebutton`
};

const Root = styled('div')({
  [`& .${classes.title}`]: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1F1F24",
    margin: "0",
  },
  [`& .${classes.centerProperty}`]: {
    display: "flex",
    justifyContent: "start",
  },
  [`&.${classes.titleContainer}`]: {
    bottom: '80px',
    position: 'fixed',
    marginTop: "7px",
    width: '100%',
    zIndex: 10
  },
  [`& .${classes.buttonBox}`]: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
  },
  [`& .${classes.buttonPostion}`]: {
    display: "flex",
    justifyContent: "end !important",
    bottom: "10px",
  },
  [`& .${classes.btnPostion}`]: {
    display: "flex",
    justifyContent: "start !important",
    bottom: "10px",
  },
  [`& .${classes.priorityButton}`]: {
    justifyContent: "end !important",
  },
  [`& .${classes.mobilebutton}`]: {
    height: '100%',
    border: "1px solid ",
    fontSize: "13px",
    padding: "3px 10px",
    justifyContent: "start",
  },
});

const MobileTitle = (props) => {
  const { addButton, title = "", setPriorityButton, resource } = props;

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Root className={classes.titleContainer}>
<div className={classes.buttonBox}>
<div>
          {setPriorityButton && (
            <RoleBasedGuard
              resource={resource}
              action={ACTION.UPDATE}
            >
              <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.priorityButton}
              startIcon={<LowPrioritySharp  style={{fontSize:'30px'}}/>}
              onClick={setPriorityButton}
              style={{
                marginRight: '5px',
                padding:'8px 35px',
                boxShadow: '-1px 5px 8px rgb(0 0 0 / 22%)'
              }}
            >
              {t("mobile-screen-priority-label")}
            </Button>
            </RoleBasedGuard>
          )}
        </div>
        <div>
          {addButton && (
            <RoleBasedGuard
              resource={resource}
              action={ACTION.ADD}
            >
              <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                padding:'8px 35px',
                boxShadow: '-1px 5px 8px rgb(0 0 0 / 22%)'
              }}
              startIcon={<AddCircleSharp style={{fontSize:'30px'}}/>}
              onClick={addButton}
            >
              {t("add-button-label")}
              </Button>
            </RoleBasedGuard>

          )}
        </div>
        
      </div>
    </Root>
  );
};

export default MobileTitle;

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
import { _bankingContacts, _bankingCreditCard, _ecommerceSalesOverview } from '../../_mock';
// components
import Page from '../../components/Page';
import { CHART_COLORS } from './../../configV2'

// sections
import {
  BankingWidgetSummary,
  BankingExpensesCategories,
} from '../../sections/@dashboard/general/banking';
import { AnalyticsCurrentVisits } from '../../sections/@dashboard/general/analytics';
import { EcommerceSalesOverview } from 'sections/@dashboard/general/e-commerce';
import { EcommerceCurrentBalance } from 'sections/@dashboard/general/e-commerce';
import { useExpenseAnalytics } from '../../hooks/react-query/useReport';
import { useInMobile } from "themes/Device";
import PageLoader from "components/Loader/PageLoader";
import moment from 'moment';
import SelectionToolBar from './SelectionToolBar';
import { useEffect, useState } from 'react';

export default function ExpenseAnalytics() {
  const theme = useTheme();
  const themeStretch = true;
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));
  useEffect(() => {
    if (month && year) {
      setSelectedDate(year + "-" + month + "-" + 1)
    }
  }, [year, month])
  const { data, isLoading } = useExpenseAnalytics(selectedDate)
  const isMobileView = useInMobile();
  return (
    <Page title="Expense Analytics">
      {!isMobileView ? "" : <br />}
      {!isLoading
        ? <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
          <Grid item xs={12} ml={'auto'}>
              <SelectionToolBar
                  month={month}
                  year={year}
                  setMonth={setMonth}
                  setYear={setYear}
                ></SelectionToolBar>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BankingWidgetSummary
                title="Total Revenue"
                icon={'eva:diagonal-arrow-left-down-fill'}
                percent={2.6}
                total={data?.counts?.total}
                chartData={[...data?.daywiseexpenseData?.totalAmountTimeData]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BankingWidgetSummary
                title="Total Tax"
                color="info"
                icon={'eva:diagonal-arrow-right-up-fill'}
                percent={-0.5}
                total={data?.counts?.tax}
                chartData={[...data?.daywiseexpenseData?.totalTaxTimeData]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BankingWidgetSummary
                title="Total Discount"
                icon={'eva:diagonal-arrow-left-down-fill'}
                percent={2.6}
                color="error"
                total={data?.counts?.discount}
                chartData={[...data?.daywiseexpenseData?.totalDiscountTimeData]}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BankingWidgetSummary
                title="Total Profit"
                color="warning"
                icon={'eva:diagonal-arrow-right-up-fill'}
                percent={-0.5}
                total={data?.counts?.profit}
                chartData={[...data?.daywiseexpenseData?.totalProfitTimeData]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <EcommerceSalesOverview title="Profit Overview" data={data?.sales_data} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <EcommerceCurrentBalance title="Current Balance"
                currentBalance={data?.counts?.profit} sentAmount={data?.counts?.total_expense} totalAmount={data?.counts?.total} />
            </Grid>
            <Grid item xs={12} md={8}>
              <BankingExpensesCategories
                title="Expenses Categories"
                chartData={[...data?.topExpenseOfMonth?.map((o) => {
                  return {
                    label: `${o.name}`,
                    value: parseFloat(o.amount)
                  }
                })]}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.darker,
                  CHART_COLORS.yellow[0],
                  CHART_COLORS.blue[0],
                  CHART_COLORS.red[0],
                  CHART_COLORS.violet[2],
                  CHART_COLORS.violet[0],
                  theme.palette.success.darker,
                  CHART_COLORS.green[0],
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentVisits
                title="Payment Mode"
                chartData={[...data?.paymentType?.map((o) => {
                  return {
                    label: `${o.type}`,
                    value: parseFloat(o.sum)
                  }
                })]}
                chartColors={[
                  theme.palette.primary.main,
                  CHART_COLORS.blue[0],
                  CHART_COLORS.violet[0],
                  CHART_COLORS.yellow[0],
                ]}
              />
            </Grid>
          </Grid>
        </Container> : <>
          <PageLoader></PageLoader>
        </>}

    </Page>
  );
}

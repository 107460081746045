import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';

import styles from "assets/jss/material-dashboard-react/components/cardAvatarStyle";

const useStyles = makeStyles()(styles);

export default function CardAvatar(props) {
  const { classes, cx } = useStyles();
  const { children, className, plain, profile, ...rest } = props;
  const cardAvatarClasses = cx({
    [classes.cardAvatar]: true,
    [classes.cardAvatarProfile]: profile,
    [classes.cardAvatarPlain]: plain,
    [className]: className !== undefined
  });
  return (
    <div className={cardAvatarClasses} {...rest}>
      {children}
    </div>
  );
}

CardAvatar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  plain: PropTypes.bool
};

import React, { useState, useRef, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
    Grid,
    Stack,
    Button as Btn,
    Button,
    Box,
    Card, Typography
} from "@mui/material";
import {
    Home as HomeIcon,
    Deck,
    People,
    History,
    Assessment,
    Category,
    HelpOutline,
    Group,
} from "@mui/icons-material";
import {
    faBookOpen,
    faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import LaunchIcon from '@mui/icons-material/Launch';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Page from "components/Page";
import { useTheme } from '@mui/material/styles';
import pending from "../../assets/img/pending.png"
import CardAvatar from "components/Card/CardAvatar";
import { useTranslation } from "react-i18next";
import DashboardCard from 'components/Dashboard/DashboardCard';
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useSelector } from "react-redux";
import UserQrPrints from 'pages/UserProfile/UserQrPrints';
import { getAllSettingList, updateBillingInfo } from "services/setting.service";
import NoImgPreview from "assets/img/NoImgPreview.jpg";
import configData from "config";
import SocialmediaIcon from 'components/Socialmedia/SocialmediaIcon';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Icon } from '@iconify/react';
import instagram from '../../assets/img/instagram.png'
import { styled } from '@mui/material/styles';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import { RESOURCE } from 'common/constant';
import { ACTION } from 'common/constant';
import CheckPermission from 'guards/CheckPermission';

const PREFIX = 'Dashboard5';

const classes = {
    icon: `${PREFIX}-icon`,
    aboutcompany: `${PREFIX}-aboutcompany`,
    sucessicon: `${PREFIX}-sucessicon`,
    inquiryicon:`${PREFIX}-inquiryicon`,
    inquirytext:`${PREFIX}-inquirytext`
};


const DashboardOverview = () => {

    const [imgSrc, setImageSrc] = useState({});

    const history = useHistory();

    const { t } = useTranslation();
    const theme = useTheme();
    const ERROR_DARK = theme.palette.warning.dark;
    const PRIMARY_MAIN = theme.palette.info.main;
    const SUCCESS_MAIN = theme.palette.success.dark;
    const SECONDARY_MAIN = theme.palette.grey[600];
    const refQRCODE = useRef();
    const userInfo = useSelector((state) => state.user.userData);

    const diningTableOrderDataList = useSelector(
        (state) => state.api.diningTable,
    );

    const foodCategoryDataList = useSelector((state) => state.api.category);

    const foodItemMenuDataList = useSelector((state) => state.api.food_meal_list);

    const Root = styled(Grid)({
        [`& .${classes.icon}`]: {
            width: "70px",
            height: "70px",
            color: SECONDARY_MAIN,
            [theme.breakpoints.down('md')]: {
                width: "40px",
                height: "40px",
            }
        },
        // [`& . ${classes.sucessicon}`]: {
        //     color:SUCCESS_MAIN,
        // },
        [`& .${classes.aboutcompany}`]: {
            borderBottom: '1px solid black',
            [theme.breakpoints.down('md')]: {
                fontSize: '18px'
            }
        },
        [`& .${classes.inquiryicon}`]:{
            color:PRIMARY_MAIN,
            marginRight:'5px'
        },
        [`& .${classes.inquirytext}`]:{
            color:SECONDARY_MAIN,
            marginTop: '8px',
        }


    });
    useEffect(() => {
        getAllSettingList()
            .then((res) => {
                if (res.data[0]?.logo)
                    setImageSrc(`${configData?.S3_BUCKET}${res?.data[0]?.logo}`);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <Root>
            <Page title="Dashboard Analytics">
                <>
                    <Grid container spacing={2} sx={{ paddingTop: "25px", p: 3 }}>
                        <Grid item md={2} lg={3} sm={3} xs={12}>
                            <Stack sx={{ alignItems: 'center', mt: 4 }}>
                                <CardAvatar profile>
                                        <img
                                            style={{ height: "100px", width: "100px" }}
                                            src={imgSrc ? imgSrc : NoImgPreview}
                                            alt="..."
                                        />
                                </CardAvatar>
                                <Typography sx={{ fontSize: 18, textAlign: 'center', mt: 1, color: SUCCESS_MAIN }}>{`Welcome ${userInfo?.user?.name}`}</Typography>
                                <RoleBasedGuard
                                    resource={RESOURCE.PROFILE}
                                    action={ACTION.UPDATE}
                                >
                                <Btn
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => { history.push("/app/profile") }}
                                    sx={{ mb: 2 }}
                                    >My Profile</Btn>
                                </RoleBasedGuard>
                                <UserQrPrints
                                    ref={refQRCODE}
                                    orderUrl={`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`}
                                />
                                <a
                                    href={`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`}
                                    target="_blank"
                                    rel="noreferrer"
                                >{`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`} <LaunchIcon /></a>
                                <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <SocialmediaIcon
                                        icon={<Icon icon="logos:facebook" width="24" height="24" />}
                                        link='https://www.facebook.com/'
                                    />
                                    <SocialmediaIcon
                                        icon={<img src={instagram} alt="..." style={{ width: "30px", height: "30px", display: 'inline-block' }} />}
                                        link="https://www.instagram.com/"
                                    />
                                    <SocialmediaIcon
                                        icon={<Icon icon="logos:whatsapp-icon" width="24" height="24" />}
                                        link="https://api.whatsapp.com/send/?phone=919773426554&text&type=phone_number&app_absent=0"
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <Grid container spacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.DINE_IN_TABLE}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/dining-table"
                                        title="Dining Tables"
                                        identifyicon={
                                            (diningTableOrderDataList && diningTableOrderDataList?.length > 0) ?
                                                <CheckCircleRoundedIcon sx={{ color: SUCCESS_MAIN }} />
                                                : <img src={pending} />}
                                        icon={<Deck className={classes.icon} />
                                        }
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.FOOD_CATEGORY}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/food-category"
                                        title="Food Category"
                                        identifyicon={
                                            (foodCategoryDataList && foodCategoryDataList?.length > 0) ?
                                                <CheckCircleRoundedIcon sx={{ color: SUCCESS_MAIN }} />
                                                : <img src={pending} />
                                        }
                                        icon={<Deck className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>

                                    <DashboardCard
                                        RESOURCE={RESOURCE.FOOD_MENUS}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/food-menu"
                                        title="Food Menu"
                                        identifyicon={
                                            (foodItemMenuDataList && foodItemMenuDataList?.length > 0) ?
                                                <CheckCircleRoundedIcon sx={{ color: SUCCESS_MAIN }} />
                                                : <img src={pending} />
                                        }
                                        icon={<FontAwesomeIcon icon={faBookOpen} className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.ANALYTICS}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/analytics"
                                        title="Analytics"
                                        icon={<HomeIcon fontSize='medium' className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.SALES_REPORT}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/report"
                                        title="Report"
                                        icon={<Assessment fontSize='medium' className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.CUSTOMERS}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/customers"
                                        title="Customers"
                                        icon={<Group fontSize='medium' className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.STAFF_MEMBERS}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/staff"
                                        title="Staff Members"
                                        icon={<People fontSize='medium' className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.WAITING_QUEUE}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/customer-queue"
                                        title="Customer Queue"
                                        icon={<FontAwesomeIcon icon={faUserTimes} className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.PARCEL_ORDER}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/order/new"
                                        title="New Order"
                                        icon={<Category className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.SALES}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/history/order"
                                        title="Sales History"
                                        icon={<History className={classes.icon} />}
                                    />
                                </Grid>
                                <Grid item md={4} lg={3} sm={6} xs={4}>
                                    <DashboardCard
                                        RESOURCE={RESOURCE.ROLES}
                                        ACTION={RESOURCE.ADD}
                                        link="/app/roles"
                                        title="Roles"
                                        icon={<SupervisedUserCircleIcon className={classes.icon} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} lg={3} sm={3} xs={12}>
                            <Card sx={{ padding: "20px" }}>
                                <h5 className={classes.aboutcompany}>About Servofeat</h5>
                                <Stack sx={{ fontSize: theme.typography.htmlFontSize }}>
                                    <a href="tel:+91 7990488965" className={classes.inquirytext}>
                                        <Icon icon="ic:baseline-call" width="20" height="20" className={classes.inquiryicon} />
                                        +91 7990488965
                                    </a>
                                    <a href="tel:+91 9426016918" className={classes.inquirytext}>
                                        <Icon icon="ic:baseline-call" width="20" height="20" className={classes.inquiryicon} />
                                        +91 9426016918
                                    </a>
                                    <a href="tel:+91 9773426554" className={classes.inquirytext}>
                                        <Icon icon="ic:baseline-call" width="20" height="20" className={classes.inquiryicon} />
                                        +91 9773426554
                                    </a>
                                    <a href=" https://docs.servofeat.org/" target='_blank' className={classes.inquirytext}>
                                        <HelpOutline style={{ width: "20px", height: '20px' }} className={classes.inquiryicon}/>
                                        Help Docs
                                    </a>
                                    <Link className={classes.inquirytext} onClick={() => { history.push("/app/help-videos") }}>
                                        <Icon icon="carbon:video-filled" width="20" height="20" className={classes.inquiryicon} />
                                        Video Tutorials
                                    </Link>
                                    <a href="mailto: info@servofeat.com" target="_blank" className={classes.inquirytext}>
                                        <Icon icon="mdi:email"  width="20" height="20" className={classes.inquiryicon}/>
                                        info@servofeat.com
                                    </a>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            </Page>
        </Root>
    )
}

export default DashboardOverview
import React, { Fragment, useState, useRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import ReactToPrint from "react-to-print";
import { toast } from "react-hot-toast";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import { isElectron } from "react-device-detect";
// core components
import MaterialTable, { MTableToolbar } from "material-table";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, IconButton } from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Print,
  GetApp,
  Delete,
  Cancel,
  Deck,
  DeleteForever,
} from "@mui/icons-material";

// API Calls
import {
  deleteDiningTable,
  DiningTableBulkDelete,
} from "../../services/diningTable.service";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiningTable } from "../../state/thunk-async";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpModel from "components/Popup/PopUpModel";
import NotFoundComponent from "components/NotFoundComponent";
import TemporaryDrawer from "components/TemporaryDrawer";
import DineInQrPrint from "./DineInQrPrint";
import AddEditDiningTable from "./AddEditDiningTable";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import PriorityDiningTable from "./PriorityDiningTable";
import { DeskTopContainer, MobileContainer } from "themes/Device";
import SearchBarInput from "components/SearchBarInput";
import DiningTable from "../../components/DiningTable/DiningTable";
import MobileTitle from "components/MobileTitle";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import AddTableInBulk from "./AddTableInBulk";
import CheckPermission from "./../../guards/CheckPermission";

const PREFIX = 'DiningTableList';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  prioritybtn: `${PREFIX}-prioritybtn`,
  mobileCard: `${PREFIX}-mobileCard`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
    "& th": {
      position: 'sticky',
      top: '50px',
      background: 'white'
    },
  },

  [`& .${classes.prioritybtn}`]: {
    "& a:hover,& a:focus": {
      backgroundColor: "#FF5C93",
    },
    marginLeft: "20px",
    backgroundColor: "#FF5C93",
    color: "white",
  },

  [`& .${classes.mobileCard}`]: {
    padding: "3px",
    paddingBottom: '80px'
  }
}));

const DiningTableList = () => {
  const theme = useTheme();
  const history = useHistory();
  const diningTableDataList = useSelector((state) => state.api.diningTable);
  const dataLoading = useSelector((state) => state.api.diningTableLoder);
  const userInfo = useSelector((state) => state.user.userData);
  const [modalShow, setModalShow] = useState(false);
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteTableId, setDeleteTableId] = useState(0);
  const [updateTableId, setUpdateTableId] = useState(0);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const refQRCODE = useRef();
  const dispatch = useDispatch();
  const [URLQRCodeURL, setURLQRCodeURL] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [beautifulDrawerShow, setBeautifulDrawerShow] = useState(false);
  const [bulkeditShow, setbulkeditShow] = useState(false);
  const [searchDiningTable, setSearchDiningTable] = useState("");
  const [filterDiningTableList, setFilterDiningTableList] = useState([]);
  const [addEditDrawerShow, setAddEditDrawerShow] = useState(false);
  const { t } = useTranslation();

  const deleteDiningTableData = async (diningTableId) => {
    if (diningTableId > 0) {
      setDeletingBtnProcess(true);
      const result = await deleteDiningTable(diningTableId);
      if (result.status === 200) {
        setDeletingBtnProcess(false);
        toast.success(result?.data?.message);
      } else {
        setDeletingBtnProcess(false);
        toast.error(result?.message);
      }
      dispatch(fetchDiningTable());
      setDeleteModalShow(false);
    }
  };

  const downloadQR = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  function sortByPriority(a, b) {
    if (a.is_priority < b.is_priority) {
      return -1;
    }
    if (a.is_priority > b.is_priority) {
      return 1;
    }
    return 0;
  }

  const mobileDeleteData = (deleteid) => {
    setDeleteTableId(deleteid);
    setDeleteModalShow(true);
  };

  const mobileEditData = (editid) => {
    // setAddEditDrawerShow(true);
    // setUpdateTableId(editid);
    history.push({
      pathname: `/app/dining-table/edit/${editid}`,
      state: {
        diningTableId: editid,
        backToMain: () => history.push(`/app/dining-table`),
      },
    });
  };
  const clearTxt = () => {
    setSearchDiningTable("");
  };

  const openQR = (tableEncryptID, id) => {
    setURLQRCodeURL(tableEncryptID);
    setModalShow(true);
  };

  const addEditDrawerClose = () => {
    setAddEditDrawerShow(false);
    setBeautifulDrawerShow(false);
    setbulkeditShow(false);
  };

  useEffect(() => {
    setFilterDiningTableList(diningTableDataList);
  }, [diningTableDataList]);

  useEffect(() => {
    if (layoutState?.currentScreenTitle != "DineIn Table") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: "DineIn Table",
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "DineIn Table") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  useEffect(() => {
    if (searchDiningTable) {
      let filterDiningTable = diningTableDataList.filter(
        (item) =>
          item.name_of_table &&
          item.name_of_table
            ?.toLowerCase()
            ?.includes(searchDiningTable.toLowerCase()),
      );
      setFilterDiningTableList(filterDiningTable);
    } else {
      setFilterDiningTableList(diningTableDataList);
    }
  }, [searchDiningTable]);

  const [bulkDeleteIds, setbulkDeleteIds] = useState([]);
  const [deletebulkPopupshow, setDeletebulkPopupshow] = useState(false);
  const handleDeleteRows = async () => {
    setDeletebulkPopupshow(false)
    setbulkDeleteIds([]);
    const result = await DiningTableBulkDelete({
      ids: bulkDeleteIds
    });
    if (result?.data?.status === 200) {
      toast.success(result?.data?.message);
    } else {
      toast.error(result?.message);
    }
    dispatch(fetchDiningTable());
  };
  const handleDeleteRowsConformation = async (event, rowData) => {
    const ids = rowData.map((d) => d.id);
    setDeletebulkPopupshow(true)
    setbulkDeleteIds(ids);
  };
  return (
    <Root>
      <PopUpModel
        show={modalShow}
        heading={t("dining-table-scan-qrcode")}
        body={
          <>
            <DineInQrPrint
              ref={refQRCODE}
              orderUrl={`${isElectron ? 'https://app.servofeat.org' : window.location.origin}/#/online-order/dine-in/${URLQRCodeURL}?table=${tableId}&username=${userInfo?.user?.username}`}
            />
            <a
              href={`${isElectron ? 'https://app.servofeat.org' : window.location.origin}/#/online-order/dine-in/${URLQRCodeURL}?table=${tableId}&username=${userInfo?.user?.username}`}
              target="_blank"
              rel="noreferrer"
            >{`${isElectron ? 'https://app.servofeat.org' : window.location.origin}/#/online-order/dine-in/${URLQRCodeURL}?table=${tableId}&username=${userInfo?.user?.username}`}</a>
          </>
        }
        footer={
          <>
            <RoleBasedGuard
              resource={RESOURCE.DINE_IN_TABLE}
              action={ACTION.DOWNLOAD}
            >
              <Button
                variant="contained"
                color="primary"
                className="mr-3"
                onClick={downloadQR}
              >
                <GetApp /> {t("dining-table-qrcode-download")}
              </Button>
            </RoleBasedGuard>
            <RoleBasedGuard
              resource={RESOURCE.DINE_IN_TABLE}
              action={ACTION.PRINT}
            >
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary">
                    <Print /> {t("dining-table-qrcode-print")}
                  </Button>
                )}
                content={() => refQRCODE.current}
              />
            </RoleBasedGuard>
          </>
        }
        onHide={() => setModalShow(false)}
      />
      <PopUpModel
        show={deleteModalShow}
        heading={t("dining-table-delete-popup-title")}
        body={t("dining-table-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeleteModalShow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteDiningTableData(deleteTableId)}
            >
              <Delete />{" "}
              {deletingBtnProcess ? (
                <CircularProgress />
              ) : (
                t("dining-table-popup-delete")
              )}{" "}
            </Button>
          </>
        }
        onHide={() => setDeleteModalShow(false)}
      />
      <PopUpModel
        show={deletebulkPopupshow}
        heading={"Are you sure you want to delete selected tables?"}
        body={"Please Conform Selected Opration."}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletebulkPopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteRows}
            >
              {" "}
              <Delete /> {t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletebulkPopupshow(false)}
      />
      <TemporaryDrawer
        isOpen={addEditDrawerShow}
        drawerClose={addEditDrawerClose}
        title={t("dining-table-drawer-title")}
        icon={<Deck />}
      >
        <AddEditDiningTable
          diningTableId={updateTableId}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={beautifulDrawerShow}
        drawerWidth="35%"
        drawerMinWidth="500px"
        icon={<LowPriorityIcon />}
        title={t("priority-label")}
      >
        <PriorityDiningTable
          diningTableDataList={diningTableDataList}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={bulkeditShow}
        drawerWidth="35%"
        drawerMinWidth="500px"
        icon={<LowPriorityIcon />}
        title={"Bulk Table Addition"}
      >
        <AddTableInBulk
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={makePayment}
          >
            Payment
          </Button> */}
          <MaterialTable

            style={{ padding: "20px" }}
            title={
              <div className="d-flex">
                <h5>
                  <b className="ml-3">{t("dining-table-list-title")}</b>
                </h5>
              </div>
            }
            columns={[
              {
                title: t("name-label"),
                field: "nameTable",
                cellStyle: {
                  width: "25%",
                  wordBreak: "break-all"
                },
              },
              {
                title: t("dining-table-capacity"),
                field: "capacity",
                cellStyle: {
                  width: "10%",
                },
              },
              {
                title: t("description-label"),
                field: "description",
                cellStyle: {
                  width: "20%",
                  wordBreak: "break-all"
                },
              },
              {
                title: t("dining-table-reserved-open"),
                field: "available",
                cellStyle: {
                  width: "15%",
                },
              },

              {
                title: t("available-unavailable"),
                field: "active",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.DINE_IN_TABLE}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        title={t("dining-table-edit")}
                        color="primary"
                        aria-label={t("dining-table-edit-icon")}
                        onClick={() => {
                          setUpdateTableId(rowData?.id);
                          setAddEditDrawerShow(true);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.DINE_IN_TABLE}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("dining-table-delete-icon")}
                        aria-label={t("dining-table-delete-icon")}
                        onClick={() => {
                          setDeleteTableId(rowData?.id);
                          setDeleteModalShow(true);
                        }}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <IconButton
                      title={t("dining-table-view-qrcode-icon")}
                      arial-label={t("dining-table-view-qrcode-icon")}
                      onClick={() => {
                        setURLQRCodeURL(rowData?.tableEncryptID);
                        setTableId(rowData?.id);
                        setModalShow(true);
                      }}
                      size="large">
                      <FontAwesomeIcon icon={faQrcode} />
                    </IconButton>
                  </Fragment>
                ),
              },
            ]}
            data={
              diningTableDataList &&
              diningTableDataList.sort(sortByPriority).map((item, index) => {
                return {
                  nameTable: item.name_of_table,
                  capacity: item.capacity,
                  description: item.description || "-",
                  tableEncryptID: item?.table_encypt_id,
                  url: item.url,
                  id: item.id,
                  active: item.is_active ? (
                    <>
                      <div>
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={"success"}
                        >
                          {item?.is_active ? "Available" : item?.is_active}
                        </Label>
                      </div>
                    </>
                  ) : (
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color="error"
                    >
                      {!item?.is_active ? "Unavailable" : item?.is_active}
                    </Label>
                  ),
                  available: item.is_available ? (
                    <>
                      <div>
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={"success"}
                        >
                          {item?.is_available ? "true" : item?.is_available}
                        </Label>
                      </div>
                    </>
                  ) : (
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color="error"
                    >
                      {!item?.is_available ? "false" : item?.is_available}
                    </Label>
                  ),
                };
              })
            }
            options={{
              paging: true,
              pageSize: 100,
              selection: CheckPermission(RESOURCE.DINE_IN_TABLE, ACTION.DELETE) ? true : false,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              maxBodyHeight: 'calc(100vh - 240px)',
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
                position: 'sticky',
                top: '0px'
              },
            }}
            actions={
              CheckPermission(RESOURCE.DINE_IN_TABLE, ACTION.DELETE) ? [
                {
                  icon: () => <DeleteForever />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRowsConformation
                }
              ] : []
            }
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: dataLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("dining-table-empty-data-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <RoleBasedGuard
                      resource={RESOURCE.DINE_IN_TABLE}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // onClick={addEditModelToggle}
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ background: "", color: "#fff" }}
                        onClick={() => {
                          setUpdateTableId(0);
                          setAddEditDrawerShow(true);
                        }}
                      >
                        {t("dining-table-add")}
                      </Button>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.DINE_IN_TABLE}
                      action={ACTION.UPDATE}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        // className={classes.prioritybtn}
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          setBeautifulDrawerShow(true);
                        }}
                        startIcon={<LowPriorityIcon />}
                        disabled={diningTableDataList.length > 0 ? false : true}
                      >
                        {t("priority-label")}
                      </Button>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.DINE_IN_TABLE}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="warning"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          setbulkeditShow(true);
                        }}
                        startIcon={<LowPriorityIcon />}
                      >
                        {"Bulk Add Table"}
                      </Button>
                    </RoleBasedGuard>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle
            resource={RESOURCE.DINE_IN_TABLE}
            title={t("dining-tables-mobile")}
            addButton={() => history.push("/app/dining-table/add")}
            setPriorityButton={() => history.push("/app/dining-table/priority")}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchDiningTable(e.target.value);
            }}
            value={searchDiningTable}
            placeholder={t("dining-table-search")}
            clearTxt={clearTxt}
          />
          {dataLoading ? (
            <SkeletonLoader count={5} height="6rem" />
          ) : (
            <div>
              {filterDiningTableList &&
                filterDiningTableList.map((item, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 4 : 0 }}>
                    <DiningTable
                      resource={RESOURCE.DINE_IN_TABLE}
                      name_of_table={item?.name_of_table}
                      is_active={item?.is_active}
                      is_available={item?.is_available}
                      capacity={item?.capacity}
                      description={item?.description || "-"}
                      deleteData={mobileDeleteData}
                      editData={mobileEditData}
                      openQR={openQR}
                      itemId={item?.id}
                      table_encypt_id={item?.table_encypt_id}
                    />
                  </div>
                ))}
            </div>
          )}
          {filterDiningTableList.length < 1 && !dataLoading && (
            <NotFoundComponent imgUrl={noFoundImg}>
              <p style={{ fontWeight: 600 }}>
                {t("dining-table-empty-data-message")}
              </p>
            </NotFoundComponent>
          )}
        </div>
      </MobileContainer>
    </Root>
  );
};

export default DiningTableList;

import React from "react";
import { IconButton } from "@mui/material";
import "./IconButtonComponent.styles.css";

const IconButtonComponent = (props) => {
  const {
    children,
    classes,
    color,
    disabled,
    disableFocusRipple,
    disableRipple,
    edge,
    size,
    style,
    ...other
  } = props;
  return (
    <>
      <IconButton
        children={children}
        classes={classes}
        color={color}
        disabled={disabled}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        edge={edge}
        size={size}
        style={style}
        {...other}
      ></IconButton>
    </>
  );
};

export default IconButtonComponent;

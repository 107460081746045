import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Link,
  Container,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { NotificationImportant } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Image from "../../components/Image";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { Alert } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { forgotPassword } from "services/login.service";
import { forgotPasswordVerify } from "services/login.service";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Register(props) {
  const mdUp = useResponsive("up", "md");
  const classes = useStyles();
  const theme = useTheme();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const handleShopCreate = (values) => {
    if (!otpSent) {
      setSubmittingBtnProcess(true);
      setEmail(values.email);
      forgotPassword(values.email)
        .then((res) => {
          if (res?.status === 200 || res?.data) {
            setOtpSent(true);
            setSubmittingBtnProcess(false);
          } else {
            setOtpSent(false);
            toast.error(res?.response?.data?.message ? res?.response?.data?.message : "Shop Not Found");
            setSubmittingBtnProcess(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try after some time");
          setOtpSent(false);
          setSubmittingBtnProcess(false);
        });
    }
    else {
      setSubmittingBtnProcess(true);
      forgotPasswordVerify({
        email: values.email,
        otp: values.otp,
        password: values.password,
      })
        .then((res) => {
          if (res?.status === 200 || res?.data) {
            props.history.push({
              pathname: `/login`
            });
            setSubmittingBtnProcess(false);
          } else {
            toast.error(res?.response?.data?.message ? res?.response?.data?.message : "Shop Not Found");
            setSubmittingBtnProcess(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try after some time");
          setSubmittingBtnProcess(false);
        });
    }
  };
  const reSendOtp = async () => {
    setSubmittingBtnProcess(true);
    forgotPassword(email)
      .then((res) => {
        if (res?.status === 200 || res?.data) {
          setOtpSent(true);
          setSubmittingBtnProcess(false);
        } else {
          setOtpSent(false);
          toast.error(res?.response?.data?.message ? res?.response?.data?.message : "Shop Not Found");
          setSubmittingBtnProcess(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try after some time");
        setOtpSent(false);
        setSubmittingBtnProcess(false);
      });
  };

  return (
    <Page title="Register">
      <Alert variant="warning" className="online-offline-alert">
        <NotificationImportant /> <span>
          You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
        </span>
      </Alert>
      <RootStyle>

        <HeaderStyle>
          {/* <Logo /> */}
          <Box>
            <Image
              disabledEffect
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
              }
              sx={{ width: 50, height: 50 }}
              className={classes.img}
            />
          </Box>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography
              variant="h3"
              sx={{ px: 5, mt: 10, mb: 5, fontSize: "2rem" }}
            >
              Forgot password by entering email or phone
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontSize: "1.5rem" }}
                >
                  {!otpSent ? "Enter your email or mobile no." : "Please Enter Otp"}
                </Typography>
                {
                  !otpSent ?
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, color: theme.palette.grey[600] }}
                    >
                      Go back to?{" "}
                      <Link
                        variant="subtitle2"
                        to={"/login"}
                        component={RouterLink}
                      >
                        Login
                      </Link>
                    </Typography>
                    :
                    <Typography
                      variant="body2"
                      sx={{ mt: 2, color: theme.palette.grey[600] }}
                    >
                      We sent an otp, please verify otp and change your password.
                    </Typography>
                }
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleShopCreate}
              validate={(values) => {
                const errors = {};
                const whitespaceRegExp = /^$|\s+/;
                const poorRegExp = /[a-z]/;
                const weakRegExp = /(?=.*?[0-9])/;
                const strongRegExp = /(?=.*?[#?!@$%^&*-])/;

                const poorPassword = poorRegExp.test(values.password);
                const weakPassword = weakRegExp.test(values.password);
                const strongPassword = strongRegExp.test(values.password);
                const whiteSpace = whitespaceRegExp.test(values.password);
                const emailRegex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (!otpSent) {
                  if (!values?.email) {
                    errors.email = "Email is required.";
                  } else if (values?.email?.length > 50) {
                    errors.email = "Maximum length should be less then 50.";
                  }
                  // else if (!emailRegex.test(values?.email)) {
                  //   errors.email = "Email is invalid";
                  // }
                }
                if (otpSent) {
                  if (!values.otp) {
                    errors.otp = "Otp is required.";
                  } 
                  if (!values.password) {
                    errors.password = "Password is required.";
                  } else if (whiteSpace) {
                    errors.password = "Whitespaces are not allowed";
                  } else if (
                    values.password.length <= 3 &&
                    (poorPassword || weakPassword || strongPassword)
                  ) {
                    errors.password = "Password is Poor.";
                  }
                  if (!values.conform_password) {
                    errors.conform_password = "Conform password is required.";
                  } else if (values.password != values.conform_password) {
                    errors.conform_password = "Password and conform password not match.";
                  }
                }
                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit=
                  {(event) => {
                    handleSubmit(event).then(() => {
                      form.reset();
                    });
                  }}>
                  <Box>
                    <Stack spacing={2}>
                      {!otpSent && <>
                        <Stack>
                          <Field name="email">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                label="Email/ Phone no"
                                type="text"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error
                                  && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>
                      </>}
                      {otpSent && <>
                        <Stack>
                          <Field name="otp">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                label="OTP"
                                type="number"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>
                        <Stack>
                          <Field name="password">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                label="Password"
                                type="password"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>
                        <Stack>
                          <Field name="conform_password">
                            {({ input, meta }) => (
                              <TextInputMui
                                {...input}
                                label="Conform Password"
                                type="password"
                                autoComplete="none"
                                variant="outlined"
                                fullWidth
                                error={meta.touched && meta.error ? true : false}
                                helperText={
                                  meta.touched && meta.error && `${meta.error}`
                                }
                                FormHelperTextProps={{
                                  style: { margin: "5px" },
                                }}
                              />
                            )}
                          </Field>
                        </Stack>
                      </>}
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        type="submit"
                        variant="contained"
                        loading={submittingBtnProcess}
                      >
                        {submittingBtnProcess ? "Processing.." :
                          otpSent ? "Change Password" :
                            "Sent OTP"}
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        onClick={() => reSendOtp()}
                        type="submit"
                        loading={submittingBtnProcess}
                      >
                        Resend OTP
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        onClick={() => {
                          props.history.push({
                            pathname: `/login`,
                          });
                        }}
                        fullWidth
                      >
                        Back
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

import { api } from "./../utility/interceptor";
import { toast } from "react-hot-toast";
import axios from "axios";

var config = {
  headers: {
    'Content-type': 'multipart/form-data'
  }
};

export const uploadFile = async (formData) => {
  try {
    let res = await api
      .post(`/attachments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
    return { status: res?.status, data: res };
  } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }
  }
};
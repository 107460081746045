import { Tooltip } from "@mui/material";
import moment from "moment";
import { isElectron } from "react-device-detect";

export const signOut = (props) => {
  localStorage.removeItem("token");
  localStorage.removeItem("billing");
  localStorage.removeItem("Info_api_break")
  localStorage.removeItem("access");
  localStorage.removeItem("username_admin");
  localStorage.clear();
  if(props && isElectron)
  {
    props?.history?.push("/login");
  }
  else {
    window.location.href = "/login";
  }
};

export const checkTheCurrentLoginRoleType = () => {
   if(localStorage.getItem("username_admin")?.split("/")?.length > 0 && localStorage.getItem("username_admin")?.split("/")[1] === "member")
   {
    return "STAFF"
   }
   else {
    return "ADMIN"
   }
} 


export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};


export const sortFoodMealBasedOnSetting = (foodMealItemData, shopSettingListData, selectedFoodItems) => {

  // if(selectedFoodItems && selectedFoodItems.length > 0)
  // {
  //   const customSortArray = [];
  //     foodMealItemData.map((data) => {
  //           let selectedFoodItem = selectedFoodItems?.find((selectedItem, i) => selectedItem?.food_item_id === data?.id);
  //           if(selectedFoodItem)
  //           {
  //             customSortArray.unshift(data)
  //           }
  //           else {
  //             customSortArray.push(data)
  //           }
  //       });
  //   return customSortArray;
  // }
  // else {
    if(foodMealItemData && foodMealItemData.length > 0)
    {
      switch (shopSettingListData.FOOD_MEAL_SORT) {
        case "SORT_BY_PRICE_ASC":
          return foodMealItemData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
        case "SORT_BY_PRICE_DESC":
          return foodMealItemData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
        case "SORT_BY_ALPHABETIC_ASC":
          return foodMealItemData.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        case "SORT_BY_ALPHABETIC_DESC":
          return foodMealItemData.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
        case "SORT_BY_NUMBER_DESC":
          return foodMealItemData.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
        case "SORT_BY_NUMBER_ASC":
          return foodMealItemData.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase());
        case "SORT_BY_PRIORITY_COUNT_DESC":
          return foodMealItemData.sort((a, b) => a.is_priority - b.is_priority);
        case "AUTO_SORT_BY_MOST_SELL_ASC":
          return foodMealItemData
        case "AUTO_SORT_BY_MOST_SELL_DESC":
          return foodMealItemData
        case "DEFAULT":
          return foodMealItemData
        default:
          return foodMealItemData || []
      }
      return foodMealItemData || []
    }
   
  // }
}
export const getGroupBy = (inputArray, key) => {
  return inputArray.reduce(function (a, e) {
    // GROUP BY estimated key (estKey), well, may be a just plain key
    // a -- Accumulator result object
    // e -- sequentally checked Element, the Element that is tested just at this itaration

    // new grouping name may be calculated, but must be based on real value of real field
    let estKey = e[key];

    (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
    return a;
  }, {});
};

export const getShopSettingFromKey = (shopSettingList, customKey) => {
  return (
    shopSettingList?.length > 0 &&
    shopSettingList.find(({ key, value }) => key === customKey)
  );
};

export const setShopSettingInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getShopSettingFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const handleImageLoadingError = (currentTarget, newSrc) => {
  currentTarget.onerror = null;
  currentTarget.src = newSrc;
};

export const isJson = (str) => {
  try {
     JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

export const checkPrinterJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return false;
  }
  // return true;
};

export const getTimeDifference = (createdDate) => {
  const createDate = moment(createdDate);
  const currentDate = moment();

  const milliseconds = currentDate.diff(createDate);

  let s = Math.floor((milliseconds / 1000) % 60);
  let m = Math.floor((milliseconds / 1000 / 60) % 60);
  let h = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  return { hour: h, minute: m, second: s };
};


export const validEmail = new RegExp(
  '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
);
export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
export const validName = new RegExp("^[a-zA-Z0-9? ,_-]*$");
export const validUserName = new RegExp("^[a-zA-Z0-9]*$");
export const checkSpaceAndRegex = (name) => {
  if (name?.trim() == '') {
    return "Empty name is not allowed.";
  }
  else if (!validName.test(name)) {
    return "Special character is not allowed";
  }
  return '';
}

export const checkUserName = (name) => {
  if (name?.trim() == '') {
    return "Empty name is not allowed.";
  }
  else if (!validUserName.test(name)) {
    return "Special character or space is not allowed";
  }
  return '';
}

export const ServiceEnabled = ({ envVariableList, serviceName, children }) => {
  if (!envVariableList?.[serviceName]) {
    return ""
  }
  return children;
}

export const AppendCustomCss = (css) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  let style = document.createElement('style');


  style.type = 'text/css';
  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);

}

// here offset will be +5:30 or -5:30
export const offSetToLocalDate = (offSet) => {
  var d = new Date(new Date().toUTCString());
  //* for test add 1day next
  var x = new Date(
    d.valueOf() +
    3600000 * offSet,
  );
  console.log("Local date from utils date");
  console.log(x);
  return x;
}

export const checkIfElectronLoaded = () => {
  try {
    if (window.require("electron")) {
      return true
    }
    return false
  } catch (error) {
    return false;
  }
}

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str && (str?.length > length)) {
    return str?.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

export const getOrderDateBasedOnTimeZone = (orderTokenResetTime) => {
  try {
    var time_data = orderTokenResetTime?.split(":");
    var ORDER_TOKEN_RESET_HOUR = parseFloat((time_data?.[0]) ? (time_data?.[0]) : `3`);
    var ORDER_TOKEN_RESET_MINUTE = parseFloat((time_data?.[1]) ? (time_data?.[1]) : `00`);
     var CURRENT_HOUR = parseFloat(moment().format("HH"));
    var CURRENT_MINUTE = parseFloat(moment().format("mm"));

    if(CURRENT_HOUR >= ORDER_TOKEN_RESET_HOUR) 
    {
      if(CURRENT_HOUR === ORDER_TOKEN_RESET_HOUR && CURRENT_MINUTE <= ORDER_TOKEN_RESET_MINUTE)
      {
        return  moment().subtract(1, "day").format("YYYY-MM-DD");
      }
      return  moment().format("YYYY-MM-DD");
    }
    return  moment().subtract(1, "day").format("YYYY-MM-DD");
    // console.log('%chelpers.js line:188 moment().format("YYYY-MM-DD")', 'color: #007acc;', moment().utc().format("YYYY-MM-DD HH:mm:ss"));
    // var utcDate = momentTz.tz(`${moment().format("YYYY-MM-DD")} ${ORDER_TOKEN_RESET_HOUR}:${ORDER_TOKEN_RESET_MINUTE}:00`, "YYYY-MM-DD HH:mm:ss", 'Asia/Kolkata').toISOString();
    // console.log('%chelpers.js line:189 utcDate', 'color: #007acc;', utcDate);
    // var data = utcDate?.split("T")[0];
    // var time = utcDate?.split("T")[1]?.split(".")[0];
    // return data;
  }
  catch(error)
  {
      console.log('%chelpers.js line:192 object', 'color: #007acc;', error);
  }

};
export function BootstrapTooltip(props) {
  return <Tooltip arrow classes={props.classes} placement="top-end" {...props} />;
}
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Save as SaveIcon,
  ArrowBackIos,
} from "@mui/icons-material";
import { Form, Field } from "react-final-form";
import { Button, FormControl } from "@mui/material";
import { useParams, useHistory } from "react-router-dom";

import { useInMobile } from "themes/Device";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import TextInputMui from "components/TextInputMui";
import { Col, Row } from "react-bootstrap";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import {
  useExpenseCategoryQuery,
  useUpdateExpenseCategoryClassMutation,
  useCreateExpenseCategoryClassMutation,
} from "hooks/react-query/useExpenseCategory";
import { useTranslation } from "react-i18next";
const PREFIX = 'AddEditExpenseCategory';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  rowHeight: `${PREFIX}-rowHeight`,
  textfield: `${PREFIX}-textfield`,
  centerProperty: `${PREFIX}-centerProperty`,
  centerButton: `${PREFIX}-centerButton`,
  buttonPosition: `${PREFIX}-buttonPosition`,
  btnSection: `${PREFIX}-btnSection`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  [`& .${classes.btnSection}`]: {
    justifyContent: 'center',
    display: 'flex',
    position: 'sticky',
    bottom: '6px',
    background: '#fff'

  },
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
  },

  [`&.${classes.root}`]: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      background: "#fff",
      borderRadius: "8px",
      padding: "1rem 0",
    },
  },

  [`& .${classes.main}`]: {
    width: "60%",
  },

  [`& .${classes.rowHeight}`]: {
    height: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    minWidth: "0",
    background: " #FFF",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    borderRadius: "6px",
    display: "flex",
  },

  [`& .${classes.textfield}`]: {
    margin: "2%",
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.centerProperty}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.centerButton}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.buttonPosition}`]: {
    display: "flex",
    justifyContent: "end",
  }
}));

const AddEditExpenseCategory = (props) => {
  //hooks

  const param = useParams();
  const isMobileView = useInMobile();
  const history = useHistory();

  let { category_id = 0, drawerClose } = props;
  const { data } = useExpenseCategoryQuery(category_id);
  const [initialExpenseCategoryData, setInitialExpenseCategoryData] =
    useState();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (param.id > 0) {
      category_id = param.id;
    }
  }, [props]);

  const { mutate: addExpenseCategory, isLoading: creating } =
    useCreateExpenseCategoryClassMutation();
  const { mutate: updateExpenseCategory, isLoading: updating } =
    useUpdateExpenseCategoryClassMutation();

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    if (category_id > 0) {
      updateExpenseCategory({
        variables: {
          id: category_id,
          input: {
            ...values,
          },
        },
      });
    } else {
      addExpenseCategory({
        variables: {
          input: {
            ...values,
          },
        },
      });
    }
    if (!isMobileView && drawerClose) drawerClose();
    else history.push("/app/expense-category");
  };

  useEffect(() => {
    let screenTitle = "";
    if (category_id > 0) {
      screenTitle = t("expense-category-edit-label");
    } else {
      screenTitle = t("expense-category-add-label");
    }
    if (isMobileView) {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: screenTitle,
      });
      return () => {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      };
    }
  }, []);

  useEffect(() => {
    setInitialExpenseCategoryData(data);
  }, [data]);

  return (
    <Root className={classes.root}>
      {initialExpenseCategoryData || category_id > 0 ? (
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={initialExpenseCategoryData}
            validate={(values) => {
              const errors = {};
              if (!values?.name?.trim()) {
                errors.name = t("name-validation-message");
              } else if (values?.name?.length > 50) {
                errors.name = t("maximumlength-validation-message");
              }
              if (values?.description && values?.description?.length > 50) {
                errors.description = t("maximumlength-validation-message");
              }
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    form.reset();
                    form.restart();
                  });
                }}
                className="mt-2"
              >
                <Row className="m-0">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.buttonPosition}
                  ></Col>
                </Row>
                <Row className="m-0">
                  <Col xs={12} sm={12} md={12}>
                    <Field name="name">
                      {({ input, meta }) => (
                        <FormControl className={classes.textfield}>
                          <TextInputMui
                            {...input}
                            type="text"
                            variant="outlined"
                            label={`${t("expense-category-name-label")}${"*"}`}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>

                  <br></br>
                  <Col xs={12} sm={12} md={12}>
                    <Field name="description">
                      {({ input, meta }) => (
                        <FormControl className={classes.textfield}>
                          <TextInputMui
                            {...input}
                            type="textarea"
                            label={t("description-label")}
                            variant="outlined"
                            multiline={true}
                            error={meta.error && meta.touched ? true : false}
                          />
                          {meta.error && meta.touched && (
                            <span style={{ color: "red" }}>{meta.error}</span>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12}>
                    <div className={classes.btnSection}>
                      <div className="ml-2">
                        <Button
                          variant="outlined"
                          color="secondary"
                          className={classes.button}
                          type="button"
                          onClick={() => {
                            if (isMobileView)
                              history.push("/app/expense-category");
                            else if (!isMobileView && drawerClose)
                              drawerClose();
                          }}
                          startIcon={<ArrowBackIos />}
                        >
                          {t("back")}
                        </Button>
                      </div>
                      <div className="ml-2">
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                          disabled={submitting}
                          startIcon={<SaveIcon />}
                        >
                          {submittingBtnProcess ? t("processing") : t("save")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            )}
          />
        </>
      ) : (
        <SkeletonLoader count={3} height={50} />
      )}
    </Root>
  );
};

export default AddEditExpenseCategory;

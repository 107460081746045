import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import moment from "moment";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import { Button, CircularProgress, IconButton } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
  Delete,
  AddCircleOutline,
} from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { deleteRoleById } from "services/role.service";
import { getRoleList } from "services/role.service";
import PopUpModel from "components/Popup/PopUpModel";
import useStyles from "./style";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import SearchBarInput from "components/SearchBarInput";
import MobileTitle from "components/MobileTitle";
import { Col, Row } from "react-bootstrap";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import MobileRole from "components/MobileRole";

const RoleList = (props) => {
  const classes = useStyles();
  const [role, setRole] = useState([]);
  const [roleID, setRoleID] = useState(0);
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const [searchRole, setSearchRole] = useState("");
  const [filterRole, setFilterRole] = useState([]);
  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("username_admin")?.split("#").length > 0
      && localStorage.getItem("username_admin")?.split("#")[1] == "admin") {
      setFilterRole(role);
    }
    else {
      const roleFilter = role?.filter((r) => r.name?.toLowerCase() != "servofeat");
      setFilterRole(roleFilter);
    }
  }, [role]);

  useEffect(() => {
    if (searchRole) {
      let filterRole = role.filter((item) =>
        item.name?.toLowerCase()?.includes(searchRole.toLowerCase()),
      );
      setFilterRole(filterRole);
    } else {
      setFilterRole(role);
    }
  }, [searchRole]);

  const navigateRole = () => {
    props.history.push("/app/roles/add");
  };

  useEffect(() => {
    getList();
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: t("role-label"),
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  const getList = () => {
    getRoleList({
      username: localStorage.getItem("username"),
      shop_id: localStorage.getItem("shopId"),
    })
      .then((res) => {
        if (localStorage.getItem("username_admin")?.split("#").length > 0
          && localStorage.getItem("username_admin")?.split("#")[1] == "admin") {
          setRole(res?.data)
        }
        else {
          const roleFilter = res?.data?.filter((r) => r.name?.toLowerCase() != "servofeat");
          setRole(roleFilter);
        }
      })
      .catch((err) => console.log(err));
  };

  const editRole = (id) => {
    const roleDetail = role.filter((item) => item.id === id)[0];
    props.history.push({
      pathname: `/app/roles/edit/${id}`,
      details: roleDetail,
    });
  };

  const deleteRole = (username) => {
    setRoleID(username);
    setDeletePopupshow(true);
  };

  const onDeleteRole = () => {
    setDeletingBtnProcess(true);
    const shopID = localStorage.getItem("shopId");
    deleteRoleById(roleID, shopID, localStorage.getItem("username"))
      .then((res) => {
        if (res?.data) {
          getList();
          setDeletingBtnProcess(false);
          setDeletePopupshow(false);
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setDeletingBtnProcess(false);
        setDeletePopupshow(false);
      });
  };

  const clearTxt = () => {
    setSearchRole("");
  };

  const roleBtn = () => {
    return (
      <div className={classes.addrolebtn}>
        <RoleBasedGuard
          resource={RESOURCE.ROLES}
          action={ACTION.ADD}
        >
        <Button variant="contained" color="primary" onClick={navigateRole}>
          {t("role-screen-add-role-label")}
          </Button>
        </RoleBasedGuard>
      </div>
    );
  };

  return <>
    <PopUpModel
      show={deletePopupshow}
      heading={t("role-screen-role-remove-popup-title")}
      body={t("role-screen-role-remove-popup-body")}
      footer={
        <>
          <Button
            variant="contained"
            color="primary"
            className="mr-3"
            onClick={() => setDeletePopupshow(false)}
          >
            {" "}
            <Cancel /> {t("popup-cancel-button-label")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onDeleteRole}
          >
            {" "}
            <Delete />{" "}
            {deletingBtnProcess ? <CircularProgress /> : t("delete")}
          </Button>
        </>
      }
      onHide={() => setDeletePopupshow(false)}
    />
    <DeskTopContainer>
      <div className={classes.tabel}>
        <MaterialTable
          style={{ padding: "20px" }}
          title={t("role-screen-title")}
          columns={[
            {
              title: t("role-screen-role-name-label"),
              field: "name",
              cellStyle: {
                width: "45%",
              },
            },
            {
              title: t("created-date-label"),
              field: "created_date",
              render: (rowData) =>
                moment(rowData.created_date).format("DD/MM/YYYY HH:MM"),
              cellStyle: {
                width: "45%",
              },
            },
            {
              title: t("action"),
              field: "action",
              cellStyle: {
                width: "10%",
              },
              render: (rowData) => (
                <>
                  <RoleBasedGuard
                    resource={RESOURCE.ROLES}
                    action={ACTION.UPDATE}
                  >
                    <IconButton
                    color="primary"
                    title={t("role-screen-edit-role-label")}
                    aria-label={t("role-screen-edit-role-label")}
                    onClick={() => editRole(rowData?.id)}
                    size="large">
                    <EditIcon color="primary" />
                    </IconButton>
                  </RoleBasedGuard>
                  <RoleBasedGuard
                    resource={RESOURCE.ROLES}
                    action={ACTION.DELETE}
                  >
                  <IconButton
                    color="secondary"
                    title={t("role-screen-delete-role-label")}
                    aria-label={t("role-screen-delete-role-label")}
                    onClick={() => deleteRole(rowData?.name)}
                    size="large">
                    <DeleteIcon color="secondary" />
                    </IconButton>
                  </RoleBasedGuard>
                </>
              ),
            },
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <NotFoundComponent imgUrl={noFoundImg}>
                  <p style={{ fontWeight: 600 }}>
                    {t("role-screen-role-empty-message")}
                  </p>
                </NotFoundComponent>
              ),
            },
          }}
          data={
            role &&
            role.map((item, index) => {
              return {
                name: item?.name,
                created_date: item?.created_date,
                id: item.id,
              };
            })
          }
          options={{
            rowStyle: (rowData, index) => {
              if (index % 2) {
                return { backgroundColor: "#f2f2f2" };
              }
            },
            paging: false,
            pageSize: 50,
            emptyRowsWhenPaging: true,
            pageSizeOptions: [50, 100, 200],
            sorting: true,
            actionsColumnIndex: -1,
          }}
          components={{
            Toolbar: (props) => (
              <div className="custom-material-table-toolbar ">
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>{roleBtn()}</div>
              </div>
            ),
          }}
        />
      </div>
    </DeskTopContainer>
    <MobileContainer>
      <div style={{ paddingTop: "10px" }}>
          <Row>
            <Col>
              <MobileTitle
                resource={RESOURCE.ROLES}
                title="Role Info" />
            </Col>
            <Col style={{ display: "inline-flex", justifyContent: "right" }}>
              {roleBtn()}
            </Col>
        </Row>
        <div style={{ paddingTop: "5px" }}>
          <SearchBarInput
            onChange={(e) => {
              setSearchRole(e.target.value);
            }}
            value={searchRole}
            placeholder={t("role-screen-search-role-label")}
            clearTxt={clearTxt}
          />
        </div>
        <div >
          {filterRole &&
            filterRole.length > 0 &&
            filterRole.map((item, index) => (
              <div className={classes.mobileCard} key={index}>
                <MobileRole
                  resource={RESOURCE.ROLES}
                  name={item.name}
                  deleteData={deleteRole}
                  editData={editRole}
                  itemId={item?.id}
                  deleteByname={item?.name}
                  created_date={item?.created_date}
                  item={item}
                  isMenuBar
                />

              </div>
            ))}
        </div>
        {filterRole && filterRole.length === 0 && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>
              {t("role-screen-role-empty-message")}
            </p>
          </NotFoundComponent>
        )}
      </div>
    </MobileContainer>
  </>;
};

export default RoleList;

import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function AddEditPrinter() {
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window.getPrinterList != "undefined") {
      window?.getPrinterList();
    }
  }, []);

  const testPrint = () => {
    if (typeof window.printData != "undefined") {
      window?.printData();
    } else {
      toast.error(t("printer-function-error-message"));
    }
  };

  return (
    <>
      <form style={{ textAlign: "left" }}>
        <Row>
          <Col md="6">
            <h5>{t("printer-list-label")}:</h5>
            <div id="list_printers">{t("empty-printer-detect-message")}</div>
          </Col>
          <Col md="6">
            <h5>{t("printer-paper-size-label")}</h5>
            <input type="radio" id="w1" name="width" value="140px" />
            <label for="w1">140px</label>
            <br />

            <input type="radio" id="w2" name="width" value="170px" />
            <label for="w2">170px</label>
            <br />

            <input type="radio" id="w3" name="width" value="200px" />
            <label for="w3">200px</label>
            <br />

            <input type="radio" id="w4" name="width" value="250px" />
            <label for="w4">250px</label>
            <br />

            <input type="radio" id="w5" name="width" value="300px" />
            <label for="w5">300px</label>
            <br />
          </Col>
        </Row>
      </form>
      <Button type="button" onClick={testPrint}>
        {t("test-print-label")}
      </Button>
    </>
  );
}

import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchExpense = async ({ }) => {
    const url = `${API_ENDPOINTS.EXPENSE}`;
    const { data } = await api.get(url);
    return data?.data;
};
const useExpense = (options = {}) => {
    return useQuery([API_ENDPOINTS.EXPENSE, options], fetchExpense, {
        keepPreviousData: true,
    });
};
const fetchExpenseQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.EXPENSE}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useExpenseQuery = (id) => {
    return useQuery([API_ENDPOINTS.EXPENSE, id], () => fetchExpenseQuery(id));
};
const useDeleteExpenseMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.EXPENSE}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSE);
            },
        }
    );
};
const useUpdateExpenseClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.EXPENSE}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useBulkDeleteExpenseClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.EXPENSE}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateExpenseClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.EXPENSE, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.EXPENSE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useExpense,
    useExpenseQuery,
    useCreateExpenseClassMutation,
    useUpdateExpenseClassMutation,
    useDeleteExpenseMutation,
    useBulkDeleteExpenseClassMutation
};

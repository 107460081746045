import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import useResponsive from "../../hooks/useResponsive";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
  Alert,
  Link,
} from "@mui/material";
import clsx from "clsx";

import { withRouter, Link as RouterLink } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  Visibility as HidePassIcon,
  VisibilityOff as ShowPassIcon,
  ErrorOutline as ErrorIcon,

} from "@mui/icons-material";
import GoogleIcon from '@mui/icons-material/Google';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import { adminLogin, adminLoginWithEmail, forgotPassword, sentMobileOtp, verifyMobileOtp } from "services/login.service";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { SetUserPermission } from "state/actions/user.action";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";
import Image from "../../components/Image";
import { NotificationImportant } from "@mui/icons-material";
import Page from "components/Page";
import { signInWithGoogle, setUpRecaptha } from "utility/firebase";

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));


const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const Login = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [isNewPassSet, setIsNewPassSet] = useState(false);
  const [loginUsingMobileToggle, setLoginUsingMobileToggle] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const params = new URLSearchParams(props?.location?.search);
  let mobileRegex =
    /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
  const theme = useTheme();
  const [flag, setFlag] = useState(false);
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [settings, setSettings] = useState([]);
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();

  useEffect(() => {
    setLoginUsingMobileToggle(false);
    setError("");
    if (params.get("new-pass")) {
      setIsNewPassSet(true);
      setInterval(() => {
        setIsNewPassSet(false);
      }, 7000);
    }
  }, []);

  useEffect(() => {
    if (shopSettingListData?.DINE_IN_SERVICE) {
      setSettings(true);
    }
  }, [shopSettingListData]);

  const onSubmit = async (values) => {
    if (loginUsingMobileToggle) {
      try {
        setIsLoading(true);
        sentMobileOtp(values?.phone)
          .then(async (res) => {
            if (res?.status === 200 || res?.data) {
              setIsLoading(false);
              // const response = await setUpRecaptha(values?.phone);
              // setResult(response);
              setFlag(true);
              setOtpSent(true);
              props.history.push({
                pathname: `/verifyOtpMobile`,
                details: { phone: values?.phone },
              });
            } else {
              toast.success("Shop Not Found");
              setIsLoading(false);
            }
          })
          .catch((err) => {
            toast.error("Something went wrong. Please try after some time");
            setIsLoading(false);
          });
      } catch (err) {
        console.log(err)
      }
    } else {
      setIsLoading(true);
      adminLogin({
        username: values?.phone.toLowerCase().trim(),
        password: values?.password.trim(),
      })
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.data) {
            let username;
            if (res.data?.data?.shop?.username?.split('/')[1] == "member") {
              let usernameWithDbname = res.data?.data?.shop?.username.split('/')[0] //get staff name with shop name
              username = usernameWithDbname?.split('_')[1];//trynow
            }
            else {
              username = res.data?.data?.shop?.username;
            }
            console.log('%cLogin.js line:168 object', 'color: #007acc;', res?.data);
            console.log(res.data?.data?.shop?.mobile_version);
            console.log(res.data?.data?.shop?.electorn_version);
            localStorage.setItem("username", username);
            localStorage.setItem("mobile_version", res.data?.data?.shop?.mobile_version);
            localStorage.setItem("electorn_version", res.data?.data?.shop?.electorn_version);
            localStorage.setItem("username_admin", values?.phone.toLowerCase().trim());
            localStorage.setItem("token", res?.data?.data?.token);
            if (settings === true) {
              props.history.push("/");
            } else {
              props.history.push("/app/order/new");
            }
          } else if (res?.status === 201) {
            setIsLoading(false);
            setError(res?.data?.message);
          } else if (res?.response?.status === 404) {
            // username password incorrect
            setIsLoading(false);
            setError(res?.response?.data?.message);
          } else {
            setIsLoading(false);
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
          setIsLoading(false);
        });
    }
  };
  const loginwithGoogle = async () => {
    const response = await signInWithGoogle();
    if (response.status === 'success') {
      const loginWithEmail = {
        email: response?.data?.email,
      };
      adminLoginWithEmail(loginWithEmail)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.data) {
            let username;
            if (res.data?.data?.shop?.username?.split('/')[1] == "member") {
              let usernameWithDbname = res.data?.data?.shop?.username.split('/')[0] //get staff name with shop name
              username = usernameWithDbname?.split('_')[1];//trynow
            }
            else {
              username = res.data?.data?.shop?.username;
            }
            localStorage.setItem("username", username);
            localStorage.setItem("token", res?.data?.data?.token);
            // dispatch(SetUserPermission(res?.data?.data?.permissions));
            if (settings === true) {
              props.history.push("/");
            } else {
              props.history.push("/app/order/new");
            }
          } else if (res?.status === 201) {
            setIsLoading(false);
            setError(res?.data?.message);
          } else if (res?.response?.status === 404) {
            // username password incorrect
            setIsLoading(false);
            setError(res?.response?.data?.message);
          } else {
            setIsLoading(false);
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
          setIsLoading(false);
        });
    }
  };
  const sendConfirmationMail = (phone) => {
    setIsLoading(true);
    sentMobileOtp(phone)
      .then((res) => {
        if (res?.status === 200 || res?.data) {
          setIsLoading(false);
          setOtpSent(true);
          props.history.push({
            pathname: `/verifyOtpMobile`,
            details: { email: phone },
          });
        } else {
          toast.info("Shop Not Found");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try after some time");
        setIsLoading(false);
      });
  };
  const resetAllState = () => {
    setLoginUsingMobileToggle(false);
    setOtpSent(false);
    setError(false);
  };


  // const verifyOtp = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   if (otp === "" || otp === null) return;
  //   try {
  //     await result.confirm(otp);
  //     navigate("/home");
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };
  return (
    <Page title="Login">
      <RootStyle>
        <div className="online-offline-alert">
        <Alert variant="warning" >
        <NotificationImportant /> <span>
          You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
        </span>
      </Alert>
        </div>
     
    
        <HeaderStyle>
         {/* <Logo/> */}
          <Image
                disabledEffect
                src={"https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"}
                sx={{ width: 50, height: 50 }}
                // className={classes.img}
              />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}   call or wp on +91 7990488965
              {/* <Link variant="subtitle2" component={RouterLink} to={"/register"}>
                Get started
              </Link> */}
            </Typography>
          )}
        </HeaderStyle>
        {mdUp && (
                  <SectionStyle>
                    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                      Hi, Welcome Back
                    </Typography>
                    <Image visibleByDefault disabledEffect src="assets/illustrations/illustration_login.png" alt="login" />
                  </SectionStyle>
                )}

          <Container maxWidth="sm">
          <ContentStyle>
            <>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ my: 2, flexGrow: 1 }}>
                  {!loginUsingMobileToggle ? (
                    <Typography
                      className={classes.heading}
                      style={{ padding: 0 }}
                      variant="h4"
                    >
                      Sign in to Servofeat
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        className={classes.heading}
                        style={{ padding: 0 }}
                        variant="subtitle2"
                      >
                        Enter Your Mobile Number
                      </Typography>
                      <Typography className={classes.subHeading}>
                        Please enter the mobile number we will send one verification code
                        into that mobile number.
                      </Typography>
                    </>
                  )}
                  {!loginUsingMobileToggle && (
                    <>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, color: theme.palette.grey[600] }}
                      >
                        Don’t have an account? {""}
                        call or wp on +91 7990488965
                        {/* <Link
                          variant="subtitle2"
                          component={RouterLink}
                          to={"/register"}
                        >
                          Get started
                        </Link> */}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ mt: 1, color: theme.palette.grey[600] }}
                      >
                        Don't remember password? {""}
                        <Link
                          variant="subtitle2"
                          color="secondary"
                          component={RouterLink}
                          to={"/forgotpassword"}>
                          Forgot Password
                        </Link>
                      </Typography>
                    </>
                  )}
                </Box>
                <Box>
                  <Image
                    disabledEffect
                    src={"https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"}
                    sx={{ width: 50, height: 50 }}
                    className={classes.img}
                  />
                </Box>
              </Box>

              <Box>
                <Box>
                  {error && !loginUsingMobileToggle && (
                    <Alert
                      variant="danger"
                      onClose={() => setError(false)}
                      dismissible
                      style={{ marginBottom: 20 }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        <ErrorIcon
                          fontWeight={600}
                          style={{ marginRight: 10 }}
                        />
                        {error}
                      </span>
                    </Alert>
                  )}
                  {isNewPassSet && !loginUsingMobileToggle && (
                    <Alert
                      variant="success"
                      onClose={() => setIsNewPassSet(false)}
                      dismissible
                      style={{ marginBottom: 20 }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Password changed now you can sign in with new password
                      </span>
                    </Alert>
                  )}
                  <Box>
                    <Form
                      onSubmit={onSubmit}
                      validate={(values) => {
                        const errors = {};
                        if (!values.phone) {
                          errors.phone = loginUsingMobileToggle
                            ? "Mobile no is required."
                            : "Username is required.";
                        } else {
                          if (loginUsingMobileToggle) {
                            if (!values.phone.match(mobileRegex)) {
                              errors.phone = "Invalid Mobile Number";
                            }
                          }
                        }
                        if (!values.password && !loginUsingMobileToggle) {
                          errors.password = "Password is required.";
                        }
                        return errors;
                      }}
                      render={({
                        handleSubmit,
                        form,
                        submitting,
                        values,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Box>
                            <Field name="phone">
                              {({ input, meta }) => (
                                <TextInputMui
                                  {...input}
                                  className={clsx(
                                    classes.textField,
                                    classes.textFieldUnderline,
                                  )}
                                  label={
                                    !loginUsingMobileToggle
                                      ? "User Name/ Shop Name"
                                      : "Mobile No"
                                  }
                                  type={
                                    !loginUsingMobileToggle
                                      ? "text"
                                      : "number"
                                  }
                                  autoComplete="none"
                                  variant="outlined"
                                  fullWidth
                                  error={
                                    meta.touched && meta.error ? true : false
                                  }
                                  helperText={
                                    meta.touched &&
                                    meta.error &&
                                    `${meta.error}`
                                  }
                                  FormHelperTextProps={{
                                    style: { margin: "5px" },
                                  }}
                                />
                              )}
                            </Field>
                            {/* {loginUsingMobileToggle &&
                              <div id="recaptcha-container"></div>} */}
                          </Box>
                          {!loginUsingMobileToggle && (
                            <Box style={{ marginTop: 7 }}>
                              <Field name="password">
                                {({ input, meta }) => (
                                  <TextInputMui
                                    {...input}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            onClick={() =>
                                              setShowPassword(!showPassword)
                                            }
                                            edge="end"
                                            size="large">
                                            {showPassword ? (
                                              <ShowPassIcon />
                                            ) : (
                                              <HidePassIcon />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                      classes: {
                                        // underline: classes.textFieldUnderline,
                                        input: classes.textField,
                                      },
                                    }}
                                    label={"Password"}
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    variant="outlined"
                                    fullWidth
                                    error={
                                      meta.touched && meta.error
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      meta.touched &&
                                      meta.error &&
                                      `${meta.error}`
                                    }
                                    FormHelperTextProps={{
                                      style: { margin: "5px" },
                                    }}
                                    className={classes.passwordSpace}
                                  />
                                )}
                              </Field>
                            </Box>
                          )}

                          <Box>
                            {isLoading ? (
                              <div style={{ textAlign: "center" }}>
                                <CircularProgress size={26} />
                              </div>
                            ) : !loginUsingMobileToggle ? (
                              <>
                                <Button
                                  fullWidth
                                  color="primary"
                                  size="large"
                                  type="submit"
                                  style={{ marginTop: 10 }}
                                  variant="contained"
                                  loading={isLoading}
                                >
                                  Login
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  fullWidth
                                  size="large"
                                  style={{
                                    marginTop: 10
                                  }}
                                  onClick={() => loginwithGoogle()}
                                >
                                  <GoogleIcon />&nbsp; &nbsp; Login via Google
                                </Button>
                                {!loginUsingMobileToggle && (
                                  <Button
                                    variant="contained"
                                    color="warning"
                                    fullWidth
                                    size="large"
                                    style={{
                                      marginTop: 10
                                    }}
                                    onClick={() => {
                                      setLoginUsingMobileToggle(
                                        !loginUsingMobileToggle,
                                      );
                                      form.reset();
                                      form.restart({});
                                    }}
                                  >
                                    <MobileFriendlyIcon />&nbsp; &nbsp;  Login via Mobile Number
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                color="secondary"
                                fullWidth
                                size="large"
                                style={{
                                  marginTop: 10
                                }}
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                              >
                                Send OTP
                              </Button>
                            )}
                            {loginUsingMobileToggle && (
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                  marginTop: 10
                                }}
                                onClick={() => {
                                  resetAllState();
                                  form.reset();
                                  form.restart({});
                                }}
                                fullWidth
                              >
                                Back
                              </Button>
                            )}
                          </Box>
                        </form>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          </ContentStyle>
        </Container>
   
    </RootStyle>
   </Page>
  );
};

export default withRouter(Login);

import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const getAllCustomerQueue = async (searchParam) => {
  let res = await api.get(`/waiting-list`, { 
    params: {
      type: searchParam?.type ? searchParam?.type : 'DESC',
      search: searchParam?.search ? searchParam?.search : '',
      page: searchParam?.page ? searchParam?.page : 1,
      limit: searchParam?.limit ? searchParam?.limit : 20,
      column: searchParam?.column ? searchParam?.column :  'id'
     }
  });
  let data = res.data;
  return data;
};

export const createCustomerQueue = async (customerData) => {
  let res = await api
    .post(`/waiting-list`, customerData)
    .catch((error) => {
      return error;
    });
  return res;
};

export const getCustomerQueueById = async (id) => {
  let res = await api.get(`/waiting-list/${id}`, config);
  let data = res.data;
  return data;
};

export const updateCustomerQueue = async (id, customerData) => {
  let res = await api.patch(
    `/waiting-list/${id}`,
    customerData
  );
  let data = res.data;
  return data;
};

export const deleteCustomerQueue = async (id) => {
  let res = await api.delete(
    `/waiting-list/${id}`,
    config
  );
  let data = res.data;
  return data;
};

export const assignCustomerQueueTable = async (assignTableObj) => {
  let res = await api.post(
    `/waiting-list/transfer-table`, assignTableObj);
  let data = res.data;
  return data;
};

export const bulkDeleteCustomerQueue = async (diningTableData) => {
  try {
    let res = await api.post(`/waiting-list/remove_bulk`, diningTableData);
    return { status: res?.status, data: res?.data };
  } catch (err) {
    return { status: err?.response?.status, message: err?.response?.data?.message }
  }
};
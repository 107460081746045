// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
// hooks
import {
  _ecommerceNewProducts,
  _ecommerceSalesOverview,
  _ecommerceBestSalesman,
  _ecommerceLatestProducts,
} from '../../_mock';
import { CHART_COLORS } from '../../configV2'

// components
import Page from '../../components/Page';
// sections
import {
  EcommerceWidgetSummary,
} from '../../sections/@dashboard/general/e-commerce';
import { useInMobile } from "themes/Device";
import { useCustomerAnalytics } from '../../hooks/react-query/useReport';
import AnalyticsWebsiteVisitsNew from '../../sections/@dashboard/general/analytics/AnalyticsWebsiteVisitsNew';
import { AnalyticsConversionRates } from '../../sections/@dashboard/general/analytics';
import moment from 'moment';
import PageLoader from "components/Loader/PageLoader";
import { useEffect, useState } from 'react';
import SelectionToolBar from './SelectionToolBar';

export default function CustomerAnalytics() {
  const theme = useTheme();
  const themeStretch = true;
  const isMobileView = useInMobile();
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));
  useEffect(() => {
    if (month && year) {
      setSelectedDate(year + "-" + month + "-" + 1)
    }
  }, [year, month])
  const { data, isLoading } = useCustomerAnalytics(selectedDate)

  return (
    <Page title="Customer Analytics">
      {!isMobileView ? "" : <br />}
      {!isLoading ? <>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12} ml={'auto'}>
              <SelectionToolBar
                  month={month}
                  year={year}
                  setMonth={setMonth}
                  setYear={setYear}
                ></SelectionToolBar>
            </Grid>
            <Grid item xs={12} md={3}>
              <EcommerceWidgetSummary
                title="Total Customer Till the date"
                percent={2.6}
                total={data?.counts?.total_customers}
                chartColor={theme.palette.primary.main}
                chartData={[22, 8, 35, 50, 82, 84, 77, 12, 87, 43]}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <EcommerceWidgetSummary
                title="New Customer of This Month"
                percent={-0.1}
                total={data?.counts?.new_cutomers}
                chartColor={CHART_COLORS.green[0]}
                chartData={[56, 47, 40, 62, 73, 30, 23, 54, 67, 68]}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <EcommerceWidgetSummary
                title="Pending Amount of All Customer"
                percent={0.6}
                total={data?.counts?.total_pending_amount}
                chartColor={CHART_COLORS.red[0]}
                chartData={[40, 70, 75, 70, 50, 28, 7, 64, 38, 27]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <EcommerceWidgetSummary
                title="Credit Amount of All Customer"
                percent={0.6}
                total={data?.counts?.total_credit_amount}
                chartColor={CHART_COLORS.blue[0]}
                chartData={[40, 70, 75, 70, 50, 28, 7, 64, 38, 27]}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AnalyticsWebsiteVisitsNew
                title="Top Customer"
                subheader="Top Customer Based on Amount"
                chartLabels={
                  [...data?.top_customer_of_month?.map((e) => `${e.name?.substring(0, 10)}`)]
                }
                chartData={data?.top_customer_of_month}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AnalyticsConversionRates
                title="Top Customer With Dept"
                subheader="Based on Overall Data"
                chartData={[...data?.topCustomerWithMostPendingAMount?.map((o) => {
                  return {
                    label: `${o.name?.substring(0, 10)}`,
                    value: parseFloat(o.dept)
                  }
                })]}
              />
            </Grid>
          </Grid>
        </Container>
      </> : <>
        <PageLoader></PageLoader>
      </>}
    </Page>
  );
}

import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
    headers: { 'Access-Control-Allow-Origin': '*' },
};

export const getAllStaff = async (searchParam) => {
    let res = await api.get(`/staff`,  { 
        params: {
          type: searchParam?.type ? searchParam?.type : 'DESC',
          search: searchParam?.search ? searchParam?.search : '',
          page: searchParam?.page ? searchParam?.page : 1,
          limit: searchParam?.limit ? searchParam?.limit : 20,
          column: searchParam?.column ? searchParam?.column :  'id'
         }
      });
    let data = res.data;
    return data;
}

export const CreateStaff = async (staffData) => {
    let res = await api.post(`/staff`, staffData).catch((error) => { return error });
    return res;
}

export const getStaffById = async (id) => {
    let res = await api.get(`/staff/${id}`, config);
    let data = res.data;
    return data;
}

export const updateStaff = async (id, staffData) => {
    let res = await api.put(`/staff/${id}`, staffData);
    let data = res.data;
    return data;
}

export const deleteStaff = async (id) => {
    let res = await api.delete(`/staff/${id}`, config);
    let data = res.data;
    return data;
}


import React, { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Card, Row, Col } from "react-bootstrap";
import { Button, IconButton, TablePagination } from "@mui/material";
import PopUpModel from "components/Popup/PopUpModel";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FastfoodOutlined,
  LinkTwoTone,
  NoteAdd,
  Cancel,
  DeleteForever,
  Delete,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import LoopIcon from "@mui/icons-material/Loop";

// API Calls
import { fetchDiningTable } from "state/thunk-async";
import DeleteConfirmPopup from "components/DeleteConfirmPopup";
import {
  deleteCustomerQueue,
  getAllCustomerQueue,
  assignCustomerQueueTable,
  bulkDeleteCustomerQueue
} from "services/customer-queue.service";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditCustomerQueue from "./AddEditCustomerQueue";
import SkeletonLoader from "../../components/Loader/SkeletonLoader";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import SearchBarInput from "components/SearchBarInput";
import Waitingqueue from "../../components/MobileWaitingQueueCard/waitingqueue";
import MobileTitle from "components/MobileTitle";
import RoleBasedGuard from "../../guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CheckPermission from "./../../guards/CheckPermission";

const PREFIX = 'CustomerQueueList';

const classes = {
  transferbtn: `${PREFIX}-transferbtn`,
  closebtn: `${PREFIX}-closebtn`,
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  mobileCard: `${PREFIX}-mobileCard`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.transferbtn}`]: {
    height: "45px",
    lineHeight: "2px",
    width: "auto",
    padding: "3px 10px",
    border: "1px solid #D25AC6",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#00AB55",
  },
  [`& .${classes.closebtn}`]: {
    height: "45px",
    lineHeight: "2px",
    width: "auto",
    backgroundColor: "#536DFE",
    padding: "3px 10px",
    border: "1px solid #536DFE",
    borderRadius: "5px",
    color: "white",
  },
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  [`& .${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  [`& .${classes.mobileCard}`]: {
    padding: "2px",
    paddingBottom: '80px'
  },
});

const CustomerQueueList = () => {

  const theme = useTheme();
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersDataList, setCustomersDataList] = useState([]);
  const [transferTableID, setTransferTableID] = useState(0);
  const [assignCustomerId, setAssignCustomerId] = useState(0);
  const diningTableDataList = useSelector((state) => state.api.diningTable);
  const [transferingTable, setTransferingTable] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [drawerShow, setDrawerShow] = useState(false);
  const [updateCustomerId, setUpdateCustomerId] = useState(0);
  const [searchCustomerQueue, setSearchCustomerQueue] = useState("");
  const [filterCustomerQueueList, setFilterCustomerQueueList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [toTransferTableName, setToTransferTableName] = useState();
  const { t } = useTranslation();
  const [paginationParam, setPaginationParam] = useState({
    type: 'DESC',
    search: '',
    page: 1,
    limit: 20,
    column: 'id'
  })
  const [customerWaitingPagerData, setCustomerWaitingPagerData] = useState({});

  useEffect(() => {
    CustomerQueueList(paginationParam);
  }, [paginationParam]);

  useEffect(() => {
    dispatch(fetchDiningTable());
  }, [])
  

  const CustomerQueueList = () => {
    setIsLoading(true);
    getAllCustomerQueue(paginationParam)
      .then(function (response) {
        setIsLoading(false);
        setCustomerWaitingPagerData(response?.pager);
        setCustomersDataList(response?.data);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteCustomerData = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteCustomerQueue(deleteId)
        .then((res) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          if (res?.data) {
            CustomerQueueList();
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          toast.success(err?.message);
        });
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const resetSelectedTableData = () => {
    setTransferTableID(0);
    setAssignCustomerId(0);
    setTransferingTable(false);
  };

  const assignTable = () => {
    if (
      transferTableID &&
      assignCustomerId &&
      transferTableID !== 0 &&
      assignCustomerId !== 0
    ) {
      setTransferingTable(true);
      assignCustomerQueueTable({
        id: assignCustomerId,
        table_id: transferTableID,
      })
        .then((res) => {
          setTransferingTable(false);
          toast.success(
            `${t("customer-transfer-toast-message")} ${toTransferTableName} ${t(
              "successfully-toast-message",
            )}`,
          );
          setModalShow(false);
          dispatch(fetchDiningTable());
          CustomerQueueList();
          resetSelectedTableData();
        })
        .catch((err) => {
          setTransferingTable(false);
          toast.error(err);
          setModalShow(false);
          resetSelectedTableData();
        });
    }
  };

  const drawerClose = () => {
    setDrawerShow(false);
    setUpdateCustomerId(0);
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const editData = (editid) => {
    // setDrawerShow(true);
    // setUpdateCustomerId(editid);
    history.push({
      pathname: `/app/customer-queue/edit/${editid}`,
      state: {
        customerQueueId: editid,
        backToMain: () => history.push(`/app/customer-queue`),
      },
    });
  };

  const addDrawerShow = () => {
    // setDrawerShow(true);
    history.push({
      pathname: `/app/customer-queue/add`,
      state: {
        customerQueueId: 0,
        backToMain: () => history.push(`/app/customer-queue`),
      },
    });
  };

  const clearTxt = () => {
    setSearchCustomerQueue("");
  };

  useEffect(() => {
    setFilterCustomerQueueList(customersDataList);
  }, [customersDataList]);

  useEffect(() => {
    if (searchCustomerQueue) {
      let filterCustomerQueue = customersDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchCustomerQueue.toLowerCase()),
      );
      setFilterCustomerQueueList(filterCustomerQueue);
    } else {
      setFilterCustomerQueueList(customersDataList);
    }
  }, [searchCustomerQueue]);

  function sortByPriority(a, b) {
    if (a.is_priority < b.is_priority) {
      return -1;
    }
    if (a.is_priority > b.is_priority) {
      return 1;
    }
    return 0;
  }

  const [bulkDeleteIds, setbulkDeleteIds] = useState([]);
  const [deletebulkPopupshow, setDeletebulkPopupshow] = useState(false);
  const handleDeleteRows = async () => {
    setDeletebulkPopupshow(false)
    setbulkDeleteIds([]);
    const result = await bulkDeleteCustomerQueue({
      ids: bulkDeleteIds
    });
    if (result?.data?.status === 200) {
      toast.success(result?.data?.message);
    } else {
      toast.error(result?.message);
    }
    CustomerQueueList();
  };
  const handleDeleteRowsConformation = async (event, rowData) => {
    const ids = rowData.map((d) => d.id);
    setDeletebulkPopupshow(true)
    setbulkDeleteIds(ids);
  };
  return (
    <Root>
      <PopUpModel
        size="lg"
        onHide={() => {
          setModalShow(false);
          resetSelectedTableData();
        }}
        show={modalShow}
        heading={t("customer-to-assign-table-title")}
        body={
          diningTableDataList && diningTableDataList?.length > 0 ? (
            <>
              <Row>
                {diningTableDataList.sort(sortByPriority).map((item, index) =>
                  item?.is_available === true &&
                    item?.is_active &&
                    (item?.order_id === 0 || item?.order_id == null) ? (
                    <Col lg={2} sm={4} xs={6} className="mb-2">
                      <Card
                        onClick={() => {
                          setTransferTableID(item?.id);
                          setToTransferTableName(item.name_of_table);
                        }}
                        hover
                        border={
                          item?.is_available === true &&
                            (item?.order_id === 0 || item?.order_id == null)
                            ? "success"
                            : "danger"
                        }
                        className={
                          transferTableID === item?.id &&
                          "transfer-table-select tblcard"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <p
                          className={`${item?.is_available === true &&
                            (item?.order_id === 0 || item?.order_id == null)
                            ? "bg-success"
                            : "bg-danger"
                            } tblcard`}
                        >
                          <b>{item?.name_of_table} </b>
                        </p>
                        <FastfoodOutlined
                          size="large"
                          style={{
                            margin: "auto",
                            fontSize: "3rem",
                            color:
                              item?.is_available === true &&
                                (item?.order_id === 0 || item?.order_id == null)
                                ? "#28a745"
                                : "#dc3545",
                          }}
                        />
                        <div className="m-2">
                          <b>{t("dining-table-capacity")}: </b> {item?.capacity}
                        </div>
                      </Card>
                    </Col>
                  ) : (
                    diningTableDataList?.length - 1 === index &&
                    item?.is_available && (
                      <h6 className="m-auto">{t("empty-table-message")}</h6>
                    )
                  ),
                )}
              </Row>
            </>
          ) : (
            <h5>{t("empty-table-message")}</h5>
          )
        }
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<LoopIcon />}
              style={{ background: "", color: "#fff" }}
              onClick={assignTable}
              disabled={transferTableID === 0 ? true : false}
            >
              {transferingTable
                ? t("assigning-label")
                : t("customer-to-assign-table-title")}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Cancel />}
              onClick={() => {
                setModalShow(false);
                resetSelectedTableData();
              }}
            >
              {t("close-button-label")}
            </Button>
          </>
        }
      />
      <PopUpModel
        show={deletebulkPopupshow}
        heading={"Are you sure you want to delete selected customer?"}
        body={"Please Conform Selected Opration."}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletebulkPopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteRows}
            >
              {" "}
              <Delete /> {t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletebulkPopupshow(false)}
      />
      <TemporaryDrawer
        isOpen={drawerShow}
        drawerClose={drawerClose}
        title={t("customer-queue-title")}
        icon={<NoteAdd />}
      >
        <AddEditCustomerQueue
          drawerClose={drawerClose}
          CustomerQueueList={CustomerQueueList}
          customerQueueId={updateCustomerId}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="ml-3">{t("queue-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("customer-name-label"),
                field: "customerName",
                cellStyle: {
                  width: "25%",
                },
              },
              {
                title: t("phone-label"),
                field: "mobile",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("number-of-person-label"),
                field: "no_of_person",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("booked-date-time-label"),
                cellStyle: {
                  width: "25%",
                },
                render: (rowData) =>
                  rowData?.present_date_time &&
                  moment(rowData?.present_date_time).format(
                    "DD/MM/YYYY hh:mm A",
                  ),
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) => (
                  <Fragment>
                    <IconButton
                      color="primary"
                      title={t("customer-to-assign-table-title")}
                      aria-label={t("customer-to-assign-table-title")}
                      onClick={() => {
                        setModalShow(true);
                        setAssignCustomerId(rowData?.id);
                      }}
                      size="large">
                      <LinkTwoTone />
                    </IconButton>

                    <RoleBasedGuard
                      resource={RESOURCE.WAITING_QUEUE}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("customer-edit-label")}
                        aria-label={t("customer-edit-label")}
                        onClick={() => {
                          setDrawerShow(true);
                          setUpdateCustomerId(rowData.id);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.WAITING_QUEUE}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("customer-delete-label")}
                        aria-label={t("customer-delete-label")}
                        onClick={() => deleteDataConfirm(rowData.id)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={
              customersDataList &&
              customersDataList.map((item, index) => {
                return {
                  customerName: item?.name,
                  mobile: item?.mobile,
                  no_of_person: item?.no_of_person,
                  is_waiting: item?.is_waiting,
                  id: item?.id,
                  present_date_time: item?.present_date_time,
                };
              })
            }
            actions={
              CheckPermission(RESOURCE.WAITING_QUEUE, ACTION.DELETE) ? [
                {
                  icon: () => <DeleteForever />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRowsConformation
                }
              ] : []
            }
            options={{
              paging: false,
              pageSize: 10,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              selection: CheckPermission(RESOURCE.WAITING_QUEUE, ACTION.DELETE) ? true : false,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "10px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: isLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("customer-queue-empty-record-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <RoleBasedGuard
                    resource={RESOURCE.WAITING_QUEUE}
                    action={ACTION.ADD}
                  >
                    <div style={{ padding: "0px 10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ background: "", color: "#fff" }}
                        onClick={() => {
                          setDrawerShow(true);
                        }}
                      >
                        {t("customer-queue-add")}
                      </Button>
                    </div>
                  </RoleBasedGuard>
                </div>
              ),
            }}
          />
        </div>
        <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50]}
              component="div"
              count={parseInt(customerWaitingPagerData?.total || 1)}
              rowsPerPage={parseInt(customerWaitingPagerData?.take || 20)}
              page={parseInt(customerWaitingPagerData?.page - 1)}
              onPageChange={(event, newPage) => {
                setPaginationParam({
                  ...paginationParam,
                  page: newPage +1
                })
              }}
              onRowsPerPageChange={(event) => {
                setPaginationParam({
                  ...paginationParam,
                  limit: event.target.value
                })
              }}
            />
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle
            resource={RESOURCE.WAITING_QUEUE}
            title={t("waiting-queue-label")}
            addButton={addDrawerShow}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchCustomerQueue(e.target.value);
            }}
            value={searchCustomerQueue}
            placeholder={t("customer-search-label")}
            clearTxt={clearTxt}
          />
          {isLoading ? (
            <SkeletonLoader count={6} height="5rem" />
          ) : (
            <div style={{ marginTop: 4 }}>
              {filterCustomerQueueList &&
                filterCustomerQueueList.map((item, index) => (
                  <div key={index} style={{ marginTop: 6 }}>
                    <Waitingqueue
                      resource={RESOURCE.WAITING_QUEUE}
                      Phone={item?.mobile || "-"}
                      customerName={item?.name}
                      no_of_person={item?.no_of_person}
                      present_date_time={moment(item?.present_date_time).format(
                        "DD/MM/YYYY hh:mm",
                      )}
                      deleteData={deleteData}
                      editData={editData}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
          {filterCustomerQueueList.length < 1 && !isLoading && (
            <NotFoundComponent imgUrl={noFoundImg}>
              <p style={{ fontWeight: 600 }}>
                {t("customer-queue-empty-record-message")}
              </p>
            </NotFoundComponent>
          )}
        </div>
      </MobileContainer>
      <DeleteConfirmPopup
        deletePopupshow={deletePopupshow}
        handleClose={() => setDeletePopupshow(false)}
        title={t("customer-remove-popup-title")}
        isDeletingFlag={isDeleting}
        content={t("customer-remove-popup-body")}
        deleteAction={deleteCustomerData}
      />
    </Root>
  );
};

export default CustomerQueueList;

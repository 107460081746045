import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signOut,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
    browserPopupRedirectResolver,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
//
const firebaseConfig = {
    apiKey: "AIzaSyAmAJGdzDNeXyCn8Pn5SB_QErLwBxD9meY",
    authDomain: "servofeat-63be4.firebaseapp.com",
    projectId: "servofeat-63be4",
    storageBucket: "servofeat-63be4.appspot.com",
    messagingSenderId: "458913264964",
    appId: "1:458913264964:web:7eca04c75ac660a319d311",
    measurementId: "G-ND9F6SFBRN"
};
;
const firebaseApp = initializeApp(firebaseConfig);
const AUTH = getAuth(firebaseApp);
const DB = getFirestore(firebaseApp);
const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);
const signInWithGoogle = async () => {
    let userDetails;
    try {
        const res = await signInWithPopup(AUTH, new GoogleAuthProvider(), browserPopupRedirectResolver);
        const { user } = res;
        userDetails = user;

        return { status: 'success', data: userDetails };
    } catch (err) {
        return { status: 'error', message: err.message };
    }
};
const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
        });
    });

function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {},
        AUTH
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(AUTH, "+91" + number, recaptchaVerifier);
}
const logout = () => signOut(AUTH);
export { login, signInWithGoogle, register, logout, setUpRecaptha };

import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";
import call from "../../assets/img/call.png";
import { textTruncate } from "utility/helpers";

const PREFIX = 'MobileCustomerAccordian';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  customerHeader: `${PREFIX}-customerHeader`,
  customerHeading: `${PREFIX}-customerHeading`,
  image: `${PREFIX}-image`,
  customerName: `${PREFIX}-customerName`,
  customerEmail: `${PREFIX}-customerEmail`,
  lastVisitDate: `${PREFIX}-lastVisitDate`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    borderRadius: "0px 0px 9px 8px",
    borderColor: "#d7d7d7 !important",
    borderWidth: "1px",
    boxShadow: "0px !important",
  },

  [`& .${classes.heading}`]: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
  },

  [`& .${classes.customerHeader}`]: {
    backgroundColor: "#eee",
  },

  [`& .${classes.customerHeading}`]: {
    flexDirection: "row",
    display: "flex",
  },

  [`& .${classes.image}`]: {
    width: 38,
    height: 38,
  },

  [`& .${classes.customerName}`]: {
    fontSize: 15,
    fontWeight: "500",
    color: "#1F1F24",
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.customerEmail}`]: {
    fontSize: 12,
    fontWeight: "400",
    color: "#8e8e8e",
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.lastVisitDate}`]: {
    fontSize: 12,
    fontWeight: "400",
    color: "#1F1F24",
    alignItems: "center",
    display: "flex",
  }
}));

const MobileCustomerAccordian = (props) => {

  const {
    Phone,
    customerName,
    customerEmail,
    visitDate,
    deleteData,
    editData,
    itemId,
    resource
  } = props;
  const { t } = useTranslation();

  return (
    // <div className={classes.root}>
    //   <Accordion defaultExpanded={true}>
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel1a-content"
    //       id="panel1a-header"
    //       className={classes.customerHeader}
    //     >
    //       <Grid className={classes.customerHeading}>
    //         <Box sx={{ flexDirection: "row", display: "flex" }}>
    //           <Typography className={classes.heading}>
    //             {t("name-label")}:{" "}
    //           </Typography>
    //           <Typography
    //             className={classes.heading}
    //             style={{ color: "#ff4949", marginLeft: "3px" }}
    //           >
    //             {customerName}
    //           </Typography>
    //         </Box>
    //       </Grid>
    //       <Grid style={{ marginLeft: "auto" }}>
    //         <Box
    //           sx={{
    //             flexDirection: "row",
    //             display: "flex",
    //             alignItems: "center",
    //           }}
    //         >
    //           <CallIcon
    //             style={{
    //               fontSize: "small",
    //               marginRight: "4.5px",
    //               color: "#016FFC",
    //             }}
    //           />
    //           <Typography
    //             className={classes.heading}
    //             style={{ color: "#016FFC" }}
    //           >
    //             {Phone}
    //           </Typography>
    //         </Box>
    //       </Grid>
    //     </AccordionSummary>
    //     <AccordionDetails>
    //       <Grid container>
    //         <Grid item container direction="row">
    //           <Grid item style={{ marginLeft: 15 }}>
    //             <Row>
    //               <Col>
    //                 <Typography className={classes.customerName}>
    //                   <PersonOutlineRoundedIcon
    //                     style={{ fontSize: "small", marginRight: "7.3" }}
    //                   ></PersonOutlineRoundedIcon>
    //                   {customerName}
    //                 </Typography>
    //               </Col>
    //               <Col>
    //                 <Typography
    //                   className={classes.customerEmail}
    //                   color="textSecondary"
    //                 >
    //                   <MailOutlineIcon
    //                     style={{ fontSize: "small", marginRight: "7.3" }}
    //                   />
    //                   {customerEmail}
    //                 </Typography>
    //               </Col>
    //             </Row>
    //           </Grid>
    //         </Grid>
    //         <Box
    //           style={{
    //             flexDirection: "row",
    //             display: "flex",
    //             marginTop: "13px",
    //             marginLeft: "15px",
    //           }}
    //         >
    //           <Typography className={classes.customerEmail}>
    //             {t("visit-date-label")}:
    //           </Typography>
    //           <Typography className={classes.lastVisitDate}>
    //             {visitDate}
    //           </Typography>
    //         </Box>
    //         <Grid style={{ marginLeft: "auto" }}>
    //           <MenuButtonEditDelete
    //             deleteClick={deleteData}
    //             editClick={editData}
    //             itemId={itemId}
    //             isMenuBar={true}
    //           />
    //         </Grid>
    //       </Grid>
    //     </AccordionDetails>
    //   </Accordion>
    // </div>



    <StyledContainer fluid className="p-0">
      <Card className="card1">
        <Row>
          <Col style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <h6 className="tale">{textTruncate(customerName, 25, '..')}</h6>
              </div>
            </div>
            {Phone && (
              <div
                className="callp"
                style={{ alignItems: "center !important" }}
              >
                <img src={call} className="call" />
                <div>
                  <p style={{ marginBottom: "10px" }}>{Phone}</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="row2">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className="para3">
                  {visitDate !== 'Invalid date' && (
                    <>
                      {t("visit-date-label")}:-<span>{visitDate}</span>
                    </>
                  )}
                </p>
                {customerEmail && customerEmail !== '-' && (
                  <p className="para3">
                    Email:-<span>{customerEmail}</span>
                  </p>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <MenuButtonEditDelete
              resource={resource}
              deleteClick={deleteData}
              editClick={editData}
              itemId={itemId}
              isMenuBar={true}
            />
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  );
};

export default MobileCustomerAccordian;

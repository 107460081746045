import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchInventoryCategory = async ({ }) => {
    const url = `${API_ENDPOINTS.INVENTORYCATEGORY}`;
    const { data } = await api.get(url);
    return data?.data;
};
const useInventoryCategory = (options = {}) => {
    return useQuery([API_ENDPOINTS.INVENTORYCATEGORY, options], fetchInventoryCategory, {
        keepPreviousData: true,
    });
};
const fetchInventoryCategoryQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.INVENTORYCATEGORY}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useInventoryCategoryQuery = (id) => {
    return useQuery([API_ENDPOINTS.INVENTORYCATEGORY, id], () => fetchInventoryCategoryQuery(id));
};
const useDeleteInventoryCategoryMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.INVENTORYCATEGORY}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.INVENTORYCATEGORY);
            },
        }
    );
};
const useBulkDeleteInventoryCategoryMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.INVENTORYCATEGORY}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.INVENTORYCATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useUpdateInventoryCategoryClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.INVENTORYCATEGORY}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.INVENTORYCATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateInventoryCategoryClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.INVENTORYCATEGORY, input),
        {
            onSuccess: (result) => {
                console.log("result", result)
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.INVENTORYCATEGORY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useInventoryCategory,
    useInventoryCategoryQuery,
    fetchInventoryCategory,
    useBulkDeleteInventoryCategoryMutation,
    useCreateInventoryCategoryClassMutation,
    useUpdateInventoryCategoryClassMutation,
    useDeleteInventoryCategoryMutation
};

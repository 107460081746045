import { Stack, MenuItem } from '@mui/material';
import TextInputMui from "components/TextInputMui";

export default function SelectionToolBar({
    month,
    year,
    setMonth,
    setYear
}) {
    return (
        <Stack
            spacing={0.2}
            direction={{ xs: 'row', md: 'row' }}
        >
            <TextInputMui
                style={{ width: 200 }}
                select
                sx={{
                    textTransform: 'capitalize',
                    marginLeft: 'auto'
                }}
                label={"Select Month"}
                variant="outlined"
                labelId="demo-simple-select-label"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
            >
                <MenuItem value="1">
                    {"January"}
                </MenuItem>
                <MenuItem value="2">
                    {"February"}
                </MenuItem>
                <MenuItem value="3">
                    {"March"}
                </MenuItem>
                <MenuItem value="4">
                    {"April"}
                </MenuItem>
                <MenuItem value="5">
                    {"May"}
                </MenuItem>
                <MenuItem value="6">
                    {"June"}
                </MenuItem>
                <MenuItem value="7">
                    {"July"}
                </MenuItem>
                <MenuItem value="8">
                    {"August"}
                </MenuItem>
                <MenuItem value="9">
                    {"September"}
                </MenuItem>
                <MenuItem value="10">
                    {"October"}
                </MenuItem>
                <MenuItem value="11">
                    {"November"}
                </MenuItem>
                <MenuItem value="12">
                    {"December"}
                </MenuItem>
            </TextInputMui>
            <TextInputMui
                style={{ width: 200 }}
                select
                sx={{
                    textTransform: 'capitalize',
                }}
                label={"Select Year"}
                variant="outlined"
                labelId="demo-simple-select-label"
                value={year}
                onChange={(e) => setYear(e.target.value)}
            >
                <MenuItem value="2021">
                    {"2021"}
                </MenuItem>
                <MenuItem value="2022">
                    {"2022"}
                </MenuItem>
                <MenuItem value="2023">
                    {"2023"}
                </MenuItem>
            </TextInputMui>
        </Stack>
    );
}

import { SET_USER_INFO, SET_USER_PERMISSION } from "./action.types";

export const SetUserInfo = (payLoad) => ({
  type: SET_USER_INFO,
  payLoad,
});

export const SetUserPermission = (payLoad) => ({
  type: SET_USER_PERMISSION,
  payLoad,
});
import { SET_STORE_THEME } from "./action.types";

export const setShopSettingData = (dispatch) => ({
  type: "SET_SHOP_SETTING",
  payLoad: dispatch,
});

export const setShopThemeSettingData = (dispatch) => ({
  type: SET_STORE_THEME,
  payLoad: dispatch,
});

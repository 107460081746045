import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Send as SendIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  ExitToApp,
  QuestionAnswer,
  Settings,
  FullscreenExit,
  FullscreenOutlined,
  Print,
  NotificationImportantSharp,
} from "@mui/icons-material";
import classNames from "classnames";
import { Navbar, Nav, NavDropdown, Form, Row } from "react-bootstrap";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import RouteLinks from "common/routes";
import { signOut } from "utility/helpers";
import useStyles from "./styles";
import { Badge, Typography } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import InfoIcon from "@mui/icons-material/Info";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { isDesktop } from "react-device-detect";
import {
  DeskTopContainer,
  MobileContainer,
  useInMobile,
} from "../../themes/Device";
import LockIcon from "@mui/icons-material/Lock";
import { Button } from "@mui/material";
import { useTranslation, initReactI18next } from "react-i18next";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
  toggleOrderSectionView,
} from "context/LayoutContext";

import "assets/css/header.css";
import InfoModel from "components/InfoModel";
import servoFeatLogo from "assets/img/servofeatlogo-preview.png";
import { ServiceEnabled } from "utility/helpers";
import { useTheme } from "@mui/material/styles";
import NavbarHorizontal from "components/Layouts/dashboard/navbar/NavbarHorizontal";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION, RESOURCE } from "common/constant";
import HelpIcon from "@mui/icons-material/Help";
import ReactPlayer from "react-player";
import PopUpModel from "components/Popup/PopUpModel";
import Label from "components/Label";
import NotificationsPopover from "components/Layouts/dashboard/header/NotificationsPopover";
import ContactsPopover from "components/Layouts/dashboard/header/ContactsPopover";
import { RefereshToken } from "services/onlineorder.service";


const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

export default function OnlineLayoutHeader(props) {
  var { classes } = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const theme = useTheme();
  const orderHistoryDataList = useSelector(
    (state) => state?.api?.orderHistoryData,
  );
  const urlSearchParamas = new URLSearchParams(history?.location?.search);
  const userName = urlSearchParamas.get('username');
  

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  
  const [modalShow, setModalShow] = React.useState(false);
  const [videomodalShow, setVideomodalShow] = React.useState(false);
  const [orderNotification, setorderNotification] = useState([]);
  const [userData, setUserData] = useState({});
  

  useEffect(() => {
    async function fetchShopData() {
        localStorage.setItem('userName', userName);
        const dataFromUserName = await RefereshToken({ username: userName });
        localStorage.setItem('token', dataFromUserName?.data?.token);
        
        setUserData(dataFromUserName?.data)
        
    }
    fetchShopData()
  }, [])
  

  const getScreenTitle = () => {
    const path = history.location.pathname;
    if (path === "/app/customer-queue") {
      return t("customer-queue-title");
    }
    return "";
  };

  return (
    <AppBar
      className={classes.appBar}
      style={{
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: useInMobile() ? "4px" : "0px",
      }}
    >
      <PopUpModel
        size="lg"
        onHide={() => {
          setVideomodalShow(false);
        }}
        show={videomodalShow}
        heading={"Help Video"}
        body={
          <Row>
            <ReactPlayer
              controls
              style={{ paddingTop: "0px" }}
              width="800px"
              height="480px"
              onError={() => console.log("onError CallBack")}
              onPause={() => console.log("onPause CallBack")}
              onStart={() => console.log("onStart CallBack")}
              onReady={() => console.log("OnReady CallBack")}
              onEnded={() => console.log("onEnded CallBack")}
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
            />
          </Row>
        }
      />
      <Toolbar className={classes.toolbar}>
      
        <Navbar.Brand className={`${classes.navBarLogo} mx-auto`}>
            <img
              src={
                "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png" ||
                servoFeatLogo
              }
              style={{ width: "37px" }}
              alt="servofeat"
            />
           &nbsp; {userData?.shop?.name}
        </Navbar.Brand>
       
        <MobileContainer>
          <div className={classes?.screenTitle}>
            {layoutState?.currentScreenTitle || getScreenTitle()}
          </div>
        </MobileContainer>
        <Navbar variant="light" expand="md" sticky="top" className="smr-navbar">
         

          <div className="d-flex ml-auto align-items-center">
            <DeskTopContainer>
              {/* <div style={{marginLeft: "8px"}}>
                <Label color="info" variant='ghost'>
                  { `Welcome to ${userData?.shop?.name}`}
                </Label>
              </div> */}
              <IconButton
                className={classes.iconButton}
                onClick={() => setModalShow(true)}
                size="large">
                <InfoIcon classes={{ root: classes.phoneIcon }} />
              </IconButton>

              
            </DeskTopContainer>
              <NotificationsPopover 
                orderHistoryDataList = {orderHistoryDataList?.data}/>
            {/* <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton> */}
            <InfoModel show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </Navbar>
       
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {t("header-new-message-label")}
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} {t("header-new-message-label")}
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        {orderNotification.length > 0 ? (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {orderNotification.map((notification) => (
              <MenuItem
                key={notification.id}
                onClick={() => {
                  setNotificationsMenu(null);
                  setorderNotification([]);
                }}
                className={classes.headerMenuItem}
              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>
        ) : (
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => {
              setNotificationsMenu(null);
              setorderNotification([]);
            }}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            <MenuItem
              // key={notification.id}
              onClick={() => {
                setNotificationsMenu(null);
                setorderNotification([]);
              }}
              className={classes.headerMenuItem}
            >
              <Notification
                message="No any notification"
                typographyVariant="inherit"
              />
            </MenuItem>
          </Menu>
        )}
      </Toolbar>
    </AppBar>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Container, Typography, Stack, Button } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Image from "../../components/Image";
import clsx from "clsx";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { verifyMobileOtp } from "services/login.service";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { sentMobileOtp } from "services/login.service";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function VerifyOtpMobile(props) {
  const mdUp = useResponsive("up", "md");
  const classes = useStyles();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [shopDetail, setShopDetails] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.location.details) {
      setShopDetails(props.location?.details);
    } else {
      props.history.push("/login");
    }
  }, []);

  const handleOTP = async (values) => {
    setSubmittingBtnProcess(true);
    try {
      const shopData = {
        phone: shopDetail?.phone,
        otp: values.otp
      };
      verifyMobileOtp(shopData)
        .then((res) => {
          if ((res?.status === 200 || res?.status === 201) && res?.data?.data) {
            let username;
            if (res.data?.data?.shop?.username?.split('/')[1] == "member") {
              let usernameWithDbname = res.data?.data?.shop?.username.split('/')[0] //get staff name with shop name
              username = usernameWithDbname?.split('_')[1];//trynow
            }
            else {
              username = res.data?.data?.shop?.username;
            }
            localStorage.setItem("username", username);
            localStorage.setItem("token", res?.data?.data?.token);
            setSubmittingBtnProcess(false);
            props.history.push("/app/order/new");
          }
          else {
            setSubmittingBtnProcess(false);
          }
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.error(err?.response?.data?.message);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };
  const reSendOtp = async () => {
    setSubmittingBtnProcess(true);
    sentMobileOtp(shopDetail?.phone)
      .then(async (res) => {
        setSubmittingBtnProcess(false);
        if (res?.status === 200 || res?.data) {
          toast.success("Otp Sent Successfully.");
        }
      })
      .catch((err) => {
        console.log("err", err)
        setSubmittingBtnProcess(false);
        toast.error("Something went wrong. Please try after some time");
      });
  };
  return (
    <Page title="Register">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Verify OTP
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  sx={{ fontSize: "1.5rem", marginBottom: "2rem" }}
                >
                  Verify OTP
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: "1rem", textAlign: "center", fontWeight: "500" }}
                >
                  Enter Verification Code
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ fontSize: "0.8rem", textAlign: "center" }}
                >
                  {`We have sent an OTP`}
                </Typography>
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleOTP}
              validate={(values) => {
                const errors = {};
                if (!values.otp) {
                  errors.otp = "OTP is required.";
                }

                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <Field name="otp">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="OTP"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>
                      <Button
                        color="secondary"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        type="submit"
                        variant="contained"
                        loading={submittingBtnProcess}
                      >
                        Verify OTP
                      </Button>
                      <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        onClick={() => reSendOtp()}
                        type="submit"
                        loading={submittingBtnProcess}
                      >
                        Resend OTP
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        onClick={() => {
                          props.history.push({
                            pathname: `/login`,
                          });
                        }}
                        fullWidth
                      >
                        Back
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}


// const handleOTP = async (values) => {
//   setSubmittingBtnProcess(true);
//   try {
//     const data = await shopDetail?.response?.confirm(values?.otp);
//     if (data) {
//       const shopData = {
//         phone: shopDetail?.phone
//       };
//       verifyMobileOtp(shopData)
//         .then((res) => {
//           if ((res?.status === 200 || res?.status === 201) && res?.data?.data) {
//             let username;
//             if (res.data?.data?.shop?.username?.split('/')[1] == "member") {
//               let usernameWithDbname = res.data?.data?.shop?.username.split('/')[0] //get staff name with shop name
//               username = usernameWithDbname?.split('_')[1];//trynow
//             }
//             else {
//               username = res.data?.data?.shop?.username;
//             }
//             localStorage.setItem("username", username);
//             localStorage.setItem("token", res?.data?.data?.token);
//             setSubmittingBtnProcess(false);
//             // dispatch(SetUserPermission(res?.data?.data?.permissions));
//             props.history.push("/app/order/new");
//           }
//           else {
//             setSubmittingBtnProcess(false);
//           }
//         })
//         .catch((err) => {
//           setSubmittingBtnProcess(false);
//           toast.error(err?.response?.data?.message);
//         });
//     }
//   } catch (err) {
//     toast.error(err.message);
//   }
// };
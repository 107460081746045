import React, { useState } from "react";
import { Alert, Drawer, IconButton } from "@mui/material";
import {
  // Home as HomeIcon,
  // NotificationsNone as NotificationsIcon,
  // FormatSize as TypographyIcon,
  // FilterNone as UIElementsIcon,
  // BorderAll as TableIcon,
  // QuestionAnswer as SupportIcon,
  // LibraryBooks as LibraryIcon,
  // HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Row } from "react-bootstrap";
import SimpleBar from "simplebar-react";

import SidebarLink from "../SidebarLink/SidebarLink";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import RouteLinks from "common/routes";
import useStyles from "./styles";
import "simplebar/dist/simplebar.min.css";
import { Stack } from "@mui/system";

function Sidebar({ location }) {
  var { classes } = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  React.useEffect(function () {
   console.log("sidebar call")
  });

  const scrollableNodeRef = React.useRef();
  const { structure } = RouteLinks();

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <SimpleBar
        style={{
          width: "100%",
          padding: 24,
          paddingTop: '21px',
          maxHeight: window.innerHeight - 160,
        }}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
      >
        <Row className="mb-2">
          {structure.map(
            (link) =>
              link?.isSidebar && (
                <SidebarLink
                  //toggleSidebar={() => toggleSidebar(layoutDispatch)}
                  key={link.id + "_" + link?.link}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  {...link}
                />
              ),
          )}
        </Row>
      </SimpleBar>
      <Stack>
          <Stack className={classes.developmentsection}>Development & Maintain By <br/>
              <a href="https://www.aimbrill.com/" rel="noreferrer" target={"_blank"}> aimbrill.com</a>
              <div> All rights Reserved by <a rel="noreferrer" href="https://www.servofeat.com/" target={"_blank"}>servofeat.com</a></div>
          </Stack>
      </Stack>
    
    </Drawer>
  );

  // ##################################################################
  // function handleWindowWidthChange() {
  //   var windowWidth = window.innerWidth;
  //   var breakpointWidth = theme.breakpoints.values.md;
  //   var isSmallScreen = windowWidth < breakpointWidth;

  //   if (isSmallScreen && isPermanent) {
  //     setPermanent(false);
  //   } else if (!isSmallScreen && !isPermanent) {
  //     setPermanent(true);
  //   }
  // }
}

export default withRouter(React.memo(Sidebar));

import React from "react";
import "./waitingqueue.styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import call from "../../assets/img/call.png";
import MenuButtonEditDelete from "../MenuButtonEditDelete/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";

function Waitingqueue(props) {
  const {
    Phone,
    customerName,
    no_of_person,
    present_date_time,
    deleteData,
    editData,
    itemId,
    resource
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Container fluid className="p-0">
        <Card className="card1">
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <p className="para" style={{ marginBottom: "15px" }}>
                  {t("number-of-person-label")}: <span>{no_of_person}</span>
                </p>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="para">{present_date_time}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ height: 38 }}>
            <Col>
              <div className="row2">
                <h6 className="tale">{customerName}</h6>
                <div className="callp">
                  <img src={call} className="call" />
                  <p>{Phone}</p>
                </div>
              </div>
            </Col>
            <Col>
              <MenuButtonEditDelete
                resource={resource}
                deleteClick={deleteData}
                editClick={editData}
                itemId={itemId}
                isMenuBar={true}
              />
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
export default Waitingqueue;

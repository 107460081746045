import React from "react";
import { styled } from '@mui/material/styles';
import "./DiningTable.styles.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import MenuButtonEditDelete from "../MenuButtonEditDelete/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";

const PREFIX = 'DiningTable';
const classes = {};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({}));

function DiningTable(props) {
  const {
    name_of_table,
    is_active,
    is_available,
    capacity,
    description,
    deleteData,
    editData,
    itemId,
    openQR,
    table_encypt_id,
    resource
  } = props;

  const { t } = useTranslation();
  return (
    <Root>
      <Container fluid className="p-0" style={{ marginBottom: 4 }}>
        <Card className={`${is_active ? "card1" : "card2"}`}>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <p className="para" style={{ marginBottom: "15px" }}>
                  {t("dining-table-capacity-label-moileview")}: &nbsp;
                  <span>{capacity}</span>
                </p>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {is_available ? (
                  <button className="btns">
                    {" "}
                    {t("dining-table-open-mobileview")}{" "}
                  </button>
                ) : (
                  <button className="btnr">
                    {" "}
                    {t("dining-table-reserved-mobileview")}{" "}
                  </button>
                )}
                <IconButton
                  title={t("dining-table-view-qrcode-icon")}
                  arial-label={t("dining-table-view-qrcode-icon")}
                  onClick={() => {
                    openQR(table_encypt_id, itemId);
                  }}
                  className="p-0"
                  size="large">
                  <FontAwesomeIcon icon={faQrcode} />
                </IconButton>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row2">
                <h6 className="tale">{name_of_table}</h6>
                {/* {description && <p className="para2">{description}</p> } */}
              </div>
            </Col>
            <Col>
              <MenuButtonEditDelete
                resource={resource}
                deleteClick={deleteData}
                editClick={editData}
                itemId={itemId}
                isMenuBar={true}
              />
            </Col>
          </Row>
          {!is_active && (
            <Row>
              <Col>
                <p style={{ color: "red", fontSize: "12px" }}>
                  {t("dining-table-unavailable-message")}
                </p>
              </Col>
            </Row>
          )}
        </Card>
      </Container>
    </Root>
  );
}
export default DiningTable;

import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionActions from '@mui/material/AccordionActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

const PREFIX = 'OrderTotalAccordion';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  icon: `${PREFIX}-icon`,
  details: `${PREFIX}-details`,
  column: `${PREFIX}-column`,
  summery: `${PREFIX}-summery`,
  helper: `${PREFIX}-helper`,
  link: `${PREFIX}-link`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.icon}`]: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },

  [`& .${classes.details}`]: {
    alignItems: 'center',
    display: 'block'
  },

  [`& .${classes.column}`]: {
    flexBasis: '33.33%',
  },

  [`& .${classes.summery}`]: {
      background: '#f4f4f4'
  },

  [`& .${classes.helper}`]: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.link}`]: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}));

export default function OrderTotalAccordion(props) {
    const {accordionSummery, accordionBody} = props;


  return (
    <Root className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
          className={classes.summery}
        >
         {accordionSummery}
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {accordionBody}
        </AccordionDetails>
        <Divider />
      </Accordion>
    </Root>
  );
}
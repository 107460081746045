import React from 'react'
import { Button, Stack, Card } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

const PREFIX = 'DashboardCard';

const classes = {
    title: `${PREFIX}-title`,
    icon: `${PREFIX}-icon`
}


const DashboardCard = (props) => {
    const { title, link, icon, identifyicon } = props;
    const history = useHistory();

    const theme = useTheme();

    const Root = styled(Card)({
        [`& .${classes.title}`]: {
            fontSize: 16,
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                fontSize: '10px'
            }
        },
        [`& .${classes.icon}`]: {
            position: 'absolute',
            padding: '6px',
            top: '0',
            right: '0',
            [theme.breakpoints.down('md')]: {
               padding:'4px'
            }
        }
    });
    return (
        <>
            <Root>
                <Card sx={{ alignItems: 'center', display: 'flex', justifyContent: "center", height: '100%', flexDirection: 'column', p: 1.5, position: 'relative' }}>
                    <Stack className={classes.icon}>{identifyicon}</Stack>
                    <Button
                        color="primary"
                        variant="outline"
                        onClick={() => {
                            history.push(`${link}`)
                        }}
                    >
                        {icon}
                    </Button>
                    <Stack className={classes.title}>{title}</Stack>
                </Card>
            </Root>
        </>
    )
}

export default DashboardCard
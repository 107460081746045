import { Button } from "@mui/material";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import autherize from "../../assets/img/autherize.png";
import "./NotAutherize.styles.css";

const NotAutherize = () => {
  return (
    <>
      <Container fluid>
        <Row className="d-flex justify-content-center mt-3">
          <Col className="d-flex justify-content-center">
            <h5 className="title-style">You are not autherized to this Page. Please Go Back to Dashboard.</h5>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-3">
          <Col lg={7} md={7} sm={10} xs={10} className="d-flex justify-content-center">
            <img src={autherize}/>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center mt-5">
          <Col className="d-flex justify-content-center">
            <Link to="/app/dashboard">
              <Button
                variant="outlined"
                color="primary"
                style={{ color:"",backgroundColor:"white", border:"1px solid " }}
              >
              GO BACK 
              </Button>
            </Link>
            
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotAutherize;

import React from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

export default function PageLoaderBlock({processing, message, children}) {
    if(!processing)
    {
        return children;
    }
    return (
            <BlockUi tag="div"
                style={{
                    height: '100%',
                    zIndex: 9999999999999999999999999999,
                    overflow: 'hidden'
                }}
                blocking={processing}
                message={message || "Please wait.."}>
                {children}
            </BlockUi>
    )
}

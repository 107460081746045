import { format, getTime } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}
export function fDateTimeForamt(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm a');
}

export function fToNow(date) {
  return format(new Date());
}

import React from "react";
import { styled } from '@mui/material/styles';
import { useTheme } from "@mui/styles";
import classnames from "classnames";

const PREFIX = 'Dot';

const classes = {
  dotBase: `${PREFIX}-dotBase`,
  dotSmall: `${PREFIX}-dotSmall`,
  dotLarge: `${PREFIX}-dotLarge`
};

const Root = styled('div\n')((
  {
    theme
  }
) => ({
  [`& .${classes.dotBase}`]: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.text.hint,
    borderRadius: "50%",
    transition: theme.transitions.create("background-color"),
  },

  [`& .${classes.dotSmall}`]: {
    width: 5,
    height: 5
  },

  [`& .${classes.dotLarge}`]: {
    width: 11,
    height: 11,
  }
}));

export default function Dot({ size, color }) {

  var theme = useTheme();

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small",
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}

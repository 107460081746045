import React from 'react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SocialmediaIcon = (props) => {

    const { icon, link } = props;
    const theme = useTheme();
    const BLACK = theme.palette.common.black;

    return (
        <>
            <a href={link} target='_blank'>
                <Stack sx={{ height: '40px', width: '40px', lineHeight: '40px', backgroundColor: "#fff", borderRadius: '50px', textAlign: 'center', display: 'block',mr:1,mt:1  ,color:BLACK }}>
                    {icon}
                </Stack>
            </a>
        </>
    )
}

export default SocialmediaIcon;
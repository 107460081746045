import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavListRoot } from './NavList';
// import { useTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { ACTION } from "common/constant";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const shopSettingData = useSelector((state) => state?.shopSettingReducer?.shopSettingData);

  const renderNavListRoot = (list) => {

    if (list.title === "kitchen" && !shopSettingData.KITCHAN_SERVICE) {
      return null;
    }
    if (list.title === "table" && !shopSettingData.DINE_IN_SERVICE) {
      return null;
    }

    return <NavListRoot key={t("list.title") + list.path} list={list} />;

  }
  return (
    <Stack direction="row" justifyContent="flex-start" sx={{ bgcolor: theme.palette.grey[200], borderRadius: 1, px: 0.5, my: 0.6, mx: 1 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 0.5 }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <RoleBasedGuard
                resource={list.resource}
                action={ACTION.VIEW}
              >
               
                {renderNavListRoot(list)}
              </RoleBasedGuard>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);

const initialState = {
  foodMenuData: []
}

const CustomerReducer =  (state = initialState, action) => {
    switch (action.type) {
    case "GET_FOOD_MENU":
        return { ...state, foodMenuData:action.payLoad }

    default:
        return state
    }
}

export default CustomerReducer;
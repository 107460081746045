import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Box, Card, Container, Typography, Stack , Button} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import Page from "../../components/Page";
import Image from "../../components/Image";
import clsx from "clsx";
import useStyles from "./styles";
import "assets/css/style.css";
import "assets/css/theme.css";
import TextInputMui from "components/TextInputMui";
import { toast } from "react-hot-toast";
import { adminShopViaCreate } from "services/login.service";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function VerifyOtp(props) {
  const mdUp = useResponsive("up", "md");
  const classes = useStyles();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [shopDetail, setShopDetails] = useState();

  useEffect(() => {
    if (props.location.details) {
      setShopDetails(props.location?.details);
    }
  }, []);
  const reSendOtp = async () => {
    const shopData = {
      ...shopDetail
    };
    adminShopViaCreate(shopData)
      .then((res) => {
        if (res?.status === 200 || res?.data || res?.data?.status === 200) {
          setSubmittingBtnProcess(false);
          toast.success(res.data.message);
        } else {
          setSubmittingBtnProcess(false);
          toast.success(res?.message);
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(err?.message);
      });
  };
  const handleOTP = (values) => {
    setSubmittingBtnProcess(true);
    const shopData = {
      ...shopDetail,
      otp: values?.otp,
    };
    adminShopViaCreate(shopData)
      .then((res) => {
        if (res?.status === 200 || res?.data || res?.data?.status === 200) {
          setSubmittingBtnProcess(false);
          toast.success(res.data.message);
          localStorage.setItem("username", res.data?.data?.shop?.username);
          localStorage.setItem("token_onboading", res?.data?.data?.token);
          props.history.push({
            pathname: `/onboarding`
          });
        } else {
          setSubmittingBtnProcess(false);
          toast.success(res?.message);
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(err?.message);
      });
  };

  return (
    <Page title="Register">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Verify OTP
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              alt="register"
              src="assets/illustrations/illustration_register.png"
            />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h4"
                  sx={{ fontSize: "1.5rem", marginBottom: "2rem" }}
                >
                  Verify OTP
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontSize: "1rem", textAlign: "center", fontWeight: "500" }}
                >
                  Enter Verification Code
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ fontSize: "0.8rem", textAlign: "center" }}
                >
                  {`We have sent an OTP to ${shopDetail?.email || shopDetail?.phone}`}
                </Typography>
              </Box>
              <Box>
                <Image
                  disabledEffect
                  src={
                    "https://servofeatproduction.s3.ap-south-1.amazonaws.com/logo512.png"
                  }
                  sx={{ width: 50, height: 50 }}
                  className={classes.img}
                />
              </Box>
            </Box>

            <Form
              onSubmit={handleOTP}
              validate={(values) => {
                const errors = {};
                if (!values.otp) {
                  errors.otp = "OTP is required.";
                }

                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Stack spacing={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <Field name="otp">
                          {({ input, meta }) => (
                            <TextInputMui
                              {...input}
                              className={clsx(
                                classes.textField,
                                classes.textFieldUnderline,
                              )}
                              label="OTP"
                              type="text"
                              autoComplete="none"
                              variant="outlined"
                              fullWidth
                              error={meta.touched && meta.error ? true : false}
                              helperText={
                                meta.touched && meta.error && `${meta.error}`
                              }
                              FormHelperTextProps={{
                                style: { margin: "5px" },
                              }}
                            />
                          )}
                        </Field>
                      </Stack>

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submittingBtnProcess}
                      >
                        Verify OTP
                      </LoadingButton>
                      <Button
                        variant="contained"
                        color="warning"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        onClick={() => reSendOtp()}
                        type="submit"
                        loading={submittingBtnProcess}
                      >
                        Resend OTP
                      </Button>
                      <Button
                        color="info"
                        fullWidth
                        size="large"
                        style={{
                          marginTop: 10
                        }}
                        type="button"
                        variant="outlined"
                        onClick={() => props.history.push("/register")}
                      >
                        Back To Login
                      </Button>
                    </Stack>
                  </Box>
                </form>
              )}
            />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FieldArray } from "react-final-form-arrays";
import { Form, Field } from "react-final-form";
import { Button, Checkbox, FormControl } from "@mui/material";
import TextInputMui from "components/TextInputMui";
import Paper from "@mui/material/Paper";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Card from "react-bootstrap/Card";
import { Save as SaveIcon } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import { createRole } from "services/role.service";
import { toast } from "react-hot-toast";
import { checkSpaceAndRegex } from "utility/helpers";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import arrayMutators from "final-form-arrays";
import { updateRole } from "services/role.service";
import useStyles from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import { RESOURCE } from "common/constant";

const AddEditRole = (props) => {
  const initialState = {
    name: "",
    roles: [
      {
        index: 1,
        RESOURCE: RESOURCE.DINE_IN_TABLE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 2,
        RESOURCE: RESOURCE.FOOD_CATEGORY,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 3,
        RESOURCE: RESOURCE.FOOD_MENUS,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 4,
        RESOURCE: RESOURCE.WAITING_QUEUE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 5,
        RESOURCE: RESOURCE.CUSTOMERS,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 6,
        RESOURCE: RESOURCE.STAFF_MEMBERS,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 7,
        RESOURCE: RESOURCE.ROLES,
        VIEW: true,
        ADD: false,
        UPDATE: false,
        DELETE: false,
      },
      {
        index: 8,
        RESOURCE: RESOURCE.EXPENSECATEGORY,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 9,
        RESOURCE: RESOURCE.EXPENSE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 10,
        RESOURCE: RESOURCE.INVENTORYCATEGORY,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 11,
        RESOURCE: RESOURCE.INVENTORY,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 12,
        RESOURCE: RESOURCE.ORDER_TYPE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 13,
        RESOURCE: RESOURCE.PAYMENT_TYPE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 14,
        RESOURCE: RESOURCE.COUPONS,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 15,
        RESOURCE: RESOURCE.PARCEL_ORDER,
        VIEW: true,
        ADD: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 16,
        RESOURCE: RESOURCE.SALES,
        VIEW: true,
        UPDATE: true,
        DELETE: true,
        PRINT: true,
        SETTLE: true
      },
      {
        index: 17,
        RESOURCE: RESOURCE.ONLINE_ORDER,
        VIEW: true,
        UPDATE: true,
        DELETE: true,
      },
      {
        index: 18,
        RESOURCE: RESOURCE.KITCHEN_QUEUE,
        VIEW: true,
        UPDATE: true,
      },
      {
        index: 19,
        RESOURCE: RESOURCE.DASHBOARD,
        VIEW: true,
      },
      {
        index: 20,
        RESOURCE: RESOURCE.ANALYTICS,
        VIEW: true,
      },
      {
        index: 21,
        RESOURCE: RESOURCE.SALES_REPORT,
        VIEW: true,
        DOWNLOAD: true,
      },
      {
        index: 22,
        RESOURCE: RESOURCE.PROFILE,
        VIEW: true,
        ADD: true,
        UPDATE: true,
      },
      {
        index: 23,
        RESOURCE: RESOURCE.SETTING,
        VIEW: true,
        ADD: true,
        UPDATE: true,
      },
      {
        index: 24,
        RESOURCE: RESOURCE.ORDER_PRINT_KOT_BTN,
        VIEW: true
      },
      {
        index: 25,
        RESOURCE: RESOURCE.ORDER_PRINT_BILL_BTN,
        VIEW: true
      },
      {
        index: 26,
        RESOURCE: RESOURCE.ORDER_SETTLE_BTN,
        VIEW: true,
      },
      {
        index: 27,
        RESOURCE: RESOURCE.ORDER_SETTLE_AND_PRINT_BTN,
        VIEW: true,
      },
      {
        index: 28,
        RESOURCE: RESOURCE.ORDER_DELETE_BTN,
        VIEW: true,
      },
      {
        index: 29,
        RESOURCE: RESOURCE.BULK_UPLOAD,
        VIEW: true,
        ADD: true
      },
    ],
  };
  const [details, setDetails] = useState(initialState);
  let history = useHistory();
  const classes = useStyles();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.location.details) {

      let permission =
        props.location?.details?.role_permissions.map(
          (access) => ({
            RESOURCE: access.resource,
            ...JSON.parse(access.permission),
          }),
        );
      permission = permission.sort((a, b) => a.index - b.index)
      setDetails({
        id: props.location.details.id,
        name: props.location.details.name,
        roles: permission,
      });
    }
  }, []);

  const navigateRole = () => {
    history.push("/app/roles");
  };

  const onSubmit = async (fields) => {
    const rolePermissionData = {
      name: fields.name,
      username: localStorage.getItem("username"),
      shop_id: localStorage.getItem("shopId"),
      permissions: fields.roles,
    };
    if (fields.id > 0) {
      setSubmittingBtnProcess(true);
      updateRole(fields.id, rolePermissionData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t("role-update-success-toast-message"));
            setSubmittingBtnProcess(false);
            navigateRole();
          } else if (res.status === 400) {
            setSubmittingBtnProcess(false);
            toast.error(res?.message);
          }
        })
        .catch((error) => {
          setSubmittingBtnProcess(false);
          toast.error(error.message);
        });
    } else {
      setSubmittingBtnProcess(true);
      createRole(rolePermissionData)
        .then((res) => {
          if (res.status === 201) {
            toast.success(t("role-create-success-message"));
            setSubmittingBtnProcess(false);
            navigateRole();
          } else {
            setSubmittingBtnProcess(false);
            toast.error(res?.message);
          }
        })
        .catch((error) => {
          setSubmittingBtnProcess(false);
          toast.error(error.message);
        });
    }
  };

  return (
    <>
      <Card>
        <Card.Header className={classes.header}>
          <ArrowBackIcon onClick={navigateRole}></ArrowBackIcon>
          <SupervisedUserCircleIcon />
          <strong className="ml-2">{t("role-screen-title")}</strong>
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={onSubmit}
            initialValues={details}
            mutators={{
              ...arrayMutators,
            }}
            validate={(values) => {
              const errors = {};
              const checkerror = checkSpaceAndRegex(values?.name);
              if (checkerror != "") errors.name = checkerror;
              if (!values.name) {
                errors.name = t("role-name-validation-message");
              } else if (values?.name?.length > 35) {
                errors.name = t("maximumlength-validation-message");
              }

              return errors;
            }}
            render={({
              handleSubmit,
              form,
              submitting,
              values,
              form: {
                mutators: { push, pop },
                change,
                reset,
                restart,
              },
            }) => (
              <form
                onSubmit={(event) => {
                  handleSubmit(event).then(() => {
                    reset();
                    restart();
                  });
                }}
              >
                <div>
                  <Row>
                    <Col sm={12} md={4}>
                      <Field name="name">
                        {({ input, meta }) => (
                          <FormControl className={classes.formcontrolwidth}>
                            <TextInputMui
                              {...input}
                              type="text"
                              variant="outlined"
                              label={`${t("role-label")}${" *"}`}
                              error={meta.error && meta.touched ? true : false}
                            />
                            {meta.error && meta.touched && (
                              <span style={{ color: "red" }}>{meta.error}</span>
                            )}
                          </FormControl>
                        )}
                      </Field>
                    </Col>
                    <Col>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        startIcon={<SaveIcon />}
                      >
                        {submittingBtnProcess ? t("processing..") : t("save")}
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  {
                    <FieldArray
                      name="roles"
                      render={({ push }) => (
                        <Paper>
                          <TableContainer>
                            <Table
                              className={classes.table}
                              aria-label="Addons"
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell alig="justify">
                                    {t("number-label")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("name-label")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("view")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("add-button-label")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("update")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("delete")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("dining-table-qrcode-print")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("dining-table-qrcode-download")}
                                  </TableCell>
                                  <TableCell align="justify">
                                    {t("settle-option")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values?.roles?.map((item, index) => (
                                  <TableRow>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{item?.RESOURCE}</TableCell>
                                    <TableCell>
                                      {(item.VIEW === false ||
                                        item.VIEW === true) && (
                                          <Field
                                            name={`roles.${index}.VIEW`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.ADD === false ||
                                        item.ADD === true) && (
                                          <Field
                                            name={`roles.${index}.ADD`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.UPDATE === false ||
                                        item.UPDATE === true) && (
                                          <Field
                                            name={`roles.${index}.UPDATE`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.DELETE === false ||
                                        item.DELETE === true) && (
                                          <Field
                                            name={`roles.${index}.DELETE`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.PRINT === false ||
                                        item.PRINT === true) && (
                                          <Field
                                            name={`roles.${index}.PRINT`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.DOWNLOAD === false ||
                                        item.DOWNLOAD === true) && (
                                          <Field
                                            name={`roles.${index}.DOWNLOAD`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                      {(item.SETTLE === false ||
                                        item.SETTLE === true) && (
                                          <Field
                                            name={`roles.${index}.SETTLE`}
                                            type="checkbox"
                                          >
                                            {({ input, meta }) => (
                                              <Checkbox
                                                color="primary"
                                                {...input}
                                                style={{ paddingLeft: "0px" }}
                                              />
                                            )}
                                          </Field>
                                        )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      )}
                    />
                  }
                </div>
              </form>
            )}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default AddEditRole;

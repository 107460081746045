import React, { useEffect, useState, useRef, useCallback } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import { Save as SaveIcon, Print, Refresh } from "@mui/icons-material";
import ThermalPrinterPlugin from "thermal-printer-cordova-plugin/www/thermal-printer";
import { savePrinterSetting } from "services/setting.service";
import { useInMobile } from "themes/Device";
import { fetchShopSettingInfo } from "state/thunk-async";

//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";

import "./MobilePrinter.css";
import { orderPrintReceipt, mobileBillPrintViaImage, getHtmlTemplate } from "../../utility/posPrinterReceipt";
import { geSettingsnew } from "services/setting.service";
import { useDispatch } from "react-redux";
import { getBase64StringFromDataURL } from "utility/helpers";
import html2canvas from 'html2canvas';

const DummyPrintModal = {
  shopName: "સર્વોફીટ ડેમો",
  shopAddress: "સર્વોફીટ, અમદાવાદ JohnDoe@gmail.com 555-555-5555",
  customerName: "Dharmik Bhai",
  date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
  tokenNo: "1 (Dine In)",
  printFoodItemArray: [{
    foodName: "પિઝા",
    qty: "2",
    price: "100"
  },
  {
    foodName: "ફાફડા & જલેબી",
    qty: "1",
    price: "50"
  },
  {
    foodName: "ઢોસા",
    qty: "1",
    price: "70" 
  },
  {
    foodName: "રોટલો & રીંગણા",
    qty: "2",
    price: "100"
  }],
  subTotal: "520",
  tax: "10",
  discount: "15",
  netTotal: "515",
};

const MobilePrinter = () => {
  const dispatch = useDispatch();
  const [blutoothPrinters, setBlutoothPrinter] = useState([]);
  const [usbPrinters, setUsbPrinter] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [usbDevice, setUsbDevice] = React.useState("");
  const [blutoothDevice, setBlutoothDevice] = React.useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [port, setPort] = useState("");
  const [kotIpAddress, setKotIpAddress] = useState("");
  const [kotPort, setKotPort] = useState("");
  const [endSpace, setEndSpace] = useState("");
  const [printerSize, setPrinterSize] = useState("");
  const isMobileView = useInMobile();
  const layoutDispatch = useLayoutDispatch();
  const [printerEndSpaceStr, setPrinterEndSpace] = useState("");
  const ref = useRef(null);

  const handleUsbChange = (event) => {
    setUsbDevice(event.target.value);
  };
  const handleBlutoothChange = (event) => {
    setBlutoothDevice(event.target.value);
  };

  useEffect(() => {
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: "POS Printer Driver",
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  useEffect(() => {
    getSettingConfiguration();
  }, []);

  const getSettingConfiguration = async () => {
    const res = await geSettingsnew();
    if (res.status === 200) {
      const {
        KITCHEN_IP,
        PRINTER_NAME,
        PRINTER_SIZE,
        PRINTER_TYPE,
        PRINTER_END_SPACE,
        mobile_printing_setting,
        printing_setting,
        BILL_IP,
      } = res.data;
      console.log("printing configuration" + mobile_printing_setting);
      if (mobile_printing_setting?.PRINTER_END_SPACE) {
        setEndSpace((mobile_printing_setting?.PRINTER_END_SPACE)?.split("\n").length - 1);
        setPrinterEndSpace(mobile_printing_setting?.PRINTER_END_SPACE);
      }
      if (mobile_printing_setting?.PRINTER_SIZE) {
        setPrinterSize(printing_setting?.PRINTER_SIZE);
      }
      if (mobile_printing_setting?.PRINTER_TYPE === "usb") {
        setUsbDevice(mobile_printing_setting?.PRINTER_NAME);
        setSelectedOption(1);
      } else if (mobile_printing_setting?.PRINTER_TYPE === "bluetooth") {
        setBlutoothDevice(mobile_printing_setting?.PRINTER_NAME);
        setSelectedOption(2);
      } else if (mobile_printing_setting?.PRINTER_TYPE === "ip") {
        setSelectedOption(0);
        if (BILL_IP?.split(":")?.length > 0) {
          const billPrinter = BILL_IP?.split(":");
          setIpAddress(billPrinter[0]);
          setPort(billPrinter[1]);
        }
        if (KITCHEN_IP?.split(":")?.length > 0) {
          const billKotPrinter = KITCHEN_IP?.split(":");
          setKotIpAddress(billKotPrinter[0]);
          setKotPort(billKotPrinter[1]);
        }
      }
    }
  };

  useEffect(() => {
    getAllConnectedPrinter("usb");
    getAllConnectedPrinter("bluetooth");
  }, []);

  const getAllConnectedPrinter = (type) => {
    try {
      ThermalPrinterPlugin.listPrinters(
        {
          type,
        },
        function (data) {
          if (type === "usb") {
            setUsbPrinter(data);
          } else if (type === "bluetooth") {
            setBlutoothPrinter(data);
          }
        },
        function (error) {
          alert(JSON.stringify(error));
          console.error("Printing error", JSON.stringify(error));
        },
      );
    } catch (error) {
      alert(JSON.stringify(error));
    }
    
  };

  const tcpPrinter = () => {
    ThermalPrinterPlugin.printFormattedTextAndCut(
      {
        type: "tcp",
        address: ipAddress,
        port: port,
        text: orderPrintReceipt(printerEndSpaceStr),
        id: "tcp-printer-001",
      },
      function () {
        // console.log("Successfully printed!");
        toast.success("Successfully printed!");
      },
      function (error) {
        alert(JSON.stringify(error));
        console.error("Printing error", JSON.stringify(error));
      },
    );
  };

  const hexaDecimalPrinter = async (type, printerId) => {
    const base64String = await getBase64StringFromHtml();
    try {
      toast.success("HEXA DECIMAL CALLED");
      console.log("HEXA DECIMAL CALLED")
      console.log(JSON.stringify({
        type: type,
        address: ipAddress,
        port: port,
        id: printerId,
      }));
      console.log('%cMobilePrinter.js line:197 base64String', 'color: #007acc;', base64String);
        if(base64String)
        {
          mobileBillPrintViaImage(type, printerId, "", base64String)
        }
    }
    catch(error)
    {
      console.log(JSON.stringify(error));
      toast.error(JSON.stringify(error));
    }
  }


  

  const usbPrinter = () => {
    ThermalPrinterPlugin.printFormattedTextAndCut(
      {
        type: "usb",
        id: usbDevice, // Use an unique identifier for each printer i. e. address:port or name
        text: orderPrintReceipt(printerEndSpaceStr), // new lines with "\n"
      },
      function () {
        // console.log("Successfully printed!");
        toast.success("Successfully printed!");
      },
      function (error) {
        alert(JSON.stringify(error));
      },
    );
  };

  const bluetoothPrinter = () => {
   
    ThermalPrinterPlugin.printFormattedText(
      {
        type: "bluetooth",
        id: blutoothDevice, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
        text: orderPrintReceipt(printerEndSpaceStr), // new lines with "\n"
      },
      function () {
        toast.success("Successfully printed!");
      },
      function (error) {
        alert(JSON.stringify(error));
        console.error("Printing error", JSON.stringify(error));
      },
    );
  };

  const handleTestPrintImageBill = () => {
   
    
    if (selectedOption === 0) {
      if (ipAddress && port) {
        tcpPrinter();
      } else {
        toast.error("Ipaddress & Port is required for Wifi/Ethernet Printer");
      }
    } else if (selectedOption === 1) {
      if (usbDevice) {
        // mobileBillPrintViaImage("usb", usbDevice, DummyPrintModal);
        hexaDecimalPrinter("usb", usbDevice)
      } else {
        toast.error("Please select USB Printer.");
      }
    } else if (selectedOption === 2) {
      if (blutoothDevice) {
        // mobileBillPrintViaImage("bluetooth", blutoothDevice, DummyPrintModal);
        hexaDecimalPrinter("bluetooth", blutoothDevice)
      } else {
        toast.error("Please select Bluetooth Printer.");
      }
    }
  };

  const handleTestPrint = () => {
    if (selectedOption === 0) {
      if (ipAddress && port) {
        tcpPrinter();
      } else {
        toast.error("Ipaddress & Port is required for Wifi/Ethernet Printer");
      }
    } else if (selectedOption === 1) {
      if (usbDevice) {
        usbPrinter();
      } else {
        toast.error("Please select USB Printer.");
      }
    } else if (selectedOption === 2) {
      if (blutoothDevice) {
        bluetoothPrinter();
      } else {
        toast.error("Please select Bluetooth Printer.");
      }
    }
  };

  const handleTestKOTPrint = () => {
    if (selectedOption === 0) {
      if (kotIpAddress && kotPort) {
        ThermalPrinterPlugin.printFormattedTextAndCut(
          {
            type: "tcp",
            address: kotIpAddress,
            port: kotPort,
            text: '[C]<u><font size="big">Hello World</font></u><u><font size="big">Hello World</font></u>', // new lines with "\n"
          },
          function () {
            toast.success("Successfully printed!");
          },
          function (error) {
            alert(JSON.stringify(error));
            console.error("Printing error", JSON.stringify(error));
          },
        );
      } else {
        toast.error(
          "KOT Ipaddress & Port is required for Wifi/Ethernet Printer",
        );
      }
    }
  };

  const handleSavePrinterSetting = async () => {
    const request = {
      BILL_IP: selectedOption === 0 ? `${ipAddress}:${port}` : "",
      KITCHEN_IP: selectedOption === 0 ? `${kotIpAddress}:${kotPort}` : "",
      PRINTER_TYPE:
        selectedOption > 0
          ? selectedOption === 1
            ? "usb"
            : "bluetooth"
          : "ip",
      PRINTER_NAME: usbDevice || blutoothDevice || "",
      PRINTER_SIZE: printerSize,
      PRINTER_END_SPACE: printerEndSpaceStr,
    };
    const res = await savePrinterSetting(request);
    if (res.status === 200 || res.status === 201) {
      dispatch(fetchShopSettingInfo());
      toast.success("Setting is saved successfully.");
    }
  };


    const getBase64StringFromHtml = useCallback(async () => {
      if (ref.current === null) {
        return
      }
      
      return html2canvas(ref.current).then(function(canvas) {
          const dataURL = canvas.toDataURL('image/jpeg', 1);
          console.log(dataURL);
          const base64 = getBase64StringFromDataURL(dataURL);
          console.log(base64);
          return base64;
      });
    }, [ref])

  return (
    <>
    <div className="invoice-pos-print-div">
      <div ref={ref}>
        {
            getHtmlTemplate(DummyPrintModal)
          }
      </div>
    </div>
      <div style={{ padding: "0px 12px" }}>
        {!isMobileView && <h5>POS Printer Driver</h5>}
        <p>Select device type to install</p>
      </div>
      <FormControlLabel
        value="female"
        control={
          <Radio
            checked={selectedOption === 0}
            onChange={() => setSelectedOption(0)}
          />
        }
        label="WIFI or Ethernet Printer"
      />
      {selectedOption === 0 && (
        <>
          <Grid
            container
            style={{ paddingLeft: 12, paddingRight: 12, marginBottom: 10 }}
          >
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="Billing IP Address"
                variant="outlined"
                style={{ width: "95%" }}
                value={ipAddress}
                onChange={(e) => setIpAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Billing Port"
                variant="outlined"
                value={port}
                onChange={(e) => setPort(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ paddingLeft: 12, paddingRight: 12, marginBottom: 10 }}
          >
            <Grid item xs={8}>
              <TextField
                id="outlined-basic"
                label="KOT IP Address"
                variant="outlined"
                style={{ width: "95%" }}
                value={kotIpAddress}
                onChange={(e) => setKotIpAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="KOT Port"
                variant="outlined"
                value={kotPort}
                onChange={(e) => setKotPort(e.target.value)}
              />
            </Grid>
          </Grid>
        </>
      )}
      <FormControlLabel
        value="male"
        control={
          <Radio
            checked={selectedOption === 1}
            onChange={() => setSelectedOption(1)}
          />
        }
        label="USB Printer"
      />
      {selectedOption === 1 && (
        <Grid
          container
          style={{ paddingLeft: 12, paddingRight: 12, marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Printer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={usbDevice}
                label="Printer"
                onChange={handleUsbChange}
              >
                {usbPrinters &&
                  usbPrinters.length > 0 &&
                  usbPrinters.map((usePrinter) => (
                    <MenuItem value={usePrinter.deviceId}>
                      <span>{usePrinter.productName}</span>
                      <span>{usePrinter.deviceId}</span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <FormControlLabel
        value="other"
        control={
          <Radio
            checked={selectedOption === 2}
            onChange={() => setSelectedOption(2)}
          />
        }
        label="Blutooth Printer"
      />
      {selectedOption === 2 && (
        <Grid
          container
          style={{ paddingLeft: 12, paddingRight: 12, marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Printer</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={blutoothDevice}
                label="Printer"
                onChange={handleBlutoothChange}
              >
                {blutoothPrinters &&
                  blutoothPrinters.length > 0 &&
                  blutoothPrinters.map((blutoothPrinter) => (
                    <MenuItem value={blutoothPrinter.address}>
                      {blutoothPrinter.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          marginBottom: 16,
          marginTop: 10,
        }}
      >
        <Grid item xs={6} style={{ paddingRight: 5 }}>
          <TextField
            id="outlined-basic"
            label="Printer End Space"
            variant="outlined"
            value={endSpace}
            type="number"
            onChange={(e) => {
              setEndSpace(e.target.value);

              let endSpaceStr = "";
              if (parseInt(e.target.value)) {
                for (let i = 0; i < parseInt(e.target.value); i++) {
                  endSpaceStr += "[C] \n";
                }
              }
              setPrinterEndSpace(endSpaceStr);
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 5 }}>
          <TextField
            id="outlined-basic"
            label="Printer Size"
            variant="outlined"
            type="number"
            value={printerSize}
            onChange={(e) => setPrinterSize(e.target.value)}
          />
        </Grid>
      </Grid>
      <div style={{ margintop: 20 }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          //   disabled={submitting || submittingBtnProcess}
          style={{
            background: "",
            color: "#fff",
            margin: 5,
            height: 30,
          }}
          startIcon={<SaveIcon />}
          onClick={() => handleSavePrinterSetting()}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{
            margin: 5,
            height: 30,
          }}
          startIcon={<Print />}
          onClick={() => handleTestPrintImageBill()}
        >
          Test Bill Image Print ( BitMap Print)
        </Button>

        <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{
            margin: 5,
            height: 30,
          }}
          startIcon={<Print />}
          onClick={() => handleTestPrint()}
        >
          Test Normal Print
        </Button>

        <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{
            margin: 5,
            height: 30,
          }}
          startIcon={<Print />}
          onClick={() => handleTestKOTPrint()}
        >
          Test KOT Print
        </Button>
        {/* <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{
            margin: 5,
            height: 30,
          }}
          startIcon={<Print />}
          onClick={() => handleImageBillPrint()}
        >
          Image Bill Print
        </Button> */}
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{
            margin: 5,
            height: 30,
          }}
          startIcon={<Refresh />}
          onClick={() => {
            getAllConnectedPrinter("usb");
            getAllConnectedPrinter("bluetooth");
          }}
        >
          Refresh
        </Button>
      </div>
    </>
  );
};

export default MobilePrinter;

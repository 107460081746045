import React, { useEffect, useState } from "react";

import checkAddonsMatch from "../../common/order-common";

import vegImg from "../../assets/img/vegetarian-food-symbol.png";
import nonVegImg from "../../assets/img/non-vegetarian-food-symbol.png";

function GroupByVariantAddons(props) {
  const [foodItemFilteredList, setFoodItemFilteredList] = useState([]);
  const {
    foodMealList,
    itemBackgroundColor,
    isOnlyVegStore,
    itemFontColor,
  } = props;

  useEffect(() => {
    setFoodItemFilteredList(getFilteredItems(foodMealList));
  }, [foodMealList]);

  const getFilteredItems = (foodMealList) => {
    const groupList = [];
    foodMealList?.forEach((meal) => {
      let isAvailable = false;

      groupList?.forEach((groupItem, i) => {
        if (
          meal?.varient_id === groupItem?.varient_id &&
          meal?.order_meal_item?.food_item_id ===
            groupItem?.order_meal_item?.food_item_id &&
          checkAddonsMatch(
            meal?.order_meal_item?.addons,
            groupItem?.order_meal_item?.addons,
          )
        ) {
          isAvailable = true;
          groupList[i].qty =
            parseInt(groupList[i].qty) + parseInt(meal?.qty || 0);
        }
      });

      if (!isAvailable) {
        groupList.push(meal);
      }
    });
    return groupList;
  };

  return (
    <>
      {foodItemFilteredList &&
        foodItemFilteredList.length > 0 &&
        foodItemFilteredList.map((item) => (
          <tr
            style={{
              backgroundColor: itemBackgroundColor(item.status, item.types),
              color: itemFontColor(item.status, item.types),
            }}
          >
            <td>
              <span>
                {item?.order_meal_item?.food_variant?.name || "Regular"}
              </span>
              {item?.order_meal_item?.addons?.length > 0 && (
                <>
                  {" ("}
                  {item?.order_meal_item?.addons?.map((addOn, index) =>
                    index === item?.order_meal_item?.addons?.length - 1
                      ? `${addOn?.name}`
                      : `${addOn?.name}, `,
                  )}
                  {") "}
                </>
              )}
            </td>
            {!isOnlyVegStore() && (
              <td className="text-center">
                {item &&
                item?.order_meal_item &&
                item?.order_meal_item?.is_non_veg ? (
                  <img
                    src={nonVegImg}
                    style={{ width: 25, objectFit: "contain" }}
                    alt="non veg"
                  />
                ) : (
                  <img
                    src={vegImg}
                    style={{ width: 25, objectFit: "contain" }}
                    alt="veg"
                  />
                )}
              </td>
            )}
            <td className="text-center">{item?.qty || 0}</td>
          </tr>
        ))}
    </>
  );
}

export default GroupByVariantAddons;

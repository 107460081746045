import React, { useEffect, useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import { Edit } from "@mui/icons-material";
import { Form, Field } from "react-final-form";
import { toast } from "react-hot-toast";
import { Save as SaveIcon, Print } from "@mui/icons-material";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import ReactToPrint from "react-to-print";

import { fetchBillingInfo } from "state/thunk-async/index";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardAvatar from "components/Card/CardAvatar";
import CardBody from "components/Card/CardBody";
import { getAllSettingList, updateBillingInfo } from "services/setting.service";
import NoImgPreview from "assets/img/NoImgPreview.jpg";
import { getBase64 } from "utility/helpers";
import UserQrPrints from "./UserQrPrints";
import { Col, Row, Container } from "react-bootstrap";
import { checkSpaceAndRegex } from "utility/helpers";
import { uploadFile } from "services/upload-files.service";
import configData from "config";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useInMobile } from "themes/Device";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
const PREFIX = 'UserProfile';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  cardInfo: `${PREFIX}-cardInfo`,
  profileInfo: `${PREFIX}-profileInfo`,
  printIcon: `${PREFIX}-printIcon`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-se\rif',
    marginBottom: "3px",
    textDecoration: "none",
  },

  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      margin: "5px",
    },
  },

  [`& .${classes.cardInfo}`]: {
    height: "88%",
    marginTop: "0px !important",
  },

  [`& .${classes.profileInfo}`]: {
    marginTop: "0px",
  },

  [`& .${classes.printIcon}`]: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  }
}));

export default function UserProfile() {

  const theme = useTheme();
  const [initialBillingData, setInitialBillingData] = useState();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const [imgSrc, setImageSrc] = useState(null);
  const [imgFile, setimgFileImageSrc] = useState(null);
  const [imageBase64, setImageBase64] = useState("");
  const isMobileView = useInMobile();
  const refQRCODE = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    getAllSettingList()
      .then((res) => {
        setInitialBillingData(res.data[0]);
        if (res.data[0]?.logo)
          setImageSrc(`${configData.S3_BUCKET}${res.data[0]?.logo}`);
      })
      .catch((err) => console.log(err));
  }, []);

  const dispatch = useDispatch();

  const onImgChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      setimgFileImageSrc(file);
      setImageSrc(URL.createObjectURL(file));
      getBase64(file)
        .then((result) => {
          setImageBase64(result);
        })
        .catch(() => {
          // console.log(err);
        });
    } else {
      setImageSrc(null);
    }
  };

  const triggerImageuploadPopup = () => {
    document.getElementById("logo-profile").click();
  };

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    values.gst_no = values?.gst_no ? values?.gst_no : null;
    if (imgFile) {
      const formData = new FormData();
      formData.append("file", imgFile);
      const { data } = await uploadFile(formData);
      values.logo = data?.data?.key;
    }
    updateBillingInfo(values)
      .then((res) => {
        if (res?.data) {
          setSubmittingBtnProcess(false);
          dispatch(fetchBillingInfo());
          toast.success(t("billing-info-updated-success-toast-message"));
        } else {
          setSubmittingBtnProcess(false);
          toast.success(t("something-went-wrong-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success(t("something-went-wrong-toast-message"));
      });
  };

  const downloadQR = () => {
    const qrCodeURL = document
      .getElementById("qrCodeE2")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  return (
    <Root>
      {initialBillingData === undefined ||
        initialBillingData === null ||
        initialBillingData === {} ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <br></br>
          <b>{t("data-fetch-message")}</b>
        </div>
      ) : (
        <Form
          onSubmit={onSubmit}
          initialValues={initialBillingData}
          validate={(values) => {
            const errors = {};
            const emailRegex =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            // const checkerror = checkSpaceAndRegex(values?.name);
            // if (checkerror != "") errors.name = checkerror;

            if (!values.name?.trim()) {
              errors.name = t("restaurant-validation-message");
            } else if (values?.name?.length > 50) {
              errors.name = t("maximumlength-validation-message");
            }

            if (values?.email) {
              if (values?.email?.length > 50) {
                errors.email = t("maximumlength-validation-message");
              } else if (!emailRegex.test(values?.email)) {
              errors.email = t("email-invalid-validation-message");
              }
          }

            if (values?.phone) {
              if (isNaN(values?.phone)) {
                errors.phone = t("phone-number-validation-message");
              } else if (values?.phone?.length !== 10) {
                errors.phone = t("Phone no. should be 10 digit.");
              }
            }

            if (values.address && values?.address?.length > 120) {
              errors.address = t("address-maximum-validation-message");
            }

            if (values?.fssai_no && values?.address?.length > 14) {
              errors.fssai_no = t(
                "billing-info-fssai-number-validation-message",
              );
            }

            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Container className="profile-container">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Card profile className="mt-5">
                      <CardAvatar profile>
                          <img
                             style={{ height: "100px", width: "100px" }}
                            src={imgSrc ? imgSrc : NoImgPreview}
                            alt="brand logo"
                          />
                      </CardAvatar>
                      <Button
                        className="mt-2"
                        onClick={triggerImageuploadPopup}
                      >
                        <b>Upload Logo</b> <Edit />
                      </Button>
                      <div className="d-none">
                        <FormControl className={classes.formcontrolwidth}>
                          <input
                            id="logo-profile"
                            type="file"
                            variant="outlined"
                            onChange={onImgChange}
                            inputProps={{ accept: ".png, .jpg, .jpeg" }}
                            title={
                              imgSrc
                                ? t("single-file-selected-title")
                                : t("no-any-one-file-selected-title")
                            }
                          />
                        </FormControl>
                      </div>
                      <CardBody profile className={classes.profileInfo}>
                        {/* <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6> */}
                        <h4 className={classes.cardTitle}>{values?.name}</h4>
                        <p className={classes.description}>{values.about}</p>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Field name="about" type="textarea">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("about-label")}
                                    rows={2}
                                    multiline
                                    variant="outlined"
                                  />
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </GridContainer>
                        {/* <UserQrPrints ref={refQRCODE} shopId={values.shop_id} />
                        <Row>
                          <Col xs={6} sm={6} md={6}>
                            <Button
                              variant="contained"
                              color="primary"
                              className="mr-3"
                              style={{
                                background: "",
                                color: "#fff",
                              }}
                              onClick={downloadQR}
                            >
                              DOWNLOAD
                            </Button>
                          </Col>
                          <Col xs={6} sm={6} md={6}>
                            <ReactToPrint
                              trigger={() => (
                                <Button variant="contained" color="primary">
                                  <Print className={classes.printIcon} /> Print
                                </Button>
                              )}
                              content={() => refQRCODE.current}
                            />
                          </Col>
                        </Row> */}
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <Card className={classes.cardInfo}>
                      <CardHeader
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <h4 className={classes.cardTitleWhite}>
                          {t("billing-info-title")}
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={8}>
                            <Field name="name">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("business-name-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Field name="res_type">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("billing-info-type-label")}
                                    variant="outlined"
                                    placeholder="Cafe, hotel, food-truck, restaurant"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </GridContainer>
                        {!isMobileView && <br />}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <Field name="email">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("email-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Field name="website">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("website-title")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </GridContainer>
                        {!isMobileView && <br />}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4}>
                            <Field name="phone">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("phone-label")}
                                    variant="outlined"
                                    error={
                                      (meta.touched || meta.visited) &&
                                        meta.error
                                        ? true
                                        : false
                                    }
                                  />
                                  {(meta.touched || meta.visited) &&
                                    meta.error && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Field name="fssai_no" type="number">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("billing-info-fssai-number-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Field name="gst_no">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("billing-info-gst-number-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </GridContainer>
                        {!isMobileView && <br />}
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <Field name="address">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextField
                                    {...input}
                                    label={t("address-label")}
                                    rows={2}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </GridItem>
                        </GridContainer>
                        <br />
                        <GridContainer style={{ justifyContent: "center" }}>
                          <RoleBasedGuard
                            resource={RESOURCE.PROFILE}
                            action={ACTION.UPDATE}
                          >
                          <Button
                            style={{
                              margin: "2%",
                              background: "",
                              color: "#fff",
                            }}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                            startIcon={<SaveIcon />}
                          >
                            {submittingBtnProcess
                              ? t("processing..")
                              : t("save")}
                            </Button>
                          </RoleBasedGuard>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </Container>
            </form>
          )}
        />
      )}
    </Root>
  );
}

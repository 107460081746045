import React from "react";

import "./ButtonComponent.styles.css";
import { Spinner, Button } from "react-bootstrap";
import useStyles from "./styles";

const ButtonIcon = (props) => {
  const {
    id,
    disabled,
    buttonLabel,
    isLoading,
    icon,
    variant,
    onClick,
    type,
    className,
  } = props;
  var { classes } = useStyles();

  return (
    <Button
      id={id}
      variant={variant}
      type={type ?? "submit"}
      disabled={disabled}
      className={`${classes.iconButtonRoot} ${className}`}
      onClick={onClick}
    >
      <div className={classes.icon}>{icon}</div>

      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <div style={{ fontSize: "12px" }}>{buttonLabel}</div>
      )}
    </Button>
  );
};

export default ButtonIcon;

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';

import { Box, Tooltip, Typography } from "@mui/material";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import {
  Add as AddIcon,
  Remove as MinusIcon,
  ArrowDropDownCircle,
} from "@mui/icons-material";

import blankImage100 from "assets/img/dummyplaceholderImg/dummy_100.svg";

import blankImage200 from "assets/img/dummyplaceholderImg/dummy_200.svg";
import config from "../../config.js";
import { useLayoutState } from "context/LayoutContext";

import CustomHtmlToolTip from "components/Tooltip";

import { handleImageLoadingError } from "utility/helpers.js";
import MuiIconButton from "components/IconButtonComponent";
import CustomVariantModel from "pages/Orders/CustomVariantModel";
import { useTheme } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import "./onlineorder.scss";
import { useInMobile } from "../../themes/Device";
import { useTranslation } from "react-i18next";
const PREFIX = 'AddEditOnlineOrder';

const classes = {
  addItemBox: `${PREFIX}-addItemBox`,
  addBtn: `${PREFIX}-addBtn`,
  qtyBox: `${PREFIX}-qtyBox`,
  qtyMinusIconButton: `${PREFIX}-qtyMinusIconButton`,
  qtyPlusIconButton: `${PREFIX}-qtyPlusIconButton`,
  noCategoy: `${PREFIX}-noCategoy`,
  customFoodMenuStyle4: `${PREFIX}-customFoodMenuStyle4`,
  customFoodMenuStyle5: `${PREFIX}-customFoodMenuStyle5`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.addItemBox}`]: {
    background: "#f4f4f4",
    borderColor: "#f4f4f4",
    width: "100%",
    borderRadius: 10,
    padding: "5px 5px",
    "&:hover": {
      color: "#212529",
      backgroundColor: "#e2e6ea",
      borderColor: "#dae0e5",
    },
    textAlign: "center",
  },

  [`& .${classes.addBtn}`]: {
    fontSize: 12,
    cursor: "pointer",
    "&:hover": {
      fontWeight: 800,
    },
  },

  [`& .${classes.qtyBox}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  [`& .${classes.qtyMinusIconButton}`]: {
    padding: "4px",
    width: "35%",
    color: "rgb(122, 12, 46)",
    backgroundColor: "rgb(255, 231, 217)",
    borderRadius: "6px",
    height: "25px",
    "&:hover": {
      background: "#6a3434e3",
      color: "#fff",
    },
  },

  [`& .${classes.qtyPlusIconButton}`]: {
    padding: "4px",
    width: "35%",
    color: "rgb(0, 82, 73)",
    backgroundColor: "rgb(200, 250, 205)",
    borderRadius: "6px",
    height: "25px",
    "&:hover": {
      background: "#399c39",
      color: "#fff",
    },
  },

  [`& .${classes.noCategoy}`]: {
    height: "100%",
    backgroundColor: "#e8deec",
    flexWrap: "wrap",
    alignContent: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "0.75rem",
    [theme.breakpoints.down('md')]: {
      height: "40%",
      backgroundColor: "#e8deec",
      flexWrap: "wrap",
      alignContent: "center",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      borderRadius: "0.75rem",
      justifyContent: "center",
    },
  },

  [`& .${classes.customFoodMenuStyle4}`]: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 25% !important",
      maxWidth: "25%!important",
    },
  },

  [`& .${classes.customFoodMenuStyle5}`]: {
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 20% !important",
      maxWidth: "20%!important",
    },
  }
}));

let categoryDataList = [];


const AddEditOnlineOrder = (props) => {
  const [filterfoodItemOptions, setFilterfoodItemOptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [foodItemData, setFoodItemData] = useState({});
  const theme = useTheme();
  const [activeCategoryColor, setActiveCategoryColor] = useState({
    name: "ALL",
    id: 0,
    is_active: true,
  });
  const layoutState = useLayoutState();
  const isMobileView = useInMobile();

  const { t } = useTranslation();
  const { plusItemQty, minusItemQty, foodItemOptions, categoryData } = props;

  useEffect(() => {
    setFilterfoodItemOptions(foodItemOptions);
  }, [foodItemOptions]);

  useEffect(() => {
    if (categoryData?.length > 0)
      categoryDataList = [
        { name: "ALL", id: 0, is_active: true },
        ...categoryData,
      ];
  }, [categoryData]);

  useEffect(() => {
    if (props?.searchFoodItem) {
      let filterFood = foodItemOptions.filter(
        (item) =>
          item.name &&
          item.name
            ?.toLowerCase()
            ?.includes(props?.searchFoodItem?.toLowerCase()),
      );
      setFilterfoodItemOptions(filterFood);
    } else {
      setFilterfoodItemOptions(foodItemOptions);
    }
  }, [props.searchFoodItem]);

  const onchangeFoodCategory = (selectedCategory) => {
    setActiveCategoryColor(selectedCategory);
    if (selectedCategory?.name === "ALL") {
      setFilterfoodItemOptions(foodItemOptions);
    } else {
      let filterFood = foodItemOptions.filter(
        (item) => item.category_id === selectedCategory?.id,
      );
      setFilterfoodItemOptions(filterFood);
    }
  };

  const onClickOfFoodItem = (item) => {
    // displayQtyContent(item, index);
    let selectedIndex = -1;
    const selectedItem = props?.selectedFoodItemData?.find(
      (selectedItem, i) => {
        if (selectedItem?.food_item_id === item?.id) {
          selectedIndex = i;
          return true;
        }
      },
    );
    if (selectedItem) {
      plusItemQty(selectedItem, selectedIndex);
    } else {
      addFoodItem(item);
    }
  };

  const addFoodItem = (selectFood) => {
    let food_variant_id = 0;
    let food_price = 0;
    if (selectFood?.food_variants?.length > 0) {
      const is_default_variant = selectFood?.food_variants.filter(
        (f) => f.is_default === true,
      );
      if (is_default_variant && is_default_variant.length > 0) {
        food_variant_id = is_default_variant[0].id;
        food_price = parseFloat(is_default_variant[0].price);
      } else {
        food_variant_id = selectFood?.food_variants?.[0].id;
        food_price = parseFloat(selectFood?.food_variants?.[0].price);
      }
    } else {
      food_price = parseFloat(selectFood?.price);
    }

    const formatFoodItem = {
      qty: 1,
      category_id: selectFood?.category?.id,
      food_item_id: selectFood.id,
      food_image: selectFood?.food_image,
      varient_id: food_variant_id || 0,
      is_non_veg: selectFood?.is_non_veg || false,
      instruction_note: "",
      addons: "",
      fooditem: selectFood,
      price: food_price,
      total_qty_price: food_price * 1,
      addon_price: 0,
      total_addon_qty_price: 0,
      sub_total: food_price,
    };

    props.foodItemsArrayAdded(formatFoodItem, undefined);
  };

  const displayQtyContent = (item, index) => {
    let selectedIndex = -1;
    const selectedItem = props?.selectedFoodItemData?.find(
      (selectedItem, i) => {
        if (selectedItem?.food_item_id === item?.id) {
          selectedIndex = i;
          return true;
        }
      },
    );

    if (selectedItem) {
      return (
        <Box className={classes.qtyBox}>
          <CustomHtmlToolTip
            interactive
            placement="top"
            arrow
            enterDelay={0}
            disableFocusListener={
              item?.food_item_addons?.length <= 0 &&
              item?.food_variants?.length <= 1
            }
            disableHoverListener={
              item?.food_item_addons?.length <= 0 &&
              item?.food_variants?.length <= 1
            }
            title={t("order-detail-food-item-body")}
          >
            <MuiIconButton
              size="small"
              className={classes.qtyMinusIconButton}
              onClick={(event) => {
                event.stopPropagation();
                minusItemQty(selectedItem, selectedIndex);
              }}
            >
              <MinusIcon style={{ fontSize: 14 }} />
            </MuiIconButton>
          </CustomHtmlToolTip>
          <div style={{ fontSize: "15px" }}>{selectedItem?.qty}</div>
          <MuiIconButton
            size="small"
            className={classes.qtyPlusIconButton}
            onClick={(event) => {
              event.stopPropagation();
              plusItemQty(selectedItem, selectedIndex);
            }}
          >
            <AddIcon style={{ fontSize: 14 }} />
          </MuiIconButton>
        </Box>
      );
    }
    return (
      <Typography
        className={classes.addBtn}
        onClick={(event) => {
          event.stopPropagation();
          addFoodItem(item);
        }}
      >
        {t("add-button-label")}
      </Typography>
    );
  };

  const getActiveCategoryColor = (activeCategoryColor, category) => {
    return activeCategoryColor.name === category.name && activeCategoryColor.id === category.id
  }

  return (
    <Root>
      <CustomVariantModel
        onHide={() => setModalShow(false)}
        show={modalShow}
        foodItemData={foodItemData}
        foodItemsArrayAdded={props.foodItemsArrayAdded}
        isEdit={false}
        editableFoodItem={{}}
        selectedFoodItemData={props.selectedFoodItemData}
      />
      <Row className="sf-mobile-tablet-category category-section">
       
          <Col
            xs={12}
            sm={3}
            md={3}
            lg={3}
            xl={2}
            className="category-list-col"
          >
              <div className="category-section-mobile">
                {categoryDataList?.map((category, index) => {
                  return (
                    category?.is_active && (
                      <Card className="ml-1 mr-1 mb-1" key={category?.id}>
                        <MuiButton
                          className={`w-100`}
                          style={{
                            color: getActiveCategoryColor(
                              activeCategoryColor,
                              category,
                            )
                              ? theme.palette.primary.contrastText
                              : theme.palette.text.secondary,
                            padding: "15px",
                            fontSize: "15px",
                            backgroundColor: getActiveCategoryColor(
                              activeCategoryColor,
                              category,
                            )
                              ? theme.palette.primary.main
                              : "",
                            wordBreak: "break-all",
                          }}
                          variant="outlined"
                          onClick={() => onchangeFoodCategory(category)}
                        >
                          {category?.name}
                        </MuiButton>
                      </Card>
                    )
                  );
                })}
              </div>
          </Col>
          <Col xs={12} sm={9} md={9} lg={9} xl={10} className="food-item-list">
            <div className="food-item-list-container">
              <Row>
                {
                  filterfoodItemOptions
                    .sort((a, b) => a.is_priority - b.is_priority)
                    .map((item, index) => {
                      return (
                        item?.is_active && (
                          <Col
                            xl={2}
                            lg={3}
                            md={4}
                            sm={4}
                            xs={6}
                            key={index}
                            style={{
                              padding: 0,
                            }}
                          >
                            <Card
                              style={{
                                margin: 4,
                                cursor: "pointer",
                              }}
                              onClick={() => onClickOfFoodItem(item)}
                            >
                              <div style={{ textAlign: "center" }}>
                                {layoutState.isOrderSectionViewToggle && (
                                  <>
                                    {item?.food_image ? (
                                      <Card.Img
                                        variant="top"
                                        src={`${config?.S3_BUCKET}${item.food_image}`}
                                        onError={({ currentTarget }) =>
                                          handleImageLoadingError(
                                            currentTarget,
                                            blankImage200,
                                          )
                                        }
                                        style={{
                                          margin: "auto",
                                          height: 100,
                                        }}
                                      />
                                    ) : (
                                      <Card.Img
                                        variant="top"
                                        src={blankImage100}
                                        style={{
                                          margin: "auto",
                                          height: 100,
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                                <div
                                  className={`${
                                    !layoutState.isOrderSectionViewToggle
                                      ? "food-item-name-box"
                                      : ""
                                  }`}
                                >
                                  <div className="sf-foodmenu-name">
                                    {item?.name}
                                  </div>
                                  <span className="sf-category-name">
                                    {item?.category?.name &&
                                      `( ${item?.category?.name} )`}
                                    {}
                                  </span>
                                </div>
                              </div>
                              <div className="px-2">
                                <Typography
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 600,
                                    textAlign: "center",
                                  }}
                                >
                                  ₹ {item?.price}{" "}
                                </Typography>
                              </div>
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box className={classes.addItemBox}>
                                  {displayQtyContent(item, index)}
                                </Box>
                                {(item?.food_variants?.length > 0 ||
                                  item?.food_item_addons?.length > 0) && (
                                  <Tooltip title={t("customize-label")} arrow>
                                    <MuiIconButton
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setFoodItemData(item);
                                        setModalShow(true);
                                      }}
                                      style={{ height: "28px" }}
                                      aria-label={t("customize-label")}
                                    >
                                      <ArrowDropDownCircle
                                        style={{
                                          fontSize: 16,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </MuiIconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </Card>
                          </Col>
                        )
                      );
                    })
                }
              </Row>
            </div>
          </Col>
      </Row>
    </Root>
  );
};

export default AddEditOnlineOrder;

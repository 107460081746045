import React, { useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  FormControlLabel ,
  TableRow, TableCell,  Box,
  Switch,
  Stack,} from "@mui/material";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
  Delete,
  Category,
} from "@mui/icons-material";
import Scrollbar from "components/Scrollbar";
import { TableNoData, TableEmptyRows, TableHeadCustom } from '../../components/table';

import LowPriorityIcon from "@mui/icons-material/LowPriority";

import { deleteFoodCategory } from "services/foodCategory.service";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryList } from "state/thunk-async";
import PopUpModel from "components/Popup/PopUpModel";
import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditFoodCategory from "./AddEditFoodCategory";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import { fetchFoodMealList } from "state/thunk-async";
import PriorityFoodCategory from "./PriorityFoodCategory";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileTitle from "components/MobileTitle";
import SearchBarInput from "components/SearchBarInput";
import MobileCategory from "components/MobileCategory";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";
import useTable, { emptyRows } from '../../hooks/useTable';
//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
const PREFIX = 'FoodCategoryList';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  mobileCategoryCard: `${PREFIX}-mobileCategoryCard`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  prioritybtn: `${PREFIX}-prioritybtn`,
  mobileCard: `${PREFIX}-mobileCard`
};

const TABLE_HEAD = [
  { id: 'orderNo', label: "Category Id", align: 'left' },
  { id: 'orderName', label: "Food Category Name", align: 'left' },
  { id: 'tableId', label: "Available/Unavailable", align: 'left' },
  { id: 'action', label: "Action", align: 'left' },
];

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.mobileCategoryCard}`]: {
    margin: '0 auto',
  },

  [`& .${classes.root}`]: {
    width: "100%"
  },

  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },

  [`& .${classes.prioritybtn}`]: {
    marginRight: "5px",
  },

  [`& .${classes.mobileCard}`]: {
    padding: "2px",
    marginTop: "9px",
    paddingBottom: '80px'
  }
}));

const FoodCategoryList = () => {

  const theme = useTheme();
  const foodCategoryDataList = useSelector((state) => state.api.category);
  const foodCategoryLoader = useSelector(
    (state) => state.api.foodCategoryLoader,
  );
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteFoodCategoryId, setDeleteFoodCategoryId] = useState(0);
  const [deletingBtnProcess, setDeletingBtnProcess] = useState(false);
  const [addEditDrawerShow, setAddEditDrawerShow] = useState(false);
  const [beautifulDrawerShow, setBeautifulDrawerShow] = useState(false);
  const [addEditFoodCategoryId, setAddEditFoodCategoryId] = useState(0);
  const [searchFoodCategory, setSearchFoodCategory] = useState("");
  const [filterFoodCategoryList, setFilterFoodCategoryList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Food Catogory") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: "Food Catogory",
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Category") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  useCallback(() => {
    dispatch(fetchCategoryList());
  }, [])

  const deleteFoodCategoryData = (foodCategoryId) => {
    if (foodCategoryId > 0) {
      setDeletingBtnProcess(true);
      deleteFoodCategory(foodCategoryId)
        .then((res) => {
          if (res?.data) {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          } else {
            setDeletingBtnProcess(false);
            toast.success(res?.message);
          }
          setDeleteModalShow(false);
          dispatch(fetchCategoryList());
          dispatch(fetchFoodMealList());
        })
        .catch((err) => {
          toast.success(err?.message);
        });
    }
  };

  const addEditDrawerClose = () => {
    setAddEditDrawerShow(false);
    setBeautifulDrawerShow(false);
    setAddEditFoodCategoryId(0);
  };

  function sortByPriority(a, b) {
    if (a.is_priority < b.is_priority) {
      return -1;
    }
    if (a.is_priority > b.is_priority) {
      return 1;
    }
    return 0;
  }

  const deleteData = (deleteid) => {
    setDeleteFoodCategoryId(deleteid);
    setDeleteModalShow(true);
  };

  const editData = (editid) => {
    // setAddEditFoodCategoryId(editid);
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-category/edit/${editid}`,
      state: {
        foodCategoryId: editid,
        backToMain: () => history.push(`/app/food-category`),
      },
    });
  };

  const addDrawerShow = () => {
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-category/add`,
      state: {
        foodCategoryId: 0,
        backToMain: () => history.push(`/app/food-category`),
      },
    });
  };

  const addSetPriorityDrawerShow = () => {
    setBeautifulDrawerShow(true);
  };

  const clearTxt = () => {
    setSearchFoodCategory("");
  };

  useEffect(() => {
    setFilterFoodCategoryList(foodCategoryDataList);
  }, [foodCategoryDataList]);

  useEffect(() => {
    if (searchFoodCategory) {
      let filterCategoryList = foodCategoryDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchFoodCategory.toLowerCase()),
      );
      setFilterFoodCategoryList(filterCategoryList);
    } else {
      setFilterFoodCategoryList(foodCategoryDataList);
    }
  }, [searchFoodCategory]);


  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected = 0,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'createDate' });

  const [filterName, setFilterName] = useState('');

  const isNotFound = (!foodCategoryDataList.length && !!filterName)

  const denseHeight = dense ? 56 : 76;

  return (
    <Root className={classes.root}>
      <PopUpModel
        show={deleteModalShow}
        heading={t("food-category-popup-delete-title")}
        body={t("food-category-popup-delete-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeleteModalShow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteFoodCategoryData(deleteFoodCategoryId)}
            >
              <Delete />
              {deletingBtnProcess ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => setDeleteModalShow(false)}
      />
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={addEditDrawerShow}
        drawerWidth="50%"
        icon={<Category />}
        title={t("food-category-title")}
      >
        <AddEditFoodCategory
          categoryId={addEditFoodCategoryId}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={beautifulDrawerShow}
        drawerWidth="35%"
        icon={<LowPriorityIcon />}
        title={t("priority-label")}
      >
        <PriorityFoodCategory
          drawerClose={addEditDrawerClose}
          foodCategoryDataList={foodCategoryDataList}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
    <Stack direction={'row'} justifyContent='space-between' padding={'15px 11px'}>
          <h5>
            <b className="ml-3">{t("food-category-title")}</b>
          </h5>
          <Stack direction={'row'} sx={{ padding: "0px 10px" }}>
            <RoleBasedGuard
              resource={RESOURCE.FOOD_CATEGORY}
              action={ACTION.ADD}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setAddEditDrawerShow(true);
                }}
              >
                {t("food-category-add")}
              </Button>
            </RoleBasedGuard>
            <RoleBasedGuard
              resource={RESOURCE.FOOD_CATEGORY}
              action={ACTION.UPDATE}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  setBeautifulDrawerShow(true);
                }}
                startIcon={<LowPriorityIcon />}
                disabled={
                  foodCategoryDataList.length > 0 ? false : true
                }
              >
                {t("priority-label")}
              </Button>
            </RoleBasedGuard>
          </Stack>

        </Stack>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  isCheckBoxShow = {false}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={foodCategoryDataList.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      foodCategoryDataList.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  { foodCategoryDataList.sort(sortByPriority).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <TableRow hover>
                        <TableCell align="left">{item?.id}</TableCell>
                        <TableCell align="left">{item?.name}</TableCell>
                        <TableCell align="left">{item?.is_active ? (
                              <>
                                <div>
                                  <Label
                                    variant={
                                      theme.palette.mode === "light" ? "ghost" : "filled"
                                    }
                                    color={"success"}
                                  >
                                    {item?.is_active ? "Available" : item?.is_active}
                                  </Label>
                                </div>
                              </>
                            ) : (
                              <Label
                                variant={
                                  theme.palette.mode === "light" ? "ghost" : "filled"
                                }
                                color="error"
                              >
                                {!item?.is_active ? "Unavailable" : item?.is_active}
                              </Label>
                            )}</TableCell>

                            <TableCell>
                              <RoleBasedGuard
                                resource={RESOURCE.FOOD_CATEGORY}
                                action={ACTION.UPDATE}
                              >
                                <IconButton
                                  color="primary"
                                  title={t("food-category-edit")}
                                  aria-label={t("food-category-edit")}
                                  onClick={() => {
                                    setAddEditFoodCategoryId(item.id);
                                    setAddEditDrawerShow(true);
                                  }}
                                  size="large">
                                  <EditIcon />
                                </IconButton>
                              </RoleBasedGuard>
                                <RoleBasedGuard
                                  resource={RESOURCE.FOOD_CATEGORY}
                                  action={ACTION.DELETE}
                                >
                                  <IconButton
                                    color="secondary"
                                    title={t("food-category-delete-icon")}
                                    aria-label={t("food-category-delete-icon")}
                                    onClick={() => {
                                      setDeleteFoodCategoryId(item.id);
                                      setDeleteModalShow(true);
                                    }}
                                    size="large">
                                    <DeleteIcon />
                                  </IconButton>
                                </RoleBasedGuard>
                            </TableCell>
                  </TableRow>
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, foodCategoryDataList.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={foodCategoryDataList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <SearchBarInput
            onChange={(e) => {
              setSearchFoodCategory(e.target.value);
            }}
            value={searchFoodCategory}
            placeholder={t("search-food-category")}
            clearTxt={clearTxt}
          />
          {foodCategoryLoader ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div className={classes.mobileCategoryCard}>
              {filterFoodCategoryList &&
                filterFoodCategoryList.map((item, index) => (
                  <MobileCategory
                    resource={RESOURCE.FOOD_CATEGORY}
                    name={item.name}
                    active={item.is_active}
                    deleteData={deleteData}
                    editData={editData}
                    itemId={item?.id}
                    isMenuBar
                  />
                ))}
            </div>
          )}
          <MobileTitle
            resource={RESOURCE.FOOD_CATEGORY}
            title={t("food-category-title")}
            addButton={addDrawerShow}
            setPriorityButton={addSetPriorityDrawerShow}
          />
        </div>
        {filterFoodCategoryList.length < 1 && !foodCategoryLoader && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>{t("food-category-empty-data-message")}</p>
          </NotFoundComponent>
        )}
      </MobileContainer>
    </Root>
  );
};


export default FoodCategoryList;

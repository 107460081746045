import React, { useEffect, useMemo, useState } from "react";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  RefreshRounded,
  InfoOutlined,
  ClearAllOutlined,
} from "@mui/icons-material";
import { Avatar, Badge, Box, IconButton, Button as Btn, Stack } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { Table, Button, Card, Spinner, Form, Container } from "react-bootstrap";

import { fetchKitchenQueue } from "state/thunk-async";
import {
  kitchenOrderDone,
  kitchenOrderItemDone,
  CompletedAllKitchenItems,
} from "services/kitchen.service";
import Swal from "sweetalert2";
import "assets/css/order.css";
import { toast } from "react-hot-toast";
import { OrderStatus, OrderType } from "common/constant";
import { getShopSettingFromKey } from "utility/helpers";
import { getGroupBy } from "utility/helpers";
import NotFoundComponent from "components/NotFoundComponent";
import CustomHtmlToolTip from "components/Tooltip";
import GroupByVariantAddons from "./GroupByVariantAddons";

import noFoundImg from "../../assets/img/no-data-found.jpg";
import vegImg from "../../assets/img/vegetarian-food-symbol.png";
import nonVegImg from "../../assets/img/non-vegetarian-food-symbol.png";
import { DeskTopContainer } from "themes/Device";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import PopUpModel from "components/Popup/PopUpModel";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTranslation } from "react-i18next";
import { isJson } from "utility/helpers";

const PREFIX = 'Kitchan';

const classes = {
  avatar: `${PREFIX}-avatar`,
  tableColor: `${PREFIX}-tableColor`,
  parcelColor: `${PREFIX}-parcelColor`,
  icon: `${PREFIX}-icon`,
  refreshBtnStyle: `${PREFIX}-refreshBtnStyle`,
  btnStyle: `${PREFIX}-btnStyle`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.avatar}`]: {
    margin: 8,
    display: "inline-block",
    cursor: "pointer",
  },

  [`& .${classes.tableColor}`]: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },

  [`& .${classes.parcelColor}`]: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },

  [`& .${classes.icon}`]: {
    width: 25,
    objectFit: "contain",
  },

  [`& .${classes.refreshBtnStyle}`]: {
    background: "",
    padding: theme.spacing(0.5),
    fontSize: "13px",
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.btnStyle}`]: {
    padding: "3px 10px",
    fontSize: "13px",
    marginLeft: "5px",
  }
}));

const Kitchan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const kitchenList = useSelector((state) => state.api.kitchenQueue);
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const loading = useSelector((state) => state.api.kitchenQueueLoader); //TODO
  const [isKitchenOrderLoading, setIsKitchenOrderLoading] = useState({
    oneCompleted: false,
    allCompleted: false,
  });
  const [allOrdersView, setAllOrdersView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [helpPopupshow, setHelpPopupshow] = useState(false);
  const layoutDispatch = useLayoutDispatch();
  const [isClearAll, setIsClearAll] = useState(false);

  useEffect(() => {
    fetchQueue();
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: t("kitchen-screen-title"),
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  const fetchQueue = async () => {
    const searchReq = {
      startDate: moment().format("YYYY-MM-DD"),
      status: [OrderStatus.IN_PROGRESS, OrderStatus.DELIVERED],
    };
    await dispatch(fetchKitchenQueue(searchReq));
  };

  const groupByFoodItem = (fooItemList) => {
    return fooItemList.reduce(function (a, e) {
      let estKey = e?.order_meal_item?.food_item_id;

      (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
      return a;
    }, {});
  };

  const kitchanGroupData = useMemo(() => {
    return getGroupBy(kitchenList, "order_id");
  }, [kitchenList]);

  const allOrdersGroupData = useMemo(() => {
    if (kitchenList.length > 0) {
      setIsClearAll(false);
    }
    else {
      setIsClearAll(true);
    }
    return groupByFoodItem(kitchenList);
  }, [kitchenList]);

  const handleAllKitchanOrderCompleted = async (orderID) => {
    try {
      setSelectedOrder(orderID);
      setIsKitchenOrderLoading((state) => ({ ...state, allCompleted: true }));
      const response = await kitchenOrderDone(orderID);
      if (response.status === 200) {
        await fetchQueue();
        setIsKitchenOrderLoading((state) => ({
          ...state,
          allCompleted: false,
        }));
        setSelectedOrder(null);
      }
    } catch (err) {
      setIsKitchenOrderLoading((state) => ({ ...state, allCompleted: false }));
      setSelectedOrder(null);
    }
  };

  const handleKitchanOrderItemCompleted = async (itemID) => {
    try {
      setSelectedItem(itemID);
      setIsKitchenOrderLoading((state) => ({ ...state, oneCompleted: true }));
      const response = await kitchenOrderItemDone(itemID);
      if (response.status === 200) {
        await fetchQueue();
        setIsKitchenOrderLoading({ allCompleted: false, oneCompleted: true });
        setSelectedItem(null);
      }
    } catch (err) {
      setIsKitchenOrderLoading((state) => ({ ...state, oneCompleted: true }));
      setSelectedItem(null);
    }
  };

  const itemBackgroundColor = (status, types) => {
    if (status === OrderStatus.DELIVERED) {
      return "rgb(199 203 196 / 85%)";
    } else if (types === OrderType.NEW) {
      return "";
    } else if (types === OrderType.ADD_ON) {
      return "orange";
    } else if (types === OrderType.DELETE) {
      return "red";
    }
    return "#FFF";
  };

  const itemFontColor = (status, types) => {
    if (status === OrderStatus.DELIVERED) {
      return "#000";
    } else if (types === OrderType.NEW) {
      return "";
    } else if (types === OrderType.ADD_ON) {
      return "#000";
    } else if (types === OrderType.DELETE) {
      return "#FFF";
    }
    return "#FFF";
  };

  const isOnlyVegStore = () => {
    return shopSettingListData?.IS_SHOP_VEGETARIAN;
  };

  const toggleAllOrdersView = () => {
    setAllOrdersView((preValue) => !preValue);
  };

  const clearKitchenOrders = () => {
    Swal.fire({
      title: t("swal-title"),
      text: t("complete-all-order-label"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("popup-cancel-button-label"),
      confirmButtonText: t("complete-all-order-confirmation-label"),
    }).then((result) => {
      if (result?.isConfirmed) {
        CompletedAllKitchenItems()
          .then((res) => {
            if (res?.message) {
              toast.success(res?.message);
            }
            fetchQueue();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err);
            fetchQueue();
          });
      }
    });
  };

  return (
    <Root className="sf-kitchen-container">
      <Card className="mb-3" style={{ marginTop: 7 }}>
        <Card.Body
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            flexGrow: "1",
            padding: "5px 16px",
          }}
        >
          {/*    <h6>
            <b>Kitchen Order</b>
          </h6> */}
          <DeskTopContainer>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                alignItems: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                flexGrow: "1",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#fff",
                    borderColor: "#eee",
                    borderRadius: 20,
                    padding: 2,
                    marginLeft: 15,
                    fontSize: 3,
                    border: "1px solid",
                    marginRight: 4,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                &nbsp;{t("new-items-label")}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "orange",
                    borderRadius: 20,
                    padding: 2,
                    fontSize: 3,
                    border: "1px solid",
                    marginLeft: 15,
                    marginRight: 4,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                &nbsp;{t("add-on-new-item-order-label")}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "rgb(46 140 0 / 85%)",
                    borderRadius: 20,
                    padding: 2,
                    fontSize: 3,
                    border: "1px solid",
                    marginLeft: 15,
                    marginRight: 4,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                &nbsp;{t("item-delivered-from-order-label")}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    backgroundColor: "red",
                    borderRadius: 20,
                    padding: 2,
                    fontSize: 3,
                    border: "1px solid",
                    marginLeft: 15,
                    marginRight: 4,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                &nbsp;{t("kitchen-delte-item-label")}
              </div>
              <div className="d-flex justify-content-between align-items-center ml-2">
                <Avatar
                  style={{ width: 22, height: 22 }}
                  className={classes.tableColor}
                >
                  <span style={{ fontSize: 16 }}>T</span>
                </Avatar>
                &nbsp;&nbsp;{t("kitchen-table-orders-label")}
              </div>
              <div className="d-flex justify-content-between align-items-center ml-2">
                <Avatar
                  style={{ width: 22, height: 22 }}
                  className={classes.parcelColor}
                >
                  <span style={{ fontSize: 16 }}>P</span>
                </Avatar>
                &nbsp;&nbsp;{t("kitchen-table-parcel-order-label")}
              </div>
            </div>
          </DeskTopContainer>
          <Form
            style={{ margin: "auto" }}
            className="d-flex align-items-center"
          >
            <IconButton
              color="primary"
              className={classes.refreshBtnStyle}
              title={t("dining-table-refresh-dine-in-section-label")}
              aria-label={t("dining-table-refresh-dine-in-section-label")}
              onClick={() => {
                fetchQueue();
              }}
              disabled={isClearAll}
              size="large">
              <RefreshRounded />
            </IconButton>
            <Form.Check
              type="switch"
              onChange={toggleAllOrdersView}
              defaultChecked={allOrdersView}
              label={t("all-orders-label")}
              id="gird-switch"
              disabled={isClearAll}
              className="mr-2"
              style={{ cursor: "pointer" }}
            />
          </Form>
          <Btn
            variant="contained"
            color="secondary"
            size="medium"
            className={` mr-2`}
            disabled={isClearAll}
            startIcon={<ClearAllOutlined />}
            onClick={clearKitchenOrders}
          >
            {t("clear-all-items-label")}
          </Btn>
          <DeskTopContainer>
            <Btn
              variant="contained"
              color="primary"
              size="medium"
              // className={classes.btnStyle}
              startIcon={<InfoOutlined />}
              onClick={() => setHelpPopupshow(true)}
            >
              {t("help-button-label")}
            </Btn>
          </DeskTopContainer>
        </Card.Body>
      </Card>

      <Container fluid>
        {Object.keys(kitchanGroupData).length > 0 ? (
          <div className="row">
            {allOrdersView && (
              <div className="col-sm-3 col-xs-12">
                <div>
                  <h5 className="h6 text-center font-weight-bold">
                    {t("all-orders-label")}
                  </h5>
                </div>
                <Card className="sf-kitchen-item">
                  <Card.Body style={{ padding: 5 }}>
                    <div>
                      {Object.keys(allOrdersGroupData).map((key, i) => {
                        const queue = allOrdersGroupData[key];
                        const totalQty = queue.reduce(
                          (a, e) => a + parseInt(e?.qty || 0),
                          0,
                        );
                        return (
                          <Card className="sf-kitchen-card">
                            <Card.Body
                              style={{
                                padding: "5px 10px",
                                margin: "5px 0",
                                border: "1px solid ",
                                borderRadius: 20,
                              }}
                            >
                              <div
                                className="table-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  fontSize: 14,
                                  marginBottom: 10,
                                  textAlign: "unset",
                                  padding: "5px auto",
                                }}
                              >
                                <h6 style={{ fontSize: 14, margin: 5 }}>
                                  {queue[0]?.order_meal_item?.fooditem?.name}
                                </h6>

                                <h6 style={{ fontSize: 14, margin: 5 }}>
                                  {totalQty}
                                </h6>
                                {/* <Button
                           onClick={() =>
                             handleAllKitchanOrderCompleted(queue[0].order_id)
                           }
                           variant="outline-info"
                           className="sf-button-icon sf-button-icon-md"
                         >
                           {isKitchenOrderLoading?.allCompleted &&
                           selectedOrder === queue[0].order_id ? (
                             <Spinner
                               as="span"
                               animation="border"
                               size="sm"
                               role="status"
                               aria-hidden="true"
                             />
                           ) : (
                             <CheckCircleIcon />
                           )}
                         </Button> */}
                              </div>

                              <div>
                                <Table
                                  bordered
                                  hover
                                  responsive
                                  className="sf-kitchen-table"
                                >
                                  <thead>
                                    <tr
                                      style={{
                                        backgroundColor: "#0c3765",
                                        color: "#fff",
                                      }}
                                    >
                                      <th>
                                        {t(
                                          "kitchen-screen-variants-add-ons-label",
                                        )}
                                      </th>
                                      {!isOnlyVegStore() && (
                                        <th>{t("is-veg-flag-label")}</th>
                                      )}
                                      <th className="text-center">
                                        {t("qty-label")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <GroupByVariantAddons
                                      foodMealList={queue}
                                      itemBackgroundColor={itemBackgroundColor}
                                      isOnlyVegStore={isOnlyVegStore}
                                      itemFontColor={itemFontColor}
                                      isKitchenOrderLoading={
                                        isKitchenOrderLoading
                                      }
                                    />
                                  </tbody>
                                </Table>
                              </div>
                              <div className="mt-1">
                                {queue.map((item) => (
                                  <Box className={classes.avatar}>
                                    <CustomHtmlToolTip
                                      interactive
                                      placement="top"
                                      arrow
                                      enterDelay={0}
                                      title={
                                        <div style={{ padding: "8px" }}>
                                          {item?.table_id > 0 &&
                                            `${t("table-title")} : ${item?.order?.diningtable
                                              ?.name_of_table
                                            }`}
                                          <span className="d-block">
                                            {t("order-number-label")} :{" "}
                                            {item?.order_id}
                                          </span>
                                          <span className="d-block">
                                            {item?.order?.name_of_customer}
                                          </span>
                                          <hr style={{ margin: "10px 0px" }} />
                                          <span className="d-block">
                                            {t("variant-label")} :{" "}
                                            {item?.order_meal_item?.food_variant
                                              ?.name || t("regular-label")}
                                          </span>
                                          {item?.order_meal_item?.addons
                                            ?.length > 0 && (
                                              <>
                                                <span className="d-block font-weight-bold mt-1">
                                                  {t("add-ons")}
                                                </span>
                                                <ul className="pl-3">
                                                  {item?.order_meal_item?.addons.map(
                                                    (addOn) => (
                                                      <li> {addOn?.name}</li>
                                                    ),
                                                  )}
                                                </ul>
                                              </>
                                            )}
                                        </div>
                                      }
                                    >
                                      <Badge
                                        badgeContent={item?.qty || 0}
                                        color="primary"
                                      >
                                        <Avatar
                                          style={{ width: 30, height: 30 }}
                                          className={clsx(
                                            item?.table_id > 0
                                              ? classes.tableColor
                                              : classes.parcelColor,
                                          )}
                                        >
                                          {item?.table_id > 0 ? "T" : "P"}
                                        </Avatar>
                                      </Badge>
                                    </CustomHtmlToolTip>
                                  </Box>
                                ))}
                              </div>
                            </Card.Body>
                          </Card>
                        );
                      })}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
            <div
              className={
                allOrdersView ? "col-sm-9 col-xs-12" : "col-xs-12 col-sm-12"
              }
            >
              <div>
                <h5 className="h6 text-center font-weight-bold">
                  {t("kitchen-queue-title")}
                </h5>
              </div>
              <div className="row sf-kitchen-item">
                {Object.keys(kitchanGroupData).map((key) => {
                  const queue = kitchanGroupData[key];
                  return (
                    <div
                      style={{ padding: "4px" }}
                      className={
                        allOrdersView
                          ? "col-sm-4 col-md-4 col-lg-3 col-xs-12"
                          : "col-sm-4 col-md-4 col-lg-3 col-xs-12"
                      }
                    >
                      <Card className="sf-kitchen-card">
                        <Card.Body>
                          <div
                            className="d-flex justify-content-between"
                            style={{ marginBottom: 10 }}
                          >
                            <h6>
                              {queue[0].order &&
                                queue[0].order.order_type === "DINE_IN"
                                ? `Table - ${queue[0]?.order?.diningtable &&
                                queue[0]?.order?.diningtable?.name_of_table
                                }`
                                : queue[0].order &&
                                  queue[0].order.order_type === "PARCEL"
                                  ? `Parcel`
                                  : `Table(QR-Code) - ${queue[0]?.order?.diningtable &&
                                  queue[0]?.order?.diningtable.name_of_table
                                  }`}
                            </h6>
                            <h6>
                              {queue[0].order &&
                                queue[0].order?.created_date &&
                                moment(queue[0].order?.created_date).format(
                                  "hh:mm",
                                )}
                            </h6>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h6 style={{ marginTop: 8 }}>
                              {t("order-number-label")} - {queue[0].order_id}
                            </h6>
                            <RoleBasedGuard
                              resource={RESOURCE.KITCHEN_QUEUE}
                              action={ACTION.UPDATE}
                            >
                              <Button
                                onClick={() =>
                                  handleAllKitchanOrderCompleted(
                                    queue[0].order_id,
                                  )
                                }
                                variant="outline-info"
                                className="sf-button-icon sf-button-icon-md"
                              >
                                {isKitchenOrderLoading?.allCompleted &&
                                  selectedOrder === queue[0].order_id ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <CheckCircleIcon />
                                )}
                              </Button>
                            </RoleBasedGuard>
                          </div>
                          <Table
                            bordered
                            hover
                            responsive
                            className="sf-kitchen-table"
                          >
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#0c3765",
                                  color: "#fff",
                                }}
                              >
                                <th>{t("item-label")}</th>
                                {!isOnlyVegStore() && (
                                  <th>{t("is-veg-flag-label")}</th>
                                )}
                                <th className="text-center">
                                  {t("qty-label")}
                                </th>
                                <th className="text-center">
                                  {t("serve-label")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {queue.map((item) => (
                                <tr
                                  style={{
                                    backgroundColor: itemBackgroundColor(
                                      item.status,
                                      item.types,
                                    ),
                                    color: itemFontColor(
                                      item.status,
                                      item.types,
                                    ),
                                  }}
                                >
                                  <td>

                                    {/* FOOD ITEM NAME */}
                                    <Stack direction={'column'} gap={0.2}>
                                      <Stack>
                                      {item &&
                                        item.order_meal_item &&
                                        item.order_meal_item.fooditem &&
                                        item.order_meal_item.fooditem.name}

                                        {/* Variant Name */}
                                      {item?.varient_id > 0 &&
                                        ` - ${item.order_meal_item.food_variant.name}`}

                                        {/* ADD ONS */}
                                      {item?.order_meal_item?.addons?.length >
                                        0 && (
                                          <>
                                            {" ("}
                                            {item?.order_meal_item?.addons?.map(
                                              (addOn, index) =>
                                                index ===
                                                  item?.order_meal_item?.addons
                                                    ?.length -
                                                  1
                                                  ? `${addOn?.name}`
                                                  : `${addOn?.name}, `,
                                            )}
                                            {") "}
                                          </>
                                        )}

                                      </Stack>
                                        {/* INSTRUCTION NOTE */}
                                        {
                                          item?.order_meal_item?.instruction_note && 
                                          <Stack>
                                            
                                              {"Note: " + (isJson(item?.order_meal_item?.instruction_note)
                                                ? JSON.parse(item?.order_meal_item?.instruction_note)?.map(
                                                    (data) => data?.label + ", ",
                                                  )
                                                : item?.order_meal_item?.instruction_note)}
                                            </Stack>
                                        }
                                   </Stack>
                                  

                                     
                                  </td>
                                  {!isOnlyVegStore() && (
                                    <td className="text-center">
                                      {item &&
                                        item?.order_meal_item &&
                                        item?.order_meal_item?.is_non_veg ? (
                                        <img
                                          src={nonVegImg}
                                          style={{
                                            width: 25,
                                            objectFit: "contain",
                                          }}
                                          alt="non veg"
                                        />
                                      ) : (
                                        <img
                                          src={vegImg}
                                          style={{
                                            width: 25,
                                            objectFit: "contain",
                                          }}
                                          alt="veg"
                                        />
                                      )}
                                    </td>
                                  )}
                                  <td className="text-center">
                                    {item && item?.qty}
                                  </td>
                                  <td className="text-center">
                                    {item.status === "DELIVERED" ? (
                                      <CheckCircleIcon
                                        title={t("delivered-item-label")}
                                        style={{
                                          color: "rgba(46, 140, 0, 0.85)",
                                        }}
                                      />
                                    ) : (
                                      <RoleBasedGuard
                                        resource={RESOURCE.KITCHEN_QUEUE}
                                        action={ACTION.UPDATE}
                                      >
                                        <Button
                                          onClick={() =>
                                            handleKitchanOrderItemCompleted(
                                              item.id,
                                            )
                                          }
                                          variant="outline-info"
                                          className="sf-button-icon sf-button-icon-md"
                                        >
                                          {isKitchenOrderLoading?.oneCompleted &&
                                            selectedItem === item.id ? (
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <CheckCircleIcon />
                                          )}
                                        </Button>
                                      </RoleBasedGuard>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <p
            style={{
              margin: "auto",
              fontSize: "0.95rem",
              color: "#4A4A4A",
              marginTop: 20,
            }}
          >
            {loading ? (
              t("loading-label")
            ) : (
              <NotFoundComponent imgUrl={noFoundImg}>
                <p style={{ fontWeight: 600 }}>{t("no-data-found-message")}</p>
              </NotFoundComponent>
            )}
          </p>
        )}
      </Container>
      <PopUpModel
        show={helpPopupshow}
        heading={t("help-button-label")}
        body={t("kitchen-cooking-done-popup-body")}
        onHide={() => setHelpPopupshow(false)}
      />
    </Root>
  );
};

export default Kitchan;

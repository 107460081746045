import { api } from "./../utility/interceptor";
import configData from "../config.js";

var config = {
    headers: {'Access-Control-Allow-Origin': '*'},
    // crossDomain: true,
    // Accept: 'application/json',
};

//  Dining table Methods 

export const  getAllFoodCategory = async()=> {
    let res = await api.get(`/category`, config);
    let data = res?.data;
    return data;
}

export const  CreateFoodCategory =  async (diningTableData) => {
    try {
    let res = await api.post(`/category`, diningTableData)
    return { status: res?.status, data: res?.data };

    } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }

    }
}

export const  getFoodCategoryById = async(id)=> {
    let res = await api.get(`/category/${id}`, config);
    let data = res?.data;
    return data;
}

export const  updateFoodCategory = async(id, diningTableData)=> {
    try {
    let res = await api.put(`/category/${id}`, diningTableData);
    return { status: res?.status, data: res?.data };

    } catch (error) {
    return { status: error?.response?.status, message: error?.response?.data?.message }

    }
}

export const  updateFoodCategoryByOrder = async(diningTableData)=> {
    let res = await api.put("/category", diningTableData);
    let data = res?.data;
    return data;
}

export const  deleteFoodCategory = async(id)=> {
    let res = await api.delete(`/category/${id}`, config);
    let data = res?.data;
    return data;
}


import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
const CustomHtmlToolTip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main || '#337AB7',
    color:  theme.palette.primary.contrastText || '#fff',
    maxWidth: 300,
    fontSize: 14,
  }
}));

export default CustomHtmlToolTip;

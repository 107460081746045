import React from 'react';
import { Modal, Button } from "react-bootstrap";
import {
    CircularProgress
} from "@mui/material";

const DeleteConfirmPopup = (props) => {
    return (
        <Modal
            show={props.deletePopupshow}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <p style={{ fontSize: 14 }}>{props.content}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                {
                    props.isDeletingFlag ? <CircularProgress size={25} />
                        : <Button variant={props.confirmClass ? props.confirmClass : "danger"} onClick={props.deleteAction}>{props?.confirmBtnText ? props?.confirmBtnText : "Delete"}</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmPopup

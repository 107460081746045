import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Button, Card, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Form, Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FormControl, MenuItem } from "@mui/material";
import TextInputMui from "components/TextInputMui";
import { useSelector, useDispatch } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import clsx from "clsx";
import { Delete } from "@mui/icons-material";
const PREFIX = 'UpdateSettingSelection';

const classes = {
    formcontrolwidth: `${PREFIX}-formcontrolwidth`,
    monacoEditorStyle: `${PREFIX}-monacoEditorStyle`
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`& .${classes.formcontrolwidth}`]: {
        width: "100%",
        marginBottom: 10,
        "& > span": {
            maxWidth: 240,
            fontSize: 12,
        },
    },

    [`& .${classes.monacoEditorStyle}`]: {
        borderColor: "hsl(0, 0%, 80%)",
        borderRadius: "4px",
        borderStyle: "solid",
        borderWidth: "1px",
    }
}));


export default function UpdateSettingSelection(props) {
    const { title, onUpdate, data, maxSelection, optionArray, addLabel, clearLabel, updateLabel } = props
    const [screenArray, setScreenArray] = useState([]);

    useEffect(() => {
        if (data) {
            setScreenArray(data)
        }
    }, [data]);

    const removeElement = (r) => {
        let myArray = screenArray.filter(elem => elem.key != r.key);
        setScreenArray(myArray)
    }
    const onSubmit = async (values) => {
        let myArray = screenArray.filter(elem => elem?.key == values?.menu?.key);
        if (screenArray?.length > maxSelection) {
            toast.success("Maximun" + maxSelection + "Screen you can select.")
        }
        if (myArray?.length <= 0) {
            setScreenArray([...screenArray, values?.menu])
        }
    };
    const clearScreenArray = () => {
        setScreenArray([])
    }

    return (
        <>
            <StyledCard sx={{ p: 3, marginTop: 10 }}>
                <h5 className="m-auto py-3">{title}</h5>
                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} sm={12} md={6}>
                                    <Field name="menu">
                                        {({ input, meta }) => (
                                            <FormControl
                                                className={clsx(
                                                    classes.selectBoxControl,
                                                    classes.formcontrolwidth,
                                                )}
                                            >
                                                <TextInputMui
                                                    {...input}
                                                    select
                                                    label={"Select Screen From Drow Down"}
                                                    variant="outlined"
                                                    error={meta.error && meta.touched ? true : false}
                                                >
                                                    {optionArray?.map((item) => (
                                                        <MenuItem value={item}>{item?.value}</MenuItem>
                                                    ))}
                                                </TextInputMui>
                                                {meta.error && meta.touched && (
                                                    <span style={{ color: "red" }}>{meta.error}</span>
                                                )}
                                            </FormControl>
                                        )}
                                    </Field>

                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <RoleBasedGuard
                                        resource={RESOURCE.PROFILE}
                                        action={ACTION.UPDATE}
                                    >
                                        <LoadingButton
                                            type="submit"
                                            variant="contained"
                                            style={{ backgroundColor: "#00AB55" }}
                                        >
                                            {addLabel}
                                        </LoadingButton>
                                    </RoleBasedGuard>
                                    <RoleBasedGuard
                                        resource={RESOURCE.PROFILE}
                                        action={ACTION.UPDATE}
                                    >
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            onClick={() => clearScreenArray()}
                                            variant="contained"
                                        >
                                            {clearLabel}
                                        </Button>
                                    </RoleBasedGuard>
                                </Col>
                            </Row>
                        </form>
                    )}
                />
                <Row style={{ padding: 20 }} >
                    {screenArray?.map((r) => (
                        <Col sm={6} md={6}>
                            <Row>
                                <p>{r?.value}</p>
                                <Delete
                                    style={{ marginLeft: 10 }}
                                    color="secondary" onClick={() => removeElement(r)} />
                            </Row>
                        </Col>))}
                </Row>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onUpdate(screenArray)}
                >
                    {updateLabel}
                </Button>
            </StyledCard>
        </>
    );
}

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Container, Row, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DashboardAnalytics from "./DashboardAnalytics";
import OrderAnalytics from "./OrderAnalytics";
import InventoryAnalytics from "./InventoryAnalytics";
import ExpenseAnalytics from "./ExpenseAnalytics";
import CustomerAnalytics from "./CustomerAnalytics";
import { useInMobile } from "themes/Device";

const PREFIX = 'Analytics';

const classes = {
  TabName: `${PREFIX}-TabName`,
  mobileTab: `${PREFIX}-mobileTab`,
  mobileCategoryList: `${PREFIX}-mobileCategoryList`,
  desktop_style: `${PREFIX}-desktop_style`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.TabName}`]: {
    borderRadius: "0.75rem",
    fontWeight: "500",
    fontSize: "0.7rem",
    backgroundColor: "",
    marginTop: "10px",
    color: "white",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down('xl')]: {},
  },

  [`& .${classes.mobileTab}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.mobileCategoryList}`]: {
    display: 'flex',
  },

  [`& .${classes.desktop_style}`]: {
    display: "flex",
    justifyContent: "space-between",
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ReportTabs = (props) => {
  const [value, setValue] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState();
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobileView = useInMobile();

  const reSize = () => {
    setWindowWidth(window.innerWidth);
  };
  const [activeCategoryColor, setActiveCategoryColor] = useState("Sales");
  useEffect(() => {
    window.addEventListener("resize", reSize);
  });
  const getActiveCategoryColor = (activeCategoryColor, category) => {
    return activeCategoryColor === category
  }
  const onchangeFoodCategory = (selectedCategory) => {
    setActiveCategoryColor(selectedCategory);
  };
  return (
    (<Root>
     {/* <div id="invoice-POS">
     {
      getHtmlTemplate(DummyPrintModal)
    }
       </div> */}
      <Container fluid>
        <Row style={{
          justifyContent: "center",
          marginBottom: 5,
          marginTop: isMobileView ? 15 : 10
        }}>
          <div className={classes.mobileCategoryList}>
            {["Sales", "Order", "Expense", "Customer"]
              .map((category, index) => {
                return (
                  (
                    <Card className="ml-1 mr-1 mb-1"
                      key={category}>
                      <MuiButton
                        className={`w-100`}
                        style={{
                          color: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                          backgroundColor: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.main : ""
                        }}
                        variant="outlined"
                        onClick={() => onchangeFoodCategory(category)}
                      >
                        {category}
                      </MuiButton>
                    </Card>
                  )
                );
              })}
          </div>
        </Row>
        {activeCategoryColor == "Sales" ?
          <DashboardAnalytics /> :
          activeCategoryColor == "Order" ?
            <OrderAnalytics /> :
            activeCategoryColor == "Inventory" ?
              <InventoryAnalytics /> :

              activeCategoryColor == "Customer" ?
                <CustomerAnalytics /> :
                activeCategoryColor == "Expense" ?
                  <ExpenseAnalytics /> :
                  <></>
        }
      </Container>
    </Root>)
  );
};

export default ReportTabs;

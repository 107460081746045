import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchPaymentType = async ({ }) => {
    const url = `${API_ENDPOINTS.PAYMENT_TYPE}`;
    const { data } = await api.get(url);
    return data?.data;
};
const usePaymentType = (options = {}) => {
    return useQuery([API_ENDPOINTS.PAYMENT_TYPE, options], fetchPaymentType, {
        keepPreviousData: true,
    });
};
const fetchPaymentTypeQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.PAYMENT_TYPE}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const usePaymentTypeQuery = (id) => {
    return useQuery([API_ENDPOINTS.PAYMENT_TYPE, id], () => fetchPaymentTypeQuery(id));
};
const useDeletePaymentTypeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.PAYMENT_TYPE}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PAYMENT_TYPE);
            },
        }
    );
};
const useUpdatePaymentTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.PAYMENT_TYPE}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PAYMENT_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreatePaymentTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.PAYMENT_TYPE, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PAYMENT_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useBulkDeletePaymentTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.PAYMENT_TYPE}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PAYMENT_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    usePaymentType,
    usePaymentTypeQuery,
    useCreatePaymentTypeClassMutation,
    useUpdatePaymentTypeClassMutation,
    useDeletePaymentTypeMutation,
    useBulkDeletePaymentTypeClassMutation
};

import React, { useEffect, useState } from "react";
import { Lock, Print as PrintIcon } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { ORDER_DETAILS_BUTTON } from "common/constant";
import ButtonIcon from "components/ButtonComponent/ButtonIcon";
import { checkIfElectronLoaded } from "utility/helpers";
import { getPrintFormatJSONFromData, printSettleStructureData,
  kotPrintStructureData } from "utility/posPrinterReceipt";
import { isJson } from "utility/helpers";

const ElectronPrintFromOrder = (props) => {
  const {
    billingData,
    orderData,
    printBtnProcess,
    printerName,
    printerSize,
    buttonLabel,
    buttonIcon,
    printType,
    printingPermission = true,
  } = props;

  const [printFormatModel, setPrintFormatModel] = useState({});
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  useEffect(() => {
    if (typeof window.getPrinterList != "undefined") {
      window?.getPrinterList();
    }
  }, []);

  useEffect(() => {
    if (orderData) {
      let orderFoodItemData = [];
      if (orderData?.order_meal_items?.length > 0) {
        orderFoodItemData = orderData?.order_meal_items
      }
      else if (orderData?.fooditems?.length > 0) {
        orderFoodItemData = orderData?.fooditems
      }
      const printFoodItemArray =
        orderFoodItemData?.length > 0 &&
        orderFoodItemData?.map((item) => {
          let foodFullName = "";
          let foodItemName = item?.fooditem?.name
            ? item.fooditem?.name
            : item?.food_item_id?.label;
          let foodVariantName = "";
          let foodAddOnObj = isJson(item?.addons)
            ? JSON.parse(item?.addons)
              ?.map((addOn) => addOn.name)
              .join(", ")
            : "";
          if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
            foodVariantName = item?.fooditem?.food_variants.find(
              ({ id }) => id === item?.varient_id,
            )?.name;
          }

          foodFullName = `${foodItemName +
            (foodVariantName ? "( " + foodVariantName + ") " : "") +
            foodAddOnObj
            }`;

          return {
            foodName: foodFullName,
            qty: item?.qty,
            price: `₹ ${parseFloat(item?.sub_total).toFixed(2)}`,
          };
        });

      const printModal = getPrintFormatJSONFromData(shopSettingListData, billingData, orderData, printType, printFoodItemArray)
      // let subTotalWithTax = parseFloat(orderData?.sub_total) + parseFloat(orderData?.tax);
      // let discountInRs = orderData?.discount_sign === "%" ? (subTotalWithTax * parseFloat(orderData?.discount))/100 : parseFloat(orderData?.discount); 

      // const printModal = {
      //   header: shopSettingListData?.printing_setting?.PRINT_BILL_HEADER ? shopSettingListData?.printing_setting?.PRINT_BILL_HEADER : "",
      //   footer: shopSettingListData?.printing_setting?.PRINT_BILL_FOOTER ? shopSettingListData?.printing_setting?.PRINT_BILL_FOOTER : "",
      //   printingTemplate: shopSettingListData?.printing_setting?.PRINT_BILL_TEMPLATE,
      //   logo: billingData?.logo ? configData?.S3_BUCKET + billingData?.logo : "",
      //   shopName: (!shopSettingListData?.printing_setting?.PRINT_BILL_HEADER && billingData?.name) ? billingData?.name : "",
      //   shopTagLine: (!shopSettingListData?.printing_setting?.PRINT_BILL_HEADER && billingData?.about) ? billingData?.about : "",
      //   shopAddress: (!shopSettingListData?.printing_setting?.PRINT_BILL_HEADER && billingData?.address)
      //     ? billingData?.address + "- " + billingData?.phone
      //     : billingData?.phone
      //       ? billingData?.phone
      //       : "",
      //   customerName: orderData?.name_of_customer ? (orderData?.name_of_customer + "  " + orderData?.phone) : "",
      //   date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
      //   tokenNo: parseInt(orderData?.token_no) ? parseInt(orderData?.token_no) : "",
      //   orderNo: orderData?.id ? parseInt(orderData?.id) : parseInt(orderData?.token),
      //   orderType: " (" + orderData?.order_type + ")",
      //   tableName: orderData?.diningtable?.name_of_table || "",
      //   printFoodItemArray: printFoodItemArray,
      //   subTotal: orderData?.sub_total,
      //   tax:
      //     parseFloat(orderData?.tax) > 0
      //       ? `GST :  ₹ ${parseFloat(orderData?.tax).toFixed(2)}`
      //       : " ",
      //   discount: orderData?.discount ? `Discount (${orderData?.discount_sign == null || orderData?.discount_sign === "INR" ? "₹"
      //       : orderData?.discount_sign + " " + orderData?.discount
      //     }): ₹` + parseFloat(discountInRs).toFixed(2)
      //     : "",
      //   netTotal: orderData?.total_bill,
      //   additionalCharge: orderData?.additional_charge,
      //   additionalChargeReason: orderData?.additional_charge_reason || "Additional Charge" 
      // };
      setPrintFormatModel(printModal);
    }

  }, [orderData]);

  const triggerPrintToElectron = () => {

    let billFormatStructure = printType === ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON ?  kotPrintStructureData(printFormatModel, printerSize)  : printSettleStructureData(printFormatModel, printerSize);
    if (typeof window?.printData != "undefined" && checkIfElectronLoaded()) {
      window?.printData(
        printerName,
        printerSize ? printerSize + "px" : "200px",
        billFormatStructure,
        shopSettingListData?.printing_setting,
      );
    } else {
      toast.error("Printer Function is not loaded");
    }
  };

  return (
    <div>
      <ButtonIcon
        disabled={
          !printingPermission ||
          (printingPermission && orderData?.sub_total > 0 ? false : true)
        }
        isLoading={printBtnProcess}
        buttonLabel={buttonLabel || "Print"}
        variant="outline-info"
        onClick={triggerPrintToElectron}
        type="button"
        icon={printingPermission ? buttonIcon || <PrintIcon /> : <Lock />}
      />
    </div>
  );
};

export default ElectronPrintFromOrder;

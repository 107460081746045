import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchOrderType = async ({ }) => {
    const url = `${API_ENDPOINTS.ORDER_TYPE}`;
    const { data } = await api.get(url);
    return data?.data;
};
const useOrderType = (options = {}) => {
    return useQuery([API_ENDPOINTS.ORDER_TYPE, options], fetchOrderType, {
        keepPreviousData: true,
    });
};
const fetchOrderTypeQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.ORDER_TYPE}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useOrderTypeQuery = (id) => {
    return useQuery([API_ENDPOINTS.ORDER_TYPE, id], () => fetchOrderTypeQuery(id));
};
const useDeleteOrderTypeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.ORDER_TYPE}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.ORDER_TYPE);
            },
        }
    );
};
const useUpdateOrderTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.ORDER_TYPE}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.ORDER_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useBulkDeleteOrderTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.ORDER_TYPE}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.ORDER_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateOrderTypeClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.ORDER_TYPE, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.ORDER_TYPE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useOrderType,
    useOrderTypeQuery,
    useCreateOrderTypeClassMutation,
    useUpdateOrderTypeClassMutation,
    useDeleteOrderTypeMutation,
    useBulkDeleteOrderTypeClassMutation
};

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { NotificationImportant } from "@mui/icons-material";
import addNotification from 'react-push-notification';
import { App } from '@capacitor/app';
import {
  Stack
} from '@mui/material';
import Swal from "sweetalert2";

// components
import Header from "../Header";
import Routes from "../../Routes";
import {
  fetchBillingInfo,
  fetchPartnerUpdateInfo,
  fetchDiningTable,
  fetchShopSettingInfo,
  fetchShopDesign,
  fetchShopInfo,
  fetchPaymentType,
  fetchCategoryList,
  fetchFoodMealList,
  fetchKitchenQueue,
  fetchOrderHistoryList,
  fetchAllCustomer
} from "state/thunk-async";
import { setDiningTableLoader } from "state/actions/api.action";
import Sidebar from "../Sidebar";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import '@fortawesome/fontawesome-svg-core/styles.css'
import { SocketEndPoint, OrderStatus } from "common/constant";
import moment from "moment";
import MobileFooterComp from "components/MobileFooterComp/MobileFooterComp";
import { MobileContainer } from "themes/Device";
import { useInMobile } from "themes/Device";
import { toast } from "react-hot-toast";
import NotificationOne from "components/Notification/NotificationOne";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { NotificationItem } from "components/Layouts/dashboard/header/NotificationsPopover";
import { checkIfElectronLoaded, checkPrinterJson, isJson, getShopSettingFromLocalStorage } from "utility/helpers";
import { isAndroid, isMobile } from "react-device-detect";
import { AppendCustomCss } from "utility/helpers";
import useOrderDate from "../../hooks/useOrderDate";
import { triggerPrintToElectron } from "utility/posPrinterReceipt";
import { getPrintFormatJSONFromData } from "utility/posPrinterReceipt";
import { ORDER_DETAILS_BUTTON } from "common/constant";
const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  onlineContent: `${PREFIX}-onlineContent`,
  contentShift: `${PREFIX}-contentShift`,
  fakeToolbar: `${PREFIX}-fakeToolbar`,
  link: `${PREFIX}-link`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    maxWidth: "100vw",
    overflow: "hidden",
    height: '100%'
  },

  [`& .${classes.content}`]: {
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: isMobile ? '0px' : '6px',
    overflow: 'auto',
    top: '56px'
  },

  [`& .${classes.onlineContent}`]: {
    maxHeight: `calc(100% - 61px)`,
    height: '100%',
    width: '100%',
    position: 'absolute',
    padding: '6px',
    overflow: 'auto',
    top: '53px'
  },

  [`& .${classes.contentShift}`]: {
    transition: `${theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })}`
  },

  [`& .${classes.fakeToolbar}`]: {
    ...theme.mixins.toolbar,
    minHeight: '30px!important'
  },

  [`& .${classes.link}`]: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  }
}));


function Layout(props) {

  const [username, setShopUsername] = useState(localStorage.getItem("username"));
  const [open, setOpen] = React.useState(false);
  // const [initialReportDate, setInitialReportDate] = useState({
  //   startDate: moment().format("YYYY-MM-DD"),
  //   endDate: moment().add(1, "days").format("YYYY-MM-DD"),
  // });
  const shopSettingListData = useSelector((state) => state.shopSettingReducer.shopSettingData);
  const initialReportDate = useOrderDate(shopSettingListData);
  const shopThemeSettingData = useSelector((state) => state?.shopSettingReducer?.shopThemeSettingData);
  const billingData = useSelector((state) => state.api.billing);
  const shopInfoData = useSelector((state) => state.api.shopInfoData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDiningTableLoader(true));
    dispatch(fetchDiningTable());
    dispatch(fetchCategoryList());
    dispatch(fetchFoodMealList());
    dispatch(fetchBillingInfo());
    dispatch(fetchPartnerUpdateInfo());
    dispatch(fetchShopSettingInfo());
    dispatch(fetchShopDesign());
    dispatch(fetchPaymentType());
    dispatch(fetchAllCustomer());
    dispatch(fetchShopInfo());
    // smartlookClient.identify(username, {
    //   username: username
    // });
    // smartlookClient.init('9ebda0842075061dbcc3ed4658816cd6153c8b13')
  }, []);
 
  useEffect(() => {
    let isEmptyReportDate = Object.keys(initialReportDate).length === 0;
    if(!isEmptyReportDate)
    {
      dispatch(fetchOrderHistoryList(initialReportDate));
    }
  }, [initialReportDate])

  
  useEffect(() => {
    if(shopThemeSettingData?.custom_css)
    {
        AppendCustomCss(shopThemeSettingData?.custom_css);
    }
  }, [shopThemeSettingData])

  const checkIfTheCurrentUserIsSameAsASocketUser = (payload) => {
    if(payload?.username_old?.length > 0 && payload?.username_old?.split("/")[1] === "member")
      {
        return true;
      }
      else {
        return false;
      }
  }
  
  const printTriggerBasedOnSocket = (payload) => {
    console.log(payload);
    if (checkIfTheCurrentUserIsSameAsASocketUser(payload) && payload?.print_type) {
            const printData = payload?.order;
            const printOBJItems = printData?.print_obj?.length > 0 ? printData?.print_obj : (printData?.fooditems || printData?.order_meal_items);
            let printFoodItemArray = printOBJItems?.length > 0 ? printOBJItems?.map(item => {
      
              let foodFullName = "";
              let foodItemName = item?.fooditem?.name ? item.fooditem?.name : item?.food_item_id?.label
              let foodVariantName = "";
              let foodAddOnObj = isJson(item?.addons) ? JSON.parse(item?.addons)?.map(addOn => addOn.name).join(', ') : ""
              if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
                foodVariantName = item?.fooditem?.food_variants.find(({ id }) => id === item?.varient_id)?.name
              }
      
              // foodFullName = `<span style=text-decoration:${item?.kot_print?.type === 'DELETE' ? 'line-through' : ''}>${foodItemName + (foodVariantName ? "( " + foodVariantName + ") " : "") + foodAddOnObj}</span>`;
              foodFullName = `${item?.kot_print?.type === 'DELETE' ? '(DELETE)' : ''}${foodItemName + (foodVariantName ? "( " + foodVariantName + ")" : '') + foodAddOnObj}`;
              return {
                foodName: foodFullName,
                qty: item?.kot_print?.qty || item?.qty,
                price: `${parseFloat(item?.sub_total).toFixed(2)}`,
              }
            }) : [];
    
          const printModal = getPrintFormatJSONFromData(shopSettingListData, billingData, payload?.order, "", printFoodItemArray);
          
          if(payload?.print_type === "KOT")
          {
            triggerPrintToElectron(ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON, printModal, shopSettingListData);
          }
          else if(payload?.print_type === "BILL") {
            triggerPrintToElectron(ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON, printModal, shopSettingListData);
          }
      }
  }

  useEffect(() => {

    let isEmptyReportDate = Object.keys(initialReportDate).length === 0;

    const searchReq = {
      startDate: moment().format("YYYY-MM-DD"),
      status: [OrderStatus.IN_PROGRESS, OrderStatus.DELIVERED],
    };
    SocketEndPoint.emit("clientconnection", username)
    SocketEndPoint.on("shop", shop => {
      //console.log("shop", shop)
    })

    SocketEndPoint.on("createOrder", (payload) => {
      console.log("order socker")
      
      if(shopSettingListData?.KITCHAN_SERVICE === true) {
        dispatch(fetchKitchenQueue(searchReq)); 
      }
      dispatch(fetchDiningTable());
      printTriggerBasedOnSocket(payload);
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
      console.log('%cLayout.js line:190 payload?.order - KOT PRINT TRIGGER', 'color: #007acc;', payload?.order);
      

      if(payload?.order?.is_online)
      {
        showNotificationToast(payload?.order);
        addNotification({
          title: 'Online Order',
          subtitle: 'online order comes via QR code',
          message: 'This is a very long message',
          theme: 'darkblue',
          native: true // when using native, your OS will handle theming.
         });
      }
    })

    SocketEndPoint.on("updateOrder", (payload) => {
      console.log('%cLayout.js line:211 payload', 'color: #007acc;', payload);
      printTriggerBasedOnSocket(payload);
      if(shopSettingListData?.KITCHAN_SERVICE === true) {
        dispatch(fetchKitchenQueue(searchReq)); 
      }
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
      dispatch(fetchDiningTable());
    })

    SocketEndPoint.on("deleteOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })
    SocketEndPoint.on("createOrderOnQr", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
      if(!isEmptyReportDate) {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })

    SocketEndPoint.on("releaseTable", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })
    SocketEndPoint.on("finishOrder", (payload) => {
      dispatch(fetchKitchenQueue(searchReq));
      dispatch(fetchDiningTable());
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })
    SocketEndPoint.on("priorityDineInTable", (payload) => {
      dispatch(fetchDiningTable());
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })
    SocketEndPoint.on("priorityFoodMenu", (payload) => {
      dispatch(fetchFoodMealList());
    })
    SocketEndPoint.on("priorityFoodCategory", (payload) => {
      dispatch(fetchCategoryList());
    })
    SocketEndPoint.on("referesh_token", (payload) => {
      localStorage.removeItem("token");
      localStorage.setItem("token", payload.token);
      if(!isEmptyReportDate)
      {
        dispatch(fetchOrderHistoryList(initialReportDate));
      }
    })
    SocketEndPoint.on("inventory_out_of_stock", (payload) => {
      if(payload.inventory)
      {
        Swal.fire({
          title: '<strong>Out of Stock</strong>',
          icon: 'warning',
          html:
            'your inventory is low. please check that.',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Mark as out of stock',
          cancelButtonText: 'Continue'
        })
      }
    })
  }, [SocketEndPoint])


  useEffect(() => {
    triggerUpdatePopupForMobile();
  }, [shopInfoData])

  const triggerUpdatePopupForMobile = async () => {
    console.log(shopInfoData);
    if(shopInfoData && isAndroid)
    {
      const appInfoData = await App.getInfo();
      if(appInfoData?.version !== shopInfoData?.mobile_version) {
        Swal.fire({
          title: "New Update!",
          text: "Please update",
          icon: "info",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "<a href='https://play.google.com/store/apps/details?id=com.app.servofeat' style='color: #fff' target='_blank'> Update </a>",
        })
      }
    }
  }
  

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  const acceptOnlineOrder = (orderID, toastConfig) => {
    try {
      onlineOrderAccepted(orderID)
        .then((res) => {
          if(res.status == 200)
          {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          toast.dismiss(toastConfig?.id);
          dispatch(fetchOrderHistoryList(initialReportDate));
          dispatch(fetchDiningTable());
        })
        .catch((err) => {
          toast.dismiss(toastConfig?.id);
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const declineOnlineOrder = (orderID, toastConfig) => {
    try {
      onlineOrderDecline(orderID)
        .then((res) => {
          if(res.status == 200)
          {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          toast.dismiss(toastConfig?.id);
          dispatch(fetchOrderHistoryList(initialReportDate));
          // dispatch(fetchDiningTable());
        })
        .catch((err) => {
          toast.dismiss(toastConfig?.id);
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };


  const showNotificationToast = (orderData) => {
    toast((toastConfig) => (
      <Stack>
        <NotificationItem
         orderData = {orderData}
          acceptOnlineOrder = {(data) => acceptOnlineOrder(data, toastConfig)}
         declineOnlineOrder = {(data) => declineOnlineOrder(data, toastConfig)}
        />
      </Stack>
    ),
    {
      position: "bottom-right",
      duration: 10000,
    }
    );
  }

  return (
    <Root className={classes.root}  
        onClick={() => {
          if(layoutState.isSidebarOpened) {
            toggleSidebar(layoutDispatch)
          }
        }
    }>
      <NotificationOne
       open= {open}
       setOpen ={setOpen}
       />
      <Header history={props.history} className={classes.header} />
      <Sidebar />
      <div
        // onClick={() =>
        //   layoutState.isSidebarOpened && toggleSidebar(layoutDispatch)
        // }
        style={{ top: useInMobile() ? '49px' : '', paddingBottom: useInMobile() ? '56px' : '' }}
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        {
          shopSettingListData.CUSTOMER_ORDER_DATE &&
          <Alert variant="warning" className="alert-message">
          <NotificationImportant /> <span>
            you set a custom order date from your setting. so all order will be goes on {moment(shopSettingListData?.CUSTOMER_ORDER_DATE).format("YYYY-MM-DD")}. 
            <Link to={'/app/profile'}> click here</Link> to remove it
          </span>
        </Alert>
        }
        {/* Online Offline Notification ON ELECTRON */}
        <Alert variant="warning" className="online-offline-alert alert-message">
          <NotificationImportant /> <span>
            You are offline. Your data can’t be saved and may not be up to date. Until you’re back online.
          </span>
        </Alert>
        <Routes />
      </div>
      <MobileContainer>
        <MobileFooterComp />
      </MobileContainer>
    </Root>
  );
}

export default withRouter(React.memo(Layout));

import { api } from "./../utility/interceptor";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const updateSettingData = async (storeData, shopId) => {
  try {
    let res = await api.put(`/settingnew/${shopId}`, storeData);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};


export const geSettingsnew = async () => {
  try {
    let res = await api.get(`/settingnew`, config);
    return { status: res?.status, data: res?.data?.data || [] };
  } catch (err) {
    return { status: err?.response?.status, data: err?.response };
  }
};

export const getAllSettingList = async () => {
  let res = await api.get(`/bill-print`, config);
  let data = res?.data;
  return data;
};

export const updateBillingInfo = async (billingData) => {
  try {
    let res = await api.post(`/bill-print`, billingData);
    console.log(res);
    let data = res?.data;
    return data;
  } catch (error) {
    return error;
  }
};

export const getStoreSetting = async () => {
  try {
    let res = await api.get(`/settingnew`, config);
    return { status: res.status, data: res.data?.data || [] };
  } catch (err) {
    return { status: err?.response?.status, data: err?.response };
  }
};

export const getStoreSettingById = async (id) => {
  let res = await api.get(`/setting/${id}`, config);
  let data = res.data;
  return data;
};

export const updateStoreSetting = async (storeData, id) => {
  try {
    let res = await api.put(`/setting/${id}`, storeData);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const savePrinterSetting = async (data) => {
  try {
    const res = await api.post(`/mobile-print-update`, data);
    return { status: res.status };
  } catch (err) {
    return { status: err?.response?.status };
  }
};

export const getStoreDesignSetting = async () => {
  try {
    let res = await api.get(`/design`, config);
    return { status: res.status, data: res.data?.data || [] };
  } catch (err) {
    return { status: err?.response?.status, data: err?.response };
  }
};

export const saveStoreThemeSetting = async (themeData) => {
  try {
    let res = await api.post(`/design`, themeData);
    return { status: res.status, data: res.data };
  } catch (error) {
    return { status: error?.response?.status };
  }
};

export const updateStoreThemeSetting = async (id, themeData) => {
  try {
    let res = await api.patch(`/design/${id}`, themeData);
    return { status: res.status, data: res.data };
  } catch (error) {
    return { status: error?.response?.status };
  }
};

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import { Button as MuiButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useInMobile } from "themes/Device";
import OrderReport from "./OrderReport";
import CustomerReport from "./CustomerReport";
import ExpenseReport from "./ExpenseReport";
import FoodItemReport from "./FoodItemReport";
import InventoryReport from "./InventoryReport";
import GstReport from "./GstReport";
import FoodMenuWiseReport from "./FoodMenuWiseReport";
import CategoryWiseReport from "./CategoryWiseReport";
import StaffWiseReport from "./StaffWiseReport";

const PREFIX = 'ReportTabs';

const classes = {
  TabName: `${PREFIX}-TabName`,
  mobileTab: `${PREFIX}-mobileTab`,
  mobileCategoryList: `${PREFIX}-mobileCategoryList`,
  desktop_style: `${PREFIX}-desktop_style`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.TabName}`]: {
    borderRadius: "0.75rem",
    fontWeight: "500",
    fontSize: "0.7rem",
    backgroundColor: "",
    marginTop: "10px",
    color: "white",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down('xl')]: {},
  },

  [`& .${classes.mobileTab}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.mobileCategoryList}`]: {
    display: 'flex',
    padding: 10,
    overflow: 'auto'
  },

  [`& .${classes.desktop_style}`]: {
    display: "flex",
    justifyContent: "space-between",
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const ReportTabs = (props) => {

  const [value, setValue] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState();
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobileView = useInMobile();

  const reSize = () => {
    setWindowWidth(window.innerWidth);
  };
  const [activeCategoryColor, setActiveCategoryColor] = useState("Sales");
  useEffect(() => {
    window.addEventListener("resize", reSize);
  });
  const getActiveCategoryColor = (activeCategoryColor, category) => {
    return activeCategoryColor === category
  }
  const onchangeFoodCategory = (selectedCategory) => {
    setActiveCategoryColor(selectedCategory);
  };
  return (
    (<Root>
      <div className={classes.mobileCategoryList}>
        {["Food", "Sales", "Inventory", "Expense", "Customer", "GST", "Menu wise", "Category wise", "Staff wise"]
          .map((category, index) => {
            return (
              (
                <Stack className="ml-1 mr-1 mb-1"
                  key={category}>
                  <MuiButton
                    className={`w-100`}
                    style={{
                      color: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                      backgroundColor: getActiveCategoryColor(activeCategoryColor, category) ? theme.palette.primary.main : ""
                    }}
                    variant="outlined"
                    onClick={() => onchangeFoodCategory(category)}
                  >
                    {category}
                  </MuiButton>
                </Stack>
              )
            );
          })}
      </div>
      {activeCategoryColor == "Sales" ?
        <OrderReport /> :
        activeCategoryColor == "Inventory" ?
          <InventoryReport /> :
          activeCategoryColor == "Customer" ?
            <CustomerReport /> :
            activeCategoryColor == "Expense" ?
              <ExpenseReport /> :
              activeCategoryColor == "Food" ?
                <FoodItemReport /> :
                activeCategoryColor == "GST" ?
                  <GstReport /> :
                  activeCategoryColor == "Menu wise" ?
                    <FoodMenuWiseReport /> :
                    activeCategoryColor == "Category wise" ?
                      <CategoryWiseReport /> :
                      activeCategoryColor == "Staff wise" ?
                        <StaffWiseReport /> :
                        <></>
      }
    </Root>)
  );
};

export default ReportTabs;

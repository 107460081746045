import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import { useInMobile } from "themes/Device";

export default makeStyles()(theme => ({
  logotype: {
    color: "white",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    // fontWeight: 500,
    width: '240px',
    fontSize: 28,
    fontFamily: 'none',
    fontStyle: 'oblique',
    fontWeight: 'bolder',
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  screenTitle: {
    width: '100%',
    fontSize: "18px",
    textAlign: 'center'
  },
  navBarLogo: {
    fontSize: '24px',
    // position: 'absolute',
    // left: '10%',
    // transform: 'translate(-31px, 0px)'
  },
  appBar: {
    width: "100vw",
    // background: "#fff",
    position: 'absolute',
    // height: "100px",
    // padding: "1.5rem 0",
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
    zIndex: 101,
    transition: `${theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })}`,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      minHeight: '40px!important',
    },
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    // transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    top: '0px!important',
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerMenuButton: {
    // marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    // marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: useInMobile ? 25 : 18,
    [theme.breakpoints.up("md")]: {
      width: '1.5em',
      height: '1.5em'
    }
  },
  phoneIcon: {
    fontSize: useInMobile ? 25 : 18,
    color: "",
    marginRight: "5px"
  },
  fullScreenIcon: {
    fontSize: useInMobile ? 25 : 18,
    color: "",
  },
  headerIconCollapse: {
    color: '',
  },
  profileMenu: {
    top: '-5px!important',
    maxWidth: 173,
    minWidth: 173,
    right: '0px!important',
    borderLeft: '3px solid '
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    marginRight: theme.spacing(3)
  },
  navLinkHeader: {
    padding: '3px!!important',
    fontSize: '12px'
  },
  iconButton: {
    padding: '0px 8px'
  },
  trialDay: {
    fontSize: '18px',
    fontWeight: 500,
    margin: 'auto',
  },
  mobileNavBar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
}));

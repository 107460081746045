import React from "react";
import { adminLogin } from "../services/login.service";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    // isAuthenticated: !!localStorage.getItem("id_token"),
    isAuthenticated: !!localStorage.getItem("token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    adminLogin({ username: login, password: password })
      .then((res) => {
        if (res?.status === 200 || res?.data) {
          setIsLoading(false);
          localStorage.setItem("token", res.data.data.token);
          // toast.success(res.data.message);
          history.push('/app/dine-in')
        } else {
          setIsLoading(false);
          // toast.warning(res?.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // toast.danger(err?.message);
      });
    setError(null)
    setIsLoading(false)
    dispatch({ type: 'LOGIN_SUCCESS' })

    history.push('/app/dine-in')

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  // localStorage.removeItem("id_token");
  localStorage.removeItem("token")
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}

// const onSubmitLogin = (loginvalue, password) => {
//   setIsLoading(true);
//     adminLogin({username:loginvalue, password: password})
//       .then((res) => {
//         if (res?.status == 200 || res?.data) {
//           setIsLoading(false);
//           localStorage.setItem("token", res.data.data.token);
//           localStorage.setItem(
//             "billing",
//             JSON.stringify(res.data.data.billingData[0])
//           );
//           // toast.success(res.data.message);
//           window.location.href = "/app/dashboard";
//         props.history.push('/app/dashboard')
//         console.log(res)

//         } else {
//           setIsLoading(false);
//           // toast.warning(res?.message);
//         }
//       })
//       .catch((err) => {
//         console.log(err)
//         setIsLoading(false);
//         // toast.danger(err?.message);
//       });
//   };
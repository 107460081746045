import { Stack } from "@mui/material";
import Card from "components/Card/Card";
import Label from "components/Label";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import React from "react";
import { useTranslation } from "react-i18next";
import "./MobileCategory.styles.css";

const MobileCategory = (props) => {
  const { name, active, deleteData, editData, itemId, resource } = props;
  const { t } = useTranslation();

  return (
    <Card className="cardCategory">
      <Stack gap={2} direction="row" className="mt-1">
        <Stack >
          <h6 className="categoryName">{name}</h6>
        </Stack>
      </Stack>
      <Stack gap={2} direction="row" className="mt-1" justifyContent={"space-between"} alignItems="center">
        <Stack>
          {active ? (
            <Label color="success"> {t("available-label")} </Label>
          ) : (
            <Label color="error"> {t("unavailable-label")} </Label>
          )}
        </Stack>
        <Stack>
          <MenuButtonEditDelete
            resource={resource}
            deleteClick={deleteData}
            editClick={editData}
            itemId={itemId}
            isMenuBar={props?.isMenuBar}
          />
        </Stack>
      </Stack>
    </Card>
  );
};

export default MobileCategory;

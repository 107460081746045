import React from "react";
import "./mobileinventorycard.styles.css";
import { Card } from "react-bootstrap";
import MenuButtonEditDelete from "components/MenuButtonEditDelete";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

function MobileExpenseCard(props) {
  const { type, data, deleteData, editData, itemId, resource } = props;
  const { t } = useTranslation();

  return (
    <>
      <Card className="card1">
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6 className="tale">{data?.name}</h6>
            <p className="para">{data?.date || ''}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h6
              className="rupee"
              style={{ marginTop: "2px", marginBottom: "4px" }}
            >
              {data?.category?.name}
            </h6>
            {data?.unit > 0 && (
              <p className="para" style={{ marginBottom: "4px" }}>
                {t("expense-management-quantity")}:&nbsp;
                <span>
                  {parseFloat(data?.unit)?.toFixed(2)} {data?.unit_type}
                </span>
              </p>
            )}
          </div>
        </div>
        <div>
          <MenuButtonEditDelete
            resource={resource}
            deleteClick={deleteData}
            editClick={editData}
            itemId={itemId}
            isMenuBar={true}
          />
        </div>
      </Card>
    </>
  );
}
export default MobileExpenseCard;

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Box, Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import { makeStyles } from 'tss-react/mui';
// API Calls
import { fetchQRCodeFoodMenuList } from "state/thunk-async";

import PageLoader from "components/Loader/PageLoader";
import SearchBarInput from "components/SearchBarInput";
import { OrderDetailCommonMessage } from "common/constant";
import checkAddonsMatch from "common/order-common";
import { isJson } from "utility/helpers";
import AddEditOnlineOrder from "./AddEditOnlineOrder";
import OnlineCheckout from "./OnlineCheckout";

import "./onlineorder.scss";

import { verifyPayment, initPayment } from "services/payment.service";
import { GenerateOnlineOrder, RefereshToken } from "services/onlineorder.service";
import {
  getDiningTableById,
} from "services/diningTable.service";

import {
  submitOrder,
  getExcitingOrderById,
  updateOrder,
} from "services/order.service";
import { ShoppingCartSharp } from "@mui/icons-material";
import { createPaymentOrder } from "services/paytm.service";
import { Card, CardContent, Typography } from "@mui/material";

const PREFIX = 'OnlineQR';

const classes = {
  arrow: `${PREFIX}-arrow`,
  tooltip: `${PREFIX}-tooltip`,
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.arrow}`]: {
    color: theme.palette.common.black,
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }
}));

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "100%",
  },
  formcontrolwidth: {
    width: "100%",
  },
};

const useStylesBootstrap = makeStyles()((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }
}));

function BootstrapTooltip(props) {


  return <Tooltip arrow classes={classes} placement="top-end" {...props} />;
}

const OnlineQR = (props) => {
  const history = useHistory();

  const [selectedFoodItemData, setSelectedFoodItemData] = useState([]);
  const [selectedFoodItemListData, setSelectedFoodItemListData] = useState([]);
  const [foodItems, setFoodItems] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const foodMenuData = useSelector((state) => state.customer.foodMenuData);
  const [pageLoaderFirst, setPageLoaderFirst] = useState(true);
  const [currentFormValue, setCurrentFormValue] = useState({}); // initial form value
  const [checkoutFormValue, setCheckoutFormValue] = useState({});
  const [orderId, setOrderId] = useState(0);
  const dispatch = useDispatch();

  const [checkoutPage, setCheckoutPage] = useState(false);
  const [thankYouPage, setThankYouPage] = useState(false);

  // Form Set values
  const [subTotal, setSubTotal] = useState(0);
  const [finalBill, setFinalBill] = useState(0);
  const [customerInfo, setCustomerInfo] = useState({});
  const [discountValue, setDiscountValue] = useState(0);
  const [discountSignValue, setDiscountSignValue] = useState("INR");
  const [taxValue, setTaxValue] = useState(false);
  const [searchFoodItem, setSearchFoodItem] = useState("");
  const urlSearchParamas = new URLSearchParams(history.location.search);
  const tableID = urlSearchParamas.get('table');
  const username = urlSearchParamas.get('username');
  const [orderAlreadyInProgress, setOrderAlreadyInProgress] = useState(false);


  useEffect(() => {
    async function fetchData() {
      const userData = await RefereshToken({ username: username });
      localStorage.setItem('token', userData?.data?.token);
      setPageLoaderFirst(true);
      if (!thankYouPage) {
        dispatch(fetchQRCodeFoodMenuList(props.match.params.id))
          .then((_) => setPageLoaderFirst(false))
          .catch((_) => setPageLoaderFirst(false));

          if(tableID)
          {
            getDiningTableById(tableID).then(res => {
              const diningTableInfo = res.data;
              if (diningTableInfo?.order_id && diningTableInfo?.order_id > 0) {
                setOrderAlreadyInProgress(true);
                setOrderId(diningTableInfo?.order_id);
                getExcitingOrderById(diningTableInfo?.order_id).then(res => {
                  const { data } = res;
                  if (data?.order_meal_items.length > 0) {
                    const orderMealItems = data?.order_meal_items?.map((meal) => {
                      if (meal?.fooditem?.food_item_addons) {
                        meal.fooditem.food_item_addons = [
                          ...meal?.fooditem?.food_item_addons,
                          ...meal?.fooditem?.category?.food_item_addons,
                        ];
                      }
                      return {
                        id: meal.id || 0,
                        qty: meal?.qty,
                        food_item_id: meal?.food_item_id || 0,
                        price: meal?.price,
                        total_qty_price: meal?.total_qty_price,
                        addon_price: meal?.addon_price,
                        total_addon_qty_price: meal?.total_addon_qty_price,
                        sub_total: meal?.sub_total,
                        varient_id: meal?.varient_id,
                        is_non_veg: meal?.is_non_veg,
                        addons: meal?.addons || "",
                        instruction_note: meal?.instruction_note,
                        fooditem: meal?.fooditem,
                      };
                    });
                    setSelectedFoodItemData(orderMealItems);
                    setSelectedFoodItemListData(orderMealItems);
                    setCheckoutFormValue({ name_of_customer: data?.name_of_customer, phone: data?.phone })

                    setCurrentFormValue({
                      ...data,
                      customer: `${data?.name_of_customer} - ${data?.phone}`,
                    });
                    setDiscountValue(data.discount);
                    setDiscountSignValue(data.discount_sign);
                    setFinalBill(data.total_bill);
                  }
                })
              }
              else {
                setOrderAlreadyInProgress(false);
              }
            })
          }
           
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    setFoodItems(foodMenuData?.foodItems);
    if (foodMenuData?.category) {
      setCategoryList(foodMenuData?.category);
    }
  }, [foodMenuData]);

  useEffect(() => {
    subTotalCalculate();
  }, [selectedFoodItemListData]);

  useEffect(() => {
    finalBillCalculate();
  }, [subTotal, discountValue, discountSignValue, taxValue]);

  const finalBillCalculate = () => {
    if (subTotal === 0) {
      setFinalBill(0.0);
    }
    //ADD GST TO bill
    var taxAmount = 0;
    var GSTPercent = 5 / 100; // SGST 2.5% + CGST 2.5%
    if (taxValue) {
      taxAmount = subTotal * GSTPercent;
    }
    if (discountSignValue === "%" && parseInt(discountValue) > 0)
      setFinalBill(
        parseFloat(
          subTotal - (subTotal * discountValue) / 100 + taxAmount,
        ).toFixed(2),
      );
    else
      setFinalBill(parseFloat(subTotal - discountValue + taxAmount).toFixed(2));
  };

  const subTotalCalculate = () => {
    let amount = 0;
    selectedFoodItemListData.forEach((order) => {
      amount += parseFloat(order?.sub_total);
    });
    setSubTotal(parseFloat(amount).toFixed(2));
  };

  const payNow = async (values) => {
    setCustomerInfo({
      name_of_customer: values?.name_of_customer || "",
      phone: values?.phone || "",
    });
    const reqObj = {
      name_of_customer: values?.name_of_customer || "",
      phone: values?.phone || "",
      amount: finalBill,
    };
    await displayRazorpay(reqObj);
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (reqObj) => {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js",
      );
      // creating a new order
      const result = await initPayment(reqObj);

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, id: order_id, currency } = result?.data?.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount.toString(),
        currency: currency,
        name: "Servo Feat",
        description: "",
        order_id: order_id,
        handler: verifyHandler,
        prefill: {
          name: reqObj?.name_of_customer,
          contact: `+91${reqObj?.phone}`,
          email: "test@gmail.com",
        },
        theme: {
          color: "",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  };

  const verifyHandler = async (response) => {
    try {
      const data = await verifyPayment(response);
      const { order_id, payment_id } = data?.data?.data;
      const shopId = props.match.params.id;
      const orderModel = {
        ...customerInfo,
        sub_total: subTotal,
        total_bill: finalBill,
        tax: 0,
        discount_sign: discountSignValue,
        fooditems: selectedFoodItemData,
        razor_pay_id: payment_id
      };
      console.log("online Order");
      GenerateOnlineOrder(shopId, orderModel)
        .then((res) => {
          console.log(res);
          setCheckoutPage(false);
          resetALlData();
          Swal.fire(
            "Thank You!",
            "Order has been submitted. Please wait..",
            "success",
          );
        })
        .catch((err) => {
          setCheckoutPage(false);
          Swal.fire({
            icon: "error",
            title: "Sorry try again...",
            text: "Something went wrong!",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const resetALlData = () => {
    setSelectedFoodItemData([]);
    setSelectedFoodItemListData([]);
    setPageLoaderFirst(false);
    setCurrentFormValue({});
    setSubTotal(0);
    setFinalBill(0);
    setDiscountValue(0);
    setDiscountSignValue("INR");
    setTaxValue(false);
  };

  const foodItemsArrayAdded = (foodItem, existing_index) => {
    let availableFoodItemIndex = -1;
    let availableTableListIndex = -1;
    if (existing_index === undefined) {
      availableFoodItemIndex = selectedFoodItemData.findIndex((item) => {
        let foodItemAddons = foodItem?.addons;
        let selectedItemAddons = item?.addons;
        if (selectedItemAddons) {
          if (isJson(selectedItemAddons)) {
            selectedItemAddons = JSON.parse(selectedItemAddons);
          }
        } else {
          selectedItemAddons = [];
        }
        if (foodItemAddons) {
          if (isJson(foodItemAddons)) {
            foodItemAddons = JSON.parse(foodItemAddons);
          }
        } else {
          foodItemAddons = [];
        }
        return (
          checkAddonsMatch(foodItemAddons, selectedItemAddons) &&
          item?.food_item_id === foodItem?.food_item_id &&
          item?.varient_id === foodItem.varient_id
        );
      });
      availableTableListIndex = selectedFoodItemListData.findIndex((item) => {
        let foodItemAddons = foodItem?.addons;
        let selectedItemAddons = item?.addons;
        if (selectedItemAddons) {
          if (isJson(selectedItemAddons)) {
            selectedItemAddons = JSON.parse(selectedItemAddons);
          }
        } else {
          selectedItemAddons = [];
        }
        if (foodItemAddons) {
          if (isJson(foodItemAddons)) {
            foodItemAddons = JSON.parse(foodItemAddons);
          }
        } else {
          foodItemAddons = [];
        }
        return (
          checkAddonsMatch(foodItemAddons, selectedItemAddons) &&
          item?.food_item_id === foodItem?.food_item_id &&
          item?.varient_id === foodItem.varient_id
        );
      });
    }

    // Foood Item update logic
    const orderMealItemData = {
      id: foodItem.id || 0,
      qty: foodItem?.qty,
      food_item_id: foodItem?.food_item_id || 0,
      price: foodItem?.price,
      total_qty_price: foodItem?.total_qty_price,
      addon_price: foodItem?.addon_price,
      total_addon_qty_price: foodItem?.total_addon_qty_price,
      sub_total: foodItem?.sub_total,
      varient_id: foodItem?.varient_id,
      is_non_veg: foodItem?.is_non_veg,
      addons: foodItem?.addons || "",
      instruction_note: foodItem?.instruction_note,
      fooditem: foodItem?.fooditem,
    };
    if (existing_index > -1 && existing_index !== undefined) {
      const updatedSelectedFoodItemListData = [...selectedFoodItemListData];
      const updatedSelectedFoodItemData = [...selectedFoodItemData];
      updatedSelectedFoodItemListData[existing_index] = foodItem;
      updatedSelectedFoodItemData[existing_index] = orderMealItemData;

      setSelectedFoodItemListData(updatedSelectedFoodItemListData);
      setSelectedFoodItemData(updatedSelectedFoodItemData);
    } else if (availableFoodItemIndex >= 0 && availableTableListIndex >= 0) {
      // ######## Increase Qty When Food Item is same also varient and addons same  #######
      const updatedSelectedFoodItems = [...selectedFoodItemData];
      let selectedItem = updatedSelectedFoodItems[availableFoodItemIndex];
      plusItemQty(selectedItem, availableFoodItemIndex, foodItem?.qty);
      toast.info(OrderDetailCommonMessage.QUANTITY_ADDED);
    } else {
      setSelectedFoodItemListData((r) => [...r, foodItem]);
      setSelectedFoodItemData((r) => [...r, orderMealItemData]);
    }
    // onchange of order id it will calculate the Subtotal amount*qty
    subTotalCalculate();
  };

  const deleteFoodItem = (item, itemIndex) => {
    //####### Delete By Item Index ##########

    setSelectedFoodItemData(
      selectedFoodItemData?.filter((element, index) => index !== itemIndex),
    );

    setSelectedFoodItemListData(
      selectedFoodItemListData?.filter((element, index) => index !== itemIndex),
    );
  };

  const plusItemQty = (item, index, updateQty = 0) => {
    const totalQty =
      updateQty > 0
        ? parseFloat(item?.qty) + parseFloat(updateQty)
        : parseFloat(item?.qty) + 1;

    const formatFoodItem = {
      ...item,
      qty: totalQty,
      id: item?.id || 0,
      price: parseFloat(item?.price),
      total_qty_price:
        parseFloat(item?.price) * parseFloat(parseFloat(item?.qty) + 1),
      addon_price: parseFloat(item?.addon_price),
      total_addon_qty_price:
        parseFloat(item?.addon_price) * parseFloat(parseFloat(item?.qty) + 1),
      sub_total:
        parseFloat(item?.addon_price) * parseFloat(parseFloat(item?.qty) + 1) +
        parseFloat(item?.price) * parseFloat(parseFloat(item?.qty) + 1),
    };

    if (formatFoodItem.qty > 0) {
      foodItemsArrayAdded(formatFoodItem, index);
    }
  };

  const minusItemQty = (item, index) => {
    const formatFoodItem = {
      ...item,
      qty: parseFloat(item?.qty) - 1,
      id: item?.id || 0,
      price: parseFloat(item?.price),
      total_qty_price: parseFloat(item?.price) * parseFloat(item?.qty) - 1,
      addon_price: parseFloat(item?.addon_price),
      total_addon_qty_price:
        parseFloat(item?.addon_price) * parseFloat(item?.qty) - 1,
      sub_total:
        parseFloat(item?.addon_price) * parseFloat(item?.qty - 1) +
        parseFloat(item?.price) * parseFloat(item?.qty - 1),
    };

    if (formatFoodItem.qty > 0) {
      foodItemsArrayAdded(formatFoodItem, index);
    } else if (formatFoodItem.qty === 0) {
      deleteFoodItem(formatFoodItem, index);
    }
  };

  function isDate(val) {
    return Object.prototype.toString.call(val) === '[object Date]'
  }

  function isObj(val) {
    return typeof val === 'object'
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)

    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
    return form
  }
  function post(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
  }
  const makePayment = async (orderResData, orderValues) => {
    //order_id fromate 
    //date_username_orderid
    const userName = localStorage.getItem('userName');
    const result = await createPaymentOrder({
      ORDER_ID: `${new Date().getTime()}` + '@' + `${userName}` + '@' + `${orderResData?.id}`,
      MOBILE_NO: orderResData?.phone,
      EMAIL: orderValues?.email,
      TXN_AMOUNT: parseFloat(orderResData?.total_bill)
    });
    var information = {
      action: process.env.REACT_APP_PAYTM_URL,
      params: result?.data?.data
    }
    post(information)
  }

  const createOrder = async (values) => {
    console.log('value', values);
    const orderModel = {
      id: parseInt(orderId), //order id
      table_id: Number(tableID || 0),
      order_type:  Number(tableID) > 0 ? "QR_DINEIN" : "QR_SHOP",
      name_of_customer: values?.name_of_customer,
      phone: values?.phone?.trim(),
      email:values?.email?.trim(),
      address: values?.address?.trim(),
      sub_total: subTotal, // Sub total
      total_bill: finalBill, // Net Amount
      fooditems: selectedFoodItemData,
      discount_sign: "INR",
      bill_type: "ONLINE",
      is_online: true
    };

    if (orderModel.id > 0) {
      await updateOrder(parseInt(orderId), orderModel)
        .then((res) => {
          console.log('res', res);
          Swal.fire({
            title: "Successfull",
            text: "Your order successfully saved. please pay now to confirm order.",
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "cancel",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Pay Now - ${parseFloat(res?.data?.data?.total_bill)}`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              makePayment(res?.data?.data, values);
            }
          });
         
        })
        .catch((err) => {
          toast.error(err?.message || "something went wrong");
        });
    } else {
      await submitOrder(orderModel)
        .then((res) => {
          // console.log('res', res);
          // setThankYouPage(true);
          Swal.fire({
            title: "Successfull",
            text: "Your order successfully saved. please pay now to confirm order.",
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "cancel",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Pay Now - ${parseFloat(res?.data?.data?.total_bill)}`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              makePayment(res?.data?.data, values);
            }
          });
        })
        .catch((err) => {
          toast.error(err?.message || "something went wrong");
        });
    }


  }

  if(orderAlreadyInProgress)
  {
  return  <Card>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div">
            Welcome to {localStorage.getItem('userName')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Oops!, the order is already in progress. please Ask to Store Owner or Waiter for Add/Edit Order.
            <br></br>
            Thank you.
          </Typography>

    </CardContent>
  </Card>
  }
  return (
    (<Root>
      {checkoutPage ? (
        <OnlineCheckout
          selectedFoodItemListData={selectedFoodItemData}
          foodItemsArrayAdded={foodItemsArrayAdded}
          isPageLoaderFirst={false}
          deleteFoodItem={deleteFoodItem}
          payNow={payNow}
          submitOrder={createOrder}
          selectedFoodItemData={selectedFoodItemData}
          plusItemQty={plusItemQty}
          minusItemQty={minusItemQty}
          setCheckoutPageToggle={setCheckoutPage}
          subTotal={subTotal}
          finalBill={finalBill}
          discountValue={discountValue}
          discountSignValue={discountSignValue}
          taxValue={taxValue}
          thankYouPage={thankYouPage}
          checkoutFormValue={checkoutFormValue}
        />
      ) : (
        <>
          {pageLoaderFirst ? (
            <PageLoader />
          ) : (
            <Row className="order-detail-screen">
              <Col className="smart-rest-order-menu">
                <Box
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    marginLeft: "auto",
                  }}
                >
                  <SearchBarInput
                    onChange={(e) => setSearchFoodItem(e?.target?.value)}
                    placeholder="Search Food Item"
                    value={searchFoodItem}
                  />
                </Box>

                <AddEditOnlineOrder
                  foodItemsArrayAdded={foodItemsArrayAdded}
                  foodItemOptions={foodItems}
                  categoryData={categoryList}
                  plusItemQty={plusItemQty}
                  minusItemQty={minusItemQty}
                  searchFoodItem={searchFoodItem}
                  selectedFoodItemData={selectedFoodItemData}
                />
              </Col>
              <Button
                  variant="contained"
                  color="primary" 
                  size="large"
                  className="addcart-footer p-2 sf-addtocartbtn"
                  onClick={() => setCheckoutPage(true)}
                >
                  <div> {selectedFoodItemData?.length} items Added</div>
                  &nbsp; &nbsp;
                     &nbsp; &nbsp;
                    <ShoppingCartSharp/>
              </Button>
            </Row>
          )}
        </>
      )}
    </Root>)
  );
};

export default OnlineQR;

// @mui
import PropTypes from 'prop-types';
import { Button, Card, Typography, Stack } from '@mui/material';
// utils
import { fCurrency, fShortenNumber } from '../../../../utility/formatNumber';

// ----------------------------------------------------------------------

EcommerceCurrentBalance.propTypes = {
  title: PropTypes.string,
  currentBalance: PropTypes.number,
  sentAmount: PropTypes.number,
  sx: PropTypes.any,
};

export default function EcommerceCurrentBalance({ title, sentAmount, totalAmount, currentBalance, sx, ...other }) {

  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>

      <Stack spacing={2}>
        <Typography variant="h3">{fShortenNumber(currentBalance)}</Typography>



        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Total Revenue
          </Typography>
          <Typography variant="subtitle1">{fShortenNumber(totalAmount)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Total Expenses
          </Typography>
          <Typography variant="body2">- {fShortenNumber(sentAmount)}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Your Current Profit
          </Typography>
          <Typography variant="body2">{fShortenNumber(currentBalance)}</Typography>
        </Stack>
        <Stack direction="row" spacing={1.5}>

        </Stack>
        <Stack direction="row" spacing={1.5}>

        </Stack>
      </Stack>
    </Card>
  );
}

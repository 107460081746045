import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import { MenuItem } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { Form, Field } from "react-final-form";
import { useHistory } from "react-router-dom";

import { getExcitingOrderById } from "services/order.service";
import { updateOrder } from "services/order.service";

const PREFIX = 'AddEditOrderHistory';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  textField: `${PREFIX}-textField`
};

const StyledModal = styled(Modal)({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  [`& .${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
  },
  [`& .${classes.textField}`]: {
    width: 200,
  },
});

const AddEditOrderHistory = (props) => {
  const { getAllOrderHistoryList } = props;

  const history = useHistory();
  const orderHistoryId =
    props?.match?.params?.id ?? props?.addEditOrderHistoryId;
  const [initialOrderHistoryData, setInitialOrderHistoryData] = useState();
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const paymentTypeOption = useSelector((state) => state.api.paymentTypeData);

  const onSubmit = (values) => {
    setSubmittingBtnProcess(true);
    if (orderHistoryId > 0) {
      updateOrder(orderHistoryId, values)
        .then((res) => {
          if (res?.data) {
            setSubmittingBtnProcess(false);
            toast.success(res?.message);
            getAllOrderHistoryList();
            history.push("/app/history/order");
          } else {
            toast.success(res?.message);
          }
          props.onHide();
        })
        .catch((err) => {
          setSubmittingBtnProcess(false);
          toast.success(err?.message);
          props.onHide();
        });
    }
  };

  useEffect(() => {
    if (orderHistoryId > 0) {
      getExcitingOrderById(orderHistoryId).then((res) => {
        setInitialOrderHistoryData(res.data);
      });
    }
  }, [orderHistoryId]);

  return (
    <StyledModal
      show={props?.show}
      onHide={props?.onHide}
      size={props?.size ? props?.size : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title as={"h5"} id="contained-modal-title-vcenter">
          {props?.heading || "Edit"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {orderHistoryId > 0 && !initialOrderHistoryData ? (
          <p style={{ textAlign: "center" }}>
            <CircularProgress />
            <br></br>
            <b>Please wait. while we fetch your data.</b>
          </p>
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={initialOrderHistoryData}
            validate={(values) => {
              const errors = {};
              /*if (!values.name_of_customer) {
                errors.name_of_customer = "Name is required.";
              } else if (values.name_of_customer.length > 100) {
                errors.name_of_customer = "Maximum length should be less then 100.";
              }

              if (!values?.phone) {
                errors.phone = "Phone no is required.";
              } else if (isNaN(values?.phone)) {
                errors.phone = "Phone no must be a number";
              } else if (values.phone?.length !== 10) {
                errors.phone = "Phone no should have 10 digit.";
              }*/
              return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: 25 }}>
                  {/* <IconButton style={{ background: "", color: "#fff", height: 36, width: 36 }}
                            aria-label="back"
                            component="span"
                            onClick={() => history.push("/app/food-category")}
                          >
                            <ArrowBack />
                          </IconButton> */}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disabled={submitting}
                    style={{
                      background: "",
                      color: "#fff",
                      margin: "2%",
                    }}
                    startIcon={<SaveIcon />}
                  >
                    {submittingBtnProcess
                      ? "Processing.."
                      : orderHistoryId
                        ? "Update"
                        : "Add"}
                  </Button>
                </div>

                <Field name="name_of_customer">
                  {({ input, meta }) => (
                    <FormControl className={classes.formcontrolwidth}>
                      <TextField
                        {...input}
                        type="text"
                        variant="outlined"
                        label="Name"
                        error={meta.error && meta.touched ? true : false}
                      />
                      {meta.error && meta.touched && (
                        <span style={{ color: "red" }}>{meta.error}</span>
                      )}
                    </FormControl>
                  )}
                </Field>
                <br />
                <br />
                <Field name="phone">
                  {({ input, meta }) => (
                    <FormControl className={classes.formcontrolwidth}>
                      <TextField
                        {...input}
                        type="number"
                        variant="outlined"
                        label="Phone No"
                        error={((meta.touched || meta.visited) && meta.error) ? true : false}
                      />
                      {((meta.touched || meta.visited) && meta.error) && (
                        <span style={{ color: "red" }}>{meta.error}</span>
                      )}
                    </FormControl>
                  )}
                </Field>
                <br />
                <br />
                <Field name="bill_type">
                  {({ input, meta }) => (
                    <FormControl className={classes.formcontrolwidth}>
                      <TextField
                        {...input}
                        style={{ textAlign: "center" }}
                        variant="outlined"
                        select
                        label="Payment Type"
                        // SelectProps={{
                        //   native: true,
                        // }}
                      >
                        <MenuItem value={"ALL"}>ALL</MenuItem>
                        {
                          paymentTypeOption && paymentTypeOption?.length > 0 ?
                            paymentTypeOption?.map(data => <MenuItem value={data?.name}>{data?.name}</MenuItem>) 
                            :
                            <MenuItem value={""}>No options. Please Add</MenuItem>
                        }
                      </TextField>
                    </FormControl>
                  )}
                </Field>
              </form>
            )}
          />
        )}
      </Modal.Body>
    </StyledModal>
  );
};

export default AddEditOrderHistory;

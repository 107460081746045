import React, { useEffect, useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import { Form, Field } from "react-final-form";
import { toast } from "react-hot-toast";
import {
  Save as SaveIcon,
  Print,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,

} from "@mui/material";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { updateShopInfo, getShopInfo, updateParnerNotificationInfo } from "services/shop.service";
import { Col, Row, Container } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import { useInMobile } from "themes/Device";
import { useTranslation } from "react-i18next";
import UserQrPrints from "./UserQrPrints";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { DeskTopContainer } from "../../themes/Device";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import TextInputMui from "components/TextInputMui";
import { first } from "lodash";
import { isMobile } from "react-device-detect";

const PREFIX = 'ShopInfo';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  formcontrolwidth: `${PREFIX}-formcontrolwidth`,
  cardInfo: `${PREFIX}-cardInfo`,
  profileInfo: `${PREFIX}-profileInfo`,
  printIcon: `${PREFIX}-printIcon`,
  formcontrolwidth2: `${PREFIX}-formcontrolwidth2`,

};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Oxygen", sans-se\rif',
    marginBottom: "3px",
    textDecoration: "none",
  },

  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    [theme.breakpoints.down('lg')]: {
      margin: "5px",
      marginTop: "15px",
    },
  },

  [`& .${classes.cardInfo}`]: {
  },

  [`& .${classes.profileInfo}`]: {
    marginTop: "0px",
  },

  [`& .${classes.printIcon}`]: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  [`& .${classes.formcontrolwidth2}`]: {
    width: "100%",
    marginBottom: 10,
    marginTop: 10,
    maxWidth: 240,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
}));

export default function ShopInfo() {

  const theme = useTheme();
  const [initialShopData, setInitialShopData] = useState();
  const [initialSPartnerData, setinitialSPartnerData] = useState({
  });
  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  const refQRCODE = useRef();
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user.userData);
  const [msg2, setMsg2] = useState(true);
  const notification = useSelector((state) => state.api.notification);
  
  useEffect(() => {
    var object = {
      notification: []
    }
    if (notification) {
      if (notification?.emails?.length > 0) {
        for (let i in notification?.emails) {
          object.notification.push({
            sms: notification?.sms[i],
            email: notification?.emails[i],
            whatsapp: notification?.whatsapp[i],
          })
        }
      }
    }
    setinitialSPartnerData(object)
  }, [notification]);

  useEffect(() => {
    getShopInfo()
      .then((res) => {
        setInitialShopData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const onSubmit = async (values) => {
    setSubmittingBtnProcess(true);
    const object = {
      email: values?.email,
      name: values?.name,
      phone: values?.phone,
      email_notification: values.email_notification,
      email_notification_time: values.email_notification_time,
    };

    updateShopInfo(object)
      .then((res) => {
        if (res?.data) {
          setSubmittingBtnProcess(false);
          toast.success(t("shop-update-success-toast-message"));
        } else {
          setSubmittingBtnProcess(false);
          // toast.success(t("something-went-wrong-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        // toast.success(t("something-went-wrong-toast-message"));
      });
  };

  const onSubmitPartner = async (values) => {
    console.log("values", values)
    var object = {
      sms: [],
      emails: [],
      whatsapp: []
    }
    if (values && values?.notification?.length > 0) {
      for (let i in values?.notification) {
        object.sms.push(values?.notification[i]?.sms)
        object.emails.push(values?.notification[i]?.email)
        object.whatsapp.push(values?.notification[i]?.whatsapp)
      }
    }
    updateParnerNotificationInfo(object)
      .then((res) => {
        if (res?.data) {
          setSubmittingBtnProcess(false);
          toast.success(t("shop-update-success-toast-message"));
        } else {
          setSubmittingBtnProcess(false);
          // toast.success(t("something-went-wrong-toast-message"));
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        // toast.success(t("something-went-wrong-toast-message"));
      });
  };


  const downloadQR = () => {
    const qrCodeURL = document
      .getElementById("qrCodeE2")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <Root>
      {initialShopData === undefined ||
        initialShopData === null ||
        initialShopData === {} ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <br></br>
          <b>{t("data-fetch-message")}</b>
        </div>
      ) : (

          <>
            <Form
              onSubmit={onSubmit}
              initialValues={initialShopData}
              validate={(values) => {
                const errors = {};
                const emailRegex =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                // const checkerror = checkSpaceAndRegex(values?.name);
                // if (checkerror != "") errors.name = checkerror;

                if (!values.name?.trim()) {
                  errors.name = t("restaurant-validation-message");
                } else if (values?.name?.length > 50) {
                  errors.name = t("maximumlength-validation-message");
                }


                if (values?.email && values?.email?.length > 50) {
                  errors.email = t("maximumlength-validation-message");
                } else if (values?.email && !emailRegex.test(values?.email)) {
                  errors.email = t("email-invalid-validation-message");
                }

                if (!values?.phone) {
                  errors.phone = t("phone-number-error-message");
                } else if (isNaN(values?.phone)) {
                  errors.phone = t("phone-number-validation-message");
                } else if (values?.phone?.length !== 10) {
                  errors.phone = t("phone-number-digit-validation-message");
                }

                return errors;
              }}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Container style={{ padding: '0px' }}>
                    <Card className={classes.cardInfo}>
                      <CardHeader
                        style={{ backgroundColor: theme.palette.primary.main }}
                      >
                        <h4 className={classes.cardTitleWhite}>{t("shop-info-title")}</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={8}>
                            <GridContainer>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <FormControl className={classes.formcontrolwidth}>
                                    <TextField
                                      {...input}
                                      label={t("shop-owner-title")}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched ? true : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            </GridContainer>
                            <DeskTopContainer>
                              <br />
                            </DeskTopContainer>
                            <GridContainer>
                              <Field name="phone">
                                {({ input, meta }) => (
                                  <FormControl className={classes.formcontrolwidth}>
                                    <TextField
                                      {...input}
                                      label={t("phone-label")}
                                      variant="outlined"
                                      error={
                                        (meta.touched || meta.visited) && meta.error
                                          ? true
                                          : false
                                      }
                                    />
                                    {(meta.touched || meta.visited) && meta.error && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            </GridContainer>
                            <DeskTopContainer>
                              <br />
                            </DeskTopContainer>
                            <GridContainer>
                              <Field name="email">
                                {({ input, meta }) => (
                                  <FormControl className={classes.formcontrolwidth}>
                                    <TextField
                                      {...input}
                                      label={t("email")}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched ? true : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            </GridContainer>
                            <GridContainer>
                              <Field name="email_notification">
                                {({ input, meta }) => (
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          Boolean(input.value) ||
                                          Boolean(input.checked)
                                        }
                                        value={Boolean(input.value)}
                                        onChange={(event) => {
                                          input.onChange(event?.target?.checked);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                    label={t("send-email-notification-flag-label")}
                                  />
                                )}
                              </Field>
                              {values?.email_notification && (
                                <Field name="email_notification_time" type="number">
                                  {({ input, meta }) => (
                                    <FormControl className={classes.formcontrolwidth}>
                                      <TextField
                                        {...input}
                                        label={t("email-notification-time-label")}
                                        variant="outlined"
                                        disabled
                                        error={
                                          (meta.touched || meta.visited) && meta.error
                                            ? true
                                            : false
                                        }
                                      />
                                      {(meta.touched || meta.visited) &&
                                        meta.error && (
                                          <span style={{ color: "red" }}>
                                            {meta.error}
                                          </span>
                                        )}
                                    </FormControl>
                                  )}
                                </Field>
                              )}
                            </GridContainer>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <UserQrPrints
                              ref={refQRCODE}
                              shopId={values?.shop_id}
                              orderUrl={`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`}
                            />
                            <a
                              href={`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`}
                              target="_blank"
                              rel="noreferrer"
                            >{`https://${userInfo?.user?.username || localStorage.getItem('username')}.servofeat.com`}
                            </a>
                            <Row>
                              <Col xs={6} sm={6} md={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="mr-3"
                                  style={{
                                    background: "",
                                    color: "#fff",
                                  }}
                                  onClick={downloadQR}
                                >
                                  DOWNLOAD
                                </Button>
                              </Col>
                              <Col xs={6} sm={6} md={6}>
                                <ReactToPrint
                                  trigger={() => (
                                    <Button variant="contained" color="primary">
                                      <Print className={classes.printIcon} /> Print
                                    </Button>
                                  )}
                                  content={() => refQRCODE.current}
                                />
                              </Col>
                            </Row>
                            <UserQrPrints
                              ref={refQRCODE}
                              shopId={values?.shop_id}
                              orderUrl={`https://play.google.com/store/apps/details?id=com.app.servofeat&pli=1`}
                            />
                            <a
                              style={{ marginTop: -10 }}
                              href={`https://play.google.com/store/apps/details?id=com.app.servofeat&pli=1`}
                              target="_blank"
                              rel="noreferrer"
                            >{`Install Our Android App`}
                            </a>
                            <p
                              style={{ marginTop: 10 }}
                            >{`Installed Version:`}{isMobile ? userInfo?.user?.mobile_version : userInfo?.user?.electorn_version}
                            </p>
                            <p
                              style={{ marginTop: 10 }}
                            >{`Current User: `}{localStorage.getItem("username_admin")}
                            </p>
                          </GridItem>
                        </GridContainer>

                        <br />
                        <GridContainer style={{ justifyContent: "center" }}>
                          <RoleBasedGuard
                            resource={RESOURCE.PROFILE}
                            action={ACTION.UPDATE}
                          >
                            <Button
                              style={{
                                margin: "2%",
                                background: "",
                                color: "#fff",
                              }}
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              type="submit"
                              startIcon={<SaveIcon />}
                            >
                              {submittingBtnProcess ? t("processing") : t("save")}
                            </Button>
                          </RoleBasedGuard>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </Container>
                </form>
              )}
            />
            <Form
              onSubmit={onSubmitPartner}
              mutators={{
                ...arrayMutators,
              }}
              initialValues={initialSPartnerData}
              validate={(values) => {
                const errors = {};
                return errors;
              }}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, pop },
                  change,
                  reset,
                  restart,
                },
                submitting,
                pristine,
                values,
                errors,
              }) => (
                <Card className={classes.cardInfo}>
                  <CardHeader
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <h4 className={classes.cardTitleWhite}>Partner Notification Update</h4>
                  </CardHeader>
                  <CardBody>
                    <form
                      onSubmit={(event) => {
                        handleSubmit(event).then(() => {
                          // reset();
                          // restart();
                        });
                      }}
                    >
                      <div>
                        <div className="d-flex align-items-center justify-content-between flex-wrap foodcategorysticky">
                          <div>
                            <h4>Add new Partner</h4>
                            <p>Add New Partner Email and Mobile Number for Whatsapp and email Notification</p>
                          </div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (errors?.["notification"]) {
                                return;
                              }
                              push("notification", initialSPartnerData);
                            }}
                            className={classes.button}
                            type="button"
                            disabled={errors?.["notification"] ? true : false}
                            startIcon={<AddIcon />}
                          >
                            {t("add-button-label")}
                          </Button>
                        </div>
                        <hr />
                        <FieldArray name="notification">
                          {({ fields }) => (
                            <TableContainer className={classes.tableContainer}>
                              <Table
                                className={classes.table}
                                aria-label="Addons"
                                stickyHeader
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="justify">
                                      Name
                                    </TableCell>
                                    <TableCell align="justify">
                                      Email
                                    </TableCell>
                                    <TableCell align="justify">
                                      Mobile Number
                                    </TableCell>
                                    <TableCell align="justify">
                                      {t("delete")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {fields.map((name, index) => (
                                    <TableRow key={name}>
                                      {fields.length > 0
                                        ? setMsg2(false)
                                        : setMsg2(true)}
                                      <TableCell style={{ minWidth: 100 }}>
                                        <Field
                                          name={`${name}.sms`}
                                          validate={(value, allValues, meta) => {
                                            if (!value?.trim()) {
                                              return "Name is required";
                                            } else if (value?.length > 50) {
                                              return "Name must be less then 50 character.";
                                            }
                                            else {
                                              return undefined;
                                            }
                                          }}
                                        >
                                          {({ input, meta }) => (
                                            <FormControl
                                              className={classes.formcontrolwidth2}
                                            >
                                              <TextInputMui
                                                {...input}
                                                type="text"
                                                variant="outlined"
                                                placeholder={t("name-label")}
                                                error={
                                                  meta.error && meta.touched
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {meta.error && meta.touched && (
                                                <span style={{ color: "red" }}>
                                                  {meta.error}
                                                </span>
                                              )}
                                            </FormControl>
                                          )}
                                        </Field>
                                      </TableCell>
                                      <TableCell style={{ minWidth: 100 }}>
                                        <Field
                                          name={`${name}.email`}
                                          validate={(value, allValues, meta) => {
                                            const errors = {};
                                            const emailRegex =
                                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                            if (!value) {
                                              return "Email is required.";
                                            } else if (value && !emailRegex.test(value)) {
                                              return "Enter Valid Email.";
                                            }
                                            else {
                                              return undefined;
                                            }
                                          }}
                                        >
                                          {({ input, meta }) => (
                                            <FormControl
                                              className={classes.formcontrolwidth2}
                                            >
                                              <TextInputMui
                                                {...input}
                                                type="text"
                                                variant="outlined"
                                                placeholder={"Email"}
                                                error={
                                                  meta.error && meta.touched
                                                    ? true
                                                    : false
                                                }
                                              />
                                              {meta.error && meta.touched && (
                                                <span style={{ color: "red" }}>
                                                  {meta.error}
                                                </span>
                                              )}
                                            </FormControl>
                                          )}
                                        </Field>
                                      </TableCell>
                                      <TableCell style={{ minWidth: 100 }}>
                                        <Field
                                          name={`${name}.whatsapp`}
                                          validate={(value, allValues, meta) => {
                                            if (!value) {
                                              return "Mobile Number is required.";
                                            }
                                            else if (isNaN(value)) {
                                              return t("phone-number-validation-message");
                                            } else if (value?.length !== 10) {
                                              return t("phone-number-digit-validation-message");
                                            }
                                            else {
                                              return undefined;
                                            }
                                          }}
                                        >
                                          {({ input, meta }) => (
                                            <FormControl
                                              className={classes.formcontrolwidth2}
                                            >
                                              <TextInputMui
                                                {...input}
                                                type="number"
                                                variant="outlined"
                                                placeholder={"Mobile No"}
                                                error={
                                                  meta.error && meta.touched
                                                    ? true
                                                    : false
                                                }
                                              />

                                              {meta.error && meta.touched && (
                                                <span style={{ color: "red" }}>
                                                  {meta.error}
                                                </span>
                                              )}
                                            </FormControl>
                                          )}
                                        </Field>
                                      </TableCell>
                                      <TableCell align="center">
                                        <IconButton
                                          color="secondary"
                                          title={t("cancel-add-on")}
                                          aria-label={t("cancel-add-on")}
                                          onClick={() => {
                                            fields.remove(index);
                                            setMsg2(true);
                                          }}
                                          size="large">
                                          <DeleteIcon />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </FieldArray>
                        {msg2 && (
                          <div className={classes.centerText}>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>
                              Press Above add button to add new entry
                            </p>
                          </div>
                        )}
                        <div className={classes.btnSection}>
                          <div className="d-flex">
                            <div className="ml-2">
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={submitting}
                                startIcon={<SaveIcon />}
                              >
                                {submittingBtnProcess ? t("processing") : t("save")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </CardBody>
                </Card>
              )}
            />
          </>
      )}
    </Root>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import {
  faBookOpen,
  faBoxOpen,
  faUserTimes,
  faMoneyCheck,
  faMoneyCheckAlt,
  faFolderPlus,
  faCloudMoon,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Home as HomeIcon,
  Deck,
  People,
  ViewList,
  History,
  Kitchen,
  Assessment,
  Category,
  NoteAdd,
  // Email,
  // Sms,
  Group,
  Print,
  ShoppingBasket,
  QueuePlayNext,
} from "@mui/icons-material";
import { RESOURCE } from "common/constant.js";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

const links = () => {
  return [
    {
      id: 1,
      label: t("manage-order-route-title"),
      link: "/app/food-category",
      icon: <ViewList />,
      isSidebar: true,
      children: [
        {
          label: t("sales-order-route-title"),
          link: "/app/order/new",
          icon: <Category />,
          resourceName: RESOURCE.DASHBOARD,
        },
        {
          label: t("sales-history-route-title"),
          link: "/app/history/order",
          icon: <History />,
          resourceName: RESOURCE.DASHBOARD,
        },
      ],
    },
    {
      id: 2,
      label: t("basic-setup-route-title"),
      icon: <People />,
      isSidebar: true,
      children: [
        {
          label: t("dining-table-list-title"),
          link: "/app/dining-table",
          icon: <Deck />,
          resourceName: RESOURCE.DINE_IN_TABLE,
        },
        {
          label: t("food-category-title"),
          link: "/app/food-category",
          icon: <Category />,
          resourceName: RESOURCE.FOOD_CATEGORY,
        },
        {
          label: t("food-menu-title"),
          link: "/app/food-menu",
          icon: <FontAwesomeIcon icon={faBookOpen} />,
          resourceName: RESOURCE.FOOD_MENUS,
        }
      ],
    },
    {
      id: 3,
      label: t("additional-settings-route-title"),
      isSidebar: true,
      children: [
        {
          label: t("analytics-title"),
          link: "/app/analytics",
          icon: <HomeIcon />,
          resourceName: RESOURCE.ANALYTICS,
        },
        {
          label: t("sales-report-title-mobile-screen"),
          link: "/app/report",
          icon: <Assessment />,
          resourceName: RESOURCE.SALES_REPORT,
        },
        {
          label: t("customer-screen-title"),
          link: "/app/customers",
          icon: <Group />,
          resourceName: RESOURCE.CUSTOMERS,
        },
        {
          label: t("staff-screen-title"),
          link: "/app/staff",
          icon: <People />,
          resourceName: RESOURCE.STAFF_MEMBERS,
        },
        {
          label: t("customer-queue-title"),
          link: "/app/customer-queue",
          icon: <FontAwesomeIcon icon={faUserTimes} />,
          resourceName: RESOURCE.DASHBOARD,
        },
        {
          label: "Order Type",
          link: "/app/ordertype",
          icon: <FontAwesomeIcon icon={faBookOpen} />,
          resourceName: RESOURCE.ORDER_TYPE,
        },
        {
          label: "Payment Type",
          link: "/app/paymenttype",
          icon: <FontAwesomeIcon icon={faMoneyCheck} />,
          resourceName: RESOURCE.PAYMENT_TYPE,
        },
        {
          label: "Coupons",
          link: "/app/coupons",
          icon: <FontAwesomeIcon icon={faCloudMoon} />,
          resourceName: RESOURCE.COUPONS,
        }
      ],
    },
    {
      id: 4,
      label: t("expense-inventory-route-title"),
      isSidebar: true,
      children: [
        {
          label: t("expense-category-title"),
          link: "/app/expense-category",
          icon: <FontAwesomeIcon icon={faMoneyCheckAlt} />,
          resourceName: RESOURCE.EXPENSECATEGORY,
        },
        {
          label: t("route-expenses"),
          link: "/app/expense",
          icon: <FontAwesomeIcon icon={faMoneyCheck} />,
          resourceName: RESOURCE.EXPENSE,
        },
        {
          label: t("inventory-category-title"),
          link: "/app/inventory-category",
          icon: <Category />,
          resourceName: RESOURCE.INVENTORYCATEGORY,
        },
        {
          label: t("route-inventories"),
          link: "/app/inventory",
          icon: <FontAwesomeIcon icon={faBoxOpen} />,
          resourceName: RESOURCE.INVENTORY,
        },
      ],
    },
    {
      id: 4,
      label: t("table-view-route-title"),
      // icon: <Deck style={{ fontSize: "22px"}} />,
      link: "/app/dine-in",
      serviceName: "DINE_IN_SERVICE",
      resourceName: RESOURCE.DINE_IN_TABLE,
    },
    {
      id: 5,
      // icon: <ShoppingBasket style={{ fontSize: "22px"  }} />,
      label: t("new-order-route-title"),
      link: "/app/order/new",
      serviceName: "NEW_ORDER",
      resourceName: RESOURCE.PARCEL_ORDER,
    },
    {
      id: 6,
      // icon: <QueuePlayNext style={{ fontSize:  "22px" }} />,
      label: t("kitchen-order-route-title"),
      link: "/app/kitchen",
      // key: "KITCHAN_SERVICE",
      serviceName: "KITCHAN_SERVICE",
      resourceName: RESOURCE.KITCHEN_QUEUE,
    },
    // { label: "Sales History", link: "/app/history/order", icon: <History />, resourceName: RESOURCE.DASHBOARD },
    {
      id: 7,
      label: t("sales-history-route-title"),
      link: "/app/history/order",
      resourceName: RESOURCE.DASHBOARD,
    },
  ];
};

const RouteLinks = () => {
  const structure = links();
  const { t } = useTranslation();
  if (!isMobile) {
    structure[1].children.push(
      {
        label: t("bulk-upload"),
        link: "/app/bulk-upload",
        icon: <FontAwesomeIcon icon={faFileCsv} />,
        resourceName: RESOURCE.BULK_UPLOAD,
      })
  }
 
  return { structure, t };
};

export default RouteLinks;

import { useQuery } from "react-query";
import { API_ENDPOINTS } from "utility/endpoints";
import { api } from "utility/interceptor";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const fetchCoupons = async ({ }) => {
    const url = `${API_ENDPOINTS.COUPONS}`;
    const { data } = await api.get(url);
    return data?.data;
};
const useCoupons = (options = {}) => {
    return useQuery([API_ENDPOINTS.COUPONS, options], fetchCoupons, {
        keepPreviousData: true,
    });
};
const fetchCouponsQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const { data } = await api.get(`${API_ENDPOINTS.COUPONS}/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useCouponsQuery = (id) => {
    return useQuery([API_ENDPOINTS.COUPONS, id], () => fetchCouponsQuery(id));
};
const useDeleteCouponsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.COUPONS}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COUPONS);
            },
        }
    );
};
const useUpdateCouponsClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.patch(`${API_ENDPOINTS.COUPONS}/${id}`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COUPONS);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateCouponsClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.COUPONS, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COUPONS);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useBulkDeleteCouponsClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.post(`${API_ENDPOINTS.COUPONS}/remove_bulk`, input),
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COUPONS);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useCoupons,
    useCouponsQuery,
    useCreateCouponsClassMutation,
    useUpdateCouponsClassMutation,
    useDeleteCouponsMutation,
    useBulkDeleteCouponsClassMutation
};

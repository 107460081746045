import React, { useEffect, useState } from "react";
import { withRouter,  Route, Switch, Redirect } from "react-router-dom";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { NotificationImportant } from "@mui/icons-material";
import addNotification from 'react-push-notification';
import {
  Stack
} from '@mui/material';

// components
import OnlineLayoutHeader from "../Header/OnlineLayoutHeader";
// context
import '@fortawesome/fontawesome-svg-core/styles.css'
import useStyles from "./styles";
import NotificationOne from "components/Notification/NotificationOne";

import OnlineQR from "pages/OnlineOrder/OnlineQR";


function OnlineLayout(props) {
  var { classes } = useStyles();
  const [open, setOpen] = React.useState(false);


  return (
    <div className={classes.root}>
      <NotificationOne
       open= {open}
       setOpen ={setOpen}
       />
      <OnlineLayoutHeader history={props?.history} className={classes.header} />
        {/* Online Offline Notification ON ELECTRON */}
        <Alert variant="warning" className="online-offline-alert">
          <NotificationImportant /> <span>
            You are offline. Your data can't be saved and may not be up to date. Until you're back online.
          </span>
        </Alert>
            <div  className={classnames(classes.onlineContent)}>
                <Switch>
                <Route exact path="/" render={() => <Redirect to="/parcel" />} />
                <Route
                    path="/online-order/dine-in/:id"
                    component={OnlineQR}
                    exact
                    />
                    <Route path="/online-order/parcel" component={OnlineQR} exact />
                </Switch>
            </div>
    </div>
  );
}

export default withRouter(OnlineLayout);

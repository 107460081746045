import { api } from "./../utility/interceptor";

var config = {
  headers: { "Access-Control-Allow-Origin": "*" },
};

export const updateShopInfo = async (storeData) => {
  try {
    let res = await api.post(`/shop`, storeData);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
};


export const getShopInfo = async () => {
  try {
    let res = await api.get(`/shop`, config);
    return { status: res?.status, data: res?.data?.data || [] };
  } catch (err) {
    return { status: err?.response?.status, data: err?.response };
  }
};

export const refreshShopToken = async (data) => {
  let res = await api.post('/auth/referesh_token', data).catch((err) => {
    return err
  });
  return res.data;
}
export const updateParnerNotificationInfo = async (storeData) => {
  try {
    let res = await api.post(`/user/updatePartner`, storeData);
    return { status: res.status, data: res.data };
  } catch (error) {
    return {
      status: error?.response?.status,
      message: error?.response?.data?.message,
    };
  }
}
import React, {useEffect, useState} from 'react';
import { App } from '@capacitor/app';
import { Button } from '@mui/material';

export default function AboutApp() {
  const [appInfoState, setAppInfoState] = useState(null);

    useEffect(() => {
        getAppInfo();
    }, [])

    const getAppInfo = async () => {
        try {
            const appInfoData = await App.getInfo();
            localStorage.setItem("androidAppInfo", appInfoData);
            console.log('%cAboutApp.js line:13 appInfoData', 'color: #007acc;', appInfoData);
            setAppInfoState(appInfoData);
        } catch (error) {
            alert(JSON.stringify(error));
        }
     
    }
    
  return (
    <div style={{ padding: '24px'}}>
        <h5>App Info</h5>
        <p>App Name: {appInfoState?.name} </p>
        <p>Build Version (Version Code): {appInfoState?.build}</p>
        <p>Version Name:  {appInfoState?.version}</p>
        <br></br>
        <a href='https://play.google.com/store/apps/details?id=com.app.servofeat' target={"_blank"}>
        <Button
            color='primary'
            variant="contained">Check For Updates</Button>
            </a>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import { Container } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";
import DeckIcon from "@mui/icons-material/Deck";
import { useHistory } from "react-router-dom";
import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import { ServiceEnabled } from "utility/helpers";
import { useSelector } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "context/LayoutContext";
import { AddShoppingCart, AddShoppingCartRounded, AddShoppingCartTwoTone, Analytics, Ballot, Widgets } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const PREFIX = 'MobileFooterComp';

const classes = {
  root: `${PREFIX}-root`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
  tabname: `${PREFIX}-tabname`,
  footerbtn: `${PREFIX}-footerbtn`,
  buttondiv: `${PREFIX}-buttondiv`,
  buttonicon: `${PREFIX}-buttonicon`
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    backgroundColor: "#ffffff",
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px -5px 19px rgba(80, 17, 0, 0.12)",
    borderRadius: "20px 20px 0px 0px",
    position: "absolute",
    bottom: 0,
    zIndex: 999,
    overflow: 'auto',
    whiteSpace: 'nowrap'
  },
  [`& .${classes.bullet}`]: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  [`& .${classes.title}`]: {
    fontSize: 14,
  },
  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },
  [`& .${classes.tabname}`]: {
    fontSize: 12,
    fontWeight: "400",
    color: "#8e8e8e",
  },
  [`& .${classes.footerbtn}`]: {
    textTransform: "capitalize",
    display: 'inline-block',
    textAlign: 'center'
  },
  [`& .${classes.buttondiv}`]: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.buttonicon}`]: {
    color: "#8e8e8e",
    fontSize: "2rem"
  },
});

const isActiveButton = (buttonType) => {};


export default function MobileFooterComp() {

  const { t } = useTranslation();
  var layoutDispatch = useLayoutDispatch();
  var layoutStateContext = useLayoutState();
  const theme = useTheme();
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [isDinInService, setIsDinInService] = useState(false);

  useEffect(() => {
    if (shopSettingListData?.DINE_IN_SERVICE) {
      setIsDinInService(true);
    } else {
      setIsDinInService(false);
    }
  }, [shopSettingListData]);

  let history = useHistory();
  const [activeButton, setActiveButton] = useState({
    DINEIN: false,
    ORDER: false,
    HISTORY: false,
    DASHBOARD: false,
    MENUBUTTON: false,
    KITCHEN: false,
  });

  return (
    <Root>
      <Container className={classes.root}>
          <Button
            className={classes.footerbtn}
            onClick={() => {
              setActiveButton({ MENUBUTTON: true });
              if(!layoutStateContext.isSidebarOpened) {
                toggleSidebar(layoutDispatch)
              }
            }}
          >
            <div className={classes.buttondiv}>
              <Widgets
                className={classes.buttonicon}
                style={{
                  color: activeButton?.MENUBUTTON
                    ? theme.palette.primary.main
                    : "",
                }}
              />
              <Typography
                className={classes.tabname}
                style={{
                  color: activeButton?.MENUBUTTON
                    ? theme.palette.primary.main
                    : "",
                }}
              >
                {"Menu"}
              </Typography>
            </div>
          </Button>
       
        {
          shopSettingListData?.customer_tabs?.length > 0 ?  shopSettingListData?.customer_tabs?.map(data => {
              return <>
                        <Button
                          className={classes.footerbtn}
                          onClick={() => {
                            setActiveButton({[data?.key]: true });
                            history.push(data.routeName);
                          }}
                        >
                          <div className={classes.buttondiv}>
                            <Ballot
                              className={classes.buttonicon}
                              style={{
                                color: activeButton?.[data?.key]
                                  ? theme.palette.primary.main
                                  : "",
                              }}
                            />
                            <Typography
                              className={classes.tabname}
                              style={{
                                color: activeButton?.[data?.key]
                                  ? theme.palette.primary.main
                                  : "",
                              }}
                            >
                              {data?.value}
                            </Typography>
                          </div>
                        </Button>
                  </>
          })
          :
          <>
          <RoleBasedGuard resource={RESOURCE.DINE_IN_TABLE} action={ACTION.VIEW}>
            <ServiceEnabled
              envVariableList={shopSettingListData}
              serviceName={"DINE_IN_SERVICE"}
            >
              <Button
                className={classes.footerbtn}
                onClick={() => {
                  setActiveButton({ DINEIN: true });
                  history.push("/app/dine-in");
                }}
              >
                <div className={classes.buttondiv}>
                  <DeckIcon
                    className={classes.buttonicon}
                    style={{
                      color: activeButton?.DINEIN
                        ? theme.palette.primary.main
                        : "",
                    }}
                  />
                  <Typography
                    className={classes.tabname}
                    style={{
                      color: activeButton?.DINEIN
                        ? theme.palette.primary.main
                        : "",
                    }}
                  >
                    {'Dine In'}
                  </Typography>
                </div>
              </Button>
            </ServiceEnabled>
            </RoleBasedGuard>
            {isDinInService === false ? (
            <RoleBasedGuard resource={RESOURCE.PARCEL_ORDER} action={ACTION.ADD}>
              <Button
                className={classes.footerbtn}
                onClick={() => {
                  setActiveButton({ ORDER: true });
                  history.push("/app/order/new");
                }}
              >
                <div className={classes.buttondiv}>
                  <AddShoppingCartSharpIcon
                  className={classes.buttonicon}
                    style={{
                      color: activeButton?.ORDER
                        ? theme.palette.primary.main
                        : "",
                    }}
                  />
                  <Typography
                    className={classes.tabname}
                    style={{
                      color: activeButton?.ORDER
                        ? theme.palette.primary.main
                        : "",
                    }}
                  >
                    Order
                  </Typography>
                </div>
              </Button>
            </RoleBasedGuard>
            ) : (
            <RoleBasedGuard resource={RESOURCE.PARCEL_ORDER} action={ACTION.ADD}>
              <Button
                className={classes.footerbtn}
                onClick={() => {
                  setActiveButton({ ORDER: true });
                  history.push("/app/order/new");
                }}
              >
                  <div className={classes.buttondiv}>
                  <FontAwesomeIcon 
                  className={classes.buttonicon}
                  style={{
                    fontSize: '25px',
                    padding:'4px',
                    color: activeButton?.ORDER ? theme.palette.primary.main : "",
                  }}
                  icon={faBoxOpen} />
                  <Typography
                    className={classes.tabname}
                    style={{
                      color: activeButton?.ORDER
                        ? theme.palette.primary.main
                        : "",
                    }}
                  >
                    Parcel/New
                  </Typography>
                  </div>
              </Button>
            </RoleBasedGuard>
            )}
            <RoleBasedGuard resource={RESOURCE.SALES} action={ACTION.VIEW}>
            <Button
              className={classes.footerbtn}
              onClick={() => {
                setActiveButton({ HISTORY: true });
                history.push("/app/history/order");
              }}
            >
              <div className={classes.buttondiv}>
                <AccessTimeIcon
                  className={classes.buttonicon}
                  style={{
                    color: activeButton?.HISTORY
                      ? theme.palette.primary.main
                      : "",
                  }}
                />
                <Typography
                  className={classes.tabname}
                  style={{
                    color: activeButton?.HISTORY
                      ? theme.palette.primary.main
                      : "",
                  }}
                >
                  {t("history-title")}
                </Typography>
              </div>
            </Button>
            </RoleBasedGuard>
            </>
        }
      </Container>
    </Root>
  );
}
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(num) {
  let input = num;
  let n1, n2;
  num = num + '' || '';
  // works for integer and floating as well
  n1 = num.split('.');
  n2 = n1[1] || null;
  n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  num = n2 ? n1 : n1;
  //  +'.' + n2 : n1;
  return num;
  // return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function containsOnlyNumbers(str) {
  return /^\d+$/.test(str);
}
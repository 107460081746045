import { Close, Search } from '@mui/icons-material';
import React from 'react';

import './SearchBarInput.styles.css';

const SearchBarInput = (props) => {
  const [searchVal, setSearchVal] = React.useState('');
  const {
    placeholder="Search Products",
    clearTxt,
    className
  }= props;
   
  const handleInput = (e) => {
    setSearchVal(e.target.value);
  }
  
  const handleClearBtn = () => {
    setSearchVal('');
    clearTxt();
  }
  
  return (
      <div className={`input-wrap  ${className}`}>
        {/* <i className="fas fa-search"></i> */}
        <Search color='' />
        <label 
          for="product-search" 
          id="input-label"
        >
          Product Search
        </label>
        <input 
          onChange={props?.onChange}
          value={props?.value}
          type="text" 
          name="product-search" 
          id="product-search" 
          placeholder={placeholder}
        />
        {/* <i 
          onClick={handleClearBtn}
          className="fas fa-times"
        ></i> */}
        <Close onClick={handleClearBtn} color=''/>
      </div>
  );
};

export default SearchBarInput;
import { api } from "./../../utility/interceptor";
import {
  setCategoryList,
  setFoodMealList,
  setDiningTableList,
  setDiningTableLoader,
  setKitchenQueue,
  setKitchenQueueLoaded,
  setBillingInfo,
  setShopInfo,
  setPartnerUpdateInfo,
  setOrderHistoryData,
  setPaymentTypeData,
  setCustomerList
} from "../actions/api.action";
import { API_ENDPOINTS } from "utility/endpoints";
import { setCustomerFoodMenuList } from "state/actions/customer.types";
import { setShopSettingData, setShopThemeSettingData } from "state/actions/shopSetting.types";
import { toast } from "react-hot-toast";
import { setShopSettingInLocalStorage } from "utility/helpers";

export function fetchCategoryList(searchParam) {
  return function (dispatch) {
    return api
      .get(`/category`,
      { 
        params: {
          type: searchParam?.type ? searchParam?.type : 'DESC',
          search: searchParam?.search ? searchParam?.search : '',
          page: searchParam?.page ? searchParam?.page : 1,
          limit: searchParam?.limit ? searchParam?.limit : 20,
          column: searchParam?.column ? searchParam?.column :  'id'
         }
      })
      .then(({ data }) => {
        dispatch(setCategoryList(data || []));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchFoodMealList(searchParam) {
  return function (dispatch) {
    return api
      .get(`/food-item`,  { 
          params: {
            type: searchParam?.type ? searchParam?.type : 'DESC',
            search: searchParam?.search ? searchParam?.search : '',
            page: searchParam?.page ? searchParam?.page : 1,
            limit: searchParam?.limit ? searchParam?.limit : 20,
            column: searchParam?.column ? searchParam?.column :  'id'
          }
        })
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          const allMeals = data.data;
          const filteredMealList = allMeals.map((meal) => {
            if (meal?.category?.food_item_addons.length > 0) {
              meal.food_item_addons = [
                ...meal?.food_item_addons,
                ...meal?.category?.food_item_addons,
              ];
            }
            return meal;
          });
          dispatch(setFoodMealList({
            data: filteredMealList,
            pager: data?.pager
            }));
        } else {
          dispatch(setFoodMealList([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchDiningTable() {
  return function (dispatch) {
    return api
      .get(`/dining-table`)
      .then(({ data }) => {
        dispatch(setDiningTableList(data?.data || []));
        dispatch(setDiningTableLoader(false));
      })
      .catch((err) => {
        dispatch(setDiningTableLoader(false));
      });
  };
}

export function fetchKitchenQueue(req) {
  return function (dispatch) {
    return api
      .post(`/kitchan`, req)
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          const allFoodMeals = data.data;
          const filteredOrderMealList = allFoodMeals.map((meal) => {
            if (meal?.order_meal_item?.addons) {
              meal.order_meal_item.addons = JSON.parse(
                meal.order_meal_item?.addons,
              );
            } else if (meal?.order_meal_item) {
              meal.order_meal_item.addons = [];
            }
            return meal;
          });
          dispatch(setKitchenQueue(filteredOrderMealList));
        } else {
          dispatch(setKitchenQueue([]));
        }
        dispatch(setKitchenQueueLoaded(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchBillingInfo(req) {
  return function (dispatch) {
    return api
      .get(`/bill-print`, req)
      .then(({ data }) => {
        dispatch(setBillingInfo(data?.data || []));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchShopInfo(req) {
  return function (dispatch) {
    return api
      .get(`/shop`, req)
      .then(({ data }) => {
        dispatch(setShopInfo(data?.data || {}));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchPartnerUpdateInfo(req) {
  return function (dispatch) {
    return api
      .get(`/user/getPartner`, req)
      .then(({ data }) => {
        console.log("dta", data)
        dispatch(setPartnerUpdateInfo(data?.data || {}));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function fetchShopSettingInfo(req) {
  return function (dispatch) {
    return api
      .get(`/settingnew`, req)
      .then(({ data }) => {
        dispatch(setShopSettingData(data?.data || {}));
        if(data?.data)
        {
            Object.keys(data?.data).forEach((element) => {
              if (element == "STORE_LANGUAGE" ||
                element == "PRINT_ON_SETTLE" ||
                element == "IS_ORDER_SUMMERY_SHOW_ON_SETTLE" ||
                element == "PRINTER_NAME" ||
                element == "PRINTER_SIZE" ||
                element == "KITCHEN_IP" ||
                element == "PRINTER_TYPE" ||
                element == "PRINTER_END_SPACE" ||
                element == "BILL_IP" ||
                element == "KITCHEN_PRINTER_SIZE" ||
                element == "SAME_PRINTER_KOT_PRINT_ENABLED" ||
                element == "printing_setting" ||
                element == "mobile_printing_setting" ||
                element == "KITCHEN_PRINTER_NAME") {
                  if(element == "printing_setting" || element == "mobile_printing_setting")
                  {
                    setShopSettingInLocalStorage(element, JSON.stringify(data?.data[element] || ""));
                  }
                  else {
                    setShopSettingInLocalStorage(element, data?.data[element] || "");
                  }
              }
            });
        }
        else {
          toast.error("Setting Data is not loaded properly")
        }
       
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
}

export function fetchShopDesign(req) {
  return function (dispatch) {
    return api
      .get(`/design`, req)
      .then(({ data }) => {
        dispatch(setShopThemeSettingData((data?.data[0])));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
}

export function fetchOrderHistoryList(orderDateRange) {
  return function (dispatch) {
    return api
      .post(`/order/history`, orderDateRange)
      .then(({ data }) => {
        if (data.data && data.data.length > 0) {
          dispatch(setOrderHistoryData(data));
        } else {
          dispatch(setOrderHistoryData([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function fetchPaymentType() {
    return function (dispatch) {
      return api
        .get(API_ENDPOINTS.PAYMENT_TYPE)
        .then(({ data }) => {
          if (data?.data && data?.data?.length > 0) {
            dispatch(setPaymentTypeData(data?.data));
          } else {
            dispatch(setPaymentTypeData([
                {
                  is_active: true,
                  name: "CASH",
                },
                {
                  is_active: true,
                  name: "CARD_SWAP",
                },
                {
                  is_active: true,
                  name: "UPI"
                }
            ]));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
}
// CUSTOMER SIDE QR CODE APIS
export function fetchQRCodeFoodMenuList(id) {
  return function (dispatch) {
    return api
      .get(`/public/customer/${id}`)
      .then(({ data }) => {
        if (data.data && data.data.foodItems && data.data.category) {
          const allMeals = data?.data?.foodItems;
          const filteredMealList = allMeals.map((meal) => {
            if (meal?.category?.food_item_addons.length > 0) {
              meal.food_item_addons = [
                ...meal?.food_item_addons,
                ...meal?.category?.food_item_addons,
              ];
            }
            return meal;
          });
          dispatch(
            setCustomerFoodMenuList({
              ...data?.data,
              foodItems: filteredMealList,
            }),
          );
        } else {
          dispatch(setCustomerFoodMenuList([]));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function fetchAllCustomer(searchParam) {
  
  return function (dispatch) {
    return api.get(`/customer`, {
      params: {
        type: searchParam?.type || 'DESC',
        search:  searchParam?.search || '',
        page: searchParam?.page || 1,
        limit: searchParam?.limit || 10,
        column: searchParam?.column || 'id'
      }
    })
      .then(({ data }) => {
        dispatch(setCustomerList(data?.data || []));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Stack,
} from "@mui/material";
import { fetchOrderHistoryList, fetchDiningTable } from "state/thunk-async";
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import MenuPopover from "../../../../components/MenuPopover";
import { IconButtonAnimate } from "../../../../components/animate";
import Label from "components/Label";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 90;

export const _notifications = [...Array(10)].map((_, index) => ({
  id: index,
  title: [
    "Your order is placed",
    "Sylvan King",
    "You have new message",
    "You have new mail",
    "Delivery processing",
    "You have new mail",
    "Delivery processing",
  ][index],
  description: [
    "waiting for shipping",
    "answered to your comment on the Minimal",
    "5 unread messages",
    "sent from Guido Padberg",
    "Your order is being shipped",
    "sent from Guido Padberg",
    "Your order is being shipped",
  ][index],
  avatar: [null, null, null, null, null, null, null][index],
  type: [
    "order_placed",
    "friend_interactive",
    "chat_message",
    "mail",
    "order_shipped",
    "mail",
    "order_shipped",
  ][index],
  // createdAt: new Date(),
  isUnRead: [true, true, false, false, false, false, false][index],
}));

export default function NotificationsPopover(props) {
  const { orderHistoryDataList } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(_notifications);
  const startDate = moment().format("YYYY-MM-DD");
  const endDate = moment().add(1, "days").format("YYYY-MM-DD");
  const orderDateBody = {
    startDate: moment(startDate).format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
    endDate: moment(endDate).format("YYYY-MM-DD"), // "startDate": "YYYY-MM-DD"
  };
  const totalUnRead = orderHistoryDataList?.filter((item) => item?.is_online && item?.is_online_accepted === "CREATED").length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const acceptOnlineOrder = (orderID) => {
    try {
      onlineOrderAccepted(orderID)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          dispatch(fetchDiningTable());
          dispatch(fetchOrderHistoryList(orderDateBody));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error?.message);
    }
  };

  const declineOnlineOrder = (orderID) => {
    try {
      onlineOrderDecline(orderID)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
          dispatch(fetchOrderHistoryList(orderDateBody));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      })),
    );
  };

  return <>
    <IconButtonAnimate
      color={open ? "primary" : "default"}
      onClick={handleOpen}
      sx={{ width: 40, height: 40 }}
    >
      <Badge badgeContent={totalUnRead} color="error">
        <Iconify icon="eva:bell-fill" width={20} height={20} />
      </Badge>
    </IconButtonAnimate>

    <MenuPopover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
      sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
    >
      <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">
            {t("notifications-label")}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {totalUnRead > 0
              ? `${t("notification-message")} ${totalUnRead} ${t(
                  "notification-un-accepted-order-message",
                )}`
              : t("notification-no-order-message")}
          </Typography>
        </Box>

        {totalUnRead > 0 && (
          <Tooltip title=" Mark all as read">
            <IconButton color="primary" onClick={handleMarkAllAsRead} size="large">
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Scrollbar sx={{ maxHeight: ITEM_HEIGHT * 6 }}>
        <List
          disablePadding
          subheader={
            <ListSubheader
              disableSticky
              sx={{ py: 1, px: 2.5, typography: "overline" }}
            >
              {t("notification-new-section-label")}
            </ListSubheader>
          }
        >

          {orderHistoryDataList?.length > 0 && orderHistoryDataList?.filter(data => data?.is_online && data?.is_online_accepted === "CREATED").map((orderData) => (
              <> <NotificationItem key={orderData?.id} 
                 orderData={orderData} 
                 acceptOnlineOrder={acceptOnlineOrder}
                 declineOnlineOrder = {declineOnlineOrder}/>
              </>
           
          ))}
        </List>

        {/* <List
          disablePadding
          subheader={
            <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              Before that
            </ListSubheader>
          }
        >
          {notifications.slice(2, 5).map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </List> */}
      </Scrollbar>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Box sx={{ p: 1 }}>
        <Button fullWidth disableRipple>
          {t("notification-all-label")}
        </Button>
      </Box>
    </MenuPopover>
  </>;
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    // createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

export function NotificationItem({
  notification,
  orderData,
  acceptOnlineOrder,
  declineOnlineOrder,
}) {
  // const { avatar, title } = renderContent(notification);
  const { avatar, title } = RenderContent(orderData);
  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
          // ...(notification.isUnRead && {
          //   bgcolor: 'action.selected',
          // }),
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Stack direction={"column"}>
                <Stack direction={"row"} gap={1}>
                  <Iconify
                    icon="eva:clock-outline"
                    sx={{ mr: 0.5, width: 16, height: 16 }}
                  />
                  {t("notification-last-modified-label")}: &nbsp;
                  {moment(orderData?.updated_date).format("DD/MM/YYYY hh:mm A")}
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <Iconify
                    icon="eva:clock-outline"
                    sx={{ mr: 0.5, width: 16, height: 16 }}
                  />
                  {t("notification-created-on-label")}: &nbsp;
                  {moment(orderData?.created_date).format("DD/MM/YYYY hh:mm A")}
                </Stack>
              </Stack>
            </Typography>
          }
        />
      </Stack>
      {orderData?.is_online && !orderData?.is_online_accepted && (
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-end"
          justifyContent={"flex-end"}
          sx={{ flexGrow: 1 }}
        >
           <Stack direction={"column"}>
            <Stack direction={"row"} gap={1}>
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
              Last Modified: &nbsp;
              {moment(orderData?.updated_date).format(
                          "DD/MM/YYYY hh:mm A",
                        )}
            </Stack>
            <Stack direction={"row"} gap={1}>
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
              Created On: &nbsp;
              {moment(orderData?.created_date).format(
                          "DD/MM/YYYY hh:mm A",
                        )}
            </Stack>
            </Stack>
    </Stack>)
}
    {
      orderData?.is_online && orderData?.is_online_accepted === "CREATED" &&
      <Stack direction="row" spacing={1} alignItems="flex-end"  justifyContent={"flex-end"} sx={{ flexGrow: 1 }}>
      <Button
       onClick={() => acceptOnlineOrder(orderData?.id)}
      size="small" sx={{textTransform: 'initial'}} variant="contained" endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}>
      Accept
      </Button>
      <Button
      size="small"
      onClick={() => declineOnlineOrder(orderData?.id)}
      style={{marginRight: '16px', textTransform: 'initial'}} variant="contained" color="error" endIcon={<Iconify icon={'eva:close-circle-fill'} />}>
          Decline
      </Button>
  </Stack>
    }
   
    <hr/>
</>);
}

// ----------------------------------------------------------------------

function RenderContent(orderData) {
  const { t } = useTranslation();
  const title = (
    <Typography variant="subtitle2">
      {
        orderData?.name_of_customer + " - " + orderData?.phone
      }
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {orderData?.is_online_accepted === "ACCEPTED" ? `order accepted of ₹ ${parseFloat(orderData?.total_bill)}` : orderData?.is_online_accepted === "CREATED" && `placed a new order of  ₹ ${parseFloat(orderData?.total_bill)}`}
      </Typography>
    </Typography>
  );

  if (orderData?.order_type === "ONLINE") {
    return {
      avatar: (
        <img
          alt={orderData?.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (orderData?.order_type === "PARCEL") {
    return {
      avatar: (
        <img
          alt={orderData?.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (orderData?.order_type === "DINE_IN") {
    return {
      avatar: (
        <img
          alt={orderData?.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    avatar: orderData?.avatar ? (
      <img alt={orderData?.title} src={orderData?.avatar} />
    ) : null,
    title,
  };
}

import React from 'react';
import { Grid, Container, Stack, Card } from '@mui/material';
import Page from '../../components/Page';

export default function HelpVideo() {
    return (
        <Page title="Help Video- Servofeat">
            <Container>
                <h5>Help Videos</h5>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} src="https://www.youtube.com/embed/1Jwcyrjr-qU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/kfWyUKo5fTc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/TkoniiktuCk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} src="https://www.youtube.com/embed/Yij_qVmb2Is" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/o3Or1EefUNk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/aD-3Adl3nzU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} src="https://www.youtube.com/embed/0li5Wy40pkY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>

                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <Stack sx={{ padding: '10px' }}>
                                <iframe style={{ height: '250px', width: '100%' }} width="560" height="315" src="https://www.youtube.com/embed/-1hkXY7CZ8E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

import React from "react";
import { styled } from '@mui/material/styles';
import { Modal } from "react-bootstrap";
const PREFIX = 'PopUpModel';

const classes = {
  modelHeader: `${PREFIX}-modelHeader`
};

const Root = styled('div')({
  [`& .${classes.modelHeader}`]: {
    padding: 0,
    position: "sticky!important",
    bottom: 0,
    background: "#fff!important"
  },
});

function PopUpModel(props) {

  return (
    <Root>
      <Modal
        {...props}
        size={props?.size ? props?.size : "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className={classes.modelHeader} closeButton>
          <Modal.Title as={"h5"} id="contained-modal-title-vcenter">
            {props.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>{props.body}</Modal.Body>
        {
          props.footer &&  <Modal.Footer style={{ 
              justifyContent: "center",
              position: "sticky",
              bottom: 0,
              background: "#fff"
             }}>
          {props.footer}
        </Modal.Footer>
        }
       
      </Modal>
    </Root>
  );
}

export default PopUpModel;
